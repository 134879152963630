import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class SubscriptionController extends BaseController {
  static async create(subscriptionData) {
    try {
      return await this.axiosPost(
        endpoint.SUBSCRIPTION_CREATE,
        subscriptionData,
        {
          headers: this.getHeaders(),
        }
      );
    } catch (error) {
      return error;
    }
  }
}
