import React, { useState, useEffect, useRef } from 'react';
import FileUploadSvgIcon from '../../../admin/Icon/FileUploadSvgIcon';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { connect } from 'react-redux';
import { deleteImageUsedCar, uploadMultipleImage } from '../../../../store/actions';
import { toast } from 'react-toastify';
import { deleteImageCarSuccess } from '../../../../store/actions/UsedCarAction';
import Loader from '../../Loader/Loader';

const MultipleImgUpdate = (props) => {

  const {
    deleteImageUsedCar, formik, id, imageSources, isDisabled = false,
    carImgloading,
    carImgerror,
    carImgsuccess,
    uploadMultipleImage,
    carMultipleImgloading,
    carMultipleImgerror,
    carMultipleImgData,
    deleteImageCarSuccess
  } = props
  const [preview, setPreview] = useState([]);
  const [draggedCount, setDraggedCount] = useState(0);
  const [deleteUrl, setDeleteUrl] = useState('')
  const dragItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    deleteImageCarSuccess({})
    if(preview?.length > 0 ){
      formik.setFieldValue('images', preview);
    }
  }, [preview?.length,draggedCount]);

  const handleFileChange = (e) => {

    const imageFiles = Array.from(e.target.files);

    uploadMultipleImage({images: imageFiles})

  };

  
  useEffect(() => {
    if (carImgsuccess) {
      toast.success(carImgsuccess, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          removeImgFromUiAfterDelete()
          
        },
        onClose: () => {
          // setShowTable(true);
          
        },
      });
    }

    if (carImgerror) {
      toast.error(carImgerror, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          removeImgFromUiAfterDelete()
        }
      });
    }
  }, [carImgsuccess, carImgerror]);

  const removeImgFromUiAfterDelete = () => {
    const previewFilter = preview?.filter((item) => item?.image !== deleteUrl)

    setPreview(previewFilter)
    setDeleteUrl('')

  }
  const imgDeleteHandler = (itemData) => {
    setDeleteUrl(itemData)
    const imgUrl = {url: itemData}
    deleteImageUsedCar(imgUrl);
  }

  useEffect(() => {
    setPreview(imageSources)
  }, [imageSources?.length])
  
  useEffect(() => {
    if(carMultipleImgData?.length > 0){
      setPreview([...preview, ...carMultipleImgData])
    }
  },[carMultipleImgData?.length])

 
  const handleDrag = () => {
    let previwImgArray = [...preview];
    const draggedItemContent = previwImgArray.splice(dragItem.current, 1)[0];
    previwImgArray.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;
    
    setPreview(previwImgArray)
    setTimeout(() => setDraggedCount((prev) => prev + 1), 500)
  }

  console.log('preview ====>>', preview)
  return (
   <div className='w-full'>
       <div
          id="FileUpload"
          className="relative mb-15 block w-full cursor-pointer border-2 border-dashed border-stroke bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
        >
      
      {
        carMultipleImgloading ?  <Loader /> : <> <input
        type="file"
        id={id}
        name={id}
        // value={formik.values[id]}
        disabled={isDisabled ? true : false}
        onBlur={formik.handleBlur}
        accept="image/*"
        className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
        onChange={handleFileChange}
        multiple={true}
      />
      <div className="flex flex-col items-center justify-center space-y-3">
       
        <span className="flex h-10 w-10 items-center justify-center border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
             <FileUploadSvgIcon />
       </span>
 
         {
           !isDisabled && <p>
           <span className="text-primary">Click to upload</span> or drag and drop
         </p>
         }
         {
           !isDisabled && <><p className="my-1">PNG, JPG, JPEG </p>
           {/* {brand && <p className="">Image size should be 105 x 105 pixels.</p>} */}
           {formik.touched.image && formik.errors.image && (
             <p className="text-sm text-[#F87171] mt-1">{formik.errors.image}</p>
           )}</>
         }
         
       </div>
       </>
      }
      </div>
      
      {
      (  preview?.length > 0 && Array.isArray(preview) )&&
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            {
              preview?.map((item, index) => (
                <div

                draggable
                onDragStart={(e) => (dragItem.current = index)}
                onDragEnter={(e) => (dragOverItem.current = index)}
                onDragEnd={handleDrag}
                onDragOver={(e) => e.preventDefault()}

                className="w-full h-40 flex items-center justify-center shadow-4 relative cursor-pointer group">
              
                {
                  deleteUrl == item?.image ? <div className='h-[50px] w-[50px] absolute m-auto left-0 right-0 top-0 bottom-0   z-50  duration-200 transition-all'><Loader/></div> : <RiDeleteBin6Line
                  onClick={() => imgDeleteHandler(item?.image)}
                  size={30}
                  className={`absolute m-auto left-0 right-0 top-0 bottom-0   z-50 invisible group-hover:visible cursor-pointer duration-200 transition-all`}
              />
                }
                <img
                    src={item?.image}
                    alt={`Cover `}
                    className="grid_img cursor-pointer group-hover:opacity-50 bg-blend-luminosity transition-all duration-50 rounded-lg z-10  shadow-4"
                    // onError={handleImageError}
                />
            
                </div>
              ))
            }
        </div>
      }
   </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carImgloading: state.usedCar.carImgloading,
    carImgerror:   state.usedCar.carImgerror,
    carImgsuccess: state.usedCar.carImgsuccess,

    carMultipleImgloading: state.usedCar.carMultipleImgloading,
    carMultipleImgerror:   state.usedCar.carMultipleImgerror,
    carMultipleImgData: state.usedCar.carMultipleImgData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteImageUsedCar: (imgUrl) => dispatch(deleteImageUsedCar(imgUrl)),
    uploadMultipleImage: (imageData) => dispatch(uploadMultipleImage(imageData)),
    deleteImageCarSuccess: (data) => dispatch(deleteImageCarSuccess(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MultipleImgUpdate);
