import React, { useState } from 'react';
import './FAQ.css';
import { Accordion } from '../../common/Accordion/Accordion';

const FAQ = ({accordionData, title}) => {
  const [selectedKey, setSelectedKey] = useState(0)

  const accordionClickHandler = (key) => {
    if(key == selectedKey){
      setSelectedKey(0)
    }
    else{
      setSelectedKey(key)
    }
  }
  return (
    <div className="container mx-auto my-20 ">
      <div>
        <p className="font-bold text-center text-3xl mt-16 mb-10">
          {title}
        </p>
      </div>

          {
            <div className=''>
                {
                  accordionData?.map((item) => (
                    <Accordion
                    accordionKey={item?.accordionKey}
                    buttonTitle={item?.title}
                    content={item?.content}
                    accordionClickHandler={accordionClickHandler} 
                    selectedKey={selectedKey}/>
                  ))
                }
            </div>
          }
  
    </div>
  );
};

export default FAQ;
