import * as yup from 'yup';

const UsedCarSchema = yup.object().shape({
	price: yup.number().typeError('Asking Price must be a valid number').nullable(true),
	mileage: yup.string().required('Mileage is required'),
	desc: yup.string().required('Description is required'),
	is_enquiry: yup.boolean(),
	fuel_type_id: yup.string().nullable(true),
	remarks: yup.string().nullable(true),
	status: yup.string().required('Status is required'),
	fuel_type_id: yup.string().required('Fuel type is required'),
	vehicle_type_id: yup.string().nullable(true),
	engine_capacity: yup.number().typeError('Engine Capacity must be a valid number').nullable(true),
	power: yup.number().typeError('Power must be a valid number').nullable(true),
	depreciation: yup.number().typeError('Depreciation must be a valid number').nullable(true),
	dereg_value: yup.number().typeError('Deregistration Value must be a valid number').nullable(true),
	coe: yup.string().required('COE is required'),
	omv: yup.number().typeError('OMV must be a valid number').nullable(true),
	arf: yup.number().typeError('ARF must be a valid number').nullable(true),
	no_of_owner: yup.string().required('Number of Owners is required'),
	registration_date: yup.string().nullable(true),
	original_registration_date: yup.string().nullable(true),
	engine_number: yup.string().nullable(true),

});

export default UsedCarSchema;
