import ShortlistedController from '../../api/ShortlistedController';
import * as actionTypes from './actionTypes';

export const shortlistedInit = () => {
  return {
    type: actionTypes.SHORTLISTED_INIT,
  };
};

export const fetchShortlistedListSuccess = (shortlist) => {
  return {
    type: actionTypes.FETCH_SHORTLISTED_SUCCESS,
    payload: shortlist,
  };
};

export const fetchShortlistedListFailure = (error) => {
  return {
    type: actionTypes.FETCH_SHORTLISTED_FAILURE,
    payload: error,
  };
};

export const fetchShortListedList = (page, type) => {
  return async (dispatch) => {
    dispatch(shortlistedInit());
    ShortlistedController.list(page, type)
      .then((result) => {
        if (result instanceof Error) {
          dispatch(fetchShortlistedListFailure('Something went wrong'));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchShortlistedListSuccess(result.data.data));
        } else {
          dispatch(fetchShortlistedListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchShortlistedListFailure('Something went wrong'));
      });
  };
};
