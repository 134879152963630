import React from 'react';

const HowItWorks = () => {
  return (
    <div className='container mx-auto p-4'>
        <h4 className='text-[26px] md:text-[36px] font-black'>How It Works?</h4>
        <div className='flex flex-row justify-start items-center mt-7'>
            <div className='bg-[#0534FF] w-[60px] h-[60px] md:w-[85px] md:h-[85px]  rounded-full flex flex-row justify-center items-center'>
                <p className='font-bold  text-[40px] md:text-[50px] text-white'>1</p>
            </div>
            <p className='font-semibold text-[14px] md:text-[20px] ms-7'>Provide your <br />Vehicle Details</p>
        </div>

        <div className='flex flex-row justify-start items-center mt-7'>
            <div className='bg-[#0534FF] w-[60px] h-[60px] md:w-[85px] md:h-[85px]  rounded-full flex flex-row justify-center items-center'>
                <p className='font-bold text-[40px] md:text-[50px] text-white'>2</p>
            </div>
            <p className='font-semibold  text-[14px] md:text-[20px] ms-7'>Our Trusted Partners <br/> will contact you</p>
        </div>

        <div className='flex flex-row justify-start items-center mt-7'>
            <div className='bg-[#0534FF] w-[60px] h-[60px] md:w-[85px] md:h-[85px] rounded-full flex flex-row justify-center items-center'>
                <p className='font-bold text-[40px] md:text-[50px] text-white'>3</p>
            </div>
            <p className='font-semibold text-[14px] md:text-[20px] ms-7'>Your Car SOLD!</p>
        </div>
    </div>
  );
};

export default HowItWorks;
