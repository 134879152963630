import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { createSubscription } from '../../../store/actions';
import { useNavigate } from 'react-router-dom';

const SendMeGreatCarOffers = (props) => {
  const navigate = useNavigate();
  const { error, success, createSubscription, subscription } = props;
  const [email, setEmail] = useState('');
  console.log(subscription, success);
  const handlePayment = (e) => {
    e.preventDefault();
    console.log(email);
    if (email) {
      createSubscription({ email });
      setEmail('');
    } else {
      toast.error('Email is required', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {},
        onClose: () => {},
      });
    }
    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  return (
    <form>
      <div className="container m-auto w-fit my-20 p-3">
        <p className="font-bold text-3xl text-center">
          Send me great car offers <br /> and the latest vehicle reviews.
        </p>
        <div className="my-5  ">
          <div className=" ">
            <label htmlFor="">Your email</label>
          </div>
          <div className="md:flex   items-center gap-15 md:gap-5">
            <input
              placeholder="e.g name@example.com"
              type="text"
              id="email"
              name="email"
              value={email}
              className="w-80 p-1.5 mr-2 my-2 border border-[#666666] border-gray-300 focus:outline-none "
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              className="border-[#0534FF]  border text-[#0534FF] px-3 font-bold py-1.5"
              onClick={handlePayment}
            >
              Sign up
            </button>
          </div>
          <div className=" my-4">
            By signing up, you agree to receive marketing emails <br /> in
            accordance with our{' '}
            <span className="text-[#0534FF] cursor-pointer">
              privacy notice
            </span>
            . You can unsubscribe at any time.
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.subscription.error,
    success: state.subscription.success,
    subscription: state.subscription.subscriptionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSubscription: (data) => dispatch(createSubscription(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendMeGreatCarOffers);
