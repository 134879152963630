import React from 'react';

import { GrPrevious, GrNext } from 'react-icons/gr';
import { Car } from '../../../assets/images/svgImages';
import { useNavigate } from 'react-router';
const BrowseVehicleTypes = ({ vehicleTypeData }) => {
  console.log(vehicleTypeData);

  const navigate = useNavigate();
  const searchHandler = (value) => {
    console.log('value', value);
    navigate(
      `/searched_used_cars?brand_id=${''}&model_id=${''}&year_range=${
        value.year_range || ''
      }&price_range=${''}&vehicle_id=${value || ''}`
    );
  };
  return (
    <div className="container mx-auto  mb-5  mt-[500px] lg:mt-8 my-12 ">
      <div>
        <p className="font-bold text-center text-3xl mt-16 mb-10">
          Browse by vehicle types
        </p>
      </div>
      <div className="grid md:grid-cols-4 sm:grid-cols-2 lg:grid-cols-6 grid-cols-1 md:gap-x-22 lg:gap-x-22 gap-x-3 gap-y-2 px-1 my-2">
        {vehicleTypeData.map((car, index) => (
          <div
            key={index}
            className="flex items-center justify-between gap-4 border px-2 border-[#D9D9D9] md:border-none lg:border-none md:flex-col lg:flex-col md:items-start cursor-pointer"
            onClick={() => searchHandler(car.value)}
          >
            {car.image !== '' ? (
              <img
                src={`${car?.image}`}
                alt={car.label}
                className="car-image"
              />
            ) : (
              <Car />
            )}

            <p className="font-bold my-0">{car.label}</p>
          </div>
        ))}
      </div>{' '}
      <div className="lg:hidden md:hidden flex items-center justify-center gap-6 w-full">
        <div className="border border-[#D9D9D9] p-2">
          <GrPrevious />
        </div>
        <div className=" px-2.5 py-1">1</div>
        <div className=" px-2.5 py-1">2</div>
        <div className=" px-2.5 py-1">3</div>
        <div className=" px-2.5 py-1">4</div>
        <div className=" px-2.5 py-1">5</div>
        <div className=" px-2.5 py-1">6</div>
        <div className="border border-[#D9D9D9] p-2">
          <GrNext />
        </div>
      </div>
    </div>
  );
};

export default BrowseVehicleTypes;
