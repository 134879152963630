import React, { useEffect, useState } from 'react';
import Layout from '../../../components/site/Layout/Base/Layout';
import ProductHeaderSlider from '../../../components/site/ProductComponents/ProductHeaderSlider';
import BackAndShareBar from '../../../components/site/ProductComponents/BackAndShareBar';
import ProductPriceAndContactInfo from '../../../components/site/ProductComponents/ProductPriceAndContactInfo';
import InterestTable from '../../../components/site/ProductComponents/InterestTable';
import MoreAndNewCar from '../../../components/site/ProductComponents/MoreAndNewCar';
import UsedCarInformation from '../../../components/site/ProductComponents/UsedCarInformation';
import UsedCarLoanCalculator from '../../../components/site/ProductComponents/UsedCarLoanCalculator';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	fetchUsedCarSingle,
	merchantCarClickCount,
	fetchSuggestedUsedCarList,
	enquiryCountUsedCarSeller
} from '../../../store/actions';
import { buildQueryString } from '../../../helper/buildQueryString'
import { usedCarShortlistSaveSuccess } from '../../../store/actions/UsedCarAction';
import Loader from '../../../components/common/Loader/Loader';

const UsedCarProduct = (props) => {
	const { id } = useParams();

	const [searchParam, setSearchParam] = useState({ model_id: '' });
	const [imageList, setImageList] = useState([])


	const {
		loading,
		error,
		success,
		fetchUsedCarSingle,
		singleUsedCar,
		merchantCarClickCount,
		fetchSuggestedUsedCarList,
		suggestedUsedCar,
		enquiryCountUsedCarSeller,
		usedCarShortlistSaveSuccess
	} = props;

	const fetchData = async (id) => {
		await Promise.all([
			merchantCarClickCount(id),
			fetchUsedCarSingle(id, 'details')
		]);
	};

	useEffect(() => {
		fetchData(id);
	}, [id]);


	useEffect(() => {
		if (singleUsedCar !== null && singleUsedCar !== undefined) {
			const newSearchParam = { ...searchParam };
			// console.log(singleUsedCar.model_id);
			newSearchParam.model_id = singleUsedCar.model_id;
			setSearchParam(newSearchParam);
			setImageList(JSON.parse(singleUsedCar?.images))
		}
	}, [singleUsedCar])

	useEffect(() => {
		if (searchParam.model_id !== '') {
			const queryString = buildQueryString(searchParam);
			fetchSuggestedUsedCarList(queryString);
		}
	}, [searchParam]);

	const sellerEnqueryCountHandler = (sellerData) => {

		enquiryCountUsedCarSeller(sellerData?.seller?.id)

		// Phone number or WhatsApp ID (replace with your contact)
		const predefinedMessage = encodeURIComponent("Hi, I would like to find out more on your car listing " + window.location.href);
		const phoneNumber = sellerData?.seller?.user?.user_details?.phone || '+8801672699678';

		// Check if the user is using a mobile device
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

		// Construct the WhatsApp URL
		// const whatsappURL = isMobile
		// 	? `whatsapp://send?phone=${phoneNumber}`
		// 	: `https://web.whatsapp.com/send?phone=${phoneNumber}`;

		const whatsappURL = isMobile
			? `https://wa.me/${phoneNumber}?text=${predefinedMessage}`
			: `https://wa.me/${phoneNumber}?text=${predefinedMessage}`;
  

		// Redirect to the WhatsApp URL
		// window.location.href = whatsappURL;
		window.open(whatsappURL, '_blank');

	}

	useEffect(() => { usedCarShortlistSaveSuccess({}) }, [])

	const renderContent = () => {
		// console.log(singleUsedCar);
		if (singleUsedCar !== null && Object.keys(singleUsedCar).length > 0) {
			return (
				<>
					<BackAndShareBar carDetails={singleUsedCar} />
					<div className="lg:container lg:mx-auto md:container md:mx-auto  lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col px-0 md:px-4 lg:px-4">
						<ProductHeaderSlider carDetails={singleUsedCar} carImgList={imageList} />
						<ProductPriceAndContactInfo carDetails={singleUsedCar} from="usedCar" />
					</div>
					<div className="w-full  lg:mx-auto md:container md:mx-auto   px-0 lg:px-4 ">
						<UsedCarInformation sellerEnqueryCountHandler={sellerEnqueryCountHandler} carDetails={singleUsedCar} />
					</div>
					<div className="w-full lg:container lg:mx-auto md:container md:mx-auto   px-2 lg:px-4 mt-4">
						<div className="w-full lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col  border border-solid border-[#D9D9D9]">
							<UsedCarLoanCalculator />
							<InterestTable />
						</div>
					</div>

					<MoreAndNewCar moreCarsData={singleUsedCar?.more_cars} suggestedUsedCar={suggestedUsedCar} />
				</>
			);
		}

		return (
			<div className="lg:container lg:mx-auto md:container md:mx-auto  lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col px-0 md:px-4 lg:px-4">
				<Loader />
			</div>
		)
	};


	return (
		<Layout>
			{renderContent()}
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.usedCar.loading,
		error: state.usedCar.error,
		singleUsedCar: state.usedCar.singleUsedCar,
		suggestedUsedCar: state.usedCar.suggestedUsedCar,

	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUsedCarSingle: (id, isPublic) =>
			dispatch(fetchUsedCarSingle(id, isPublic)),
		merchantCarClickCount: (id) => dispatch(merchantCarClickCount(id)),
		fetchSuggestedUsedCarList: (searchParam) =>
			dispatch(fetchSuggestedUsedCarList(searchParam)),
		enquiryCountUsedCarSeller: (id) =>
			dispatch(enquiryCountUsedCarSeller(id)),
		usedCarShortlistSaveSuccess: ({ }) =>
			dispatch(usedCarShortlistSaveSuccess({})),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsedCarProduct);