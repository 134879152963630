import { useFormik } from 'formik';
import React, { useEffect } from 'react';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import { options, promoType } from '../../../constants';
import PromoCodeSchema from '../../../schemas/PromoCodeSchema';

const PromoCodesForm = (props) => {
  const { initialValues, onSubmit } = props;

  let initVal = {
    type: '',
    code: '',
    discount_amount: '',
    expiration_date: '',
    status: '',
  };
  const formik = useFormik({
    initialValues: initialValues || initVal,
    validationSchema: PromoCodeSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialValues || initVal);
  }, [initialValues]);

  return (
    <AdminForm formik={formik} onBtnCancelClick={props.onCancel}>
      <div className="p-6.5">
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="select"
              label={'Type'}
              id={'type'}
              options={promoType}
              formik={formik}
            />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField type="text" label={'Code'} id={'code'} formik={formik} />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField
              type="number"
              label={'Discount Amount'}
              id={'discount_amount'}
              formik={formik}
            />
          </div>
        </div>{' '}
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="date"
              label={'Expiration Date'}
              id={'expiration_date'}
              formik={formik}
            />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="select"
              label="Status"
              id="status"
              options={options}
              formik={formik}
            />
          </div>
        </div>
      </div>
    </AdminForm>
  );
};

export default PromoCodesForm;
