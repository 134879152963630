import * as actionTypes from '../actions/actionTypes';

const initialState = {
  promoList: [],
  singlePromo: null,
  siteSinglePromo: null,
  loading: false,
  error: null,
  success: null,
};

const PromoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROMO_CODE_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case actionTypes.FETCH_PROMO_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        promoList: action.payload,
        error: null,
      };

    case actionTypes.CREATE_PROMO_CODE_FAILURE:
    case actionTypes.FETCH_PROMO_CODE_FAILURE:
    case actionTypes.UPDATE_PROMO_CODE_FAILURE:
    case actionTypes.FETCH_PROMO_CODE_SINGLE_FAILURE:
    case actionTypes.CHECK_PROMO_CODE_VALIDATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_PROMO_CODE_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        singlePromo: action.payload,
        error: null,
      };
    case actionTypes.CHECK_PROMO_CODE_VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        siteSinglePromo: action.payload,
        error: null,
        success: action.payload,
      };
    case actionTypes.CREATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        promoList: state.promoList,
        error: null,
        success: action.payload,
      };

    case actionTypes.UPDATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };

    default:
      return state;
  }
};

export default PromoReducer;
