import React from 'react';

const Recommended = ({ img }) => {
  return (
    <div className="border-[#E8E7E6] border  w-full  shadow-lg">
      <img src={img} alt="" className="w-full" />
      <p className="tracking-widest font-bold mx-auto text-center md:text-start lg:text-start p-3">
        Vauxhall Astra Electric (2023 - ) review
      </p>
      <div className="md:w-60 lg:w-60 w-60 p-3">
        <div className="hidden lg:block md:block">
          {' '}
          <p className="text-[#666]">
            Before you buy a Honda HR-V or Honda Vezel, make sure you read this
            article (or watch this video) first!
          </p>
          <p className="my-5">
            <span>CAR Comparison</span> | <span>Today</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Recommended;
