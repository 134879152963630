import React from 'react';

const ActionButton = ({
  row,
  onEdit,
  showViewButton,
  showDeleteButton,
  onDelete,
  onView,
  showBoostBtn,
  onBoost,
  isLoading,
  boostingId,
  notEdit,
}) => {
  return (
    <div className="flex space-x-2">
      {showViewButton && (
        <button
          onClick={() => onView(row?.id)}
          className="flex justify-center rounded bg-secondary py-2 px-6 font-medium text-gray hover:bg-opacity-70"
        >
          View
        </button>
      )}
      {notEdit ? (
        ''
      ) : (
        <button
          onClick={() => onEdit(row.id)}
          className="flex justify-center rounded bg-cyanBg py-2 px-6 font-medium text-gray hover:bg-opacity-70"
        >
          Edit
        </button>
      )}
      {showDeleteButton && (
        <button
          onClick={() => onDelete(row.id)}
          className="flex justify-center rounded bg-danger py-2 px-6 font-medium text-gray hover:bg-opacity-70"
        >
          Delete
        </button>
      )}

      {showBoostBtn && (
        <button
          onClick={() => onBoost(row.id)}
          className={`flex justify-center rounded ${
            row?.is_boost ? 'bg-[#FFC107]' : 'bg-[#FFC107]'
          }  py-2 px-4 shrink-0 text-gray font-medium  hover:bg-opacity-70`}
        >
          {isLoading && row.id == boostingId ? (
            'Loading...'
          ) : (
            <>{row?.is_boost ? 'Boost' : 'Boost'}</>
          )}
        </button>
      )}

      
    </div>
  );
};

export default ActionButton;
