import React from 'react';

const MerchantCarTab = ({ carTabControl, totalUsedCar, carTab }) => {
  return (
    <div className="w-full flex flex-row justify-start items-center px-4 mt-7">
      <div
        className={`${
          carTab == 'used car'
            ? 'border border-solid border-[#0958D9]'
            : 'border border-solid border-[#666666]'
        } w-[150px] h-[32px] flex flex-row justify-center items-center rounded cursor-pointer`}
      >
        <p
          onClick={() => carTabControl('used car')}
          className={`text-[14px] ${
            carTab == 'used car' ? 'text-[#0958D9]' : 'text-[#666666]'
          } cursor-pointer`}
        >
          Used Cars ({totalUsedCar})
        </p>
      </div>
      <div
        className={`w-[150px] h-[32px] flex flex-row justify-center items-center ${
          carTab == 'new car'
            ? 'border border-solid border-[#0958D9]'
            : 'border border-solid border-[#666666]'
        } ms-4 rounded`}
      >
        <p
          onClick={() => carTabControl('new car')}
          className={`text-[14px] ${
            carTab == 'new car' ? 'text-[#0958D9]' : 'text-[#666666]'
          } cursor-pointer`}
        >
          New Cars (0)
        </p>
      </div>
    </div>
  );
};

export default MerchantCarTab;
