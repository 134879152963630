import BaseController from './BaseController';
import { endpoint } from './EndPoint';
export default class ShortlistedController extends BaseController {
  static async list(page, type) {
    try {
      return await this.axiosGet(
        `${endpoint.SHORTLISTED_USER_CAR_LIST}?type=${type}&page=${page}`,
        {
          headers: this.getHeaders(),
        }
      );
    } catch (error) {
      return error;
    }
  }
  catch(error) {
    return error;
  }
}
