import * as actionTypes from '../actions/actionTypes';

const initialState = {
	newCarList: [],
	loading: false,
	error: null,
	success: null,
	singleNewCar: {},
	publicSingleNewCar: {},
	searchNewCarList: []
};

const newCarReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.NEW_CAR_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
				// usedCarList:[]
			};
		case actionTypes.FETCH_NEW_CAR_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				newCarList: action.payload,
				error: null,
				// success: null,
			};
		case actionTypes.FETCH_NEW_CAR_LIST_FAILURE: 
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.CREATE_NEW_CAR_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				newCarList: state.newCarList,
				error: null,
				success: action.payload,
			};
		case actionTypes.FETCH_NEW_CAR_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleNewCar: action.payload,
				error: null,
			};
		case actionTypes.FETCH_PUBLIC_NEW_CAR_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				publicSingleNewCar: action.payload,
				error: null,
			};
		case actionTypes.FETCH_USED_CAR_LIST_FAILURE:
		case actionTypes.CREATE_NEW_CAR_SINGLE_FAILURE:
		case actionTypes.UPDATE_NEW_CAR_FAILURE:
		case actionTypes.FETCH_NEW_CAR_SEARCH_LIST_FAILURE:
		case actionTypes.FETCH_PUBLIC_NEW_CAR_SINGLE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.UPDATE_NEW_CAR_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		case actionTypes.FETCH_NEW_CAR_SEARCH_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				searchNewCarList: action.payload,
				error: null,
				// success: null,
			};
		default:
			return state;
	}
};
 
export default newCarReducer;
