import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Layout from '../../../components/admin/Layout/Base/Layout';
import BrandForm from './BrandForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import BrandTable from '../../../components/admin/Table/Table';
import { fetchBrandList, createBrand, deleteBrand, fetchBrandSingle, updateBrand } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';



const Brands = (props) => {
	const { loading, error, success, brandList, fetchBrandList, createBrand, deleteBrand, fetchBrandSingle, singleBrand, updateBrand } = props;

	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1)

	useEffect(() => {
		setShowTable(true)
	}, [brandList])

	useEffect(() => {
		fetchBrandList(page);
	}, [fetchBrandList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchBrandList(page);
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id)
			setShowTable(false)
			setButtonLabel('List')
			fetchBrandSingle(id)
		} else if (action === 'delete') {
			deleteBrand(id);
		}
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null)
	};

	const onPageChange = (page) => {
		setPage(page)
	};

	const onSubmit = (values) => {
		console.log(values)
		if (editingId) {
			updateBrand(editingId, values)
		} else {
			createBrand(values);
		}
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row.status ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Feature',
			selector: row => row.is_feature ? 'Featured' : 'Not Featured',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					showDeleteButton={true}
					onDelete={id => handleEditDelete(id, 'delete')}
				/>
			),
			sortable: false
		},
	];

	const renderContent = () => {
		if (loading) {
			return <Loader />
		}

		if (showTable && !loading) {
			return <BrandTable  defaultPage={page} columns={columns} data={brandList.rows == undefined ? [] : brandList.rows} totalCount={brandList.count} handlePageChange={onPageChange} />
		}

		if (editingId && !loading) {
			return <BrandForm onSubmit={onSubmit} initialValues={singleBrand} onCancel={handleButtonClick} />;
		}

		if (!loading) {
			return <BrandForm onSubmit={onSubmit} onCancel={handleButtonClick} />;
		}

	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Brands" buttonText={buttonLabel} onButtonClick={handleButtonClick} />
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.brand.loading,
		error: state.brand.error,
		success: state.brand.success,
		brandList: state.brand.brandList,
		singleBrand: state.brand.singleBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchBrandList: (page) => dispatch(fetchBrandList(page)),
		createBrand: (data) => dispatch(createBrand(data)),
		deleteBrand: (id) => dispatch(deleteBrand(id)),
		fetchBrandSingle: (id) => dispatch(fetchBrandSingle(id)),
		updateBrand: (id, data) => dispatch(updateBrand(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
// export default connect(mapStateToProps,mapDispatchToProp)(withErrorHandler(ContactData,axios))
