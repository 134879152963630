// actions.js
import NewCarListingController from '../../api/NewCarsController';
import * as actionTypes from './actionTypes';

export const newCarInit = () => {
  return {
    type: actionTypes.NEW_CAR_INIT,
  };
};

export const fetchNewCarListSuccess = (cars) => {
  return {
    type: actionTypes.FETCH_NEW_CAR_LIST_SUCCESS,
    payload: cars,
  };
};

export const fetchNewCarListFailure = (error) => {
  return {
    type: actionTypes.FETCH_NEW_CAR_LIST_FAILURE,
    payload: error,
  };
};

export const createNewCarSuccess = (message) => {
  return {
    type: actionTypes.CREATE_NEW_CAR_SINGLE_SUCCESS,
    payload: message,
  };
};

export const createNewCarFailure = (error) => {
  return {
    type: actionTypes.CREATE_NEW_CAR_SINGLE_FAILURE,
    payload: error,
  };
};

export const fetchNewCarSingleSuccess = (car) => {
  return {
    type: actionTypes.FETCH_NEW_CAR_SINGLE_SUCCESS,
    payload: car,
  };
};

export const fetchNewCarSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_NEW_CAR_SINGLE_FAILURE,
    payload: error,
  };
};

export const updateNewCarSuccess = (brand) => {
  return {
    type: actionTypes.UPDATE_NEW_CAR_SUCCESS,
    payload: brand,
  };
};

export const updateNewCarFailure = (error) => {
  return {
    type: actionTypes.UPDATE_NEW_CAR_FAILURE,
    payload: error,
  };
};

export const fetchNewCarSearchListSuccess = (cars) => {
  return {
    type: actionTypes.FETCH_NEW_CAR_SEARCH_LIST_SUCCESS,
    payload: cars,
  };
};

export const fetchNewCarSearchListFailure = (error) => {
  return {
    type: actionTypes.FETCH_NEW_CAR_SEARCH_LIST_FAILURE,
    payload: error,
  };
};
export const fetchPublicNewCarSingleSuccess = (car) => {
  return {
    type: actionTypes.FETCH_PUBLIC_NEW_CAR_SINGLE_SUCCESS,
    payload: car,
  };
};

export const fetchPublicNewCarSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_PUBLIC_NEW_CAR_SINGLE_FAILURE,
    payload: error,
  };
};

export const fetchNewCarList = (page) => {
  return async (dispatch) => {
    dispatch(newCarInit());
    NewCarListingController.newcarlist(page)
      .then((result) => {
        // console.log('New Cars Result >>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchNewCarListFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          // console.log('Resutl ==>', result.data.data);
          dispatch(fetchNewCarListSuccess(result.data.data));
        } else {
          dispatch(fetchNewCarListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchNewCarListFailure('Something went wrong'));
      });
  };
};

export const createNewCar = (data, from) => {
  // console.log('Data ===>>>', data)
  return async (dispatch) => {
    dispatch(newCarInit());
    let formData = new FormData();

    if (data.user_id != null) {
      formData.append('user_id', data.user_id);
    }
    formData.append('ads_type_id', data.ads_type_id);
    // if (data.ads_on_id !== '') {
    formData.append('ads_on_id', data.ads_on_id);
    // }
    if (data.model_id != '') {
      formData.append('model_id', data.model_id);
    }
    if (data.submodel?.length > 0) {
      formData.append('submodel', data.submodel);
    }
    if (data.brand_id != '') {
      formData.append('brand_id', data.brand_id);
    }

    if (data.promotions != null) {
      formData.append('promotions', data.promotions);      
    }
    if (data.colors?.length > 0) {
      formData.append('colors', data.colors);
    }
    formData.append('built_in', data.built_in);
    formData.append('allow_email', data.allow_email);
    formData.append('desc', data.desc);

    if (data.image && data.image.length > 0) {
      data.image.forEach((file, index) => {
        formData.append('images', file);
      });
    }


    NewCarListingController.create(formData, from)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(createNewCarFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(createNewCarSuccess(result.data.message));
        } else {
          dispatch(createNewCarFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(createNewCarFailure('Something went wrong'));
      });
  };
};

export const fetchNewCarSingle = (carid, isPublic = false) => {
  return async (dispatch) => {
    dispatch(newCarInit());
    NewCarListingController.single(carid, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchNewCarSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          result.data.data.email = result.data.data.user
            ? result.data.data.user?.email
            : '';
          result.data.data.phone = result.data.data.user
            ? result.data.data.user.user_details
              ? result.data.data.user.user_details?.phone
              : ''
            : '';
          result.data.data.name = result.data.data.user
            ? result.data.data.user.user_details
              ? result.data.data.user.user_details?.first_name
              : ''
            : '';
          dispatch(fetchNewCarSingleSuccess(result.data.data));
        } else {
          dispatch(fetchNewCarSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchNewCarSingleFailure('Something went wrong'));
      });
  };
};
export const fetchPublicNewCarSingle = (newCarId, isPublic = true) => {
  return async (dispatch) => {
    dispatch(newCarInit());
    NewCarListingController.publicSingle(newCarId, isPublic)
      .then((result) => {
        // console.log(result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchPublicNewCarSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          // console.log('result.data.data-----------', result.data);
          result.data.data.email = result.data.data.user
            ? result.data.data.user?.email
            : '';
          result.data.data.phone = result.data.data.user
            ? result.data.data.user.user_details
              ? result.data.data.user.user_details?.phone
              : ''
            : '';
          result.data.data.name = result.data.data.user
            ? result.data.data.user.user_details
              ? result.data.data.user.user_details?.first_name
              : ''
            : '';
          dispatch(fetchPublicNewCarSingleSuccess(result.data.data));
        } else {
          dispatch(fetchPublicNewCarSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchPublicNewCarSingleFailure('Something went wrong'));
      });
  };
};

export const updateNewCar = (usedCarId, data, isPublic = false) => {
  return async (dispatch) => {
    dispatch(newCarInit());

    NewCarListingController.update(usedCarId, data, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updateNewCarFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updateNewCarSuccess(result.data.message));
        } else {
          dispatch(updateNewCarFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updateNewCarFailure('Something went wrong'));
      });
  };
};

export const fetchNewCarSearchList = (
  page,
  isProcess = false,
  isPublic = false,
  searchParam = ''
) => {
  return async (dispatch) => {
    dispatch(newCarInit());
    NewCarListingController.list(page, isPublic, searchParam)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchNewCarSearchListFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          // console.log(result.data.data);
          dispatch(fetchNewCarSearchListSuccess(result.data.data));
        } else {
          dispatch(fetchNewCarSearchListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchNewCarSearchListFailure('Something went wrong'));
      });
  };
};


export const enquiryCountNewCar = (usedCarId) => {
  return async (dispatch) => {
    // dispatch(newCarInit());
    NewCarListingController.updateenquiry(usedCarId)
      .then((result) => {
        console.log('Enquiry Called Result ======>>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
        } else if (result.data.status !== 'Failed') {
          console.log('enquiry called failed');
        } else {
          console.log('enquiry called Something went wrong');
        }
      })
      .catch((error) => {
        console.log('enquiry called Something went wrong');
      });
  };
};
