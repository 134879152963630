import React, { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../../../components/common/Layout/Auth/Layout';
import AuthForm from '../../../components/common/AuthForm/AuthForm';
import { useFormik } from 'formik';
import { resetPassword } from '../../../store/actions';
import DynamicFormFields from '../../../components/common/FormFileds/Auth/DynamicFormFields';
import { toast } from 'react-toastify';

const ResetPassword = (props) => {
	const { loading, error, success } = props;

	useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {},
        onClose: () => {
          // setShowTable(true)
          // setButtonLabel('Add')
        },
      });
			setTimeout(() => {
				window.location.href = '/login';
			}, 5000)
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  const resetPasswordHandler = (values) => {
		const currentUrl = window.location.href;
		let token = currentUrl.split('=')[1];
    if (values['new-password'] !== values['confirm-password']) {
      toast.error('Password is not matching', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
			let data = {
				token: token,
				password: values['new-password']
			};
      props.resetPassword(data);
    }
  };

  const formik = useFormik({
    initialValues: { email: '' },
    // validationSchema: LoginSchema,
    onSubmit: resetPasswordHandler,
  });
  const fields = [
    { name: 'new-password', label: 'New Password', type: 'password' },
    {
      name: 'confirm-password',
      label: 'Confirm New Password',
      type: 'password',
    },
  ];

  return (
    <Layout>
      <div className="w-full lg:w-3/4 p-6">
        <AuthForm
          formik={formik}
          // loading={props.loading}
          // error={props.error}
          formBlock={<DynamicFormFields formik={formik} fields={fields} />}
          buttonText="Reset password"
          additionalText=" "
          additionalLink="/login"
          // registerButton="Return to login"
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
    redirectTo: state.auth.redirectTo,
    isAuth: state.auth.isAuthenticated,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    resetPassword: (data) => dispatch(resetPassword(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(ResetPassword);
