import PaymentController from '../../api/PaymentController';
import * as actionTypes from './actionTypes';

export const paymentInit = () => {
	return {
		type: actionTypes.PAYMENT_INIT,
	};
};

export const fetchPaymentListSuccess = (payments) => {
	return {
		type: actionTypes.FETCH_USED_CAR_LIST_SUCCESS,
		payload: payments,
	};
};

export const fetchPromoCodeOrderCountFailure = (error) => {
	return {
		type: actionTypes.FETCH_PROMO_CODE_COUNT_LIST_FAILURE,
		payload: error,
	};
};

export const fetchPromoCodeOrderCountSuccess = (payments) => {
	return {
		type: actionTypes.FETCH_PROMO_CODE_COUNT_LIST_SUCCESS,
		payload: payments,
	};
};

export const fetchOrderListByPromoIdFailure = (error) => {
	return {
		type: actionTypes.FETCH_ORDER_LIST_BY_PROMO_CODE_FAILURE,
		payload: error,
	};
};

export const fetchOrderListByPromoIdSuccess = (payments) => {
	return {
		type: actionTypes.FETCH_ORDER_LIST_BY_PROMO_CODE_SUCCESS,
		payload: payments,
	};
};

export const fetchPaymentList = (page) => {

	return async (dispatch) => {
		dispatch(paymentInit());
		PaymentController.getpaymentlist(page)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response
						? result.response.data
							? result.response.data.message
							: 'Something went wrong'
						: 'Something went wrong';
					console.log('payment list Errmsg >>>', errorMsg);
				} else if (result.data.status !== 'Failed') {

					dispatch(fetchPaymentListSuccess(result.data.data)
					);
				} else {
					console.log('payment list Something went wrong');
				}
			})
			.catch((error) => {
				console.log('payment list Something went wrong');
			});
	};
};


export const paymentStatusUpdate = (paymentId, statusData) => {
	return async (dispatch) => {
		dispatch(paymentInit());
		PaymentController.paymentstatusupdate(paymentId, statusData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						console.log('payment status respnse >>>', result.response)
					} else {
						errorMsg = 'Something went wrong';
					}
					// console.log('payment status error >>>', result)
					// return result.data
				} else if (result.data.status !== 'Failed') {
					// console.log('payment status success >>>', result.data)
					// return result.data
				} else {
					console.log('Something went wrong 1')
				}
			})
			.catch((error) => {
				console.log('Something went wrong 2')
			});

	};
};

export const fetchPromoCodeWiseOrderList = (page, searchParam) => {
	return async (dispatch) => {
		dispatch(paymentInit());
		PaymentController.getPromoCodeWiseOrderList(page, searchParam)
			.then((result) => {
				if (result instanceof Error) {
					dispatch(fetchPromoCodeOrderCountFailure('Something went wrong'));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchPromoCodeOrderCountSuccess(result.data.data));
				} else {
					dispatch(fetchPromoCodeOrderCountFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchPromoCodeOrderCountFailure('Something went wrong'));
			});
	};
}


export const fetchOrderListByPromoCodeId = (page, promoId, searchParam) => {
	return async (dispatch) => {
		dispatch(paymentInit());
		PaymentController.getOrderListByPromoCodeId(page, promoId, searchParam)
			.then((result) => {
				if (result instanceof Error) {
					dispatch(fetchPromoCodeOrderCountFailure('Something went wrong'));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchOrderListByPromoIdSuccess(result.data.data));
				} else {
					dispatch(fetchPromoCodeOrderCountFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchPromoCodeOrderCountFailure('Something went wrong'));
			});

	};
};
