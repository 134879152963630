import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AdminForm from '../../AdminForm/AdminForm';
import FormField from '../../../common/FormFileds/Admin/FormField';
const Measurements = (props) => {
  const { formik } = props;

  return (
    <div className="p-6.5">
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Seating Capacity"
            id="seating_capacity"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Wheelbase (mm)"
            id="wheelbase"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Min. Turning Radius (mm)"
            id="turning_radius"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Dimensions Length (mm)"
            id="dimension_length"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Dimensions Width (mm)"
            placeholder=" "
            id="dimension_width"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Dimensions Height (mm)"
            id="dimension_height"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>

      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Kerb Weight (kg)"
            id="kerb_weight"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            placeholder=" "
            label="Fuel Tank Capacity (L)"
            id="tank_capacity"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Boot/Cargo Capacity (L)"
            id="cargo_capacity"
            placeholder=" "
            formik={formik}
          />
        </div>
      </div>
    </div>
  );
};

export default Measurements;
