import React from 'react';
import car2 from '../../../assets/images/web/Used _Cars_Page_Featured_Deals.png';

const FeaturedDeals = () => {
  return (
    <div className="container mx-auto my-10">
      <div className="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-2 gap-3 bg-gray-3 ">
        <div className="p-12 px-2 mx-auto">
          <p className="font-bold text-2xl my-3">Featured Deals</p>
          <p className="text-[#666666] w-80 ">
            Want to buy a used car at a huge discount?Suzuki is giving up to 50%
            discount for various model. The deal will end on 31 Aug 2023. So
            quick!{' '}
          </p>
          <button className="px-5 py-1 my-4 text-[#0534FF] border border-[#0534FF]">
            Find out more
          </button>
        </div>
        <img src={car2} alt="" className="w-full h-full" />
      </div>
    </div>
  );
};

export default FeaturedDeals;
