import React from 'react'
import NewCarForm from '../../admin/NewCar/CreateNewCarForm';

const NewCarPostForm = (props) => {
  const {
    onCancel,
		onSubmit,
		brandList,
		carModelList,
    userList,
		carSubModelList,
		colorList,
		selectedColors, setSelectedColors,
		selectedSubModels, setSelectedSubModels,
		countryList,
    isloading,
    userData
  } = props
  return (
    <NewCarForm 
      onSubmit={onSubmit}
      brandList={brandList}
      carModelList={carModelList ?? []}
      carSubModelList={carSubModelList ?? []}
      colorList={colorList}
      userList={userList}
      selectedColors={selectedColors}
      setSelectedColors={setSelectedColors}
      selectedSubModels={selectedSubModels} 
      setSelectedSubModels={setSelectedSubModels}
      countryList={countryList}
      onCancel={onCancel}
      from="site"
      isloading={isloading}
      userData={userData}
    />
  )
}

export default NewCarPostForm;