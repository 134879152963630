// customerReducer.js
import * as actionTypes from "../actions/actionTypes";

const initialState = {
	loading: false,
	customerList: [],
	singleCustomer: null,
	error: null,
	success: null,
};

const customerReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CUSTOMER_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};

		case actionTypes.FETCH_CUSTOMER_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				customerList: action.payload,
				error: null,
			};

		case actionTypes.FETCH_CUSTOMER_LIST_FAILURE:
			return {
				...state,
				loading: false,
				customerList: [],
				error: action.payload,
			};

		case actionTypes.FETCH_CUSTOMER_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleCustomer: action.payload,
				error: null,
			};

		case actionTypes.FETCH_CUSTOMER_SINGLE_FAILURE:
			return {
				...state,
				loading: false,
				singleCustomer: null,
				error: action.payload,
			};

		case actionTypes.CREATE_CUSTOMER_SUCCESS:
		case actionTypes.UPDATE_CUSTOMER_SUCCESS:
			return {
				...state,
				loading: false,
				success: action.payload,
				error: null,
			};

		case actionTypes.UPDATE_CUSTOMER_FAILURE:
		case actionTypes.CREATE_CUSTOMER_FAILURE:
			return {
				...state,
				loading: false,
				success: null,
				error: action.payload,
			};

		default:
			return state;
	}
};

export default customerReducer;
