import * as yup from 'yup';

const AdminUserSchema = yup.object().shape({
	username: yup.string().required('Name is required'),
	email: yup.string().email('Invalid email address').required('Email is required'),
	contact_no: yup.string().required('Phone number is required').min(8, 'Phone number must be at least 8 characters'),
	role_id: yup.string().required('Role is required'),
	status: yup.string().required('Status is required'),
});

export default AdminUserSchema;
