import * as actionTypes from '../actions/actionTypes';

const initialState = {
	loading: false,
	error: null,
	success: null,
	paymentList: {},
	PaymentListPromoCodeWise: {},
	OrderListByPromoCode: {}
};

const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PAYMENT_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
				PaymentListPromoCodeWise: {},
				OrderListByPromoCode: {}
			};
		case actionTypes.FETCH_USED_CAR_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				paymentList: action.payload,
			};
		case actionTypes.FETCH_PROMO_CODE_COUNT_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				PaymentListPromoCodeWise: action.payload,
			};
		case actionTypes.FETCH_ORDER_LIST_BY_PROMO_CODE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				OrderListByPromoCode: action.payload,
			};
		case actionTypes.FETCH_PROMO_CODE_COUNT_LIST_FAILURE:
		case actionTypes.FETCH_ORDER_LIST_BY_PROMO_CODE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default paymentReducer;
