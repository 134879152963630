import moment from 'moment';
import React, {useState, useEffect} from 'react';
import { isURL } from '../../../helper/isUrlCheck';

const NewCars = ({ car, img, carClickHandler }) => {

	const [imagePath, setImagePath] = useState(img)



	useEffect(() => {
		if (car?.images !== undefined) {
			const images = JSON.parse(car?.images)
			let imgVal = images.length > 0 ? images[0].image : img
			setImagePath(imgVal)
		}

	}, [car])

	return (
		<div onClick={() => carClickHandler(car?.id)} className='border-[#E8E7E6] px-2 pt-2 pb-5 border w-full md:each-slide-effect shadow-4 h-[455px] lg:h-[445px] 2xl:h-[470px] 3xl:h-[570px] cursor-pointer'>
			<img
				src={imagePath}
				alt=""
				className="w-full "
			/>

			<div className="px-3 w-full">
				<div className="flex justify-between items-center border-[#E8E7E6]  border-b w-full">
					<div className=" my-3">
						<div className="flex justify-start items-center text-sm">
							<p className="font-bold mx-auto  text-xl">
								{' '}
								${car?.price ? car?.price : ''}
							</p>
						</div>
						<p className="">Price</p>
					</div>
				</div>{' '}
				<div className="my-2 ">
					<p className="font-bold text-xl ">
						{car?.car_model?.name || ''}
					</p>
					<p className="h-10 mt-3">Vauxhall Corsa 1.2 GS 5dr</p>
					<p className="">
						COE till{' '}
						{car?.coe_expiry ? moment(car?.coe_expiry).format('MM/YYYY') : '-'}
					</p>
				</div>

			</div>
		</div>
	);
};

export default NewCars;
