import BaseController from './BaseController';
import { endpoint } from "./EndPoint";

export default class CountryController extends BaseController {
	static async list(page) {
		try {
			const pageQueryParam = page ? `?page=${page}` : '';
			return await this.axiosGet(`${endpoint.COUNTRY_LIST}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(countryId) {
		try {
			return await this.axiosGet(`${endpoint.COUNTRY_SINGLE}/${countryId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(countryData) {
		try {
			return await this.axiosPost(endpoint.COUNTRY_CREATE, countryData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(countryId, updatedCountryData) {
		try {
			return await this.axiosPost(`${endpoint.COUNTRY_UPDATE}/${countryId}`, updatedCountryData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async delete(countryId) {
		try {
			return await this.axiosGet(`${endpoint.COUNTRY_DELETE}/${countryId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
