import React from 'react';
import DynamicFormFields from '../../common/FormFileds/Auth/DynamicFormFields';

const IndividualRegistrationFormFields = (props) => {
	const fields = [
		{ name: 'name', label: 'Name', type: 'text' },
		{ name: 'username', label: 'Username', type: 'text' },
		{ name: 'contact_no', label: 'Contact No', type: 'text' },
		{ name: 'email', label: 'Email', type: 'text' },
		{ name: 'password', label: 'Password', type: 'password' },
	];

	return <DynamicFormFields formik={props.formik} fields={fields} />

};

export default IndividualRegistrationFormFields;
