import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  fetchVehicleTypePublicList,
  fetchUsedCarList,
  fetchBrandPublicList,
  fetchCarModelList,
  fetchNewCarSearchList,
  register,
  fetchPublicSettingSingle,
} from '../../../store/actions';

import Layout from './../../../components/site/Layout/Base/Layout';
import CarSlide from '../../../components/site/CarSlide/CarSlide';

import BrowseByBrand from '../../../components/site/BrowseByBrand/BrowseByBrand';
import PostAnadvert from '../../../components/site/PostAnadvert/PostAnadvert';
import RightNowInCarInc from '../../../components/site/RightNowInCarInc/RightNowInCarInc';
import LatestFromCarInc from '../../../components/site/LatestFromCarInc/LatestFromCarInc';
import FollowOnSocialMedia from '../../../components/site/FollowOnSocialMedia/FollowOnSocialMedia';
import HeroSection from '../../../components/site/HeroSection/HeroSection';

import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader/Loader';
import newsData from './../../../news.json'; 

const Home = (props) => {
  const navigate = useNavigate();
  const {
    usedCarloading,
    brandLoading,
    newCarLoading,
    settingLoading,
    error,
    success,
    vehicleTypePublicList,
    usedCarList,
    fetchVehicleTypePublicList,
    fetchUsedCarList,
    fetchBrandPublicList,
    brandPublicList,
    fetchCarModelList,
    carModelList,
    fetchNewCarSearchList,
    searchNewCarList,
    isAuth,
    redirectTo,
    register,
    authLoading,
    authSuccess,
    autError,
    publicSetting,
    fetchPublicSettingSingle
  } = props;
  // console.log(searchNewCarList);
  const fetchData = async () => {
    await Promise.all([
      fetchVehicleTypePublicList(),
      fetchBrandPublicList('all'),
      fetchUsedCarList(1, false, 'latest'),
      fetchCarModelList('all', true, true),
      // fetchNewCarSearchList(1, false, 'buyNewCar'),
      // fetchPublicSettingSingle()
    ]);
  };
  const searchHandler = (value, isNewCar) => {
    const basePath = isNewCar ? '/searched_new_cars' : '/searched_used_cars';
    navigate(
      `${basePath}?brand_id=${value.brand_id || ''}&model_id=${value.model_id || ''
      }&year_range=${value.year_range || ''}&price_range=${value.price_range || ''
      }&vehicle_id=${value.vehicle_id || ''}`
    );
  };

  const formSubmitHandler = (values) => {
    register(values, 'merchant');
  };

  useEffect(() => {
    if (!usedCarList?.rows || !searchNewCarList.rows || brandPublicList?.length == 0 || !brandPublicList) {
      fetchData();
    }
  }, [true]);

  return (
    <>
      {
        (usedCarloading ||
          brandLoading ||
          newCarLoading ||
          settingLoading) ? <div className='h-screen w-full bg-white flex flex-row justify-center items-center'>
          <Loader />
        </div>
          :
          <Layout>
            <div className="relative">
              <HeroSection
                vehicleData={vehicleTypePublicList}
                brandList={brandPublicList}
                carModelList={carModelList}
                onSearch={(value) => searchHandler(value, false)}
              />
              {usedCarList.rows && usedCarList.rows.length > 0 && (
                <CarSlide
                  carList={usedCarList?.rows}
                  headText={'Buy a used car'}
                  onSearch={searchHandler}
                />
              )}
              {/* {searchNewCarList.rows && searchNewCarList.rows.length > 0 && (
                <CarSlide
                  carList={searchNewCarList?.rows}
                  headText={'Buy a brand new car'}
                  onSearch={(value) => searchHandler(value, true)}
                  from="newCar"
                  newCar
                />
              )} */}

              {/* <BuyBrandNewCar /> */}
              {brandPublicList.length > 0 && (
                <BrowseByBrand brandList={brandPublicList} />
              )}
              <PostAnadvert
                isAuth={isAuth}
                redirectTo={redirectTo}
                submitHandler={(val) => formSubmitHandler(val)}
                authLoading={authLoading}
                authSuccess={authSuccess}
                autError={autError}
              />
              <RightNowInCarInc />
              <LatestFromCarInc news={newsData}/>
              <FollowOnSocialMedia />
            </div>
          </Layout>
      }

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    usedCarloading: state.usedCar.loading,
    brandLoading: state.brand.loading,
    newCarLoading: state.newcars.loading,
    settingLoading: state.setting.loading,

    error: state.usedCar.error,
    success: state.usedCar.success,
    isAuth: state.auth.isAuthenticated,
    redirectTo: state.auth.redirectTo,
    vehicleTypePublicList: state.vehicleType.vehicleTypePublicList,
    usedCarList: state.usedCar.usedCarList,
    brandPublicList: state.brand.brandPublicList,
    carModelList: state.carModel.carModelList,
    searchNewCarList: state.newcars.searchNewCarList,
    publicSetting: state.setting.publicSetting,

    authLoading: state.auth.loading,
    autError: state.auth.error,
    authSuccess: state.auth.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVehicleTypePublicList: () => dispatch(fetchVehicleTypePublicList()),
    fetchUsedCarList: (page, isProcess, isPublic) =>
      dispatch(fetchUsedCarList(page, isProcess, isPublic)),
    fetchBrandPublicList: (page) => dispatch(fetchBrandPublicList(page)),
    fetchCarModelList: (page, isProcess, isPublic) =>
      dispatch(fetchCarModelList(page, isProcess, isPublic)),
    // fetchNewCarSearchList: (page, isProcess, isPublic) => dispatch(fetchNewCarSearchList(page, isProcess, isPublic)),
    register: (data, type) => dispatch(register(data, type)),
    // fetchPublicSettingSingle: () => dispatch(fetchPublicSettingSingle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
