import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AdminForm from '../../AdminForm/AdminForm';
import FormField from '../../../common/FormFileds/Admin/FormField';

const SafetyAndDrivingAssurance = (props) => {
  const { formik } = props;

  return (
    <div className="p-6.5">
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="No. of Airbags"
            id="no_of_airbags"
            formik={formik}
            placeholder=" "
          />
          {/* <FormField
						type="checkbox"
						label="Bluetooth"
						id="bluetooth"
						placeholder=" "
						formik={formik}
					/> */}
        </div>

        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Isofix"
            id="isofix"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Tyre Pressure Monitor"
            placeholder=" "
            id="tyre_presure_monitor"
            formik={formik}
          />
        </div>
        {/* <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Heads-up Display"
            placeholder=" "
            id="heads_up"
            formik={formik}
          />
        </div> */}
      </div>

      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Electric Park Brake"
            id="electric_park_brake"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Hill Start Assist"
            id="hill_start_assist"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Emergency Brake Assist"
            id="emergency_brake_assist"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>

      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Parking Sensors"
            id="parking_sensors"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Park Assist"
            placeholder=" "
            id="park_assist"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Reverse Camera"
            placeholder=" "
            id="reverse_camera"
            formik={formik}
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="360 Degrees View Camera"
            id="view_camera"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Cruise Control"
            placeholder=" "
            id="cruise_control"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Lane Departure Warning"
            placeholder=" "
            id="lane_departure_warning"
            formik={formik}
          />
        </div>
      </div>
      <div className="w-full xl:w-1/2">
        <FormField
          type="checkbox"
          label="Blind Spot Monitor"
          placeholder=" "
          id="blind_spot_monitor"
          formik={formik}
        />
      </div>
    </div>
  );
};

export default SafetyAndDrivingAssurance;
