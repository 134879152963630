import React from 'react';
import placeholderImage from '../../../assets/images/car_slider_placeholder.png';
import NewCars from '../CarsComponents/NewCars';
// import {
// 	MdKeyboardArrowLeft,
// 	MdKeyboardArrowRight,
// } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const MarchantNewAndUsedCars = ({ carData, loadmoreCarHandler, totalUsedCar }) => {
  const navigate = useNavigate();

  const merchantProfileNavigate = (id) => {
    navigate(`/used-car-product/${id}`);
  };
  return (
    <div className='w-full px-4'>
      {
        carData?.length > 0 ?
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-7'>

            {carData?.map((car, index) => (
              <NewCars carClickHandler={merchantProfileNavigate} car={car} img={placeholderImage} key={index} />
            ))}
          </div> : <h4 className='mt-4 text-[20px] font-bold text-gray-500'>New Cars Comming Soon.</h4>
      }

      {
        carData?.length < totalUsedCar && <div className=''>
          <div className="flex justify-center items-center my-8">

            <button onClick={loadmoreCarHandler} className='bg-[#0958D9] text-white text-[20px] h-[46px] w-[230px] rounded'>
              Load More
            </button>
            {/* <MdKeyboardArrowLeft className="p-1" size={30} />
            <div className="flex justify-start gap-5 items-center opacity-50">
              <p className="font-semibold px-3 my-2 text-[#2A65BA]">1</p>
              <p className="font-semibold px-3 my-2 text-[#2A65BA]">2</p>
              <p className="font-semibold px-3 my-2 text-[#2A65BA]">3</p>
              <p className="font-semibold px-3 my-2 text-[#2A65BA]">4</p>
              <p className="font-semibold px-3 my-2 text-[#2A65BA]">5</p>
              <p className="font-semibold px-3 my-2 text-[#2A65BA]">6</p>
            </div>
            <MdKeyboardArrowRight className="p-1" size={30} /> */}
          </div>
        </div>
      }
    </div>
  );
};

export default MarchantNewAndUsedCars;
