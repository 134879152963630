// CarSubModelReducer.js
import * as actionTypes from '../actions/actionTypes';

const initialState = {
	carSubModelPublicList: [],
	carSubModelList: [],
	singleCarSubModel: null,
	loading: false,
	error: null,
	success: null,
};

const carSubModelReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CAR_SUB_MODEL_INIT:
			return {
				...state,
				loading: false,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_CAR_SUB_MODEL_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				carSubModelList: action.payload,
				error: null,
			};
		case actionTypes.FETCH_CAR_SUB_MODEL_LIST_FAILURE:
		case actionTypes.FETCH_CAR_SUB_MODEL_SINGLE_FAILURE:
		case actionTypes.CREATE_CAR_SUB_MODEL_FAILURE:
		case actionTypes.UPDATE_CAR_SUB_MODEL_FAILURE:
		case actionTypes.DELETE_CAR_SUB_MODEL_FAILURE:
		case actionTypes.FETCH_CAR_SUB_MODEL_PUBLIC_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				success: null
			};
		case actionTypes.FETCH_CAR_SUB_MODEL_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleCarSubModel: action.payload,
				error: null,
			};

		case actionTypes.FETCH_CAR_SUB_MODEL_PUBLIC_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				carSubModelPublicList: action.payload,
				error: null,
			};
		case actionTypes.CREATE_CAR_SUB_MODEL_SUCCESS:
			return {
				...state,
				loading: false,
				carSubModelList: state.carSubModelList,
				error: null,
				success: action.payload,
			};
		case actionTypes.UPDATE_CAR_SUB_MODEL_SUCCESS:
		case actionTypes.DELETE_CAR_SUB_MODEL_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		default:
			return state;
	}
};

export default carSubModelReducer;
