import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../assets/images/admin/admin_logo.png';
import { fetchPublicSettingSingle } from '../../../store/actions';
import { connect } from 'react-redux';
import logo from '../../../assets/images/web/Car_Inc_Logo.webp';

const SidebarHeader = ({
  sidebarOpen,
  setSidebarOpen,
  trigger,
  fetchPublicSettingSingle,
  publicSetting,
}) => {
  useEffect(() => {
    fetchPublicSettingSingle();
  }, []);
  return (
    <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
      <NavLink to="/">
        <div className={` w-[132px] h-[34.991px] md:mr-12 mx-auto`}>
          {/* <img
                src={logo}
                className={`cursor-pointer lg:order-1 md:order-2 w-[132px] h-[34.991px] md:mr-12 mx-auto `}
              /> */}
          <img
            src={publicSetting?.image ? publicSetting?.image : logo}
            className={`cursor-pointer lg:order-1 md:order-2  rounded-md
              `}
          />
        </div>
      </NavLink>

      <button
        ref={trigger}
        onClick={() => setSidebarOpen(!sidebarOpen)}
        aria-controls="sidebar"
        aria-expanded={sidebarOpen}
        className="block lg:hidden"
      >
        <svg
          className="fill-current"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
            fill=""
          />
        </svg>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    publicSetting: state.setting.publicSetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPublicSettingSingle: () => dispatch(fetchPublicSettingSingle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
