import * as actionTypes from '../actions/actionTypes';

const initialState = {
  siteSingleSetting: null,
  loading: false,
  error: null,
  success: null,
  publicSetting: null,
};

const SiteSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SITE_SETTING_INIT:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    case actionTypes.FETCH_SITE_SETTING_SINGLE_FAILURE:
    case actionTypes.UPDATE_SITE_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.FETCH_SITE_SETTING_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        siteSingleSetting: action.payload,
        error: null,
      };
    case actionTypes.FETCH_PUBLIC_SETTING_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        publicSetting: action.payload,
        error: null,
      };

    case actionTypes.UPDATE_SITE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };

    default:
      return state;
  }
};

export default SiteSettingReducer;
