// actions.js
import * as actionTypes from "./actionTypes";
import VehicleTypeController from "../../api/VehicleTypeController";

export const vehicleTypeInit = () => {
	return {
		type: actionTypes.VEHICLE_TYPE_INIT,
	};
};

export const fetchVehicleTypeListSuccess = (vehicleTypes) => {
	return {
		type: actionTypes.FETCH_VEHICLE_TYPE_LIST_SUCCESS,
		payload: vehicleTypes,
	};
};

export const fetchVehicleTypeListFailure = (error) => {
	return {
		type: actionTypes.FETCH_VEHICLE_TYPE_LIST_FAILURE,
		payload: error,
	};
};

export const fetchVehicleTypePublicListSuccess = (vehicleTypes) => {
	return {
	  type: actionTypes.FETCH_VEHICLE_TYPE_PUBLIC_LIST_SUCCESS,
	  payload: vehicleTypes,
	};
  };
  
  export const fetchVehicleTypePublicListFailure = (error) => {
	return {
	  type: actionTypes.FETCH_VEHICLE_TYPE_PUBLIC_LIST_FAILURE,
	  payload: error,
	};
  };

export const fetchVehicleTypeSingleSuccess = (vehicleType) => {
	return {
		type: actionTypes.FETCH_VEHICLE_TYPE_SINGLE_SUCCESS,
		payload: vehicleType,
	};
};

export const fetchVehicleTypeSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_VEHICLE_TYPE_SINGLE_FAILURE,
		payload: error,
	};
};

export const createVehicleTypeSuccess = (message) => {
	return {
		type: actionTypes.CREATE_VEHICLE_TYPE_SUCCESS,
		payload: message,
	};
};

export const createVehicleTypeFailure = (error) => {
	return {
		type: actionTypes.CREATE_VEHICLE_TYPE_FAILURE,
		payload: error,
	};
};

export const updateVehicleTypeSuccess = (vehicleType) => {
	return {
		type: actionTypes.UPDATE_VEHICLE_TYPE_SUCCESS,
		payload: vehicleType,
	};
};

export const updateVehicleTypeFailure = (error) => {
	return {
		type: actionTypes.UPDATE_VEHICLE_TYPE_FAILURE,
		payload: error,
	};
};

export const deleteVehicleTypeSuccess = (vehicleTypeId) => {
	return {
		type: actionTypes.DELETE_VEHICLE_TYPE_SUCCESS,
		payload: vehicleTypeId,
	}
};

export const deleteVehicleTypeFailure = (error) => {
	return {
		type: actionTypes.DELETE_VEHICLE_TYPE_FAILURE,
		payload: error,
	}
};


export const fetchVehicleTypeList = (page, isProcess = false, isPublic = false, searchParam = '') => {
	return async (dispatch) => {
		dispatch(vehicleTypeInit());
		VehicleTypeController.list(page, isPublic, searchParam)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(createVehicleTypeFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					if (isProcess) {
						const processData = result.data.data.map(item => ({
							value: item.id,
							label: item.name,
							image: item.image
						}));
						dispatch(fetchVehicleTypeListSuccess(processData));
					} else {
						dispatch(fetchVehicleTypeListSuccess(result.data.data));
					}
				} else {
					dispatch(fetchVehicleTypeListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchVehicleTypeListFailure('Something went wrong'));
			});
	};
};

export const fetchVehicleTypePublicList = (page='all') => {

	return async (dispatch) => {
	  dispatch(vehicleTypeInit());
	  VehicleTypeController.publicList(page)
		.then((result) => {
		  if (result instanceof Error) {
			dispatch(fetchVehicleTypePublicListFailure('Something went wrong'));
		  } else if (result.data.status !== 'Failed') {
			const processData = result.data.data.map(item => ({
				value: item.id,
				label: item.name,
				image: item.image
			}));
  
			dispatch(fetchVehicleTypePublicListSuccess(processData));
		  } else {
			dispatch(fetchVehicleTypePublicListFailure('Something went wrong'));
		  }
		})
		.catch((error) => {
		  dispatch(fetchVehicleTypePublicListFailure('Something went wrong'));
		});
	};
  };

export const fetchVehicleTypeSingle = (vehicleTypeId) => {
	return async (dispatch) => {
		dispatch(vehicleTypeInit());
		VehicleTypeController.single(vehicleTypeId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(fetchVehicleTypeSingleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchVehicleTypeSingleSuccess(result.data.data));
				} else {
					dispatch(fetchVehicleTypeSingleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchVehicleTypeSingleFailure('Something went wrong'));
			});
	};
};

export const createVehicleType = (vehicleTypeData) => {
	return async (dispatch) => {
		dispatch(vehicleTypeInit());

		let formData = new FormData();
		formData.append("name", vehicleTypeData.name);
		formData.append("status", vehicleTypeData.status);
		if (vehicleTypeData.image && vehicleTypeData.image.length > 0) {
			vehicleTypeData.image.forEach((file, index) => {
				formData.append("image", file);
			});
		}

		VehicleTypeController.create(formData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message
						} else {
							errorMsg = 'Something went wrong'
						}
					} else {
						errorMsg = 'Something went wrong'
					}
					dispatch(createVehicleTypeFailure(errorMsg))
				} else if (result.data.status !== 'Failed') {
					dispatch(createVehicleTypeSuccess(result.data.message));
				} else {
					dispatch(createVehicleTypeFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(createVehicleTypeFailure('Something went wrong'));
			});

	};
};

export const updateVehicleType = (vehicleTypeId, updatedVehicleTypeData) => {
	return async (dispatch) => {
		dispatch(vehicleTypeInit());

		let formData = new FormData();
		formData.append("name", updatedVehicleTypeData.name);
		formData.append("status", updatedVehicleTypeData.status);
		if (updatedVehicleTypeData.image != undefined && typeof updatedVehicleTypeData.image !== 'string') {
			updatedVehicleTypeData.image.forEach((file, index) => {
				formData.append("image", file);
			});
		}

		VehicleTypeController.update(vehicleTypeId, formData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(updateVehicleTypeFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(updateVehicleTypeSuccess(result.data.message));
				} else {
					dispatch(updateVehicleTypeFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(updateVehicleTypeFailure('Something went wrong'));
			});

	};
};

export const deleteVehicleType = (vehicleTypeId) => {
	return async (dispatch) => {
		dispatch(vehicleTypeInit());
		VehicleTypeController.delete(vehicleTypeId)
			.then(result => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(deleteVehicleTypeFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					fetchVehicleTypeList()
					dispatch(deleteVehicleTypeSuccess(result.data.message));
				} else {
					dispatch(deleteVehicleTypeFailure('Something went wrong'));
				}
			})
			.catch(error => {
				dispatch(deleteVehicleTypeFailure('Something went wrong'));
			})
	};
};

