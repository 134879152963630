// actions.js
import PromoCodesController from '../../api/PromoCodesController';
import * as actionTypes from './actionTypes';

export const promoCodeInit = () => {
  return {
    type: actionTypes.PROMO_CODE_INIT,
  };
};

export const fetchPromoCodeSuccess = (promos) => {
  return {
    type: actionTypes.FETCH_PROMO_CODE_SUCCESS,
    payload: promos,
  };
};

export const fetchPromoCodeFailure = (error) => {
  return {
    type: actionTypes.FETCH_PROMO_CODE_FAILURE,
    payload: error,
  };
};

export const fetchPromoSingleSuccess = (promo) => {
  return {
    type: actionTypes.FETCH_PROMO_CODE_SINGLE_SUCCESS,
    payload: promo,
  };
};

export const fetchPromoSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_PROMO_CODE_SINGLE_FAILURE,
    payload: error,
  };
};
export const fetchPromoValidationSuccess = (promo) => {
  return {
    type: actionTypes.CHECK_PROMO_CODE_VALIDATION_SUCCESS,
    payload: promo,
  };
};

export const fetchPromoValidationFailure = (error) => {
  return {
    type: actionTypes.CHECK_PROMO_CODE_VALIDATION_FAILURE,
    payload: error,
  };
};

export const createPromoCodeSuccess = (message) => {
  return {
    type: actionTypes.CREATE_PROMO_CODE_SUCCESS,
    payload: message,
  };
};

export const createPromoCodeFailure = (error) => {
  return {
    type: actionTypes.CREATE_PROMO_CODE_FAILURE,
    payload: error,
  };
};

export const updatePromoSuccess = (promo) => {
  return {
    type: actionTypes.UPDATE_PROMO_CODE_SUCCESS,
    payload: promo,
  };
};

export const updatePromoFailure = (error) => {
  return {
    type: actionTypes.UPDATE_PROMO_CODE_FAILURE,
    payload: error,
  };
};

export const fetchPromoCodeList = (
  page,
  isProcess = false,
  isPublic = false,
  searchParam = ''
) => {
  return async (dispatch) => {
    dispatch(promoCodeInit());
    PromoCodesController.list(page, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(createPromoCodeFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          console.log(result.data);
          if (isProcess) {
            const processData = result.data.data.map((item) => ({
              value: item.id,
              type: item.type,
              code: item.code,
              discount_amount: item.discount_amount,
              expiration_date: item.expiration_date,
              status: 0,
            }));
            console.log(processData);
            dispatch(fetchPromoCodeSuccess(processData));
          } else {
            dispatch(fetchPromoCodeSuccess(result.data.data));
          }
        } else {
          dispatch(fetchPromoCodeFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPromoCodeFailure('Something went wrong'));
      });
  };
};

export const fetchPromoSingle = (promoId) => {
  return async (dispatch) => {
    dispatch(promoCodeInit());
    PromoCodesController.single(promoId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchPromoSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          console.log('------------', result.data);
          dispatch(fetchPromoSingleSuccess(result.data.data));
        } else {
          dispatch(fetchPromoSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchPromoSingleFailure('Something went wrong'));
      });
  };
};
export const fetchPromoValidationCheck = (promoCode) => {
  console.log(promoCode);
  return async (dispatch) => {
    dispatch(promoCodeInit());
    PromoCodesController.validationCheck(promoCode)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchPromoValidationFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          if (result.data.data === null) {
            dispatch(fetchPromoValidationFailure('Code is not valid'));
          } else {
            console.log('------------', result.data);
            dispatch(fetchPromoValidationSuccess(result.data.data));
          }
        } else {
          dispatch(fetchPromoValidationFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchPromoValidationFailure('Something went wrong'));
      });
  };
};

export const createPromoCode = (promoData) => {
  console.log(promoData);
  return async (dispatch) => {
    dispatch(promoCodeInit());
    PromoCodesController.create(promoData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(createPromoCodeFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(createPromoCodeSuccess(result.data.message));
        } else {
          dispatch(createPromoCodeFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(createPromoCodeFailure('Something went wrong'));
      });
  };
};

export const updatePromo = (promoId, promoData) => {
  return async (dispatch) => {
    dispatch(promoCodeInit());
    let formData = new FormData();
    formData.append('code', promoData.code);
    formData.append('expiration_date', promoData.discount_amount);
    formData.append('expiration_date', promoData.expiration_date);
    formData.append('status', promoData.status);
    formData.append('type', promoData.type);
    PromoCodesController.update(promoId, promoData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            console.log('formData====================', formData);
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updatePromoFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updatePromoSuccess(result.data.message));
        } else {
          dispatch(updatePromoFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updatePromoFailure('Something went wrong'));
      });
  };
};
