const options = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
];

const approveOptions = [
  { value: 1, label: 'Approved' },
  { value: 0, label: 'Not Approved' },
];

const countryOptions = [{ value: 1, label: 'Singapore' }];

const categoryTypeOptions = [
  { value: 1, label: 'Used Car' },
  { value: 2, label: 'New Car' },
];

const ownerIdTYpeOptions = [
  { value: 1, label: 'Business (e.g. 51234567M)' },
  {
    value: 2,
    label: 'Club / Association / Organisation (e.g. T08PQ1234A)',
  },
  { value: 3, label: 'Company (e.g. 198912345K)' },
  { value: 4, label: 'Foreign Company (e.g. T08FC1234A)' },
  {
    value: 5,
    label: 'Foreign Identification Number (e.g. F/G/M1234567N)',
  },
  { value: 6, label: 'Foreign Passport (e.g. 12345678)' },
  { value: 7, label: 'Government (e.g. T08GA1234A)' },
  {
    value: 8,
    label: 'Limited Liability Partnership (e.g. T08LL1234A)',
  },
  {
    value: 9,
    label: 'Limited Partnership (e.g. T08LP1234A)',
  },
  { value: 10, label: 'Malaysia NRIC (e.g. 200312345678)' },
  // { value: 'Others', label: 'Others' },
  { value: 11, label: 'Professional (e.g. T08PQ1234A)' },
  { value: 12, label: 'Singapore NRIC (e.g. S1234567D)' },
  // { value: 'Singaporean', label: 'Singaporean' },
  // { value: 'Singaporean PR', label: 'Singaporean PR' },
  { value: 13, label: 'Statutory Board (e.g. T08GB1234A)' },
];

const getInstantOfferOwnerIdTypes = [
  { value: 'Business', label: 'Business (e.g. 51234567M)' },
  {
    value: 'Club / Association / Organisation',
    label: 'Club / Association / Organisation (e.g. T08PQ1234A)',
  },
  { value: 'Company', label: 'Company (e.g. 198912345K)' },
  { value: 'Foreign Company', label: 'Foreign Company (e.g. T08FC1234A)' },
  {
    value: 'Foreign Identification Number',
    label: 'Foreign Identification Number (e.g. F/G/M1234567N)',
  },
  { value: 'Foreign Passport', label: 'Foreign Passport (e.g. 12345678)' },
  { value: 'Government', label: 'Government (e.g. T08GA1234A)' },
  {
    value: 'Limited Liability Partnership',
    label: 'Limited Liability Partnership (e.g. T08LL1234A)',
  },
  {
    value: 'Limited Partnership',
    label: 'Limited Partnership (e.g. T08LP1234A)',
  },
  { value: 'Malaysia NRIC', label: 'Malaysia NRIC (e.g. 200312345678)' },
  // { value: 'Others', label: 'Others' },
  { value: 'Professional', label: 'Professional (e.g. T08PQ1234A)' },
  { value: 'Singapore NRIC', label: 'Singapore NRIC (e.g. S1234567D)' },
  // { value: 'Singaporean', label: 'Singaporean' },
  // { value: 'Singaporean PR', label: 'Singaporean PR' },
  { value: 'Statutory Board', label: 'Statutory Board (e.g. T08GB1234A)' },
];

const usedCarStatusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'pending', label: 'pending' },
];

const transmissionTypeOptions = [
  { value: 'auto', label: 'Auto' },
  { value: 'manual', label: 'Manual' },
];

const carPriceRanges = [
  { value: '0-5000', label: 'Up to $5,000' },
  { value: '5001-10000', label: '$5,001 - $10,000' },
  { value: '10001-20000', label: '$10,001 - $20,000' },
  { value: '20001-30000', label: '$20,001 - $30,000' },
  { value: '30001-40000', label: '$30,001 - $40,000' },
  { value: '40001-50000', label: '$40,001 - $50,000' },
  { value: '50001-60000', label: '$50,001 - $60,000' },
  { value: '60001-70000', label: '$60,001 - $70,000' },
  { value: '70001-80000', label: '$70,001 - $80,000' },
  { value: '80001-90000', label: '$80,001 - $90,000' },
  { value: '90001-100000', label: '$90,001 - $100,000' },
  { value: '100001-120000', label: '$100,001 - $120,000' },
  { value: '120001-140000', label: '$120,001 - $140,000' },
  { value: '140001-160000', label: '$140,001 - $160,000' },
  { value: '160001-180000', label: '$160,001 - $180,000' },
  { value: '180001-200000', label: '$180,001 - $200,000' },
  { value: '200001-999999999', label: '$200,001 and above' },
];

const engineCapacityRanges = [
  { value: '0-1000', label: 'Up to 1,000 cc' },
  { value: '1001-1500', label: '1,001 - 1,500 cc' },
  { value: '1501-2000', label: '1,501 - 2,000 cc' },
  { value: '2001-2500', label: '2,001 - 2,500 cc' },
  { value: '2501-3000', label: '2,501 - 3,000 cc' },
  { value: '3001-4000', label: '3,001 - 4,000 cc' },
  { value: '4001-5000', label: '4,001 - 5,000 cc' },
  { value: '5001-6000', label: '5,001 - 6,000 cc' },
  { value: '6001-8000', label: '6,001 - 8,000 cc' },
  { value: '8001-10000', label: '8,001 - 10,000 cc' },
  { value: '10001-99999999', label: '10,001 cc and above' },
];

const mileageRanges = [
  { value: '0-5000', label: 'Up to 5,000 miles' },
  { value: '5001-10000', label: '5,001 - 10,000 miles' },
  { value: '10001-20000', label: '10,001 - 20,000 miles' },
  { value: '20001-30000', label: '20,001 - 30,000 miles' },
  { value: '30001-40000', label: '30,001 - 40,000 miles' },
  { value: '40001-50000', label: '40,001 - 50,000 miles' },
  { value: '50001-75000', label: '50,001 - 75,000 miles' },
  { value: '75001-100000', label: '75,001 - 100,000 miles' },
  { value: '100001-150000', label: '100,001 - 150,000 miles' },
  { value: '150001-200000', label: '150,001 - 200,000 miles' },
  { value: '200001-999999999', label: '200,001 miles and above' },
];

const engineCapacity = [
  { label: '<661cc', value: '661' },
  { label: '661cc - 1,600cc', value: '661-1600' },
  { label: '1,601cc - 2,000cc', value: '1601 - 2000' },
  { label: '2,001cc - 3,000cc', value: '2001 - 3000' },
  { label: '>3,000cc', value: '3000' },
];

const depreciationValue = [
  { value: '0-10000', label: 'Below $10k /yr ' },
  { value: '10001-11000', label: '$10k to $11k /yr ' },
  { value: '11001-12000', label: '$11k to $12k /yr ' },
  { value: '12001-14000', label: '$12k to $14k /yr ' },
  { value: '16000-18000', label: '$16k to $18k /yr ' },
  { value: '20000-25000', label: '$20k to $25k /yr ' },
  { value: '25001-30000', label: '$25k to $30k /yr ' },
  { value: '30001-40000', label: '$30k to $40k /yr ' },
  { value: '40001-50000', label: '$40k to $50k /yr ' },
  { value: '50001-60000', label: '$50k to $60k /yr ' },
  { value: '60001-999999999', label: 'Above $60k /yr ' },
  { value: '0-999999999', label: 'Any ' },
];

const currentYear = new Date().getFullYear();
const years = Array.from(
  { length: currentYear - 1900 + 1 },
  (_, index) => 1900 + index
).reverse();
const yearOptions = years.map((year) => ({
  value: year,
  label: `${year}`,
}));

const carListingTypeOptions = [
  { label: 'All', value: 'All' },
  { label: 'Featured', value: 'Featured' },
];
const activityOptions = [
  { value: '', label: 'Select One' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'pending', label: 'Pending' },
];
const featuredTypeOptions = [
  { value: '', label: 'Select One' },
  { value: 1, label: 'Featured' },
  { value: 0, label: 'Not Featured' },
];

const promoType = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Percentage', value: 'percentage' },
];

export {
  options,
  engineCapacity,
  approveOptions,
  countryOptions,
  categoryTypeOptions,
  ownerIdTYpeOptions,
  usedCarStatusOptions,
  transmissionTypeOptions,
  yearOptions,
  carPriceRanges,
  carListingTypeOptions,
  engineCapacityRanges,
  mileageRanges,
  depreciationValue,
  getInstantOfferOwnerIdTypes,
  promoType,
  featuredTypeOptions,
  activityOptions,
};
