import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import Layout from '../../../components/admin/Layout/Base/Layout';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import AdsTypeTable from '../../../components/admin/Table/Table';
import Loader from '../../../components/common/Loader/Loader';
import {
  fetchAdsTypeList,
  fetchAdsTypeSingle,
  updateAdsType,
} from '../../../store/actions';
import AdsTypeForm from './AdsTypeForm';

const AdsType = (props) => {
  const {
    loading,
    error,
    success,
    adsTypeList,
    fetchAdsTypeList,
    fetchAdsTypeSingle,
    singleAdsType,
    updateAdsType,
  } = props;

  const [showTable, setShowTable] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setShowTable(true);
  }, [adsTypeList]);
  useEffect(() => {
    fetchAdsTypeList(page);
  }, [fetchAdsTypeList, page]);

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          fetchAdsTypeList(page);
          setShowTable(true);
        },
        onClose: () => {},
      });
    }
    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  const handleEditDelete = (id, action) => {
    if (action === 'edit') {
      console.log(id, action);
      setEditingId(id);
      setShowTable(false);
      setButtonLabel('List');
      fetchAdsTypeSingle(id);
    }
  };

  const handleButtonClick = () => {
    setShowTable(!showTable);
    setButtonLabel(showTable && 'List');
    setEditingId(null);
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const onSubmit = (values) => {
    updateAdsType(editingId, values);
  };
  console.log(showTable, loading);
  const columns = [
    {
      name: 'Id',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row) => row?.price,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (row?.status ? 'Active' : 'Inactive'),
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <ActionButton row={row} onEdit={(id) => handleEditDelete(id, 'edit')} />
      ),
      sortable: false,
    },
  ];
  console.log('singleAdsType=================', singleAdsType);
  const renderContent = () => {
    if (showTable && !loading) {
      console.log('1st page');
      return (
        <AdsTypeTable
          key={adsTypeList?.rows}
          defaultPage={page}
          columns={columns}
          data={adsTypeList?.rows == undefined ? [] : adsTypeList?.rows}
          totalCount={adsTypeList?.count}
          handlePageChange={onPageChange}
        />
      );
    }

    if (editingId && !loading) {
      console.log('2nd AdsType');
      return (
        <AdsTypeForm
          onSubmit={onSubmit}
          initialValues={singleAdsType}
          onCancel={handleButtonClick}
        />
      );
    }

    return <Loader />;
  };

  return (
    <Layout>
      <div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
        <div className="flex flex-col gap-1">
          <div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
            <SectionHeader
              title="Ads Type"
              buttonText={buttonLabel}
              onButtonClick={handleButtonClick}
            />
            {renderContent()}
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.adsType.loading,
    error: state.adsType.error,
    success: state.adsType.success,
    adsTypeList: state.adsType.adsTypeList,
    singleAdsType: state.adsType.singleAdsType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAdsTypeList: (page) => dispatch(fetchAdsTypeList(page)),
    fetchAdsTypeSingle: (id) => dispatch(fetchAdsTypeSingle(id)),
    updateAdsType: (id, data) => dispatch(updateAdsType(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsType);
