import React, { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import 'swiper/css';
import BrandBrowse from '../CarsComponents/BrandBrowse';
import { useNavigate } from 'react-router';

const BrowseByBrand = ({ brandList, newCar }) => {

  const [allBrands, setAllBrands] = useState(false);
  const navigate = useNavigate();
  return (
    <div className={`container mx-auto my-2 lg:mt-8 ${newCar && 'mt-125'}`}>
      <div>
        <p className="font-bold text-center text-3xl mt-16 mb-10">
          Browse by brand
        </p>
        <div className=" grid lg:grid-cols-4 grid-cols-1 gap-5 sm:grid-cols-2">
          {brandList
            ?.filter((brand, i) => brand.is_feature === 1 && brand.status === 1)
            .map((brand, i) => (
              <BrandBrowse brand={brand} key={i} newCar={newCar}/>
            ))}
        </div>
        <div>
          <button
            className="flex items-center justify-center gap-3 mb-18 mt-10 w-fit mx-auto  cursor-pointer"
            onClick={() => setAllBrands(true)}
          >
            <FiPlus />
            <span>Show all brands</span>
          </button>
        </div>
        {allBrands && navigate('/brands')}
      </div>
    </div>
  );
};

export default BrowseByBrand;
