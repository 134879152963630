import React from 'react';
import ProductIfoLogo from '../../../assets/images/web/productInfoLogo.png';
import { BsChat } from 'react-icons/bs';
import { TbPhone } from 'react-icons/tb';
import { formatNumberWithCommas } from '../../../helper/formatNumberWithCommas';
import moment from 'moment';
import { enquiryCountUsedCar } from '../../../store/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { depreciationCalc } from '../../../helper/depreciationCalc';
import { enquiryCountNewCar } from '../../../store/actions/NewCarsActions';

const ProductPriceAndContactInfo = (props) => {
  const navigate = useNavigate();
  const { from, carDetails, loading, error, success, enquiryCountUsedCar, enquiryCountNewCar } =
    props;

  const handleWhatsAppRedirect = () => {
    if (from == 'newCar') {
      enquiryCountNewCar(carDetails?.id)
    }
    else {
      enquiryCountUsedCar(carDetails?.id);
    }
    // Phone number or WhatsApp ID (replace with your contact)

    const predefinedMessage = encodeURIComponent("Hi, I would like to find out more on your car listing " + window.location.href);
    const phoneNumber = carDetails?.phone || '+8801672699678';

    // Check if the user is using a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Construct the WhatsApp URL
    const whatsappURL = isMobile
      ? `https://wa.me/${phoneNumber}?text=${predefinedMessage}`
      : `https://wa.me/${phoneNumber}?text=${predefinedMessage}`;

    // Redirect to the WhatsApp URL
    // window.location.href = whatsappURL;
    window.open(whatsappURL, '_blank');
  };

  const merchantProfileNavigate = () => {
    navigate(`/usedcar-merchant-profile/${carDetails?.user_id}`);
  };
  return (
    <div
      className="pl-0 lg:pl-4 sm:0 w-full lg:w-2/5 mt-4 lg:mt-0"
      key={carDetails?.id}
    >
      <div className="w-full p-2 md:p-0 lg:p-0">
        <div className="w-fit">
          {/* {from !== 'usedCar' && (
            <p className="text-[#313C53] text-[14px]">Porsche Singapore</p>
          )} */}
          {from === 'newCar' && (
            <p className="text-black text-sm">
              {carDetails?.user?.user_company?.name ? carDetails?.user?.user_company?.name
                : ''}
            </p>
          )}
          <p className="text-[#313C53] text-[32px]">
            {carDetails?.car_model?.name
              ? carDetails?.car_model?.name
              : 'Porsche Panamera'}
          </p>{' '}

          {from == 'usedCar' && (
            <p className="text-[#313C53] text-[16px]">
              {carDetails?.car_submodel?.key_info?.name} |{' '}
              {carDetails?.registration_date
                ? moment(carDetails?.registration_date).format('YYYY')
                : ''}{' '}
            </p>
          )}
        </div>

        <div className="w-fit my-4">
          <p className="text-[#313C53] text-[32px] font-bold">
            {carDetails?.price &&
              `$ ${formatNumberWithCommas(carDetails?.price)}`}
          </p>
          {from === 'newCar' && (
            <>
              {carDetails?.new_car_submodel?.length > 0 ? (
                <p className="text-[#313C53] text-[32px] font-bold">
                  ${' '}
                  {formatNumberWithCommas(
                    carDetails?.new_car_submodel[0]?.car_submodel?.key_info
                      ?.price
                  )}{' '}
                  {carDetails?.new_car_submodel.length > 1 && (
                    <>
                      - ${' '}
                      {formatNumberWithCommas(
                        carDetails?.new_car_submodel[
                          carDetails.new_car_submodel.length - 1
                        ]?.car_submodel?.key_info?.price
                      )}
                    </>
                  )}
                </p>
              ) : null}
            </>
          )}

          {from == 'usedCar' ? (
            <p className="text-[#313C53] text-[12px]">
              Depreciation:{' '}
              {depreciationCalc(
                carDetails?.is_forFeited,
                carDetails?.price,
                carDetails?.arf,
                carDetails?.coe_expiry
              )
                ? `$ ${depreciationCalc(
                  carDetails?.is_forFeited,
                  carDetails?.price,
                  carDetails?.arf,
                  carDetails?.coe_expiry
                )}`
                : '0'}{' '}
              / yr
            </p>
          ) : (
            <p className="text-[#313C53] text-[12px]">(without COE)</p>
          )}
        </div>

        <div
          onClick={handleWhatsAppRedirect}
          className="bg-[#0534FF] w-full h-[40px] flex flex-row justify-center items-center rounded-sm cursor-pointer "
        >
          <p className="text-white text-[16px] font-bold mr-2">
            Message merchant
          </p>
        </div>
      </div>

      <div className="w-full bg-[#F7F7F5] p-2 md:p-4 lg:p-4 mt-4 h-[370px]">
        <div className="w-full grid grid-cols-3 items-start">
          <p className="font-bold text-base">Merchant</p>
          <div className="text-sm  col-span-2">
            <p
              onClick={merchantProfileNavigate}
              className="text-[#0534FF] text-[16px] font-bold cursor-pointer"
            >
              {carDetails?.user?.user_company?.name
                ? carDetails?.user?.user_company?.name
                : carDetails?.user?.user_details?.first_name
                  ? carDetails?.user?.user_details?.first_name
                  : 'Porsche Singapore'}
            </p>
            <div className="w-full flex flex-row justify-start items-center mt-4">
           
              <div className='w-[70px] h-[70px]'>
                <img
                  className="search_img"
                  src={
                    carDetails?.user?.image
                      ? carDetails?.user?.image
                      : ProductIfoLogo
                  }
                />
              </div>
              <div className="ms-4">
                <div className="w-full flex flex-row justify-start items-center">
                  <TbPhone color="#0534FF" size={24} />
                  <p className="text-[#0534FF] text-[16px] ms-2">
                    {carDetails?.phone ? carDetails?.phone : '6816 9911'}
                  </p>
                </div>
                <div className="w-full flex flex-row justify-start items-center mt-2">
                  <BsChat color="#0534FF" size={24} />
                  <p className="text-[#0534FF] text-[16px] ms-2">Chat</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {from == 'usedCar' ? (
          <div className="w-full">
            <div className="w-full flex flex-row justify-start items-center mt-4">
              <p className="font-bold text-[16px]">PARF Rebate</p>
              <p className="text-[16px]  ms-5 md:ms-17 lg:ms-17">
                {carDetails?.arf
                  ? `$ ${formatNumberWithCommas(carDetails?.arf)}`
                  : ''}
              </p>
            </div>

            <div className="w-full flex flex-row justify-start items-center mt-4">
              <p className="font-bold text-[16px]">Dereg Value</p>
              <p className="text-[16px]  ms-7 md:ms-18 lg:ms-18 ">
                {carDetails?.dereg_value
                  ? `$ ${formatNumberWithCommas(
                    carDetails?.dereg_value
                  )} as of today`
                  : ''}
              </p>
            </div>

            <div className="w-full flex flex-row justify-start items-center mt-4">
              <p className="font-bold text-[16px]">Fuel type</p>
              <p className="text-[16px]  ms-13 md:ms-24 lg:ms-24 ">
                {carDetails?.fuel_type?.name}
              </p>
            </div>

            <div className="w-full flex flex-row justify-start items-center  mt-4">
              <p className="font-bold text-[16px]">Promotion</p>
              <p className="text-[16px]  ms-[42px] md:ms-24 lg:ms-21 ">
                {/* No current promotions. Message seller to check if there is any
                new promotions. */}
                {carDetails?.promotions !== null && carDetails?.promotions !== '' ? carDetails?.promotions : 'No current promotions. Message seller to check if there is any new promotions.'}
              </p>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="w-full grid grid-cols-3 items-center mt-4">
              <p className="font-bold text-base">Built in</p>
              <p className="text-sm  col-span-2 ">
                {carDetails?.built_in_name?.name ? carDetails?.built_in_name?.name : carDetails?.car_model?.country?.name}
              </p>
            </div>

            <div className="w-full grid grid-cols-3 items-center mt-4">
              <p className="font-bold text-base">Predecessor</p>
              <p className="text-sm  col-span-2 ">
                {' '}
                {carDetails?.car_model?.predecessor}
              </p>
            </div>

            <div className="w-full grid grid-cols-3 items-center mt-4">
              <p className="font-bold text-base">Brochure</p>
              <p className="text-[#0534FF] text-sm  col-span-2 underline cursor-pointer">
                Click here
              </p>
            </div>

            <div className="w-full grid grid-cols-3 items-center mt-4">
              <p className="font-bold text-base">Promotion</p>
              <p className="text-sm  col-span-2">
                {/* No current promotions. Message seller to check if there is any
                new promotions. */}
                {carDetails?.promotions !== null && carDetails?.promotions !== '' ? carDetails?.promotions : 'No current promotions. Message seller to check if there is any new promotions.'}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    enquiryCountUsedCar: (id) => dispatch(enquiryCountUsedCar(id)),
    enquiryCountNewCar: (id) => dispatch(enquiryCountNewCar(id)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPriceAndContactInfo);
