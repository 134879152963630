import React from 'react'
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";

export const Accordion = ({buttonTitle, content, selectedKey, accordionKey, accordionClickHandler}) => {
  return (
    <div className="border  border-[#E8E7E6] p-4 mx-2">
        <div className='flex flex-row cursor-pointer items-center' onClick={() => accordionClickHandler(accordionKey)}>
          {
            selectedKey == accordionKey  ? <TiArrowSortedUp size={24} /> : <TiArrowSortedDown size={24} />
          }
            <p className='font-bold ms-2'>{buttonTitle}</p>
        </div>
        {
             selectedKey == accordionKey   && <div className='ps-7 mt-4'>
            <p>{content}</p>
        </div>
        }
        
    </div>
  )
}
