import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import Layout from '../../../components/admin/Layout/Base/Layout';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import PromoTable from '../../../components/admin/Table/Table';
import Loader from '../../../components/common/Loader/Loader';
import {
  createPromoCode,
  fetchPromoCodeList,
  fetchPromoSingle,
  updatePromo,
} from '../../../store/actions';
import PromoCodesForm from './PromoCodesForm';
import moment from 'moment';

const PromoCodes = (props) => {
  const {
    loading,
    error,
    success,
    promoList,
    fetchPromoCodeList,
    createPromoCode,
    fetchPromoSingle,
    singlePromo,
    updatePromo,
  } = props;

  const [showTable, setShowTable] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Add');
  const [editingId, setEditingId] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setShowTable(true);
  }, [promoList]);
  console.log(promoList);
  useEffect(() => {
    fetchPromoCodeList(page);
  }, [fetchPromoCodeList, page]);

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          fetchPromoCodeList(page);
          setShowTable(true);
          setButtonLabel('Add');
        },
        onClose: () => {
          // setShowTable(true)
          // setButtonLabel('Add')
        },
      });
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  const handleEditDelete = (id, action) => {
    if (action === 'edit') {
      setEditingId(id);
      setShowTable(false);
      setButtonLabel('List');
      fetchPromoSingle(id);
    }
  };

  const handleButtonClick = () => {
    setShowTable(!showTable);
    setButtonLabel(showTable ? 'List' : 'Add');
    setEditingId(null);
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const onSubmit = (values) => {
    console.log(values);
    if (editingId) {
      updatePromo(editingId, values);
    } else {
      createPromoCode(values);
    }
  };

  const columns = [
    {
      name: 'Id',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) =>
        row?.type.charAt(0).toUpperCase() + row?.type?.slice(1),
      sortable: true,
    },
    {
      name: 'Code',
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: 'Discount Amount',
      selector: (row) => row.discount_amount,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (row?.status ? 'Active' : 'Inactive'),
      sortable: false,
    },
    {
      name: 'Expiration Date',
      selector: (row) => moment(row.expiration_date).format('DD MMM YY'),
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <ActionButton row={row} onEdit={(id) => handleEditDelete(id, 'edit')} />
      ),
      sortable: false,
    },
  ];

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (showTable && !loading) {
      return (
        <PromoTable
          defaultPage={page}
          columns={columns}
          data={promoList.rows == undefined ? [] : promoList.rows}
          totalCount={promoList.count}
          handlePageChange={onPageChange}
        />
      );
    }

    if (editingId && !loading) {
      return (
        <PromoCodesForm
          onSubmit={onSubmit}
          initialValues={singlePromo}
          onCancel={handleButtonClick}
        />
      );
    }
    if (!loading) {
      return (
        <PromoCodesForm onSubmit={onSubmit} onCancel={handleButtonClick} />
      );
    }
  };

  return (
    <Layout>
      <div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
        <div className="flex flex-col gap-1">
          <div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
            <SectionHeader
              title="Promo Codes"
              buttonText={buttonLabel}
              onButtonClick={handleButtonClick}
            />
            {renderContent()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.promoCodes.loading,
    error: state.promoCodes.error,
    success: state.promoCodes.success,
    promoList: state.promoCodes.promoList,
    singlePromo: state.promoCodes.singlePromo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPromoCodeList: (page) => dispatch(fetchPromoCodeList(page)),
    createPromoCode: (data) => dispatch(createPromoCode(data)),
    fetchPromoSingle: (id) => dispatch(fetchPromoSingle(id)),
    updatePromo: (id, data) => dispatch(updatePromo(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);
// export default connect(mapStateToProps,mapDispatchToProp)(withErrorHandler(ContactData,axios))
