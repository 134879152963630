import React from 'react';
import latest from '../../../assets/images/web/latest.webp';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import LatestCars from '../CarsComponents/LatestCars';
const LatestFromCarInc = ({ news }) => (
	<div className="container mx-auto my-6 mt-15 mb-28">
		<div>
			<p className="font-bold text-center text-3xl my-6">
				The latest from Car Inc
			</p>
		</div>
		<div className="block sm:block md:block lg:hidden xl:hidden p-3">
			<Swiper
				spaceBetween={50}
				slidesPerView={3}
				breakpoints={{
					100: {
						slidesPerView: 1,
					},
					280: {
						slidesPerView: 1,
					},
					400: {
						slidesPerView: 1,
					},
					639: {
						slidesPerView: 2,
					},
					865: {
						slidesPerView: 2,
					},
					1000: {
						slidesPerView: 3,
					},
					1300: {
						slidesPerView: 4,
					},
					1500: {
						slidesPerView: 4,
					},
					1700: {
						slidesPerView: 4,
					},
				}}
				onSlideChange={() => console.log('slide change')}
				onSwiper={(swiper) => console.log(swiper)}
			>
				{news.map(article => (
					<SwiperSlide key={article.id}>
						<LatestCars
							img={article.image}
							title={article.title}
							excerpt={article.excerpt}
							category="CAR News"
							date={article.date}
							link={article.slug}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>

		<div className="md:hidden md:grid-cols-1 lg:grid-cols-3 grid-cols-1  gap-3 hidden sm:hidden  lg:grid xl:grid px-3">
			{news.map(article => (
				<LatestCars
					key={article.id}
					img={article.image}
					title={article.title}
					excerpt={article.excerpt}
					category="CAR News"
					date={article.date}
					link={article.slug}
				/>
			))}
		</div>
	</div>
);

export default LatestFromCarInc;
