import BaseController from './BaseController';
import { endpoint } from "./EndPoint";

export default class PaymentController extends BaseController {
	static async getpaymentlist(page) {
		try {
			return await this.axiosGet(`${endpoint.GET_PAYMENT_LIST}?page=${page}&type=used_car`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async paymentstatusupdate(paymentId, statusData) {
		try {
			return await this.axiosPost(`${endpoint.PAYMENT_STATUS_UPDATE}/${paymentId}`, statusData, {
				headers: this.getHeaders(),
			});
		} catch (error) {
			return error;
		}
	}

	static async getPromoCodeWiseOrderList(page, searchParam) {
		try {
			let pageQueryParam = page ? `?page=${page}` : '';
			let path = endpoint.ORDER_PROMO_COUNTER;

			let headers = this.getHeaders();
			if (searchParam !== '') {
				pageQueryParam =
					searchParam !== ''
						? pageQueryParam + '&' + searchParam
						: pageQueryParam;
			}

			return await this.axiosGet(`${path}${pageQueryParam}`, {headers: headers});
		} catch (error) {
			return error;
		}
	}

	static async getOrderListByPromoCodeId(page, promoId, searchParam) {
		try {
			let pageQueryParam = page ? `?page=${page}` : '';
			let path = `${endpoint.ORDER_LIST_BY_PROMO_CODE_ID}/${promoId}`;

			let headers = this.getHeaders();
			if (searchParam !== '') {
				pageQueryParam =
					searchParam !== ''
						? pageQueryParam + '&' + searchParam
						: pageQueryParam;
			}
			
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: headers });
		} catch (error) {
			return error;
		}
	}
}
