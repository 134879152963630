import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../../components/common/FormFileds/Admin/FormField';
import CustomerSchema from '../../../../schemas/CustomerSchema';
import { options, countryOptions } from '../../../../constants';
import FileUpload from '../../../../components/common/FormFileds/Admin/FileUpload';


const CustomerUserForm = (props) => {
	const { initialValues, onSubmit } = props;

	let initVal = {
		name: '',
		username: '',
		email: '',
		contact_no: '',
		address_one: '',
		address_two: '',
		status: '',
		country_id: '',
		city_id: '',
		postal_code: '',
		image: '',
		isImageRequired: true
	}

	const formik = useFormik({
		initialValues: initialValues || initVal,
		validationSchema: CustomerSchema,
		onSubmit,
	});

	useEffect(() => {
		formik.setValues(initialValues || initVal);
	}, [initialValues]);

	return (
		<AdminForm formik={formik} onBtnCancelClick={props.onCancel}>
			<FileUpload formik={formik} id={"image"} />

			<div className="p-6.5">
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Name" id="name" formik={formik} />
					</div>

					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Username" id="username" formik={formik} />
					</div>

				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Email" id="email" formik={formik} />
					</div>

					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Phone No" id="contact_no" formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
					<div className="w-full">
						<FormField type="text" label="Address Line 1" id="address_one" formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
					<div className="w-full">
						<FormField type="text" label="Address Line 2" id="address_two" formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="select" label="Country" id="country_id" options={countryOptions} formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="City" id="city_id" formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Postal Code" id="postal_code" formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="select" label="Status" id="status" options={options} formik={formik} />
					</div>
				</div>
			</div>
		</AdminForm>
	);
};

export default CustomerUserForm;
