import React, { useState } from 'react'
import Layout from '../../../components/admin/Layout/Base/Layout'
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader'
import PaymentList from './PaymentList'
import EditPayment from './EditPayment'
import { paymentStatusUpdate } from '../../../store/actions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import ViewPayment from './ViewPayment'

const Payment = (props) => {
	const {paymentStatusUpdate} = props
	const [isEdit, setIsEdit] = useState(false);
	const [isView, setIsView] = useState(false);
	const [editItem, setEditItem] = useState({})
	const [paymentStatus, setPaymentStatus] = useState('')
	const [statusErr, setStatusErr] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	
	const paymentViewHandler = (item) => {
		setEditItem(item)
		setIsEdit(false)
		setIsView(true)
	}
	const paymentEditHandler = (item) => {
		setPaymentStatus('')
		setEditItem(item)
		setIsEdit(true)
		setIsView(false)
	}
	const cancelHandler = () => {
		setStatusErr('')
		setEditItem({})
		setIsEdit(false)
		setIsView(false)
	}
	// console.log('paymentStatus >>', paymentStatus)
	const statusSaveHandler = async () => {
		if(paymentStatus){
			setIsLoading(true)
			const paymentStatusObj = {
				status: paymentStatus
			}
			paymentStatusUpdate(editItem?.id, paymentStatusObj);
			setTimeout(() => {
				toast.success('success', {
					position: toast.POSITION.TOP_RIGHT,
					onOpen: () => {
					  setIsLoading(false)
					  cancelHandler()
					},
					onClose: () => {
					},
				  });
			}, 1000)
		}
		else{
			setStatusErr('Select status')
		}
	}


  return (
   <Layout>
		<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
			<div className="flex flex-col gap-1">
				<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
					<SectionHeader
						title={"Payments"}
						buttonText={isView ? 'List' : ''}
						onButtonClick={isView ? cancelHandler : ''}
					/>
					{
						isEdit && <EditPayment isLoading={isLoading} setStatusErr={setStatusErr} statusErr={statusErr} setPaymentStatus={setPaymentStatus} statusSaveHandler={statusSaveHandler} paymentItem={editItem} cancelHandler={cancelHandler}/>
					}
					{
						isView && <ViewPayment paymentItem={editItem} />
					}
					{
						(!isEdit && !isView ) ? <PaymentList paymentViewHandler={paymentViewHandler} paymentEditHandler={paymentEditHandler}/> : ''
					}
				</div>
			</div>
		</div>
   </Layout>
  )
}


const mapStateToProps = (state) => {
	return {
        paymentList: state.payment.paymentList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
        paymentStatusUpdate: (paymentId, paymentStatus) => dispatch(paymentStatusUpdate(paymentId, paymentStatus)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);