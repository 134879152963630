import * as Yup from 'yup';

const CarSearchFilterSchema = Yup.object().shape({
	brand_id: Yup.string(),
	model_id: Yup.string(),
	price_range: Yup.string(),
	year_range: Yup.number().integer('Year Reg must be an integer'),
	vehicle_id: Yup.string(),
	depreciation_range: Yup.string(),
});

export default CarSearchFilterSchema;
