// settingActions.js
import * as actionTypes from "./actionTypes";
import SettingController from "../../api/SettingController";

export const settingInit = () => {
	return {
		type: actionTypes.SETTING_INIT,
	};
};


export const fetchSettingSingleSuccess = (setting) => {
	return {
		type: actionTypes.FETCH_SETTING_SINGLE_SUCCESS,
		payload: setting,
	};
};

export const fetchPublicSettingSuccess = (setting) => {
	return {
		type: actionTypes.FETCH_PUBLIC_SETTING_SINGLE_SUCCESS,
		payload: setting,
	};
};

export const fetchSettingSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_SETTING_SINGLE_FAILURE,
		payload: error,
	};
};

export const updateSettingSuccess = (setting) => {
	return {
		type: actionTypes.UPDATE_SETTING_SUCCESS,
		payload: setting,
	};
};

export const updateSettingFailure = (error) => {
	return {
		type: actionTypes.UPDATE_SETTING_FAILURE,
		payload: error,
	};
};


export const updateSetting = (updatedSettingData) => {
	return async (dispatch) => {
		dispatch(settingInit());

		const addressJson = {
			address_line1: updatedSettingData.address_one,
			address_line2: updatedSettingData.address_two
		};

		let formData = new FormData();

		formData.append("name", updatedSettingData.name);
		formData.append("uen", updatedSettingData.uen);
		formData.append("email", updatedSettingData.email);
		formData.append("address", JSON.stringify(addressJson));
		formData.append("country_id", updatedSettingData.country_id);
		formData.append("postal_code", updatedSettingData.postal_code);
		formData.append("contact_no", updatedSettingData.contact_no);


		formData.append("fb", updatedSettingData.fb);
		formData.append("insta", updatedSettingData.insta);
		formData.append("youtube", updatedSettingData.youtube);
		formData.append("tiktok", updatedSettingData.tiktok);


		if (updatedSettingData.image != undefined && typeof updatedSettingData.image !== 'string') {
			formData.append("image", updatedSettingData.image[0]);
		}

		SettingController.update(formData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = "";
					if (result.response) {
						if (
							result.response.data &&
							!Array.isArray(result.response.data.message)
						) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = "Something went wrong";
						}
					} else {
						errorMsg = "Something went wrong";
					}
					dispatch(updateSettingFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					dispatch(updateSettingSuccess(result.data.message));
				} else {
					dispatch(updateSettingFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(updateSettingFailure("Something went wrong"));
			});
	};
};

export const fetchSettingSingle = () => {
	return async (dispatch) => {
		console.log('hrtrt');
		dispatch(settingInit());
		SettingController.single()
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(fetchSettingSingleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					try {
						let addressObj = JSON.parse(result.data.data.address)
						result.data.data.address_one = addressObj.address_line1
						result.data.data.address_two = addressObj.address_line2
					} catch (error) {
						result.data.data.address_one = ''
						result.data.data.address_two = ''
					}
					dispatch(fetchSettingSingleSuccess(result.data.data));
				} else {
					dispatch(fetchSettingSingleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchSettingSingleFailure('Something went wrong'));
			});
	};
};


export const fetchPublicSettingSingle = () => {
	return async (dispatch) => {
		dispatch(settingInit());
		SettingController.publicsetting()
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
				} else if (result.data.status !== 'Failed') {
					try {
						let addressObj = JSON.parse(result.data.data.address)
						result.data.data.address_one = addressObj.address_line1
						result.data.data.address_two = addressObj.address_line2
					} catch (error) {
						result.data.data.address_one = ''
						result.data.data.address_two = ''
					}
					dispatch(fetchPublicSettingSuccess(result.data.data));
				} else {
				}
			})
			.catch((error) => {
				dispatch(fetchSettingSingleFailure('Something went wrong'));
			});
	};
};