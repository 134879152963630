import React from 'react';
import FormField from '../../../common/FormFileds/Admin/FormField';
import { transmissionTypeOptions } from '../../../../constants';

const EngineTransmission = (props) => {
  const { formik, fuelTypeList } = props;
  const type = fuelTypeList?.map((fuel) => fuel);
  return (
    <div className="p-6.5">
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Engine Capacity (cc)*"
            id="engine_capacity"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Engine Type"
            id="engine_type"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Battery Type"
            id="battery_type"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="select"
            label="Fuel Type *"
            id="fuel_type_id"
            required={true}
            placeholder=" "
            formik={formik}
            options={fuelTypeList?.map((fuel) => fuel)}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Drive Type"
            placeholder=" "
            id="drive_type"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="select"
            label="Transmission Type *"
            id="transmission_type"
            formik={formik}
            placeholder=" "
            options={transmissionTypeOptions}
          />
        </div>
      </div>
      <div className="w-full xl:w-1/3">
        <FormField
          type="text"
          label="Transmission"
          id="transmission"
          formik={formik}
          placeholder=" "
        />
      </div>
    </div>
  );
};

export default EngineTransmission;
