// Login.js
import { React, useState } from 'react';
import { connect } from 'react-redux';

// Assets
import carImage from './../../../../assets/images/site/car.png';
import userImage from './../../../../assets/images/site/user.png';

//components
import RegistrationForm from '../../../../components/site/RegistrationForm/RegistrationForm';
import Box from '../../../../components/site/Box/Box';
import Layout from '../../../../components/common/Layout/Auth/Layout';

import * as authActions from '../../../../store/actions';

const Register = (props) => {
  const [selectedUserType, setSelectedUserType] = useState(null);

  const formSubmitHandler = (values) => {
    props.register(values, selectedUserType);
  };

  const userTypes = [
    { type: 'Individual', imageSrc: carImage },
    { type: 'Merchant', imageSrc: userImage },
  ];

  const userTypeSelectHandler = (type) => {
    setSelectedUserType(type);
  };

  let registerBlock = (
    <div className="">
      <div className="flex flex-row justify-center md:justify-start items-center gap-4">
        {userTypes.map((userTypeObj) => (
          <Box
            key={userTypeObj.type}
            imageSrc={userTypeObj.imageSrc}
            userType={userTypeObj.type}
            userTypeHandler={() => userTypeSelectHandler(userTypeObj.type)}
          />
        ))}
      </div>
    </div>
  );

  if (selectedUserType !== null) {
    registerBlock = (
      <RegistrationForm
        submitHandler={(val) => formSubmitHandler(val)}
        error={props.error}
        userType={selectedUserType}
        loading={props.loading}
      />
    );
  }

  if (props.success != null && selectedUserType === 'Merchant') {
    registerBlock = (
      <div className="font-semibold w-full">
        <p>
          Your merchant account application has been submitted successfully. It
          will take 3-5 working days for our admin to approve or revert to you.
        </p>
        <p>
          Please contact us if you require your application to be approved
          urgently.
        </p>
        <div className="w-full mt-5 mb-4">
          <a
            href="/login"
            className="flex my-2   justify-center rounded bg-primary p-3 font-medium text-gray focus:outline-none w-full"
          >
            Login
          </a>
        </div>
      </div>
    );
  } else if (props.success != null && selectedUserType === 'Individual') {
    registerBlock = (
      <div className="">
        <p className="font-semibold">
          Your account has been created successfully and <br /> ready to login
        </p>
        <div className="w-full my-5 mb-4 tracking-wide">
          <a
            href="/login"
            className="flex my-2  md:w-90 lg:w-90 justify-center rounded bg-primary p-3 font-medium text-gray focus:outline-none"
          >
            LOGIN
          </a>
        </div>
      </div>
    );
  }

  return <Layout>{registerBlock}</Layout>;
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
    redirectTo: state.auth.redirectTo,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    register: (data, type) => dispatch(authActions.register(data, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(Register);
