import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NewCarUpdateForm from './NewCarUpdateForm';
import NewCarPostForm from './NewCarPostForm';
import Layout from '../../../components/site/Layout/Base/Layout';
import { connect } from 'react-redux';
import { createNewCar, fetchBrandList, fetchBrandPublicList, fetchCarCategoryList, fetchCarModelList, fetchCarSubModelList, fetchCarSubModelPublicList, fetchColorList, fetchCountryList, fetchMerchantList, fetchNewCarSearchList, fetchNewCarSingle, fetchVehicleTypeList, updateNewCar } from '../../../store/actions';
import { toast } from 'react-toastify';
import FullWidthSection from '../../../components/site/FullWidthSection/FullWidthSection';
import { deleteImageCarSuccess } from '../../../store/actions/UsedCarAction';

const NewCarPosting = (props) => {
  const {
    loading,
    error,
    success,
    fetchColorList,
    colorList,
    merchantList,
    createNewCar,
    fetchMerchantList,
    singleNewCar,
    fetchNewCarSingle,
    updateNewCar,
    fetchCountryList,
		countryList,

    
    fetchCarSubModelPublicList,
    carSubModelPublicList,
    carModelList,
    fetchCarModelList,
    fetchBrandPublicList,
    brandPublicList,

    fetchVehicleTypeList,
    vehicleTypeList,
    fetchCarCategoryList,
    carCategoryList,
    userData,
    deleteImageCarSuccess
  } = props;

    const { id } = useParams();
    const navigate = useNavigate();

    const [showTable, setShowTable] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [selectedColors, setSelectedColors] = useState([])
    const [selectedSubModels, setSelectedSubModels] = useState([])
  
    const [subModelData, setSubModelData] = useState([]);

    const cancelClick = () => {
      navigate('/user-dashboard',{
        state: {
          activeTab: 1,
        },
      });
    }

    
  const fetchData = async () => {
    await Promise.all([
      fetchColorList('all', true, true),
      fetchBrandPublicList('all'),
      fetchCarModelList('all', true, true),
      fetchCarSubModelPublicList(),
      fetchCountryList('all', true),

      fetchVehicleTypeList('all', true, true),
      fetchCarCategoryList('all', true, true),

      fetchMerchantList('all'),
    ]);
  };

  useEffect(() => {
    deleteImageCarSuccess({})
    fetchData();
  }, []);

  useEffect(() => {
    const processData = carSubModelPublicList?.map(item => ({
      id: item?.value,
      key_info:{
        name: item?.label
      },
      model_id: item?.model
    }));

    setSubModelData(processData);
  }, [carSubModelPublicList]);

  useEffect(() => {
    if(id){
      fetchNewCarSingle(id, true);
    }
  },[id])

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          cancelClick()
        },
        onClose: () => {},
      });
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

    const onSubmit = (values) => {
      console.log(values);
      const sub_model_id = selectedSubModels?.map((item) => item?.value)
      const color_id = selectedColors?.map((item) => item?.value)
     
      const newCarObj = {
        ...values,
        user_id: userData?.id,
        submodel: sub_model_id,
        colors: color_id
       }
   
      //  console.log('newCarObj', newCarObj)
      //  return
       if (id) {
        const stringiFyImg = JSON.stringify(newCarObj?.images)
			const newEditValue = {...values, images: stringiFyImg}
         updateNewCar(id, newEditValue, true);
       } else {
         // console.log(values);
         createNewCar(newCarObj, 'site');
       
       }
     };

// console.log('userData ===>', userData)
// console.log('merchantList ===>', merchantList)


  return (
    
    <Layout>
      <FullWidthSection title="Post Car Advertisement" bgColor="#cccccc" />
       <div className="container mx-auto">
       {
            id ? <NewCarUpdateForm
            onSubmit={onSubmit}
            brandList={brandPublicList}
            carModelList={carModelList ?? []}
            carSubModelList={subModelData ?? []}
            colorList={colorList}
            userList={merchantList}
            selectedColors={selectedColors}
            setSelectedColors={setSelectedColors}
            selectedSubModels={selectedSubModels} 
            setSelectedSubModels={setSelectedSubModels}
            countryList={countryList}
            onCancel={cancelClick}
            initialValues={singleNewCar}
            vehicleTypeList={vehicleTypeList}
            carCategoryList={carCategoryList}
            isloading={loading} 
            /> : 
            
            <NewCarPostForm 
              onSubmit={onSubmit}
              brandList={brandPublicList}
              carModelList={carModelList ?? []}
              carSubModelList={subModelData ?? []}
              colorList={colorList}
              userList={merchantList}
              selectedColors={selectedColors}
              setSelectedColors={setSelectedColors}
              selectedSubModels={selectedSubModels} 
              setSelectedSubModels={setSelectedSubModels}
              countryList={countryList}
              onCancel={cancelClick}
              isloading={loading}
              userData={userData}
          /> 
        }
       </div>
        
    </Layout>
  )
} 


const mapStateToProps = (state) => {
  return {
    loading: state.newcars.loading,
    error: state.newcars.error,
    success: state.newcars.success,

    carModelList: state.carModel.carModelList,
    carSubModelPublicList: state.carSubModel.carSubModelPublicList,
    brandPublicList: state.brand.brandPublicList,

    colorList: state.color.colorList,

    singleNewCar: state.newcars.singleNewCar,
    countryList: state.country.countryList,
    merchantList: state.merchant.merchantList,
    
    vehicleTypeList: state.vehicleType.vehicleTypeList,
    carCategoryList: state.carCategory.carCategoryList,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchColorList: (page, isProcess, isPublic) =>
      dispatch(fetchColorList(page, isProcess, isPublic)),
    fetchBrandPublicList: (page) => dispatch(fetchBrandPublicList(page)),
    fetchCarModelList: (page, isProcess, isPublic) =>
      dispatch(fetchCarModelList(page, isProcess, isPublic)),
    fetchCarSubModelPublicList: () => dispatch(fetchCarSubModelPublicList()),

      createNewCar: (data,from) => dispatch(createNewCar(data, from)),
      fetchNewCarSingle: (id , isPublic) => dispatch(fetchNewCarSingle(id, isPublic)),
      updateNewCar: (id, data, isPublic) => dispatch(updateNewCar(id, data, isPublic)),
      fetchCountryList: (page, isProcess) => dispatch(fetchCountryList(page, isProcess)),

     fetchMerchantList: (page) => dispatch(fetchMerchantList(page)),
     fetchNewCarSearchList: (page, isProcess, isPublic) =>
     dispatch(fetchNewCarSearchList(page, isProcess, isPublic)),

     fetchVehicleTypeList: (page, isProcess, isPublic) =>
     dispatch(fetchVehicleTypeList(page, isProcess, isPublic)),
     fetchCarCategoryList: (page, isProcess, isPublic) =>
     dispatch(fetchCarCategoryList(page, isProcess, isPublic)),
     deleteImageCarSuccess: (data) => dispatch(deleteImageCarSuccess(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCarPosting);
 