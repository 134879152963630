import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Advertisement from '../../../assets/images/web/advertisement.png';
import SideBarAdvertisement from '../../../assets/images/web/sidebar-advertisement.png';
import Loader from '../../../components/common/Loader/Loader';
import Layout from '../../../components/site/Layout/Base/Layout';
import newsData from './../../../news.json';
import './News.css'
import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import SmallLogo from '../../../assets/images/web/small-logo.jpeg';

const SingleNewsPage = () => {
    const { slug } = useParams();
    const [newsItem, setNewsItem] = useState(null);
    const navigate = useNavigate();


    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const foundNews = newsData.find(item => item.slug === slug);
        setNewsItem(foundNews);
    }, [slug]);

    if (!newsItem) {
        return (
            <Layout>
                <div className="flex justify-center items-center h-screen">
                    <Loader />
                </div>
            </Layout>
        );
    }

    let { title, content, author, date, image } = newsItem;

    const imageSrc = `${process.env.REACT_APP_BASE_URL}${image}`;
    const contentWithImages = (content) => content.replace(/src='storage/g, `src='${process.env.REACT_APP_BASE_URL}/storage`);

      
    return (
        <Layout>
            <div className="lg:mb-0 container mx-auto">
                <img src={Advertisement} alt="Advertisement" className="w-screen lg:h-[122px] md:h-[100px]" />
            </div>

            <div className="container mx-auto p-4">
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-8 p-4">

                        <div onClick={handleBack} className="cursor-pointer flex flex-row justify-center items-center w-fit sm:0 pr-4 mb-5">
                            <div className="bg-[#0534FF] w-[24px] h-[24px] flex flex-row justify-center items-center rounded cursor-pointer">
                                <IoMdArrowBack color="white" />
                            </div>
                            <p className="text-[#0534FF] text-[16px] font-bold ps-4">
                                Back to results
                            </p>
                        </div>

                        <div className='heading_section'>
                            <h1 className='news_header text-3xl font-bold mb-4'>{title}</h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-6 text-sm text-gray-600 date_author">
                            <div className="author col-span-6 md:col-span-2 flex items-center">
                                <img src={SmallLogo} alt="Author Image" className="w-5 h-auto rounded-full mr-4" />
                                <span>{author}</span>
                            </div>
                            <div className="text-left date">{date}</div>
                        </div>

                        <div className='feature_image mb-5'>
                            <img src={imageSrc} alt="Advertisement" className="feature_image" />
                        </div>




                        <div className='content' dangerouslySetInnerHTML={{ __html: contentWithImages(content) }}></div>
                    </div>


                    <div className="col-span-12 md:col-span-4 p-4">
                        <img
                            src={SideBarAdvertisement}
                            alt="Sidebar Advertisement"
                            className="w-full h-auto object-contain"
                        />
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default SingleNewsPage;
