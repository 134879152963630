import React, { useEffect, useState } from 'react'

 const ImageVewGrid = ({imgData}) => {
    const [preview, setPreview] = useState([]);

    useEffect(() => {
        if(imgData?.length > 0){
            imgData?.forEach((file, index) => {
                const reader = new FileReader();
                reader.onload = () => {
                  
                  setPreview((prevImages) => [...prevImages, reader.result]);
      
                };
                reader.readAsDataURL(file);
              });
        }
    },[imgData?.length]);

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            {
              preview?.map((item, index) => (
                <div
                className="w-full h-40 flex items-center justify-center shadow-4  group">
                <img
                     src={item}
                    alt={`Cover `}
                    className=" bg-blend-luminosity transition-all duration-50 rounded-lg z-10 w-full h-full shadow-4 grid_img"
                    // onError={handleImageError}
                />
            
                </div>
              ))
            }
        </div>
  )
}

export default ImageVewGrid;