import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchOrderSingle } from '../../../store/actions';
import Layout from '../Layout/Base/Layout';
import FullWidthSection from '../FullWidthSection/FullWidthSection';
import FormField from '../../common/FormFileds/Admin/FormField';
import { useFormik } from 'formik';
import moment from 'moment';

const ViewPayment = ({ fetchOrderSingle, singleOrder, tabLabel }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabLabelSet, setTabLabelSet] = useState(tabLabel);

  useEffect(() => {
    const { state } = location;
    if (state && state.activeTab !== undefined) {
      setTabLabelSet(state.activeTab);
      console.log('Active Tab:', tabLabelSet);
    }
  }, [location]);
  const fetchData = async () => {
    await Promise.all([
      // fetchMerchantList('all'),
      fetchOrderSingle(id),
    ]);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const initVal = {
    orderID: '',
    name: '',
    paymentFor: '',
    paymentType: '',
    amount: '',
    paymentDate: '',
    referenceNo: '',
    carPlateNo: '',
    status: '',
  };
  console.log('singleOrder============', singleOrder);
  const formik = useFormik({
    // initialValues: initialValues || initVal,
    initialValues: initVal,
    // onSubmit,
  });
  const addTypeArr = [
    {
      id: 'normal_ads',
      label: 'Normal Ads',
      name: 'add_type',
      value: '4',
    },
    {
      id: 'feature_ads',
      label: 'Feature ads',
      name: 'add_type',
      value: '5',
    },
    {
      id: 'premium_ads',
      label: 'Premium ',
      name: 'add_type',
      value: '1',
    },
    {
      id: 'import_used_ads',
      label: 'Import Used ',
      name: 'add_type',
      value: '2',
    },
    {
      id: 'consignment_ads',
      label: 'Consignment ',
      name: 'add_type',
      value: '3',
    },
  ];
  let add_OnArr = [
    { id: 'none', label: 'None', name: 'service_type', value: '3' },
    {
      id: 'photo_service',
      label: 'Photography Services',
      name: 'service_type',
      value: '1',
    },
    {
      id: 'photo__video_service',
      label: 'Photography & Videography',
      name: 'service_type',
      value: '2',
    },
  ];
  const handleCancel = () => {
    navigate('/user-dashboard', {
      state: {
        activeTab: tabLabelSet,
      },
    });

    console.log('Cancel==========', tabLabelSet);
  };
  return (
    <Layout>
      <FullWidthSection title="View Payment" bgColor="#cccccc" />
      <div className="py-6.5 px-2 container mx-auto">
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Order ID"
              id="orderID"
              formik={formik}
              disabled={true}
              value={singleOrder?.order_id}
            />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Name"
              id="name"
              formik={formik}
              disabled={true}
              value={singleOrder?.user?.user_details?.first_name}
            />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Payment For"
              id="paymentFor"
              formik={formik}
              disabled={true}
              value={
                singleOrder?.type == 'used_car'
                  ? 'Used Car listing'
                  : 'New Car listing'
              }
            />
          </div>
        </div>
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Payment Type"
              id="paymentType"
              formik={formik}
              disabled={true}
              value={
                singleOrder?.paymentMethod == 'pay_now'
                  ? 'Pay Now'
                  : 'Credit Card'
              }
              // value={'PayNow'}
            />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Amount"
              id="amount"
              formik={formik}
              disabled={true}
              value={singleOrder?.total_amount}
            />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Payment Date"
              id="paymentDate"
              formik={formik}
              disabled={true}
              value={moment(singleOrder?.orderDate).format('DD MMM YY')}
            />
          </div>
        </div>
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Payment Reference No"
              id="referenceNo"
              formik={formik}
              disabled={true}
              value={singleOrder?.payment_reference_no}
            />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Car Plate No"
              id="carPlateNo"
              formik={formik}
              disabled={true}
              value={singleOrder?.used_car_listing?.car_plate_no}
            />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Status"
              id="status"
              // options={options}
              formik={formik}
              disabled={true}
              value={
                singleOrder?.status.charAt(0).toUpperCase() +
                singleOrder?.status?.slice(1)
              }
            />
          </div>
        </div>
        <div className="my-10  overflow-x-auto">
          <table className="w-full table-auto  ps-4">
            <thead className="">
              <tr className="border-b border-[#E8E7E6]">
                <th className="py-2 text-start px-4 md:px-0 w-fit md:w-[1000px]  ">
                  Item
                </th>
                <th className="py-2 text-start px-4 md:px-0">Cost</th>
                <th className="py-2 text-start px-4 md:px-0">Quantity</th>
                <th className="py-2 text-start px-4 md:px-0">Total</th>
              </tr>
            </thead>
            <tbody>
              {singleOrder?.order_items?.map((item) => (
                <tr className="border-b border-[#E8E7E6]  px-4 md:px-0">
                  <td className=" py-2">
                    {item?.type == 'add_on'
                      ? add_OnArr?.find(
                          (addon) => addon?.value == item?.item_id
                        )?.label
                      : item?.type == 'add_type'
                      ? addTypeArr?.find(
                          (addtype) => addtype?.value == item?.item_id
                        )?.label
                      : ''}
                  </td>

                  <td className="py-2  px-4 md:px-0">
                    ${parseFloat(item?.price).toFixed(2)}
                  </td>

                  <td className=" py-2  px-4 md:px-0">
                    <p className="">1</p>
                  </td>

                  <td className=" py-2  px-4 md:px-0">
                    ${parseFloat(item?.price).toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr>
                <td className=" py-2  px-4 md:px-0"></td>

                <td className=" py-2  px-4 md:px-0"></td>
                <td className=" py-2  px-4 md:px-0">Subtotal</td>

                <td className="py-2 px-4 md:px-0">
                  $
                  {singleOrder?.order_items
                    .reduce((sum, order) => sum + order.price, 0)
                    .toFixed(2)}
                </td>
              </tr>

              <tr>
                <td className=" py-2  px-4 md:px-0"></td>
                <td className=" py-2  px-4 md:px-0"></td>
                <td className=" py-2  px-4 md:px-0">
                  Coupon (
                  {singleOrder?.promo_codes?.type == 'percentage' ? '%' : '$'})
                </td>

                <td className=" py-2  px-4 md:px-0">
                  {singleOrder?.promo_codes
                    ? `${parseFloat(
                        singleOrder?.promo_codes?.discount_amount
                      )?.toFixed(2)} `
                    : '0.00'}
                </td>
              </tr>

              <tr>
                <td className=" py-2  px-4 md:px-0"></td>

                <td className=" py-2  px-4 md:px-0"></td>
                <td className=" py-2  px-4 md:px-0">Tax (0%)</td>

                <td className=" py-2  px-4 md:px-0">
                  ${parseFloat(singleOrder?.tax_amount)?.toFixed(2)}
                </td>
              </tr>

              <tr>
                <td className=" py-2  px-4 md:px-0"></td>

                <td className=" px-4 md:px-0 py-2"></td>
                <td className=" px-4 md:px-0 py-2">Total</td>

                <td className=" px-4 md:px-0 py-2">
                  ${parseFloat(singleOrder?.total_amount)?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-10 flex justify-end ">
          <button
            className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black dark:border-strokedark dark:text-white"
            type="submit"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    singleOrder: state.sitePayment.singleOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderSingle: (id) => dispatch(fetchOrderSingle(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPayment);
