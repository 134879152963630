import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import FileUpload from '../../../components/common/FormFileds/Admin/FileUpload';
import CompanySettingSchema from '../../../schemas/CompanySettingSchema';
import { countryOptions } from '../../../constants';

const CompanySettingForm = (props) => {
	const { initialValues, onSubmit } = props;

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: CompanySettingSchema,
		onSubmit,
	});

	useEffect(() => {
		formik.setValues(initialValues);
	}, [initialValues]);

	return (
		<AdminForm formik={formik} onBtnCancelClick={props.onCancel}>
			<div className="p-6.5">
				<FileUpload formik={formik} id={"image"} />
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Company name" id="name" formik={formik} />
					</div>

					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Company UEN" id="uen" formik={formik} />
					</div>

					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Company Email" id="email" formik={formik} />
					</div>

				</div>

				<div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
					<div className="w-full">
						<FormField type="text" label="Address Line 1" id="address_one" formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
					<div className="w-full">
						<FormField type="text" label="Address Line 2" id="address_two" formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="select" label="Country" id="country_id" options={countryOptions} formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Company contact No" id="contact_no" formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Postal Code" id="postal_code" formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Facebook Url" id="fb" formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Instagram Url" id="insta" formik={formik} />
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Youtube Url" id="youtube" formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Tiktok Url" id="tiktok" formik={formik} />
					</div>
				</div>
			</div>
		</AdminForm>
	);
};

export default CompanySettingForm;
