import React from 'react';
import img from '../../../assets/images/web/Benz.png';
import { useNavigate } from 'react-router';
const BrandBrowse = ({ brand, allBrands, newCar }) => {
  const navigate = useNavigate();
  const searchHandler = (value) => {
    console.log('value', value);
    let path = newCar
      ? `/searched_new_cars?brand_id=${
          value || ''
        }&model_id=${''}&year_range=${''}&price_range=${''}&vehicle_id=${''}`
      : `/searched_used_cars?brand_id=${
          value || ''
        }&model_id=${''}&year_range=${''}&price_range=${''}&vehicle_id=${''}`;

    navigate(path);
  };
  return (
    <div
      className={` border-[#E8E7E6] border sm:px-6 w-60  py-8 mx-auto cursor-pointer ${
        allBrands && 'w-[250px] h-[70px] flex justify-start items-center '
      }
      `}
      onClick={() => searchHandler(brand.value)}
    >
      <img
        src={brand?.image}
        // src={img}
        className={`h-[104px] ${
          allBrands && 'w-25 h-10 ms-0 p-1'
        } mx-auto hidden sm:block lg:block md:block  p-5`}
      />
      <p className={`${allBrands ? ' px-3' : 'text-center'}  font-semibold`}>
        {brand?.label}
        {/* Mercedes-Benz */}
      </p>
    </div>
  );
};

export default BrandBrowse;
