// actions.js
import * as actionTypes from "./actionTypes";
import RoleController from "./../../api/RoleController";

export const roleInit = () => {
	return {
		type: actionTypes.ROLE_INIT,
	};
};

export const fetchRoleListSuccess = (roles) => {
	return {
		type: actionTypes.FETCH_ROLE_LIST_SUCCESS,
		payload: roles,
	};
};

export const fetchRoleListFailure = (error) => {
	return {
		type: actionTypes.FETCH_ROLE_LIST_FAILURE,
		payload: error,
	};
};

export const fetchRoleSingleSuccess = (role) => {
	return {
		type: actionTypes.FETCH_ROLE_SINGLE_SUCCESS,
		payload: role,
	};
};

export const fetchRoleSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_ROLE_SINGLE_FAILURE,
		payload: error,
	};
};

export const createRoleSuccess = (message) => {
	return {
		type: actionTypes.CREATE_ROLE_SUCCESS,
		payload: message,
	};
};

export const createRoleFailure = (error) => {
	return {
		type: actionTypes.CREATE_ROLE_FAILURE,
		payload: error,
	};
};

export const updateRoleSuccess = (role) => {
	return {
		type: actionTypes.UPDATE_ROLE_SUCCESS,
		payload: role,
	};
};

export const updateRoleFailure = (error) => {
	return {
		type: actionTypes.UPDATE_ROLE_FAILURE,
		payload: error,
	};
};

export const deleteRoleSuccess = (roleId) => {
	return {
		type: actionTypes.DELETE_ROLE_SUCCESS,
		payload: roleId,
	};
};

export const deleteRoleFailure = (error) => {
	return {
		type: actionTypes.DELETE_ROLE_FAILURE,
		payload: error,
	};
};

export const fetchRoleList = (page, isProcess) => {
	return async (dispatch) => {
		dispatch(roleInit());
		RoleController.list(page)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(createRoleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					if (isProcess) {
						const processData = result.data.data.rows.map(item => ({
							value: item.id,
							label: item.name
						}));
						dispatch(fetchRoleListSuccess(processData));
					} else {
						dispatch(fetchRoleListSuccess(result.data.data));
					}
				} else {
					dispatch(fetchRoleListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchRoleListFailure('Something went wrong'));
			});
	};
};

export const fetchRoleSingle = (roleId) => {
	return async (dispatch) => {
		dispatch(roleInit());
		RoleController.single(roleId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(fetchRoleSingleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchRoleSingleSuccess(result.data.data));
				} else {
					dispatch(fetchRoleSingleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchRoleSingleFailure('Something went wrong'));
			});
	};
};

export const createRole = (roleData) => {
	return async (dispatch) => {
		dispatch(roleInit());
		RoleController.create(roleData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(createRoleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(createRoleSuccess(result.data.message));
				} else {
					dispatch(createRoleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(createRoleFailure('Something went wrong'));
			});
	};
};

export const updateRole = (roleId, updatedRoleData) => {
	return async (dispatch) => {
		dispatch(roleInit());
		RoleController.update(roleId, updatedRoleData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(updateRoleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(updateRoleSuccess(result.data.message));
				} else {
					dispatch(updateRoleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(updateRoleFailure('Something went wrong'));
			});
	};
};

export const deleteRole = (roleId) => {
	return async (dispatch) => {
		dispatch(roleInit());
		RoleController.delete(roleId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(deleteRoleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					fetchRoleList()(dispatch); // Dispatch fetchRoleList after successful deletion
					dispatch(deleteRoleSuccess(result.data.message));
				} else {
					dispatch(deleteRoleFailure('Something went wrong1'));
				}
			})
			.catch((error) => {
				dispatch(deleteRoleFailure('Something went wrong3'));
			});
	};
};
