// Layout.js
import React, { useEffect } from 'react';
import './../../../../index.css';
import logoImage from '../../../../assets/images/web/logo.png';
import { useLocation } from 'react-router-dom';

import './Layout.css';
import { fetchPublicSettingSingle } from '../../../../store/actions';
import { connect } from 'react-redux';

const Layout = ({ children , fetchPublicSettingSingle, publicSetting}) => {
  const pathName = useLocation().pathname;

  useEffect(() => {
    fetchPublicSettingSingle()
  },[])

  let loginTitle = 'Login';
  if (pathName === '/register') {
    loginTitle = 'Register';
  } else if (pathName === '/admin/login') {
    loginTitle = 'Admin Login';
  } else if (
    pathName === '/reset-password' ||
    pathName === '/forgot-password'
  ) {
    loginTitle = 'Reset Your Password';
  } else {
    loginTitle = 'Login';
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 h-screen">
      <div className="main w-full">
        <div className="authlogo">
          <a href="/">
            {/* <img src={logoImage} alt="Logo" /> */}
            <img src={publicSetting?.image ? publicSetting?.image : logoImage} alt="Logo" />

          </a>
        </div>

        <div className="flex items-center justify-center form_wrapper overflow-y-scroll">
          <div className="w-full lg:w-3/4 p-6">
            <h1 className="text-2xl font-bold mb-7 mt-15">{loginTitle}</h1>
            {pathName === '/forgot-password' && (
              <p className="font-semibold ml-6 my-2">
                We will email you instructions to reset your <br /> password.
              </p>
            )}
            {pathName === '/reset-password' && (
              <p className="font-semibold ml-6 my-2">
                Please update your new password.
              </p>
            )}
            {children}
          </div>
        </div>
      </div>
      <div
        className={`wrapper ${
          pathName === '/login' || pathName === '/register'
            ? 'login-bg'
            : 'admin-login-bg'
        }`}
      ></div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    publicSetting: state.setting.publicSetting
  };
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchPublicSettingSingle: () => dispatch(fetchPublicSettingSingle()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
