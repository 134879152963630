import React from 'react';
import SuggestedCars from './SuggestedCars'; // Import your SuggestedCars component
import likeCar from '../../../assets/images/web/likeCar.png';
import likeLogo from '../../../assets/images/web/likeLogo.png';

const SuggestedCarsSection = ({ suggestedUsedCarList }) => {
  // You can customize the data as needed
  const suggestedCarsData = [
    { img: likeCar, logo: likeLogo },
    { img: likeCar, logo: likeLogo },
    { img: likeCar, logo: likeLogo },
  ];

  return (
    <div>
      <p className="font-bold text-center text-3xl mt-16 mb-10">
        You may like these cars
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        {suggestedUsedCarList.slice(0, 3).map((car, index) => (
          <SuggestedCars key={index} data={car} />
        ))}
      </div>
    </div>
  );
};

export default SuggestedCarsSection;
