// settingActions.js
import * as actionTypes from './actionTypes';
import SettingController from '../../api/SettingController';
import SiteSettingController from '../../api/UserSettingController';

export const siteSettingInit = () => {
  return {
    type: actionTypes.SITE_SETTING_INIT,
  };
};

export const fetchSiteSettingSingleSuccess = (setting) => {
  return {
    type: actionTypes.FETCH_SITE_SETTING_SINGLE_SUCCESS,
    payload: setting,
  };
};

export const fetchSiteSettingSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_SITE_SETTING_SINGLE_FAILURE,
    payload: error,
  };
};

export const updateSiteSettingSuccess = (setting) => {
  return {
    type: actionTypes.UPDATE_SITE_SETTING_SUCCESS,
    payload: setting,
  };
};

export const updateSiteSettingFailure = (error) => {
  return {
    type: actionTypes.UPDATE_SITE_SETTING_FAILURE,
    payload: error,
  };
};

export const fetchSiteSettingSingle = (userId) => {
  return async (dispatch) => {
    dispatch(siteSettingInit());
    SiteSettingController.single(userId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchSiteSettingSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          try {
            let addressObj = JSON.parse(result.data.data.address);
            result.data.data.address_one = addressObj.address_line1;
            result.data.data.address_two = addressObj.address_line2;
          } catch (error) {
            result.data.data.address_one = '';
            result.data.data.address_two = '';
          }
          dispatch(fetchSiteSettingSingleSuccess(result.data.data));
        } else {
          dispatch(fetchSiteSettingSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchSiteSettingSingleFailure('Something went wrong'));
      });
  };
};

export const updateSiteSettings = (data, type, id) => {
  return async (dispatch) => {
    dispatch(siteSettingInit());
    try {
      const addressJson = {
        address_line1: data.address_one,
        address_line2: data.address_two,
      };
      console.log(data);
      const formData = new FormData();
      formData.append('name', data.name || '');
      formData.append('email', data.email || '');
      formData.append('contact_no', data.contact_no || '');
      formData.append('address', JSON.stringify(addressJson));

      formData.append('country_id', data.country_id || '');
      formData.append('city_id', data.city_id || '');
      formData.append('postal_code', data.postal_code || '');
      if (data.image != undefined && typeof data.image !== 'string') {
        data.image.forEach((file, index) => {
          formData.append('image', file);
        });
      }
      SiteSettingController.update(id, type, formData)
        .then((result) => {
          console.log('----------------------', formData);
          if (result instanceof Error) {
            let errorMsg = '';
            if (result.response) {
              if (
                result.response.data &&
                !Array.isArray(result.response.data.message)
              ) {
                errorMsg = result.response.data.message;
              } else {
                errorMsg = 'Something went wrong';
              }
            } else {
              errorMsg = 'Something went wrong';
            }
            dispatch(updateSiteSettingFailure(errorMsg));
          } else if (result.data.status !== 'Failed') {
            dispatch(updateSiteSettingSuccess(result.data.message));
          } else {
            dispatch(updateSiteSettingFailure('Something went wrong'));
          }
        })
        .catch((error) => {
          dispatch(updateSiteSettingFailure('Something went wrong'));
        });
    } catch (error) {
      dispatch(updateSiteSettingFailure('Something went wrong'));
    }
  };
};
