import axios from "axios";

export default class BaseController {
	static baseUrl = process.env.REACT_APP_API_URL;

	static getHeaders(config = {}) {
		const token = localStorage.getItem("token");
		return {
			Authorization: `Bearer ${token}`,
			...config.headers,
		};
	}
	
	static async axiosPost(path, data, config = {}) {
		try {
			return await axios.post(this.baseUrl + path, data, config);
		} catch (error) {
			if(error?.response?.data?.message == 'Unauthrized'){
				localStorage.removeItem('token');
				localStorage.removeItem('userData');
				
				const currentBaseUrl = window.location.origin; 

				// Create a new URL by combining the current base URL with the desired path
				const newUrl = new URL('/login', currentBaseUrl).toString();
			
				// Replace the current URL with the new URL
				window.location.replace(newUrl);
			}
			throw error;
		}
	}

	static async axiosGet(path, config = {}) {
		try {
			return await axios.get(this.baseUrl + path, config);
		} catch (error) {
			if(error?.response?.data?.message == 'Unauthrized'){
				localStorage.removeItem('token');
				localStorage.removeItem('userData');

				const currentBaseUrl = window.location.origin; 

				// Create a new URL by combining the current base URL with the desired path
				const newUrl = new URL('/login', currentBaseUrl).toString();
			
				// Replace the current URL with the new URL
				window.location.replace(newUrl);
			}
			throw error;
		}
	}
}
