import * as actionTypes from '../actions/actionTypes';

const initialState = {
	carModelDropDown: [],
	carModelList: [],
	singleCarModel: null,
	loading: false,
	error: null,
	success: null,
};

const carModelReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CAR_MODEL_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_CAR_MODEL_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				carModelList: action.payload,
				error: null,
			};

		case actionTypes.FETCH_CAR_MODEL_LIST_DROPDOWN_SUCCESS:
			return {
				...state,
				loading: false,
				carModelDropDown: action.payload,
				error: null,
			};
		case actionTypes.FETCH_CAR_MODEL_LIST_FAILURE:
		case actionTypes.FETCH_CAR_MODEL_SINGLE_FAILURE:
		case actionTypes.CREATE_CAR_MODEL_FAILURE:
		case actionTypes.UPDATE_CAR_MODEL_FAILURE:
		case actionTypes.DELETE_CAR_MODEL_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_CAR_MODEL_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleCarModel: action.payload,
				error: null,
			};
		case actionTypes.CREATE_CAR_MODEL_SUCCESS:
			return {
				...state,
				loading: false,
				carModelList: state.carModelList,
				error: null,
				success: action.payload,
			};
		case actionTypes.UPDATE_CAR_MODEL_SUCCESS:
		case actionTypes.DELETE_CAR_MODEL_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		default:
			return state;
	}
};

export default carModelReducer;
