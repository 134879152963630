import * as actionTypes from '../actions/actionTypes';

const initialState = {
  usedCarList: [],
  suggestedUsedCar: [],
  singleUsedCar: null,
  loading: false,
  error: null,
  success: null,
  merchantUsedCar: null,
  getSearchUsedCar: [],

  carImgloading: false,
  carImgerror: null,
  carImgsuccess: null,

  carMultipleImgloading: false,
  carMultipleImgerror: null,
  carMultipleImgData: null, 

};

const usedCarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USED_CAR_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
        singleUsedCar: null,
        // usedCarList: [],
      };
      case actionTypes.IMAGE_INIT:
        return {
          ...state,
          carImgloading: true,
          carImgerror: null,
          carImgsuccess: null,
          // usedCarList:[]
        }; 

  case actionTypes.MULTIPLE_IMAGE_INIT:
    return {
      ...state,
      carMultipleImgloading: true,
      carMultipleImgerror: null,
      carMultipleImgData: null,
      // usedCarList:[]
    }; 
    case actionTypes.FETCH_USED_CAR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        usedCarList: action.payload,
        error: null,
        // success: null,
      };
    case actionTypes.FETCH_USED_CAR_LIST_FAILURE:
    case actionTypes.FETCH_USED_CAR_SINGLE_FAILURE:
    case actionTypes.CREATE_USED_CAR_FAILURE:
    case actionTypes.UPDATE_USED_CAR_FAILURE:
    case actionTypes.USED_CAR_UPDATE_STATUS_FAILURE:
    case actionTypes.DELETE_USED_CAR_FAILURE:
    case actionTypes.FETCH_SUGGESTED_USED_CAR_LIST_FAILURE:
    case actionTypes.USED_CAR_SHORT_LIST_SAVE_FAILURE:
    case actionTypes.USED_CAR_SEARCH_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_USED_CAR_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleUsedCar: action.payload,
        error: null,
      };
    case actionTypes.FETCH_SUGGESTED_USED_CAR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        suggestedUsedCar: action.payload,
        error: null,
      };
    case actionTypes.CREATE_USED_CAR_SUCCESS:
      return {
        ...state,
        loading: false,
        usedCarList: state.usedCarList,
        error: null,
        success: action.payload,
      };
    case actionTypes.UPDATE_USED_CAR_SUCCESS:
    case actionTypes.DELETE_USED_CAR_SUCCESS:
    case actionTypes.USED_CAR_UPDATE_STATUS_SUCCESS:
    case actionTypes.USED_CAR_SHORT_LIST_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    case actionTypes.GET_MERCHANT_USED_CAR:
      return {
        ...state,
        loading: false,
        error: null,
        merchantUsedCar: action.payload,
      };
    case actionTypes.USED_CAR_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        usedCarList: action.payload,
      };
      case actionTypes.DELETE_CAR_IMAGE_FAILURE:
        return {
          ...state,
          carImgloading: false,
          carImgerror: action.payload,
        };
        case actionTypes.DELETE_CAR_IMAGE_SUCCESS:
          return {
            ...state,
            carImgloading: false,
            carImgerror: null,
            carImgsuccess: action.payload,
          };

        case actionTypes.CAR_MULTIPLE_IMAGE_FAILURE:
          return {
            ...state,
            carMultipleImgloading: false,
            carMultipleImgerror: action.payload,
          };
          case actionTypes.CAR_MULTIPLE_IMAGE_SUCCESS:
            return {
              ...state,
              carMultipleImgloading: false,
              carMultipleImgerror: null,
              carMultipleImgData: action.payload,
            };
    default:
      return state;
  }
};

export default usedCarReducer;
