import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class SitePaymentController extends BaseController {
  static async list(page) {
    try {
      return await this.axiosGet(
        `${endpoint.SITE_PAYMENT_LIST}?page=${page}&type=used_car`,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }
  static async single(orderId) {
    try {
      return await this.axiosGet(`${endpoint.SITE_SINGLE_PAYMENT}/${orderId}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
}
