import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class addsOnsController extends BaseController {
  static async list(page, isPublic) {
    try {
      const pageQueryParam = page ? `?page=${page}` : '';
      return await this.axiosGet(`${endpoint.ADDS_ONS_LIST}${pageQueryParam}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async publicList(page) {
    try {
      const pageQueryParam = page ? `?page=${page}` : '';
      return await this.axiosGet(`${endpoint.SITE_ADDS_ONS}${pageQueryParam}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async single(addsonid) {
    try {
      return await this.axiosGet(`${endpoint.ADDS_ONS_SINGLE}/${addsonid}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async update(addsonid, updatedaddsOnsData) {
    try {
      return await this.axiosPost(
        `${endpoint.ADDS_ONS_UPDATE}/${addsonid}`,
        updatedaddsOnsData,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }
}
