// Merchant.js
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/admin/Layout/Base/Layout';
import MerchantForm from './MerchantUserForm';
import SectionHeader from '../../../../components/admin/SectionHeader/SectionHeader';
import MerchantTable from '../../../../components/admin/Table/Table';
import { fetchMerchantList, createMerchant, fetchMerchantSingle, updateMerchant } from '../../../../store/actions';
import Loader from '../../../../components/common/Loader/Loader';
import ActionButton from '../../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MerchantAnalytics from './MerchantAnalytics';

const Merchant = (props) => {
	const { loading, error, success, merchantList, fetchMerchantList, createMerchant, fetchMerchantSingle, singleMerchant, updateMerchant } = props;

	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [isViewMerchant, setIsViewMerchant] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		setShowTable(true)
	}, [merchantList])

	useEffect(() => {
		fetchMerchantList(page)
	}, [fetchMerchantList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchMerchantList(page)
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id);
			setShowTable(false);
			setButtonLabel('List');
			fetchMerchantSingle(id);
			setIsViewMerchant(false)
		} else if (action === 'delete') {
			// deleteMerchant(id);
		}
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null);
		setIsViewMerchant(false)
	};

	const handleViewMerchantDetails = (id) => {
		setEditingId(null);
		setShowTable(false);
		setButtonLabel('List');
		fetchMerchantSingle(id);
		setIsViewMerchant(true)
	}
	
	const onPageChange = (page) => {
		setPage(page);
	};

	const onSubmit = (values) => {
		if (editingId) {
			updateMerchant(editingId, values, 'merchant');
		} else {
			createMerchant(values, 'merchant');
		}
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row.username,
			sortable: true
		},
		{
			name: 'Email',
			selector: row => row.email,
			sortable: true
		},
		{
			name: 'Phone',
			selector: row => row.contact_no,
			sortable: false
		},
		{
			name: 'Status',
			selector: row => row.status ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					showDeleteButton={false}
					onDelete={id => handleEditDelete(id, 'delete')}
					onView={id => handleViewMerchantDetails(id)}
					showViewButton={true}
				/>
			),
			sortable: false,
			width: '20%'
		},
	];

	const renderContent = () => {
		if (loading) {
			return <Loader />	
		}

		if (showTable && !loading) {
			return (
				<MerchantTable
					defaultPage={page}
					columns={columns}
					data={merchantList.rows === undefined ? [] : merchantList.rows}
					totalCount={merchantList.count}
					handlePageChange={onPageChange}
				/>
			);
		}

		if (editingId && !loading) {
			return (
				<MerchantForm
					onSubmit={onSubmit}
					initialValues={singleMerchant}
					onCancel={handleButtonClick}
				// countryList={countryList}
				// cityList={cityList}
				isViewMerchant={false}
				/>
			);
		}

		if (isViewMerchant && !loading) {
			return (
			  <Tabs>
				<TabList>
				  <Tab>Details</Tab>
				  <Tab>Vehicle  List</Tab>
				</TabList>
				<TabPanel>
					<MerchantForm
					onSubmit={onSubmit}
					initialValues={singleMerchant}
					onCancel={handleButtonClick}
					isViewMerchant={true}
					/> 
				</TabPanel>
				<TabPanel>
				  	<MerchantAnalytics singleMerchant={singleMerchant}/>
				</TabPanel>
			  </Tabs>
			);
		  }

		if (!loading) {
			return <MerchantForm onSubmit={onSubmit} onCancel={handleButtonClick} />;
		}
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader
							title={isViewMerchant ? "View Management" : "Merchant Management"}
							buttonText={buttonLabel}
							onButtonClick={handleButtonClick}
						/>
					
						{/* {error && <Alert message={error} type="error" />}
						{success && <Alert message={success} type="success" />} */}
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.merchant.loading,
		error: state.merchant.error,
		success: state.merchant.success,
		merchantList: state.merchant.merchantList,
		singleMerchant: state.merchant.singleMerchant,
		// countryList: state.country.countryList,
		// cityList: state.city.cityList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchMerchantList: (page) => dispatch(fetchMerchantList(page)),
		createMerchant: (data, type) => dispatch(createMerchant(data, type)),
		updateMerchant: (id, data, type) => dispatch(updateMerchant(id, data, type)),
		fetchMerchantSingle: (id) => dispatch(fetchMerchantSingle(id)),
		// fetchCountryList: (page, isProcess) => dispatch(fetchCountryList(page, isProcess)),
		// fetchCityList: (page, isProcess, countryId) => dispatch(fetchCityList(page, isProcess, countryId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
