import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class SiteSettingController extends BaseController {
  static async single(userId) {
    try {
      return await this.axiosGet(`${endpoint.SITE_SETTING_SINGLE}/${userId}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async update(id, type, data) {
    try {
      let path =
        type === 'customer'
          ? endpoint.SITE_SETTING_UPDATE_CUSTOMER
          : endpoint.SITE_SETTING_UPDATE_MERCHANT;

      return await this.axiosPost(`${path}/${id}`, data);
    } catch (error) {
      return error;
    }
  }
}
