import React from 'react';
import img from '../../../assets/images/web/newCar.png';
import Layout from '../../../components/site/Layout/Base/Layout';
import NewCarSpaceFeatureHomeTogglers from '../../../components/site/NewCarSpaceFeatureHomeComponents/NewCarSpaceFeatureHomeTogglers';
import BackAndShareBar from '../../../components/site/ProductComponents/BackAndShareBar';
import ProductHeaderSlider from '../../../components/site/ProductComponents/ProductHeaderSlider';
import ProductPriceAndContactInfo from '../../../components/site/ProductComponents/ProductPriceAndContactInfo';
import img2 from '../../../assets/images/web/car.png';
const NewCarSpaceFeatureHome = () => {
  const imgArray = [img, img2, img, img2];
  const engineTransmission = [
    { name: 'Engine Capacity (cc)', value: '1,995' },
    { name: 'Engine Type', value: '4-cylinder in-line 16-valve Turbocharged' },
    { name: 'Battery Type', value: 'N/A' },
    { name: 'Fuel Type', value: 'Petrol' },
    { name: 'Drive Type', value: 'Rear-wheel drive' },
    { name: 'Transmission', value: '8-speed (A)' },
  ];
  const performance = [
    { name: 'Power (bhp)', value: 276 },
    { name: 'Engine Power (bhp)', value: 'N/A' },
    { name: 'Motor Power (bhp)', value: 'N/A' },
    { name: 'Torque (Nm)', value: 400 },
    { name: 'Acceleration (0-100km/h)', value: '5.2 s' },
    { name: 'Top Speed (km/h)', value: '240' },
    { name: 'Fuel Consumption (km/L)', value: '14.1' },
    { name: 'CO2 Emission (g/km)', value: '175' },
  ];
  const performance2 = [
    { name: 'Battery Capacity (kWh)', value: 'N/A' },
    { name: 'Drive Range (km)', value: 'N/A' },
    { name: 'Energy Consumption (km/kWh)', value: 'N/A' },
    { name: 'DC Charging Rate (Max)', value: 'N/A' },
    { name: 'DC Charging Time (Total)', value: 'N/A' },
    { name: 'AC Charging Rate (Max)', value: 'N/A' },
    { name: 'AC Charging Time (Total)', value: 'N/A' },
  ];
  const measurements = [
    { name: 'Seating Capacity', value: '5' },
    { name: 'Dimensions (mm)', value: '4643 x 1860 x 1438' },
    { name: 'Wheelbase (mm)', value: '2,820' },
    { name: 'Min. Turning Radius (mm)', value: '-' },
    { name: 'Kerb Weight (kg)', value: '1,520' },
    { name: 'Fuel Tank Capacity (L)', value: '58' },
    { name: 'Boot/Cargo Capacity (L)', value: '480' },
  ];
  const suspensionBrakesWheels = [
    {
      name: 'Suspension (Front)',
      value: 'Alfa Link double-wishbone with semi-virtual steering axle',
    },
    {
      name: 'Suspension (Rear)',
      value: 'Alfa Link with vertical rod',
    },
    { name: 'Brakes (Front)', value: 'Ventilated disc' },
    { name: 'Brakes (Rear)', value: 'Ventilated disc' },
    { name: 'Rim Size', value: '19"' },
  ];

  const exterior = [
    { name: 'Head Lights', value: 'LED' },
    { name: 'Auto Head Lights', value: '✅' },
    { name: 'Daytime Running Lights', value: 'LED' },
    { name: 'Electric Folding Side Mirrors', value: '✅' },
    { name: 'Rain Sensing Wipers', value: '✅' },
  ];
  const interior = [
    { name: 'Multi-Function Steering Wheel', value: '✅' },
    { name: 'Paddle Shifters', value: '✅' },
    { name: 'Keyless Entry', value: '✅' },
    { name: 'Keyless Engine Start', value: '✅' },
    { name: 'Multi-zone Aircon', value: '✅' },
    { name: 'Rear Aircon', value: '✅' },
    { name: 'Seat Upholstery', value: '✅' },
    { name: `Driver's Electric Seat`, value: '✅' },
    { name: `Passenger's Electric Seat`, value: 'unknown' },
    { name: `Ventilated Seats`, value: 'unknown' },
    { name: `Knockdown Rear Seats`, value: '✅' },
    { name: `Electric Tailgate`, value: '❌' },
    {
      name: `Sunroof/Moonroof/
    Panoramic roof`,
      value: '❌',
    },
  ];

  const InfotainmentSystem = [
    { name: 'Bluetooth', value: '✅' },
    { name: 'Apple CarPlay', value: 'Wired' },
    { name: 'Android Auto', value: 'Wired' },
    { name: 'Wireless Smartphone Charging', value: '✅' },
    { name: 'Navigation System', value: '✅' },
    { name: 'Heads-up Display', value: '❌' },
    { name: 'Infotainment', value: '8.8" Touchscreen' },
    { name: 'Sound System', value: '8 Speaker Audio System' },
  ];
  const SafetyDrivingAssistance = [
    { name: 'No. of Airbags', value: 6 },
    { name: 'Isofix', value: '✅' },
    { name: 'Tyre Pressure Monitor', value: '✅' },
    { name: 'Electric Park Brake', value: '✅' },
    { name: 'Hill Start Assist', value: '❌' },
    { name: 'Emergency Brake Assist', value: '✅' },
    { name: 'Parking Sensors', value: 'Front & Rear' },
    { name: 'Park Assist', value: 'Wired' },
    { name: 'Reverse Camera', value: '✅' },
    { name: '360° View Camera', value: 'unknown' },
    { name: 'Cruise Control', value: 'Adaptive' },
    { name: 'Lane Departure Warning', value: '✅' },
    { name: 'Blind Spot Monitor', value: '✅' },
  ];
  const AdditionalFeatures = [
    {
      name: 'Features',
      value: (
        <>
          <ul className="h-[500px] items-center ">
            <li className="my-2">Twin Dark Exhaust Tip</li>
            <li className="my-2">Automatic High-beam</li>
            <li className="my-2">Integrated Braking System</li>
            <li className="my-2">Alfa Romeo D.N.A System</li>
            <li className="my-2">Brembo Braking System</li>
            <li className="my-2">Brake Assistance System</li>
            <li className="my-2">Limited Slip Differential</li>
            <li className="my-2">
              Aluminium inserts on Dashboard + Central Tunnel + Door Panels
            </li>
            <li>Sports Front and Rear Bumpers</li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <Layout>
      <BackAndShareBar from="newcar_spacefeature" />
      <div className="lg:container lg:mx-auto md:container md:mx-auto  lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col px-0 md:px-4 lg:px-4 mb-8">
        <ProductHeaderSlider carImgList={imgArray} from="newCar" />
        <ProductPriceAndContactInfo from="newCar" />
      </div>

      <div>
        <NewCarSpaceFeatureHomeTogglers
          arrayName={engineTransmission}
          performance={performance}
          title="Engine & Transmission"
        />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers
          arrayName={performance}
          title="Performance"
        />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers
          arrayName={performance2}
          title="Performance"
        />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers
          arrayName={measurements}
          title="Measurements"
        />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers
          arrayName={suspensionBrakesWheels}
          title="Suspension, Brakes & Wheels"
        />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers arrayName={exterior} title="Exterior" />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers arrayName={interior} title="Interior" />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers
          arrayName={InfotainmentSystem}
          title="InfotainmentSystem"
        />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers
          arrayName={SafetyDrivingAssistance}
          title="Safety & Driving Assistance"
        />
      </div>
      <div>
        <NewCarSpaceFeatureHomeTogglers
          arrayName={AdditionalFeatures}
          title="Additional Features"
          feature
        />
      </div>
    </Layout>
  );
};

export default NewCarSpaceFeatureHome;
