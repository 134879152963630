// Dashboard.js
import React from 'react';
import heroImage from '../../../assets/images/web/Home_Page_Search_Banner_copy.png';
import HeroBox from '../CarsComponents/HeroBox';

const HeroSection = ({ vehicleData, onSearch, brandList, carModelList }) => {
  return (
    <div className={` p-0  mb-16  md:bg-white container mx-auto w-full`}>
      <HeroBox
        img={heroImage}
        vehicleData={vehicleData}
        brandList={brandList}
        carModelList={carModelList}
        onSearch={onSearch}
        title={'USED CARS'}
        from="home"
      />
    </div>
  );
};

export default HeroSection;
