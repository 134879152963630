import React, { useEffect } from 'react';
import FormField from '../../../common/FormFileds/Admin/FormField';
import { useFormik } from 'formik';

const KeyInfo = (props) => {
  const { formik, carModelList } = props;

  return (
    <div className="p-6.5">
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="select"
            label={'Car Model'}
            id={'model_id'}
            options={carModelList}
            formik={formik}
          />
        </div>

        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Name *"
            id="name"
            placeholder=" "
            required={true}
            formik={formik}
          />
        </div>

        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Price *"
            id="price"
            placeholder=" "
            formik={formik}
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Instalment"
            id="instalment"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Depreciation"
            id="depreciation"
            formik={formik}
            placeholder=" "
          />
        </div>

        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="COE Price *"
            id="coe_price"
            placeholder=" "
            formik={formik}
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Road Tax"
            placeholder=" "
            id="road_tax"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="OMV"
            id="omv"
            formik={formik}
            placeholder=" "
          />
        </div>

        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="PARF Rebate"
            id="arf"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>

      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            placeholder=" "
            label="VES / CVES"
            id="ves"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Total Basic"
            id="total_basic"
            placeholder=" "
            formik={formik}
          />
        </div>

        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Premium"
            id="premium"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>
    </div>
  );
};

export default KeyInfo;
