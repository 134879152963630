import * as yup from 'yup';

const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];

const VehicleTypeSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	status: yup.string().required('Status is required'),
	isImageRequired: yup.boolean(),
	image: yup
		.mixed()
		.when('isImageRequired', (isImageRequired) => isImageRequired[0] === undefined ? yup.mixed() : yup.mixed().required('Image is required'))
		.test('fileType', 'Invalid file type. Please select a valid image (JPEG, JPG, PNG).', (value) => {
			if (!value) {
				return true;
			}
			const fileExtension = value.name.split('.').pop().toLowerCase();
			return allowedExtensions.includes(fileExtension);
		}),
});

export default VehicleTypeSchema;
