import AdSTypeController from '../../api/AddTypesController';
import * as actionTypes from './actionTypes';

export const adsTypeInit = () => {
  return {
    type: actionTypes.ADS_TYPE_INIT,
  };
};

export const fetchAdsTypeListSuccess = (adsTypeData) => {
  return {
    type: actionTypes.FETCH_ADS_TYPE_LIST_SUCCESS,
    payload: adsTypeData,
  };
};

export const fetchAdsTypeListFailure = (error) => {
  return {
    type: actionTypes.FETCH_ADS_TYPE_SINGLE_FAILURE,
    payload: error,
  };
};

export const fetchPublicAdsTypeListSuccess = (adsTypeData) => {
  return {
    type: actionTypes.PUBLIC_ADS_TYPE_SUCCESS,
    payload: adsTypeData,
  };
};

export const fetchPublicAdsTypeListFailure = (error) => {
  return {
    type: actionTypes.PUBLIC_ADS_TYPE_FAILURE,
    payload: error,
  };
};

export const fetchAdsTypesSingleSuccess = (data) => {
  return {
    type: actionTypes.FETCH_ADS_TYPE_SINGLE_SUCCESS,
    payload: data,
  };
};

export const fetchAdsTypeSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_ADS_TYPE_SINGLE_FAILURE,
    payload: error,
  };
};

export const updateAdsTypeSuccess = (adsType) => {
  return {
    type: actionTypes.UPDATE_ADS_TYPE_SUCCESS,
    payload: adsType,
  };
};

export const updateAdsTypeFailure = (error) => {
  return {
    type: actionTypes.UPDATE_ADS_TYPE_FAILURE,
    payload: error,
  };
};

export const fetchAdsTypeList = (page, isProcess = false, isPublic = false) => {
  return async (dispatch) => {
    dispatch(adsTypeInit());
    AdSTypeController.list(page, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          dispatch(fetchAdsTypeListFailure('Something went wrong'));
        } else if (result.data.status !== 'Failed') {
          if (isProcess) {
            const processData = result.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            dispatch(fetchAdsTypeListSuccess(processData));
          } else {
            dispatch(fetchAdsTypeListSuccess(result.data.data));
          }
        } else {
          dispatch(fetchAdsTypeListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchAdsTypeListFailure('Something went wrong'));
      });
  };
};
export const fetchPublicAdsTypeList = (
  page,
  isProcess = true,
  isPublic = false
) => {
  return async (dispatch) => {
    dispatch(adsTypeInit());
    AdSTypeController.publicList(page, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          dispatch(fetchPublicAdsTypeListFailure('Something went wrong'));
        } else if (result.data.status !== 'Failed') {
          const processData = result.data.data.rows.map((item) => ({
            value: item.id,
            label: `${item.name} $${item.price}`,
            type: item.type,
            price: item.price,
        }));
        
          dispatch(fetchPublicAdsTypeListSuccess(processData));
        } else {
          dispatch(fetchPublicAdsTypeListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchPublicAdsTypeListFailure('Something went wrong'));
      });
  };
};

export const fetchAdsTypeSingle = (id) => {
  return async (dispatch) => {
    dispatch(adsTypeInit());
    AdSTypeController.single(id)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchAdsTypeListFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchAdsTypesSingleSuccess(result.data.data));
        } else {
          dispatch(fetchAdsTypeListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchAdsTypeListFailure('Something went wrong'));
      });
  };
};

export const updateAdsType = (id, updatedData) => {
  return async (dispatch) => {
    dispatch(adsTypeInit());
    AdSTypeController.update(id, updatedData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updateAdsTypeFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updateAdsTypeSuccess(result.data.message));
        } else {
          dispatch(updateAdsTypeFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updateAdsTypeFailure('Something went wrong'));
      });
  };
};
