// UserCarPostingForm.js
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createSeller,
  // fetchMerchantList,
  fetchSellerList,
  fetchSellerSingle,
  updateSeller,
} from '../../../store/actions';
import Loader from '../../common/Loader/Loader';
import FullWidthSection from '../FullWidthSection/FullWidthSection';
import Layout from '../Layout/Base/Layout';
import CreateUserForm from './CreateUserForm';

const SalesPersonPost = (props) => {
  const { id } = useParams();
  console.log(id);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    loading,
    error,
    success,
    fetchSellerList,
    createSeller,
    // fetchMerchantList,
    // merchantList,
    sellerList,
    userData,
    fetchSellerSingle,
    singleSeller,
    updateSeller,
    tabLabel,
  } = props;
  const [tabLabelSet, setTabLabelSet] = useState(tabLabel);

  // console.log(id);
  const [page, setPage] = useState(1);
  // console.log(merchantList);
  const fetchData = async () => {
    await Promise.all([
      // fetchMerchantList('all'),
      fetchSellerList(page, userData.id),
    ]);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (id) {
      fetchSellerSingle(id);
    }
  }, [id]);

  const onPageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const { state } = location;
    if (state && state.activeTab !== undefined) {
      setTabLabelSet(state.activeTab);
      console.log('Active Tab:', tabLabelSet);
    }
  }, [location]);
  const onSubmit = (values) => {
    if (id) {
      console.log('id =================== ', singleSeller);
      updateSeller(id, values);
    } else {
      createSeller(values);
    }
  };
  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          navigate('/user-dashboard', {
            state: {
              activeTab: tabLabelSet,
            },
          });
        },
        onClose: () => {},
      });
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  const renderContent = () => {
    if (id) {
      return (
        <CreateUserForm
          onSubmit={onSubmit}
          totalCount={sellerList?.count}
          handlePageChange={onPageChange}
          defaultPage={page}
          initialValues={singleSeller}
          // merchant={merchantList}
          userData={userData}
          loading={loading}
        />
      );
    }

    return (
      <CreateUserForm
        onSubmit={onSubmit}
        userData={userData}
        loading={loading}
      />
    );
  };

  let content = '';

  content = renderContent();

  return (
    <Layout>
      <FullWidthSection title="Salesperson Post" bgColor="#cccccc" />
      <div className="container mx-auto">
        {loading && <Loader />}
        {content}
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    error: state.seller.error,
    success: state.seller.success,
    loading: state.seller.loading,
    sellerList: state.seller.sellerList,
    // merchantList: state.merchant.merchantList,
    userData: state.auth.userData,
    singleSeller: state.seller.singleSeller,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSeller: (data) => dispatch(createSeller(data)),
    // fetchMerchantList: (page) => dispatch(fetchMerchantList(page)),
    fetchSellerList: (page, id) => dispatch(fetchSellerList(page, id)),
    fetchSellerSingle: (id) => dispatch(fetchSellerSingle(id)),
    updateSeller: (id, data) => dispatch(updateSeller(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesPersonPost);
