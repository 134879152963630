// Dashboard.js
import React from 'react';
import Layout from './../../../components/admin/Layout/Base/Layout';

const Dashboard = (props) => {

	return (
		<Layout >
			<div className="p-8">
				<h2 className="text-2xl font-semibold mb-4">Dashboard</h2>
				<p>Welcome to the admin dashboard! Customize this page as needed.</p>
			</div>
		</Layout>
	);
}


export default Dashboard;
