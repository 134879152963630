import React, { useEffect, useState } from 'react'
import AnalyticsTable from '../../../components/admin/Table/Table';
import { SearchInput } from '../../../components/common/SearchInput/SearchInput';
import { SingleSelectInput } from '../../../components/common/SingleSelectInput/SingleSelectInput';
import { connect } from 'react-redux';
import { MdFilterListAlt } from "react-icons/md";
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { fetchPaymentList } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';

const PaymentList = (props) => {
    const {
		paymentList,
		fetchPaymentList,
		paymentViewHandler,
		paymentEditHandler,
		loading,
		viewBtnShow = true,
		from,
		paymentsData = []
        } = props

    // console.log('paymentList >>>', paymentList)

    const [page, setPage] = useState(1);
    const selectOptions = [
        {label: 'Used Car', value: 'usedcar'},
        // {label: 'New Car', value: 'newcar'},
        // {label: 'Car Request', value: 'carrequest'}
    ]

    const searchHandler = (value) => {
        // console.log('value >>>', value)
      
    }

    const onListingTypeSelect = (listingType) => {
        console.log('listingType >>>', listingType)

    }

	const actionHandler = (id, flag) => {
		const findEditItem = paymentList?.rows?.find((item) => item?.id == id);
    // console.log(findEditItem)
		if(flag == 'edit'){
			paymentEditHandler(findEditItem)
		}
		else if(flag == 'view'){
			paymentViewHandler(findEditItem)
		}
	}
	
    const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Order ID',
			selector: row => row?.order_id,
			sortable: true
		},
		{
			name: 'User Id',
			selector: row =>  row?.user_id, 
			sortable: true
		},
		{
			name: 'Name',
			selector: row =>  row?.user?.user_details?.first_name, 
			sortable: true
		},
		{
			name: 'Payment For',
			selector: row => row?.type == 'used_car' ? 'Used Car listing' : 'New Car listing',
			sortable: false
		},
		{
			name: 'Payment Type',
			selector: row => row?.paymentMethod == "pay_now" ? "Pay Now" : row?.paymentMethod == 'credit_card' ? 'Credit Card' : row?.paymentMethod,
			sortable: false
		},
    {
			name: 'Amount',
			selector: row => row?.total_amount,
			sortable: false
		},
    	{
			name: 'Status',
			selector: row =>  row?.status,
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => actionHandler(id, 'edit')}
					showDeleteButton={false}
					showViewButton={viewBtnShow}
					onView={(id) => actionHandler(id, 'view')}
				/>
			),
			sortable: false,
			width: '20%'
		},
	];

    const onPageChange = (page) => {
        setPage(page)
    }

	useEffect(() => {
		fetchPaymentList(page)
	},[page]);

  return (
    <div className="p-6">

        {/* <div className="mb-4.5 flex flex-col gap-6 xl:flex-row ">
            <div className="w-full xl:w-1/2 ">
               <SearchInput searchHandler={searchHandler} label='Search' />
            </div>
            <div className="w-full xl:w-1/2 flex flex-row ">
                <div className='w-full'>
                    <SingleSelectInput label='Listing Type' options={selectOptions} onSelect={onListingTypeSelect} />
                </div>
                <MdFilterListAlt size={30} style={{marginTop: '10px', marginLeft: '5px'}} />
            </div>
        </div> */}

        <div>
			{
				loading ?  <Loader /> : <AnalyticsTable
                defaultPage={page}
                columns={columns}
                data={from == 'newcar' ? paymentsData : paymentList?.rows}
                totalCount={from == 'newcar' ? paymentsData?.length : paymentList?.count}
                handlePageChange={onPageChange}
            /> 	
			}
            
        </div>
    </div>
        
  )
}

const mapStateToProps = (state) => {
	return {
        paymentList: state.payment.paymentList,
        loading: state.payment.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
        fetchPaymentList: (page) => dispatch(fetchPaymentList(page)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);