import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


import Layout from '../../../../components/admin/Layout/Base/Layout';
import UserForm from './UserForm';
import SectionHeader from '../../../../components/admin/SectionHeader/SectionHeader';
import UserTable from '../../../../components/admin/Table/Table';
import { fetchAdminList, createAdmin, fetchAdminSingle, fetchRoleList, updateAdmin } from '../../../../store/actions';
import Loader from '../../../../components/common/Loader/Loader';
import ActionButton from '../../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';


const User = (props) => {
	const { loading, error, success, adminList, fetchAdminList, createAdmin, fetchAdminSingle, singleAdmin, fetchRoleList, roleList, updateAdmin } = props;

	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1)

	const fetchData = async () => {
		await Promise.all([fetchAdminList(page), fetchRoleList('', true)]);
	};

	useEffect(() => {
		setShowTable(true)
	}, [adminList])

	useEffect(() => {
		fetchData()
	}, [fetchAdminList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchData()
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id)
			setShowTable(false)
			setButtonLabel('List')
			fetchAdminSingle(id)
		} else if (action === 'delete') {
			// deleteVehicleType(id);
		}
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null)
	};

	const onPageChange = (page) => {
		setPage(page)
	};

	const onSubmit = (values) => {
		if (editingId) {
			updateAdmin(editingId, values, 'user')
		} else {
			createAdmin(values, 'user');
		}
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row.username,
			sortable: true
		},
		{
			name: 'Email',
			selector: row => row.email,
			sortable: true
		},
		{
			name: 'Phone',
			selector: row => row.contact_no,
			sortable: false
		},
		{
			name: 'Status',
			selector: row => row.status ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					showDeleteButton={false}
					onDelete={id => handleEditDelete(id, 'delete')}
				/>
			),
			sortable: false
		},
	];

	const renderContent = () => {
		if (loading) {
			return <Loader />	
		}

		if (showTable && !loading) {
			return (
				<UserTable
				defaultPage={page}
					columns={columns} data={adminList.rows == undefined ? [] : adminList.rows} totalCount={adminList.count} handlePageChange={onPageChange}
				/>
			);
		}

		if (editingId && !loading) {
			return <UserForm onSubmit={onSubmit} initialValues={singleAdmin} onCancel={handleButtonClick} roleList={roleList} />;
		}
		if (!loading) {
			return <UserForm onSubmit={onSubmit} onCancel={handleButtonClick} roleList={roleList} />;
		}
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Admin User" buttonText={buttonLabel} onButtonClick={handleButtonClick} />
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.adminUser.loading,
		error: state.adminUser.error,
		success: state.adminUser.success,
		adminList: state.adminUser.adminList,
		roleList: state.role.roleList,
		singleAdmin: state.adminUser.singleAdmin
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAdminList: (page) => dispatch(fetchAdminList(page)),
		createAdmin: (data, type) => dispatch(createAdmin(data, type)),
		updateAdmin: (id, data, type) => dispatch(updateAdmin(id, data, type)),
		fetchAdminSingle: (id) => dispatch(fetchAdminSingle(id)),
		fetchRoleList: (page, isProcess) => dispatch(fetchRoleList(page, isProcess))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
