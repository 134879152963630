// IndividualRegistrationForm.js

import React from 'react';
import { useFormik } from 'formik';
import IndividualRegisterSchema from '../../../schemas/IndividualRegisterSchema';
import MerchantRegisterSchema from '../../../schemas/MerchantRegisterSchema';
import AuthForm from '../../common/AuthForm/AuthForm';

import IndividualRegistrationFormFields from './IndividualRegistrationFormFields';
import MerchantRegistrationFormFields from './MerchantRegistrationFormFields';

const RegistrationForm = (props) => {
	const initialValues =
		props.userType === 'Merchant'
			? {
				company_name: '',
				uen: '',
				name: '',
				contact_no: '',
				email: '',
			}
			: {
				name: '',
				username: '',
				contact_no: '',
				email: '',
				password: '',
			};

	const validationSchema =
		props.userType === 'Merchant'
			? MerchantRegisterSchema
			: IndividualRegisterSchema;

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			props.submitHandler(values);
		},
	});

	const formBlock =
		props.userType === 'Merchant' ? (
			<MerchantRegistrationFormFields formik={formik} />
		) : (
			<IndividualRegistrationFormFields formik={formik} />
		);

	return (
		<AuthForm
			formik={formik}
			loading={props.loading}
			error={props.error}
			formBlock={formBlock}
			buttonText="Create My account"
			additionalText="Already have an account?"
			additionalLink="/login"
			additionalLinkText="Login Now"
		/>
	);
};

export default RegistrationForm;
