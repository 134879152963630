import * as actionTypes from './actionTypes';
import AuthController from './../../api/AuthController';

export const authInit = () => {
  return {
    type: actionTypes.AUTH_INIT,
  };
};

export const authSuccess = (token, userData, redirectTo) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userData: userData,
    redirectTo: redirectTo,
  };
};

export const authFail = (error, redirectTo) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
    redirectTo: redirectTo,
  };
};

export const authRegisterSuccess = (success) => {
  return {
    type: actionTypes.AUTH_REGISTER_SUCCESS,
    success: success,
  };
};

export const authLogin = (data) => {
  return (dispatch) => {
    dispatch(authInit());
    AuthController.loginRequest(data)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(authFail(errorMsg));
        } else if (result.data.status !== 'Failed') {
          localStorage.setItem(
            'userData',
            btoa(JSON.stringify(result.data.data))
          );
          localStorage.setItem('token', result.data.token);
          let redirectTo =
            result.data.data.type !== 'user' ? '/user-dashboard' : '/dashboard';
          dispatch(
            authSuccess(result.data.token, result.data.data, redirectTo)
          );
        } else {
          dispatch(authFail('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(authFail('Something went wrong'));
      });
  };
};

export const authLogOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userData');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authCheckState = () => {
	return (dispatch) => {
		const token = localStorage.getItem("token");
		if (!token) {
			dispatch(authLogOut());
		} else {
			const userData = JSON.parse(atob(localStorage.getItem("userData")));
      let redirectTo = userData.type !== 'user' ? '/user-dashboard' : '/dashboard';
			dispatch(authSuccess(token, userData, redirectTo));
		}
	};
};

export const register = (data, type) => {
  return (dispatch) => {
    dispatch(authInit());
    AuthController.registrationRequest(data, type)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          if (Array.isArray(errorMsg) && errorMsg.length > 0) {
            const firstError = Object.values(errorMsg[0])[0];
            dispatch(authFail(firstError));
          } else {
            dispatch(authFail(errorMsg));
          }
        } else if (result.data.status !== 'Failed') {
          dispatch(authRegisterSuccess(result.data.message));
        } else {
          dispatch(authFail('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(authFail('Something went wrong'));
      });
  };
};

export const forgetPassword = (data) => {
	return (dispatch) => {
		dispatch(authInit());
		AuthController.forgetPasswordRequest(data).then((result) => {
			if (result instanceof Error) {
				let errorMsg = result.response
					? result.response.data
						? result.response.data.message
						: 'Something went wrong'
					: 'Something went wrong';
				dispatch(authFail(errorMsg));
			} else if (result.data.status !== 'Failed') {
				dispatch(authRegisterSuccess(result.data.message));
			} else {
				dispatch(authFail('Something went wrong'));
			}
		})
		.catch((error) => {
			dispatch(authFail('Something went wrong'));
		});
	};
}

export const resetPassword = (data) => {
	return (dispatch) => {
		dispatch(authInit());
		AuthController.resetPasswordRequest(data).then((result) => {
			if (result instanceof Error) {
				let errorMsg = result.response
					? result.response.data
						? result.response.data.message
						: 'Something went wrong'
					: 'Something went wrong';
				dispatch(authFail(errorMsg));
			} else if (result.data.status !== 'Failed') {
				dispatch(authRegisterSuccess(result.data.message));
			} else {
				dispatch(authFail('Something went wrong'));
			}
		})
		.catch((error) => {
			dispatch(authFail('Something went wrong'));
		});
	};
}