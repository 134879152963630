import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class PromoCodesController extends BaseController {
  static async list(page) {
    try {
      const pageQueryParam = page ? `?page=${page}` : '';
      return await this.axiosGet(`${endpoint.PROMO_CODES}${pageQueryParam}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async create(promoData) {
    try {
      return await this.axiosPost(endpoint.PROMO_CODES_CREATE, promoData, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async single(promoId) {
    try {
      return await this.axiosGet(`${endpoint.SINGLE_PROMO_CODES}/${promoId}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async validationCheck(promoCode) {
    try {
      return await this.axiosGet(
        `${endpoint.SITE_PROMO_CODE_CHECK}/${promoCode}`,
        {
          headers: this.getHeaders(),
        }
      );
    } catch (error) {
      return error;
    }
  }
  static async update(promoId, promoData) {
    try {
      return await this.axiosPost(
        `${endpoint.PROMO_CODES_UPDATE}/${promoId}`,
        promoData,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }
}
