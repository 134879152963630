import React from 'react';
import Layout from '../../../components/site/Layout/Base/Layout';
import Advertisement from '../../../assets/images/web/advertisement.png'
import newsData from './../../../news.json'; 
import LatestCars from '../../../components/site/CarsComponents/LatestCars';


const News = () => {
  return (
    <Layout>
      <div className="container mx-auto">
        <img src={Advertisement} alt="" className="my-5 w-full" />
      </div>
      <div className="container mx-auto md:grid-cols-1 lg:grid-cols-3 grid-cols-1  gap-5  lg:grid xl:grid ">
	  		{newsData.map(article => (
				<LatestCars
					key={article.id}
					img={article.image}
					title={article.title}
					excerpt={article.excerpt}
					category="CAR News"
					date={article.date}
					link={article.slug}
				/>
			))}
		</div>
    <div className='flex flex-row justify-center items-center mt-13'>
        <button className='border border-2 border-[#0958D9] rounded text-[#0958D9] h-[46px] w-[230px] text-[20px]'>Load More</button>
    </div>
    </Layout>
  );
};

export default News;
