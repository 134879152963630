export const endpoint = {
	LOGIN: 'login',
	CUSTOMER_REGISTER: 'individual_register',
	MERCHANT_REGISTER: 'merchant_register',
	FORGET_PASSWORD: 'forget_password',
	RESET_PASSWORD: 'reset_password',

	// Brand
	BRAND_LIST: 'brand',
	BRAND_CREATE: 'brand/create',
	BRAND_UPDATE: 'brand/update',
	BRAND_SINGLE: 'brand',
	BRAND_DELETE: 'brand/delete',

	BRAND_LIST_PUBLIC_LIST: 'site/brand',

	// Vehicle Type
	VEHICLE_TYPE_LIST: 'vehicle',
	VEHICLE_TYPE_CREATE: 'vehicle/create',
	VEHICLE_TYPE_UPDATE: 'vehicle/update',
	VEHICLE_TYPE_SINGLE: 'vehicle',
	VEHICLE_TYPE_DELETE: 'vehicle/delete',

	VEHICLE_TYPE_LIST_PUBLIC_LIST: 'site/vehicle',

	// Color
	COLOR_LIST: 'color',
	COLOR_CREATE: 'color/create',
	COLOR_UPDATE: 'color/update',
	COLOR_SINGLE: 'color',
	COLOR_DELETE: 'color/delete',

	COLOR_LIST_PUBLIC_LIST: 'site/color',

	// roles
	ROLE_LIST: 'role',
	ROLE_SINGLE: 'role',
	ROLE_CREATE: 'role/create',
	ROLE_UPDATE: 'role/update',
	ROLE_DELETE: 'role/delete',

	// users
	USER_LIST: 'user',
	USER_SINGLE: 'user',

	USER_CREATE: 'user/create_admin',
	USER_CUSTOMER_CREATE: 'user/create_customer',
	USER_MERCHANT_CREATE: 'user/create_merchant',

	USER_UPDATE: 'user/update_admin',
	USER_CUSTOMER_UPDATE: 'user/update_customer',
	USER_MERCHANT_UPDATE: 'user/update_merchant',

	COUNTRY_LIST: 'site/country',
	COUNTRY_SINGLE: 'country',
	COUNTRY_CREATE: 'country/create',
	COUNTRY_UPDATE: 'country/update',
	COUNTRY_DELETE: 'country/delete',

	CITY_LIST: 'city',
	CITY_SINGLE: 'city',
	CITY_CREATE: 'city/create',
	CITY_UPDATE: 'city/update',
	CITY_DELETE: 'city/delete',

	MERCHANT_PROFILE_GET: 'site/user_profile',

	MERCHANT_SELLERS_GET: 'site/seller_list',

	// Car Categories
	CAR_CATEGORY_LIST: 'car_category',
	CAR_CATEGORY_SINGLE: 'car_category',
	CAR_CATEGORY_CREATE: 'car_category/create',
	CAR_CATEGORY_UPDATE: 'car_category/update',
	CAR_CATEGORY_DELETE: 'car_category/delete',

	CAR_CATEGORY_LIST_PUBLIC_LIST: 'site/car_category',

	// Fuel Types
	FUEL_TYPE_LIST: 'fuel_type',
	FUEL_TYPE_SINGLE: 'fuel_type',
	FUEL_TYPE_CREATE: 'fuel_type/create',
	FUEL_TYPE_UPDATE: 'fuel_type/update',
	FUEL_TYPE_DELETE: 'fuel_type/delete',

	FUEL_TYPE_LIST_PUBLIC_LIST: 'site/fuel_type',

	// Setting
	SETTING_SINGLE: 'basic_settings',
	SETTING_UPDATE: 'basic_settings/update',
	SETTING_PUBLIC: 'site/setting',

	// Car Model
	CAR_MODEL_LIST: 'car_model',
	CAR_MODEL_SINGLE: 'car_model',
	CAR_MODEL_CREATE: 'car_model/create',
	CAR_MODEL_UPDATE: 'car_model/update',
	CAR_MODEL_DELETE: 'car_model/delete',

	CAR_MODEL_LIST_PUBLIC_LIST: 'site/car_model',

	// Car Sub-Model
	CAR_SUB_MODEL_LIST_BY_MODEL: 'car_sub_model',
	CAR_SUB_MODEL_LIST: 'car_sub_model/list',
	CAR_SUB_MODEL_SINGLE: 'car_sub_model',
	CAR_SUB_MODEL_CREATE: 'car_sub_model/create',
	CAR_SUB_MODEL_UPDATE: 'car_sub_model/update',
	CAR_SUB_MODEL_DELETE: 'car_sub_model/delete',

	CAR_SUB_MODEL_PUBLIC_LIST: 'site/car_sub_model',

	// Used Car Listing
	USED_CAR_LIST: 'used_car_listing',
	USED_CAR_SINGLE: 'used_car_listing',
	USED_CAR_UPDATE: 'used_car_listing/update',
	USED_CAR_DELETE: 'car_sub_model/delete',
	USED_CAR_ENQUIRY_COUNT: 'update_enquiry_count',
	USED_CAR_BOOST: 'used_car_listing/boost',

	USED_CAR_PUBLIC_LIST: 'site/used_car_listing',
	USED_CAR_CREATE: 'site/used_car_listing/create',
	LATEST_USED_CAR_LIST: 'site/latest_used_car',
	USED_CAR_STATUS_UPDATE: 'site/used_car_listing/update_status',
	USED_CAR_PUBLIC_SINGLE: 'site/used_car_listing',
	USED_CAR_UPDATE_PUBLIC: 'site/used_car_listing/update',
	USED_CAR_LIST_ENQUIRY: 'site/used_car_listing',
	USED_CAR_DETAILS: 'site/used_car_details',
	// USED_CAR_IMAGE_DELETE: 'site/used_car_listing/delete_image', 
	USEDCAR_SHORTLIST_CREATE: 'site/shortlist/create',
	USEDCAR_SHORTLIST_DELETE: 'site/shortlist/delete',

	MERCHANT_USED_CAR_LIST: 'site/user_listed_used_car_listing',
	MERCHANT_UPDATE_CLICK_COUNT: 'site/used_car_listing/update_click_count',

	SEARCH_USED_CAR: 'site/user_listed_used_car_listing',
	SELLER_LIST: 'site/seller_list',
	SELLER_SINGLE: 'site/seller',
	CREATE_SELLER: 'user/create_customer',
	UPDATE_SELLER: 'site/seller/update',
	SUGGESTED_USED_CAR_GET: 'site/suggested_used_car_listing',

	//get instant offers
	GET_INSTANT_OFFER_CREATE: 'site/instant-offer',

	SELLER_ENQUIRY_COUNT: 'site/seller/enquirycount',

	//Payment
	GET_PAYMENT_LIST: 'orders',
	PAYMENT_STATUS_UPDATE: 'orders/update',
	ORDER_PROMO_COUNTER: 'orders/promo-code-order-counts',
	ORDER_LIST_BY_PROMO_CODE_ID: 'orders/promo-code-order-list',
	SITE_PAYMENT_LIST: 'site/user-order-list',
	SITE_SINGLE_PAYMENT: 'site/single-order',

	//Adds Ons

	ADDS_ONS_LIST: 'addons',
	ADDS_ONS_SINGLE: 'addons',
	ADDS_ONS_UPDATE: 'addons/update',
	// ADDS_ONS_LIST_PUBLIC_LIST: 'site/fuel_type',
	SUBSCRIPTION_CREATE: 'site/subscription/create',

	// ads Type
	ADS_TYPE_LIST: 'addTypes',
	ADS_TYPE_UPDATE: 'addTypes/update',
	SITE_ADS_TYPE: 'site/adtypes-list',
	SITE_ADDS_ONS: 'site/adons-list',

	// new Car Listing
	NEW_CAR_LIST: 'new_cars',
	NEW_CAR_CREATE: 'new_cars/create',
	NEW_CAR_SINGLE: 'new_cars',
	NEW_CAR_UPDATE: 'new_cars/update',
	NEW_CAR_SEARCH_LIST: 'site/new_car_listing',
	NEW_CAR_LIST_LATEST: 'site/latest_new_car',
	NEW_CAR_SITE_CREATE: 'site/new_car_listing/create',
	SINGLE_NEW_CAR: 'site/new_car_listing',
	NEW_CAR_SITE_UPDATE: 'site/new_car_listing/update',
	NEW_CAR_PUBLIC_LIST: 'site/buy_new_car_listing',

	NEW_CAR_ENQUIRY_COUNT: 'site/new_car_listing/update_enquiry_count',

	// site settings
	SITE_SETTING_SINGLE: 'site/user_profile',
	SITE_SETTING_UPDATE_CUSTOMER: 'site/update_customer',
	SITE_SETTING_UPDATE_MERCHANT: 'site/update_merchant',

	// promo codes
	PROMO_CODES: 'promo_codes',
	SINGLE_PROMO_CODES: 'promo_codes',
	PROMO_CODES_CREATE: 'promo_codes/create',
	PROMO_CODES_UPDATE: 'promo_codes/update',
	SITE_PROMO_CODE_CHECK: 'site/promo_codes',
	// shortlisted
	SHORTLISTED_USER_CAR_LIST: 'site/shortlist',
	SEARCHED_USED_CAR: 'site/searched_used_car_listing',

	//Image 
	CAR_IMAGE_DELETE: 'upload/delete_photos',
	MULTIPLE_CAR_IMAGE_UPLOAD: 'upload/upload_photos',

};
