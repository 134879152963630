import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../../components/common/FormFileds/Admin/FormField';
import MerchantSchema from '../../../../schemas/MerchantSchema';
import { options, approveOptions, countryOptions } from '../../../../constants';
import FileUpload from '../../../../components/common/FormFileds/Admin/FileUpload';

const CommonUserForm = (props) => {
  const { initialValues, onSubmit , isViewMerchant} = props;

  let initVal = {
    name: '',
    email: '',
    contact_no: '',
    address_one: '',
    address_two: '',
    country_id: '',
    city_id: '',
    postal_code: '',
    status: '',
    approve_status: '',
    company_name: '',
    company_phone: '',
    company_uen: '',
    company_url: '',
    image: '',
    isImageRequired: true,
  };

  const formik = useFormik({
    initialValues: initialValues || initVal,
    validationSchema: MerchantSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialValues || initVal);
  }, [initialValues]);

  // console.log(initialValues);

  return (
    <AdminForm formik={formik} onBtnCancelClick={props.onCancel} buttonViewShow={isViewMerchant ? false : true}>
      <FileUpload isDisabled={isViewMerchant ? true : false}  formik={formik} id={'image'} />

      <div className="p-6.5">
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Company name"
              id="company_name"
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Company UEN"
              id="company_uen"
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Company Phone"
              id="company_phone"
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>
        </div>
        <div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
          <div className="w-full ">
            <FormField
              type="text"
              label="Company URL"
              id="company_url"
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>
        </div>
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField disabled={isViewMerchant ? true : false} type="text" label="Name" id="name" formik={formik} />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField disabled={isViewMerchant ? true : false} type="text" label="Email" id="email" formik={formik} />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Phone No"
              id="contact_no"
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>
        </div>

        <div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
          <div className="w-full">
            <FormField
              type="text"
              label="Address Line 1"
              id="address_one"
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>
        </div>

        <div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
          <div className="w-full">
            <FormField
              type="text"
              label="Address Line 2"
              id="address_two"
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>
        </div>

        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="select"
              label="Country"
              id="country_id"
              options={countryOptions}
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField disabled={isViewMerchant ? true : false} type="text" label="City" id="city_id" formik={formik} />
          </div>
          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Postal Code"
              id="postal_code"
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>
        </div>

        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="select"
              label="Status"
              id="status"
              options={options}
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="select"
              label="Approve Status"
              id="approve_status"
              options={approveOptions}
              formik={formik}
              disabled={isViewMerchant ? true : false}
            />
          </div>
        </div>
      </div>
    </AdminForm>
  );
};

export default CommonUserForm;
