import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import FileUpload from '../../../components/common/FormFileds/Admin/FileUpload';
import {
	ownerIdTYpeOptions,
	usedCarStatusOptions,
	transmissionTypeOptions,
	countryOptions,
} from '../../../constants';
import ImageGrid from '../../../components/common/ImageGrid';
import { deleteImageUsedCar } from '../../../store/actions';
import { connect } from 'react-redux';
import AdminUpdateNewCarSchema from '../../../schemas/AdminUpdateNewCarSchema';
import { MultiSelect } from '../../../components/common/MultiSelect/MultiSelect';
import MultipleImgUpdate from '../../../components/common/FormFileds/Admin/MultipleImgUpdate';

const NewCarForm = (props) => {
	const {
		initialValues,
		onSubmit,
		brandList,
		carModelList,
		fuelTypeList,
		vehicleTypeList,
		carCategoryList,
		// onModelChange,
		carSubModelList,
		colorList,
		deleteImageUsedCar,
		// deleteimage,
		selectedColors,
		setSelectedColors,
		selectedSubModels,
		setSelectedSubModels,
		countryList,
		from,
		isloading,
	} = props;
	const [subModelOption, setSubModelOption] = useState([]);
	const [modelList, setModelList] = useState([]);
	const [updatedFlag, setUpdateFlag] = useState(false);
	const [imageSources, setImageSources] = useState([]);

	let initVal = {
		add_type: '',
		service_type: '',
		desc: '',
		is_enquiry: false,
		fuel_type_id: '',
		remarks: '',
		status: '',
		vehicle_type_id: '',
		color_id: '',
		built_in: '',
		sub_model_id: '',
		model_id: '',
		image: '',
		promotions: ''
	};

	const formik = useFormik({
		initialValues: initialValues || initVal,
		validationSchema: AdminUpdateNewCarSchema,
		onSubmit,
	});

	useEffect(() => {
		formik.setValues(initialValues || initVal);
		if (initialValues !== null && initialValues !== undefined && initialValues?.images?.length > 0 && initialValues?.images) {
			const parstImages = JSON.parse(initialValues?.images)
			setImageSources(parstImages);

			if (initialValues.brand_id !== null) {
				handleBrandChange(initialValues.brand_id);
			}

			if (initialValues.model_id !== null) {
				handleModelChange(initialValues.model_id);
			}
		}
	}, [initialValues]);

	useEffect(() => {
		if (updatedFlag) {
			formik.setValues(initialValues);
			setUpdateFlag(false);
		}
	}, [updatedFlag]);

	const handleBrandChange = (val) => {
		setSelectedSubModels([]);
		initialValues.brand_id = val;
		const filteredModels = carModelList.filter((model) => model.brand == val);

		setModelList(filteredModels);
		setSubModelOption([]);
	};

	const handleModelChange = (val) => {
		setSelectedSubModels([]);
		initialValues.model_id = val;
		const formattedSubModels = [];

		carSubModelList.forEach((subModel) => {
			if (subModel.model_id == val) {
				formattedSubModels.push({
					value: subModel.id,
					label: subModel?.key_info?.name,
					model: subModel.model_id,
				});
			}
		});
		setSubModelOption(formattedSubModels);
	};

	const handleSubModelChange = (e) => {
		if (carSubModelList) {
			const result = carSubModelList.find((item) => item.id == e.target.value);
			initialValues.sub_model_id = e.target.value;
			if (result && result.key_info != undefined) {
				initialValues.coe = result.key_info.coe_price;
				initialValues.depreciation = result.key_info.depreciation;
				initialValues.omv = result.key_info.omv;
				initialValues.arf = result.key_info.arf;
			}

			if (result && result.enginer_transmission != undefined) {
				initialValues.transmission_type =
					result.enginer_transmission.transmission_type;
				initialValues.engine_capacity =
					result.enginer_transmission.engine_capacity;
				initialValues.fuel_type_id = result.enginer_transmission.fuel_type_id;
			}

			if (result && result.performance != undefined) {
				initialValues.power = result.performance.power;
			}

			setUpdateFlag(true);
		}
	};

	// console.log(
	// 	'carCategoryList---------',
	// 	carCategoryList.filter((car) => car?.type == 2)
	// );
	const handleId = (id) => {
		deleteImageUsedCar(id);
		console.log('image', id);
	};

	useEffect(() => {
		if (initialValues?.colors) {
			const editedItemColors = initialValues?.colors.split(',');
			const initiaColors = [];
			for (const ColorId of editedItemColors) {
				const findColor = colorList?.find((item) => item?.value == ColorId);
				initiaColors?.push(findColor);
			}
			setSelectedColors(initiaColors);
			// console.log('initiaColors items >>>', initiaColors)
		}
	}, [initialValues?.colors]);

	useEffect(() => {
		if (initialValues?.new_car_submodel?.length > 0) {
			const subModelInitialArr = initialValues?.new_car_submodel?.map(
				(item) => {
					const itemObj = {
						value: item?.sub_model_id,
						label: item?.car_submodel?.key_info?.name,
					};
					return itemObj;
				}
			);

			setSelectedSubModels(subModelInitialArr);
		}
	}, [
		initialValues?.new_car_submodel?.length,
		initialValues?.new_car_submodel,
	]);
	// console.log('initialValues', initialValues);
	// console.log('initialValues', imageSources);

	return (
		<AdminForm
			loading={isloading}
			formik={formik}
			onBtnCancelClick={props.onCancel}
		>
			<div className={`${from == 'site' ? 'p-0' : 'p-0 md:p-6.5'}`}>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Brand"
							id="brand_id"
							options={brandList}
							formik={formik}
							customOnChange={(e) => handleBrandChange(e.target.value)}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Model"
							id="model_id"
							options={modelList}
							formik={formik}
							customOnChange={(e) => handleModelChange(e.target.value)}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<MultiSelect
							required={true}
							optionsData={subModelOption || []}
							selectedOptions={selectedSubModels}
							setSelectedOptions={setSelectedSubModels}
							label="Sub Model"
							placeholder="Select sub model"
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Category"
							id="built_in"
							options={carCategoryList?.filter(
								(car) => car?.type === 2 && car?.status == 1
							)}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Vehicle Type"
							id="vehicle_type_id"
							options={vehicleTypeList}
							formik={formik}
						/>
					</div>

					<div className="w-full xl:w-1/2 mt-5">
						<FormField
							type="checkbox"
							label={'Featured'}
							id={'is_feature'}
							formik={formik}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<MultiSelect
							required={true}
							optionsData={colorList}
							selectedOptions={selectedColors}
							setSelectedOptions={setSelectedColors}
							label="Color"
							placeholder="Select Color"
						/>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Country"
							id="built_in"
							formik={formik}
							options={countryList}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label={'Remarks'}
							id={'remarks'}
							formik={formik}
						/>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Status"
							id="status"
							options={usedCarStatusOptions}
							formik={formik}
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2 mt-5">
						<FormField
							type="checkbox"
							label={'Allow Enquiry'}
							id={'allow_email'}
							formik={formik}
							disabled
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-2/2">
						<FormField
							type="textarea"
							label={'Promotions (up to 500 characters)'}
							id={'promotions'}
							formik={formik}
						/>
					</div>
				</div>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-2/2">
						<FormField
							type="textarea"
							label={'Description (up to 500 characters)'}
							id={'desc'}
							formik={formik}
						/>
					</div>
				</div>
				<div>
				<MultipleImgUpdate  imageSources={imageSources} formik={formik} id={'images'} multiple={true} />
					{/* <FileUpload formik={formik} id={'image'} multiple={true} /> */}
				</div>

				{/* <ImageGrid
					imageSources={imageSources}
					handleId={handleId}
					usedCarGrid
				/> */}
			</div>
		</AdminForm>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.usedCar.loading,
		error: state.usedCar.error,
		success: state.usedCar.success,
		deleteimage: state.usedCar,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteImageUsedCar: (id) => dispatch(deleteImageUsedCar(id)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(NewCarForm);
