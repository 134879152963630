import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class GetOfferController extends BaseController {
	static async create(getOfferData) {
		try {
			return await this.axiosPost(endpoint.GET_INSTANT_OFFER_CREATE, getOfferData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
