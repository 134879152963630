import * as yup from 'yup';

const PromoCodeSchema = yup.object().shape({
  type: yup.string().required('Type is required'),
  code: yup.string().required('Code is required'),
  discount_amount: yup
    .number()
    .typeError('Discount amount must be a number')
    .required('Discount amount is required'),
  expiration_date: yup.string().nullable(true).required('Expiration date is required'),
  status: yup.string().required('Status is required'),
});

export default PromoCodeSchema;
