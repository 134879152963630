import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Layout from '../../../components/admin/Layout/Base/Layout';
import FuelTypeForm from './FuelTypeForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import FuelTypeTable from '../../../components/admin/Table/Table';
import { fetchFuelTypeList, fetchFuelTypeSingle, createFuelType, updateFuelType, deleteFuelType } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';

 
const FuelType = (props) => {
	const {
		loading,
		error,
		success,
		fuelTypeList,
		fetchFuelTypeList,
		createFuelType,
		deleteFuelType,
		fetchFuelTypeSingle,
		singleFuelType,
		updateFuelType
	} = props;

	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1)

	useEffect(() => {
		setShowTable(true)
	}, [fuelTypeList])

	useEffect(() => {
		fetchFuelTypeList(1);
	}, [fetchFuelTypeList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchFuelTypeList(page);
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id)
			setShowTable(false)
			setButtonLabel('List')
			fetchFuelTypeSingle(id)
		} else if (action === 'delete') {
			deleteFuelType(id);
		}
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null)
	};

	const onPageChange = (page) => {
		setPage(page)
	};

	const onSubmit = (values) => {
		if (editingId) {
			updateFuelType(editingId, values)
		} else {
			createFuelType(values);
		}
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row.status ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					showDeleteButton={true}
					onDelete={id => handleEditDelete(id, 'delete')}
				/>
			),
			sortable: false
		},
	];

	const renderContent = () => {
		if (showTable && !loading) {
			return (
				<FuelTypeTable
				defaultPage={page}
					columns={columns} data={fuelTypeList.rows == undefined ? [] : fuelTypeList.rows} totalCount={fuelTypeList.count} handlePageChange={onPageChange}
				/>
			);
		}

		if (editingId && !loading) {
			return <FuelTypeForm onSubmit={onSubmit} initialValues={singleFuelType} onCancel={handleButtonClick} />;
		}

		if (!loading) {
			return <FuelTypeForm onSubmit={onSubmit} onCancel={handleButtonClick} />;
		}


		return <Loader />
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Fuel Type" buttonText={buttonLabel} onButtonClick={handleButtonClick} />
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.fuelType.loading,
		error: state.fuelType.error,
		success: state.fuelType.success,
		fuelTypeList: state.fuelType.fuelTypeList,
		singleFuelType: state.fuelType.singleFuelType
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchFuelTypeList: (page) => dispatch(fetchFuelTypeList(page)),
		createFuelType: (data) => dispatch(createFuelType(data)),
		deleteFuelType: (id) => dispatch(deleteFuelType(id)),
		fetchFuelTypeSingle: (id) => dispatch(fetchFuelTypeSingle(id)),
		updateFuelType: (id, data) => dispatch(updateFuelType(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelType);
