import * as actionTypes from '../actions/actionTypes';

const initialState = {
	singleSetting: null,
	loading: false,
	error: null,
	success: null,
	publicSetting: null
};

const settingReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SETTING_INIT:
			return {
				...state,
				loading: false,
				error: null,
				success: null,
			};

		case actionTypes.FETCH_SETTING_SINGLE_FAILURE:
		case actionTypes.UPDATE_SETTING_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case actionTypes.FETCH_SETTING_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleSetting: action.payload,
				error: null,
			};
		case actionTypes.FETCH_PUBLIC_SETTING_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				publicSetting: action.payload,
				error: null,
			};

		case actionTypes.UPDATE_SETTING_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};

		default:
			return state;
	}
};

export default settingReducer;
