// carCategoryActions.js
import * as actionTypes from './actionTypes';
import CarCategoryController from '../../api/CarCategoryController';

export const carCategoryInit = () => {
  return {
    type: actionTypes.CAR_CATEGORY_INIT,
  };
};

export const fetchCarCategoryListSuccess = (carCategories) => {
  return {
    type: actionTypes.FETCH_CAR_CATEGORY_LIST_SUCCESS,
    payload: carCategories,
  };
};

export const fetchCarCategoryListFailure = (error) => {
  return {
    type: actionTypes.FETCH_CAR_CATEGORY_LIST_FAILURE,
    payload: error,
  };
};

export const fetchCarCategorySingleSuccess = (carCategory) => {
  return {
    type: actionTypes.FETCH_CAR_CATEGORY_SINGLE_SUCCESS,
    payload: carCategory,
  };
};

export const fetchCarCategorySingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_CAR_CATEGORY_SINGLE_FAILURE,
    payload: error,
  };
};

export const createCarCategorySuccess = (message) => {
  return {
    type: actionTypes.CREATE_CAR_CATEGORY_SUCCESS,
    payload: message,
  };
};

export const createCarCategoryFailure = (error) => {
  return {
    type: actionTypes.CREATE_CAR_CATEGORY_FAILURE,
    payload: error,
  };
};

export const updateCarCategorySuccess = (carCategory) => {
  return {
    type: actionTypes.UPDATE_CAR_CATEGORY_SUCCESS,
    payload: carCategory,
  };
};

export const updateCarCategoryFailure = (error) => {
  return {
    type: actionTypes.UPDATE_CAR_CATEGORY_FAILURE,
    payload: error,
  };
};

export const deleteCarCategorySuccess = (carCategoryId) => {
  return {
    type: actionTypes.DELETE_CAR_CATEGORY_SUCCESS,
    payload: carCategoryId,
  };
};

export const deleteCarCategoryFailure = (error) => {
  return {
    type: actionTypes.DELETE_CAR_CATEGORY_FAILURE,
    payload: error,
  };
};

export const fetchCarCategoryList = (
  page,
  isProcess = false,
  isPublic = false
) => {
  return async (dispatch) => {
    dispatch(carCategoryInit());
    CarCategoryController.list(page, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchCarCategoryListFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          if (isProcess) {
            const processData = result.data.data.map((item) => ({
              value: item.id,
              label: item.name,
              type: item.category_type,
              status: item.status,
            }));
            // console.log('processData result.data--------------', processData);
            dispatch(fetchCarCategoryListSuccess(processData));
          } else {
            dispatch(fetchCarCategoryListSuccess(result.data.data));
          }
        } else {
          dispatch(fetchCarCategoryListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchCarCategoryListFailure('Something went wrong'));
      });
  };
};

export const fetchCarCategorySingle = (carCategoryId) => {
  return async (dispatch) => {
    dispatch(carCategoryInit());
    CarCategoryController.single(carCategoryId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchCarCategorySingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchCarCategorySingleSuccess(result.data.data));
        } else {
          dispatch(fetchCarCategorySingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchCarCategorySingleFailure('Something went wrong'));
      });
  };
};

export const createCarCategory = (carCategoryData) => {
  return async (dispatch) => {
    dispatch(carCategoryInit());
    CarCategoryController.create(carCategoryData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(createCarCategoryFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(createCarCategorySuccess(result.data.message));
        } else {
          dispatch(createCarCategoryFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(createCarCategoryFailure('Something went wrong'));
      });
  };
};

export const updateCarCategory = (carCategoryId, updatedCarCategoryData) => {
  return async (dispatch) => {
    dispatch(carCategoryInit());
    CarCategoryController.update(carCategoryId, updatedCarCategoryData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updateCarCategoryFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updateCarCategorySuccess(result.data.message));
        } else {
          dispatch(updateCarCategoryFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updateCarCategoryFailure('Something went wrong'));
      });
  };
};

export const deleteCarCategory = (carCategoryId) => {
  return async (dispatch) => {
    dispatch(carCategoryInit());
    CarCategoryController.delete(carCategoryId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(deleteCarCategoryFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(deleteCarCategorySuccess(result.data.message));
        } else {
          dispatch(deleteCarCategoryFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(deleteCarCategoryFailure('Something went wrong'));
      });
  };
};
