import {jwtDecode} from 'jwt-decode';

function checkTokenValidity(token) {
  // console.log('token ----->>>', token)
  try {
    const decodedToken = jwtDecode(token);
    // Check if the token is expired
    const currentTime = Date.now() / 1000; // get current time in seconds
    if (decodedToken.exp < currentTime) {
      return false;
      // Token is expired
    } else {
      return true;
      // Token is valid
    }
  } catch (err) {
    // Invalid token
    return err;
  }
}

const token = localStorage.getItem('token');
export const isTokenValid = checkTokenValidity(token);
