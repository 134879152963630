import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class CarCategoryController extends BaseController {
	static async list(page, isPublic) {
		try {
			const pageQueryParam = page ? `?page=${page}` : '';
			let path = isPublic ? endpoint.CAR_CATEGORY_LIST_PUBLIC_LIST : endpoint.CAR_CATEGORY_LIST
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(carCategoryId) {
		try {
			return await this.axiosGet(`${endpoint.CAR_CATEGORY_SINGLE}/${carCategoryId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(carCategoryData) {
		try {
			return await this.axiosPost(endpoint.CAR_CATEGORY_CREATE, carCategoryData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(carCategoryId, updatedCarCategoryData) {
		try {
			return await this.axiosPost(`${endpoint.CAR_CATEGORY_UPDATE}/${carCategoryId}`, updatedCarCategoryData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async delete(carCategoryId) {
		try {
			return await this.axiosGet(`${endpoint.CAR_CATEGORY_DELETE}/${carCategoryId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
