import GetOfferController from '../../api/OfferController';
import * as actionTypes from './actionTypes';

export const getOfferInit = () => {
	return {
		type: actionTypes.GET_INSTANT_OFFER_INIT,
	};
};

export const postInstantOfferSuccess = (isSuccess) => {
	return {
		type: actionTypes.GET_INSTANT_OFFER_SUCCESS,
		payload: isSuccess,
	};
};

export const postInstantOfferError = (error) => {
	return {
		type: actionTypes.GET_INSTANT_OFFER_FAILURE,
		payload: error,
	};
};

export const postGetInstantOffer = async (data) => {
	return await GetOfferController.create(data)
		.then((result) => {
			if (result instanceof Error) {
				let errorMsg = '';
				if (result.response) {
					if (result.response.data && !Array.isArray(result.response.data.message)) {
						errorMsg = result.response.data.message;
					} else {
						errorMsg = 'Something went wrong';
					}
				} else {
					errorMsg = 'Something went wrong';
				}
				return false;
				// dispatch(postInstantOfferError(errorMsg));
			} else if (result.data.status !== 'Failed') {
				// dispatch(postInstantOfferSuccess(true));
				return true;
			} else {
				// dispatch(postInstantOfferError('Something went wrong'));
				return false;
			}
		})
		.catch((error) => {
			console.log(error);
			// dispatch(postInstantOfferError('Something went wrong'));
			return false;
		});
};