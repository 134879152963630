// addsonsActions.js
import addsOnsController from '../../api/AddsOnsController';
import * as actionTypes from './actionTypes';

export const addsOnsInit = () => {
  return {
    type: actionTypes.ADDS_ONS_INIT,
  };
};

export const fetchAddsOnsListSuccess = (addsonss) => {
  return {
    type: actionTypes.FETCH_ADDS_ONS_LIST_SUCCESS,
    payload: addsonss,
  };
};

export const fetchAddsOnsListFailure = (error) => {
  return {
    type: actionTypes.FETCH_ADDS_ONS_LIST_FAILURE,
    payload: error,
  };
};

export const fetchAddsOnsSingleSuccess = (addsons) => {
  return {
    type: actionTypes.FETCH_ADDS_ONS_SINGLE_SUCCESS,
    payload: addsons,
  };
};

export const fetchAddsOnsSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_ADDS_ONS_SINGLE_FAILURE,
    payload: error,
  };
};

export const updateAddsOnsSuccess = (addsons) => {
  return {
    type: actionTypes.UPDATE_ADDS_ONS_SUCCESS,
    payload: addsons,
  };
};

export const updateAddsOnsFailure = (error) => {
  return {
    type: actionTypes.UPDATE_ADDS_ONS_FAILURE,
    payload: error,
  };
};
export const fetchPublicAddsOnsListSuccess = (addsons) => {
  return {
    type: actionTypes.PUBLIC_ADDS_ONS_SUCCESS,
    payload: addsons,
  };
};

export const fetchPublicAddsOnsListFailure = (error) => {
  return {
    type: actionTypes.PUBLIC_ADDS_ONS_FAILURE,
    payload: error,
  };
};
export const fetchAddsOnsList = (page, isProcess = false, isPublic = false) => {
  return async (dispatch) => {
    dispatch(addsOnsInit());
    addsOnsController
      .list(page, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          dispatch(fetchAddsOnsListFailure('Something went wrong'));
        } else if (result.data.status !== 'Failed') {
          if (isProcess) {
            const processData = result.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            dispatch(fetchAddsOnsListSuccess(processData));
          } else {
            dispatch(fetchAddsOnsListSuccess(result.data.data));
          }
        } else {
          dispatch(fetchAddsOnsListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchAddsOnsListFailure('Something went wrong'));
      });
  };
};
export const fetchPublicAddsOnsList = (
  page,
  isProcess = true,
  isPublic = false
) => {
  return async (dispatch) => {
    dispatch(addsOnsInit());
    addsOnsController
      .publicList(page, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          dispatch(fetchPublicAddsOnsListFailure('Something went wrong'));
        } else if (result.data.status !== 'Failed') {
          const processData = result.data.data.rows.map((item) => ({
            value: item.id,
            label: `${item.name} $${item.price}`,
            type: item.type,
            price: item.price,
          }));
          dispatch(fetchPublicAddsOnsListSuccess(processData));
        } else {
          dispatch(fetchPublicAddsOnsListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchPublicAddsOnsListFailure('Something went wrong'));
      });
  };
};

export const updateAddsOns = (addsonsId, updatedAddsOnsData) => {
  return async (dispatch) => {
    dispatch(addsOnsInit());
    addsOnsController
      .update(addsonsId, updatedAddsOnsData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updateAddsOnsFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updateAddsOnsSuccess(result.data.message));
        } else {
          dispatch(updateAddsOnsFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updateAddsOnsFailure('Something went wrong'));
      });
  };
};

export const fetchAddsOnsSingle = (addsonsId) => {
  return async (dispatch) => {
    dispatch(addsOnsInit());
    addsOnsController
      .single(addsonsId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchAddsOnsSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchAddsOnsSingleSuccess(result.data.data));
        } else {
          dispatch(fetchAddsOnsSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchAddsOnsSingleFailure('Something went wrong'));
      });
  };
};
