import React, { useState } from 'react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import placeholderImage from './../../../assets/images/car_slider_placeholder.png';
import { Link } from 'react-router-dom';

const SuggestedCars = ({ data }) => {
  const [imageLoadError, setImageLoadError] = useState(false);

  // Construct the image path
  const imgPath =
    data.car_listing_images && data.car_listing_images.length > 0
      ? `${data.car_listing_images[0].image}`
      : placeholderImage;

  // Function to handle image loading error
  const handleImageError = () => {
    setImageLoadError(true);
  };

  return (
    <div className="border-[#E8E7E6] border  w-full  shadow-lg">
      {!imageLoadError ? (
        <Link
          className="block flex-shrink-0"
          to={`/used-car-product/${data.id}`}
        >
          <div className="flex items-center justify-center h-64  w-full">
            <img
              src={imgPath}
              alt=""
              className="object-fill  slider_img"
              onError={handleImageError}
            />
          </div>
        </Link>
      ) : (
        <Link
          className="block flex-shrink-0"
          to={`/used-car-product/${data.id}`}
        >
          <div className="w-full h-[auto] bg-gray-300 flex items-center justify-center">
            <img
              src={placeholderImage}
              alt="Placeholder"
              className="w-full h-full"
            />
          </div>
        </Link>
      )}
      <div className=" p-3">
        <div className="flex justify-between items-center ">
          <div>
            <p className="tracking-widest font-bold">Price</p>
            <p className="text-[#666]">$ {data.price ?? ''}</p>
          </div>
          <img src={''} alt="" className="" />
        </div>
        <p> {data.car_model ? data.car_model?.name : '-'}</p>
        <Link
          to={`/used-car-product/${data?.id}`}
          className="flex justify-start items-center text-[#0534FF] font-semibold"
        >
          <p>More details</p> <IoIosArrowRoundForward size={40} />
        </Link>
      </div>
    </div>
  );
};

export default SuggestedCars;
