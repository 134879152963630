import * as actionTypes from '../actions/actionTypes';

const initialState = {
	colorList: [],
	singleColor: null,
	loading: false,
	error: null,
	success: null,
};

const colorReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.COLOR_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_COLOR_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				colorList: action.payload,
				error: null,
			};
		case actionTypes.FETCH_COLOR_LIST_FAILURE:
		case actionTypes.FETCH_COLOR_SINGLE_FAILURE:
		case actionTypes.CREATE_COLOR_FAILURE:
		case actionTypes.UPDATE_COLOR_FAILURE:
		case actionTypes.DELETE_COLOR_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_COLOR_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleColor: action.payload,
				error: null,
			};
		case actionTypes.CREATE_COLOR_SUCCESS:
			return {
				...state,
				loading: false,
				colorList: state.colorList,
				error: null,
				success: action.payload,
			};
		case actionTypes.UPDATE_COLOR_SUCCESS:
		case actionTypes.DELETE_COLOR_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		default:
			return state;
	}
};

export default colorReducer;
