import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../../components/common/FormFileds/Admin/FormField';
import AdminUserSchema from '../../../../schemas/AdminUserSchema';
import { options } from '../../../../constants';

const UserForm = (props) => {
  const { initialValues, onSubmit, roleList } = props;
  let initVal = {
    username: '',
    email: '',
    contact_no: '',
    role_id: '',
    status: '',
  };

  const formik = useFormik({
    initialValues: initialValues || initVal,
    validationSchema: AdminUserSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues(initialValues || initVal);
  }, [initialValues]);

  return (
    <AdminForm formik={formik} onBtnCancelClick={props.onCancel}>
      <div className="p-6.5">
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField type="text" label="Name" id="username" formik={formik} />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField type="text" label="Email" id="email" formik={formik} />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="text"
              label="Phone No"
              id="contact_no"
              formik={formik}
            />
          </div>
        </div>
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
          <div className="w-full xl:w-1/2">
            <FormField
              type="select"
              label="Role"
              id="role_id"
              options={roleList}
              formik={formik}
            />
          </div>

          <div className="w-full xl:w-1/2">
            <FormField
              type="select"
              label="Status"
              id="status"
              options={options}
              formik={formik}
            />
          </div>
        </div>
      </div>
    </AdminForm>
  );
};

export default UserForm;
