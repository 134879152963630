import React, { useState, useEffect } from 'react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import placeholderImage from './../../../assets/images/car_slider_placeholder.png';



const SuggestedCars = ({ logo, from, data }) => {
	const [imageLoadError, setImageLoadError] = useState(false);
	const [imagePath, setImagePath] = useState(placeholderImage)
	const navigate = useNavigate();


	useEffect(() => {
		if (data?.images !== undefined) {
			const images = JSON.parse(data?.images)
			let img = images?.length > 0 ? images[0]?.image : placeholderImage
			setImagePath(img)			
		}

	}, [data])

	const handleImageError = () => {
		setImageLoadError(true);
	};

	const merchantProfileNavigate = () => {
		navigate(`/usedcar-merchant-profile/${data?.user?.id}`);
	};
	return (
		<div className="border-[#E8E7E6] border  w-full  shadow-lg">
			{!imageLoadError ? (
				<img src={imagePath} alt="" className="w-full" onError={handleImageError} />
			) : (
				<img src={placeholderImage} alt="Placeholder" className="w-full" />
			)}
			<div className=" p-3">
				<div className='flex justify-between items-center '>
					<div>
						<p className={`tracking-widest  ${from == 'product_page' ? 'text-[#B11212]' : 'text-black font-bold'}`}>Price</p>
						<p className={`${from == 'product_page' ? 'text-[#B11212]' : 'text-[#666]'}`}>${data?.price}</p>
					</div>
					<div className="w-[46px] ">
						{data?.user && data.user?.user_company !== null ? (
							<img
								className="cursor-pointer"
								onClick={merchantProfileNavigate}
								src={data?.user?.image}
								alt=""
							/>
						) : (
							''
						)}
					</div>
				</div>
				{

					from == 'product_page' && <div className='bg-[#D1D0CF] h-[2px] my-4'></div>
				}
				<p> {data?.car_model ? data?.car_model?.name : '-'}</p>
				<Link to={`/used-car-product/${data?.id}`} className="flex justify-start items-center text-[#0534FF] font-semibold">
					<p>More details</p> <IoIosArrowRoundForward size={40} />
				</Link>
			</div>
		</div>
	);
};

export default SuggestedCars;
