import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import FuelTypeSchema from '../../../schemas/FuelTypeSchema';
import { options } from '../../../constants';
import AddsOnsSChema from '../../../schemas/AddsOnSchema';

const AddsOnsForm = (props) => {
	const { initialValues, onSubmit } = props;
	let initVal = {
		name: '',
		category_type: '',
		status: '',
		price: 0, 
	}

	const formik = useFormik({
		initialValues: initialValues || initVal,
		validationSchema: AddsOnsSChema,
		onSubmit,
	});

	useEffect(() => {
		formik.setValues(initialValues || initVal);
	}, [initialValues]);

	return (
		<AdminForm formik={formik} onBtnCancelClick={props.onCancel}>
			<div className="p-6.5">
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="text" label="Name" id="name" formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField type="number" label="Price" id="price" formik={formik} />
					</div>
					<div className="w-full xl:w-1/2">
						<FormField type="select" label="Status" id="status" options={options} formik={formik} />
					</div>
				</div>
			</div>
		</AdminForm>
	);
};

export default AddsOnsForm;
