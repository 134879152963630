import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ownerIdTYpeOptions } from '../../../constants';
import {
  fetchPromoValidationCheck,
  fetchPublicAddsOnsList,
  fetchPublicAdsTypeList,
} from '../../../store/actions';
import FileUpload from '../../common/FormFileds/Admin/FileUpload';
import FormField from '../../common/FormFileds/Admin/FormField';
import Loader from '../../common/Loader/Loader';
import ImageVewGrid from '../../common/FormFileds/Admin/ImageVewGrid';

const PaymentPage = ({
  proMoError,
  data,
  onSubmit,
  publicAddsOnsList,
  fetchPublicAddsOnsList,
  fetchPublicAdsTypeList,
  publicAdsTypeList,
  initialValues,
  carModelList,
  carSubModelList,
  brandList,
  colorList,
  siteSinglePromo,
  fetchPromoValidationCheck,
  promoSuccess,
  promoLoading,
}) => {
  const [promoCode, setPromoCode] = useState('');
  let [total, setTotal] = useState(0);

  const handleSubmit = async () => {
    console.log(promoCode);
    fetchPromoValidationCheck(promoCode);
  };

  console.log(siteSinglePromo);
  useEffect(() => {
    fetchPublicAddsOnsList(1);
    fetchPublicAdsTypeList(1);
  }, []);
  // let serviceData = [
  //   { id: 'none', label: 'None', name: 'service_type', value: '3', price: 0 },
  //   {
  //     id: 'photo_service',
  //     label: 'Photography Services ',
  //     name: 'service_type',
  //     value: '1',
  //     price: 500,
  //   },
  //   {
  //     id: 'photo__video_service',
  //     label: 'Photography & Videography ',
  //     name: 'service_type',
  //     value: '2',
  //     price: 300,
  //   },
  // ];
  // let adTypeData = [
  //   {
  //     id: 'normal_ads',
  //     label: 'Normal Ads ',
  //     name: 'add_type',
  //     value: '4',
  //     price: 48,
  //   },
  //   {
  //     id: 'feature_ads',
  //     label: 'Feature ads ',
  //     name: 'add_type',
  //     value: '5',
  //     price: 98,
  //   },
  // ];
  const formik = useFormik({
    initialValues: initialValues,
  });
  let service = publicAddsOnsList.find(
    (service) => service.value == data.ads_on_id
  );
  let addType = publicAdsTypeList.find(
    (type) => type.value == data.ads_type_id
  );

  console.log(formik.values);
  console.log(formik.errors);

  useEffect(() => {
    let totalCost = service.price + addType.price;
    if (
      siteSinglePromo &&
      siteSinglePromo?.type &&
      siteSinglePromo?.discount_amount
    ) {
      if (siteSinglePromo?.type === 'percentage') {
        const percentageDiscount =
          (totalCost - (siteSinglePromo?.discount_amount / 100) * totalCost) *
          1;
        setTotal(percentageDiscount);
        console.log(total);
      } else if (siteSinglePromo?.type === 'fixed') {
        const fixedDiscount =
          (totalCost - siteSinglePromo?.discount_amount) * 1;
        setTotal(fixedDiscount);
      }
    }
  }, [siteSinglePromo]);

  const qrSubmit = () => {
    onSubmit(
      total ? total : service.price + addType.price,
      siteSinglePromo?.id
    );
  };

  useEffect(() => {
    if (promoSuccess) {
      toast.success(promoSuccess, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {},
        onClose: () => {},
      });
    }
    if (proMoError) {
      toast.error(proMoError, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [proMoError]);
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 items-start container mx-auto my-8 ">
        <form
          //   onSubmit={formik.handleSubmit}
          className="mx-auto container col-span-2"
        >
          <div className="p-6.5">
            <div className=" mb-4">
              <p className="mb-4">
                Ad Type <span className="text-[#B11212]">*</span>
              </p>
              <input
                type="radio"
                id="ads_type_id"
                name="ads_type_id"
                checked
                className="mr-1"
              />{' '}
              <label htmlFor="ads_on_id">{addType?.label}</label>
            </div>
            <div className=" mb-4">
              <p className="mb-4">
                Photography Services <span className="text-[#B11212]">*</span>
              </p>
              <input
                type="radio"
                id="ads_on_id"
                name="ads_on_id"
                checked
                className="mr-1"
              />
              <label htmlFor="ads_type_id">{service?.label}</label>{' '}
            </div>
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-1/2">
                <FormField
                  type="text"
                  label={'Car Plate No'}
                  id={'car_plate_no'}
                  formik={formik}
                  disabled={true}
                />
              </div>

              <div className="w-full xl:w-1/2">
                <FormField
                  type="select"
                  disabled={true}
                  label={'Owner Id Type'}
                  id={'owner_id_type_id'}
                  options={ownerIdTYpeOptions}
                  formik={formik}
                />
              </div>
            </div>
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-1/2">
                <FormField
                  type="text"
                  label={'Owner Id'}
                  id={'owner_id'}
                  formik={formik}
                  disabled={true}
                />
              </div>

              <div className="w-full xl:w-1/2">
                <FormField
                  type="text"
                  label={'Asking Price (Optional)'}
                  id={'price'}
                  formik={formik}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-1/2">
                <FormField
                  type="select"
                  label="Brand"
                  id="brand_id"
                  options={brandList}
                  formik={formik}
                  disabled={true}
                />
              </div>

              <div className="w-full xl:w-1/2">
                <FormField
                  type="select"
                  label="Model"
                  id="model_id"
                  options={carModelList}
                  formik={formik}
                  disabled={true}
                />
              </div>

              <div className="w-full xl:w-1/2">
                <FormField
                  type="select"
                  label="Sub Model"
                  id="sub_model_id"
                  options={carSubModelList}
                  formik={formik}
                  disabled={true}
                />
              </div>
            </div>{' '}
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-1/2">
                <FormField
                  type="select"
                  label={'Color'}
                  id={'color_id'}
                  options={colorList}
                  formik={formik}
                  disabled={true}
                />
              </div>

              <div className="w-full xl:w-1/2">
                <FormField
                  type="text"
                  label={'Mileage (Optional)'}
                  id={'mileage'}
                  formik={formik}
                  disabled={true}
                />
              </div>

              <div className="w-full xl:w-1/2">
                <FormField
                  type="text"
                  label={'Preferred Viewing Area (Optional)'}
                  id={'prefered_area'}
                  formik={formik}
                  disabled={true}
                />
              </div>
            </div>{' '}
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-2/2">
                <FormField
                  type="textarea"
                  label={'Promotions (up to 500 characters)'}
                  id={'promotions'}
                  formik={formik}
                />
              </div>
            </div>
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full  xl:w-1/2">
                <FormField
                  type="text"
                  label={'Chassis number (Optional)'}
                  id={'chasis_number'}
                  formik={formik}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
              <div className="w-full xl:w-2/2">
                <FormField
                  type="textarea"
                  label={'Description (up to 500 characters)'}
                  id={'desc'}
                  formik={formik}
                  disabled={true}
                />
              </div>
            </div>{' '}
            <div className="flex flex-wrap mb-4">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <FormField
                  type="checkbox"
                  label={'Allow enquiry to be send to my email'}
                  id={'allow_email'}
                  formik={formik}
                  disabled={true}
                />
              </div>
            </div>
            <div>
              {/* <FileUpload
                formik={formik}
                disabled={true}
                id={'image'}
                multiple={true}
                isDisabled
              /> */}
              <ImageVewGrid imgData={formik.values.image} />
            </div>
          </div>
        </form>
        <div className="p-4 ">
          <div className="p-6 w-full h-fit border border-[#E8E7E6] shadow-4">
            <p className="text-2xl">Your order</p>
            <div className="flex justify-between items-center gap-5 my-5">
              <input
                type="text"
                name="code?"
                id="code"
                placeholder="Promo code"
                className="border p-1 w-full border-[#E8E7E6]"
                onChange={(e) => setPromoCode(e.target.value)}
              />
              <button
                type="submit"
                onClick={handleSubmit}
                className="bg-[#1677FF] text-white px-3 py-1 rounded"
              >
                Apply
              </button>
            </div>
            <div className="my-6 px-3">
              <div>
                <div className="flex justify-between items-center gap-8 my-4 border-b border-[#E8E7E6] pb-2">
                  <p className="text-xl font-bold ">Service</p>
                  <p className="text-xl font-bold ">Subtotal</p>
                </div>
                <div className="flex justify-between items-center gap-18 my-4 border-b border-[#E8E7E6] pb-2  ">
                  <p>{service.label}</p>
                  <p>${(Math.round(service.price * 100) / 100).toFixed(2)}</p>
                </div>
                <div className="flex justify-between items-center gap-18 my-4 border-b border-[#E8E7E6] pb-2">
                  <p>{addType.label}</p>
                  <p>${(Math.round(addType.price * 100) / 100).toFixed(2)}</p>
                </div>
                {promoLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div className="flex justify-between items-center gap-18 my-4 border-b border-[#E8E7E6] pb-2">
                      <p>
                        Discount
                        {siteSinglePromo ? (
                          <>
                            {siteSinglePromo.type === 'percentage' ? (
                              <> {siteSinglePromo.discount_amount}%</>
                            ) : (
                              <> ${siteSinglePromo.discount_amount}</>
                            )}
                          </>
                        ) : (
                          ' (None)'
                        )}
                      </p>
                      <p>
                        {siteSinglePromo && (
                          <>
                            {siteSinglePromo.type === 'percentage' ? (
                              <>
                                $
                                {(
                                  service.price +
                                  addType.price -
                                  total
                                ).toFixed(2)}
                              </>
                            ) : (
                              <> ${siteSinglePromo.discount_amount}</>
                            )}
                          </>
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between items-center gap-18 my-4 border-b border-[#E8E7E6] pb-2">
                      <p className="font-bold">Total</p>
                      <p className="font-bold">
                        $
                        {total
                          ? (Math.round(total * 100) / 100).toFixed(2)
                          : (
                              Math.round(
                                (service.price + addType.price) * 100
                              ) / 100
                            ).toFixed(2)}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <button
              className="bg-[#1677FF] text-white py-2 w-full rounded"
              onClick={qrSubmit}
            >
              Place order
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.usedCar.loading,
    promoLoading: state.promoCodes.loading,
    error: state.usedCar.error,
    proMoError: state.promoCodes.error,
    success: state.usedCar.success,
    promoSuccess: state.promoCodes.success,
    publicAddsOnsList: state.addsOns.publicAddsOnsList,
    publicAdsTypeList: state.adsType.publicAdsTypeList,
    siteSinglePromo: state.promoCodes.siteSinglePromo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPublicAddsOnsList: (id) => dispatch(fetchPublicAddsOnsList(id)),
    fetchPublicAdsTypeList: (id) => dispatch(fetchPublicAdsTypeList(id)),
    fetchPromoValidationCheck: (code) =>
      dispatch(fetchPromoValidationCheck(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
