import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


import Layout from '../../../components/admin/Layout/Base/Layout';
import FuelType from './CarCategoryForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import CarCategoryTable from '../../../components/admin/Table/Table';
import { fetchCarCategoryList, fetchCarCategorySingle, createCarCategory, updateCarCategory, deleteCarCategory } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';


const CarCategory = (props) => {
	const {
		loading,
		error,
		success,
		carCategoryList,
		fetchCarCategoryList,
		createCarCategory,
		deleteCarCategory,
		fetchCarCategorySingle,
		singleCarCategory,
		updateCarCategory
	} = props;

	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1)

	useEffect(() => {
		setShowTable(true)
	}, [carCategoryList])

	useEffect(() => {
		fetchCarCategoryList(page);
	}, [fetchCarCategoryList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchCarCategoryList(page);
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id)
			setShowTable(false)
			setButtonLabel('List')
			fetchCarCategorySingle(id)
		} else if (action === 'delete') {
			deleteCarCategory(id);
		}
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null)
	};

	const onPageChange = (page) => {
		setPage(page)
	};

	const onSubmit = (values) => {
		if (editingId) {
			updateCarCategory(editingId, values)
		} else {
			createCarCategory(values);
		}
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true
		},
		{
			name: 'Category Type',
			selector: row => row.category_type == 1 ? 'Used Car' : 'New Car',
			sortable: false
		},
		{
			name: 'Status',
			selector: row => row.status ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					showDeleteButton={true}
					onDelete={id => handleEditDelete(id, 'delete')}
				/>
			),
			sortable: false
		},
	];

	const renderContent = () => {
		if (loading) {
			return <Loader />
		}

		if (showTable && !loading) {
			return (
				<CarCategoryTable
				defaultPage={page}
					columns={columns} data={carCategoryList.rows == undefined ? [] : carCategoryList.rows} totalCount={carCategoryList.count} handlePageChange={onPageChange}
				/>
			);
		}

		if (editingId && !loading) {
			return <FuelType onSubmit={onSubmit} initialValues={singleCarCategory} onCancel={handleButtonClick} />;
		}

		if (!loading) {
			return <FuelType onSubmit={onSubmit} onCancel={handleButtonClick} />;
		}
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Car Category" buttonText={buttonLabel} onButtonClick={handleButtonClick} />
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.carCategory.loading,
		error: state.carCategory.error,
		success: state.carCategory.success,
		carCategoryList: state.carCategory.carCategoryList,
		singleCarCategory: state.carCategory.singleCarCategory
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCarCategoryList: (page) => dispatch(fetchCarCategoryList(page)),
		createCarCategory: (data) => dispatch(createCarCategory(data)),
		deleteCarCategory: (id) => dispatch(deleteCarCategory(id)),
		fetchCarCategorySingle: (id) => dispatch(fetchCarCategorySingle(id)),
		updateCarCategory: (id, data) => dispatch(updateCarCategory(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CarCategory);
