import React from 'react';
import LatestCars from '../CarsComponents/LatestCars';
import recomended from '../../../assets/images/web/recomended.png';
import { IoIosArrowRoundForward } from 'react-icons/io';
import Recommended from '../CarsComponents/Recommended';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
const RecommendedByOurTeam = ({ title, dec }) => {
  return (
    <div className="container mx-auto  mb-5  lg:my-15 p-4 ">
      <div className="text-center mt-16 mb-10">
        <p className="font-bold  text-3xl mb-2">{title}</p>
        <p className="text-[#666]">{dec}</p>
      </div>
      {/* <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-3  px-2">
        <Recommended img={recomended} />
        <Recommended img={recomended} />
        <Recommended img={recomended} />
      </div>{' '} */}
      <Swiper
        // modules={[EffectFade]}
        effect="fade"
        spaceBetween={50}
        slidesPerView={3}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          280: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: 1,
          },
          639: {
            slidesPerView: 2,
          },
          865: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 2,
          },
          1120: {
            slidesPerView: 3,
          },
          1300: {
            slidesPerView: 4,
          },
          1500: {
            slidesPerView: 4,
          },
          1700: {
            slidesPerView: 4,
          },
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <Recommended img={recomended} />
        </SwiperSlide>
        <SwiperSlide>
          <Recommended img={recomended} />
        </SwiperSlide>
        <SwiperSlide>
          <Recommended img={recomended} />
        </SwiperSlide>
        <SwiperSlide>
          <Recommended img={recomended} />
        </SwiperSlide>
      </Swiper>
      <div className="flex items-center justify-center mb-14 mt-10 border-b w-fit mx-auto border-[#0534FF] cursor-pointer">
        <p>See more reviews</p> <IoIosArrowRoundForward size={40} />
      </div>
    </div>
  );
};

export default RecommendedByOurTeam;
