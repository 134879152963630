import * as actionTypes from '../actions/actionTypes';

const initialState = {
	roleList: [],
	singleRole: null,
	loading: false,
	error: null,
	success: null,
};

const roleReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ROLE_INIT:
			return {
				...state,
				loading: false,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_ROLE_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				roleList: action.payload,
				error: null,
			};
		case actionTypes.FETCH_ROLE_LIST_FAILURE:
		case actionTypes.FETCH_ROLE_SINGLE_FAILURE:
		case actionTypes.CREATE_ROLE_FAILURE:
		case actionTypes.UPDATE_ROLE_FAILURE:
		case actionTypes.DELETE_ROLE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_ROLE_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleRole: action.payload,
				error: null,
			};
		case actionTypes.CREATE_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				roleList: state.roleList,
				error: null,
				success: action.payload,
			};

		case actionTypes.UPDATE_ROLE_SUCCESS:
		case actionTypes.DELETE_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		default:
			return state;
	}
};

export default roleReducer;
