// actions.js
import * as actionTypes from "./actionTypes";
import CountryController from "../../api/CountryController";

export const countryInit = () => {
	return {
		type: actionTypes.COUNTRY_INIT,
	};
};

export const fetchCountryListSuccess = (countries) => {
	return {
		type: actionTypes.FETCH_COUNTRY_LIST_SUCCESS,
		payload: countries,
	};
};

export const fetchCountryListFailure = (error) => {
	return {
		type: actionTypes.FETCH_COUNTRY_LIST_FAILURE,
		payload: error,
	};
};

export const fetchCountrySingleSuccess = (country) => {
	return {
		type: actionTypes.FETCH_COUNTRY_SINGLE_SUCCESS,
		payload: country,
	};
};

export const fetchCountrySingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_COUNTRY_SINGLE_FAILURE,
		payload: error,
	};
};

export const createCountrySuccess = (message) => {
	return {
		type: actionTypes.CREATE_COUNTRY_SUCCESS,
		payload: message,
	};
};

export const createCountryFailure = (error) => {
	return {
		type: actionTypes.CREATE_COUNTRY_FAILURE,
		payload: error,
	};
};

export const updateCountrySuccess = (country) => {
	return {
		type: actionTypes.UPDATE_COUNTRY_SUCCESS,
		payload: country,
	};
};

export const updateCountryFailure = (error) => {
	return {
		type: actionTypes.UPDATE_COUNTRY_FAILURE,
		payload: error,
	};
};

export const deleteCountrySuccess = (countryId) => {
	return {
		type: actionTypes.DELETE_COUNTRY_SUCCESS,
		payload: countryId,
	};
};

export const deleteCountryFailure = (error) => {
	return {
		type: actionTypes.DELETE_COUNTRY_FAILURE,
		payload: error,
	};
};

export const fetchCountryList = (page, isProcess) => {
	return async (dispatch) => {
		dispatch(countryInit());
		CountryController.list(page)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(fetchCountryListFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					if (isProcess) {
						const processData = result.data.data.map(item => ({
							value: item.id,
							label: item.name
						}));
						dispatch(fetchCountryListSuccess(processData));
					} else {
						dispatch(fetchCountryListSuccess(result.data.data));
					}
				} else {
					dispatch(fetchCountryListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchCountryListFailure('Something went wrong'));
			});
	};
};

export const fetchCountrySingle = (countryId) => {
	return async (dispatch) => {
		dispatch(countryInit());
		CountryController.single(countryId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(fetchCountrySingleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchCountrySingleSuccess(result.data.data));
				} else {
					dispatch(fetchCountrySingleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchCountrySingleFailure('Something went wrong'));
			});
	};
};

export const createCountry = (countryData) => {
	return async (dispatch) => {
		dispatch(countryInit());
		CountryController.create(countryData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(createCountryFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(createCountrySuccess(result.data.message));
				} else {
					dispatch(createCountryFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(createCountryFailure('Something went wrong'));
			});
	};
};

export const updateCountry = (countryId, updatedCountryData) => {
	return async (dispatch) => {
		dispatch(countryInit());
		CountryController.update(countryId, updatedCountryData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(updateCountryFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(updateCountrySuccess(result.data.message));
				} else {
					dispatch(updateCountryFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(updateCountryFailure('Something went wrong'));
			});

	};
};

export const deleteCountry = (countryId) => {
	return async (dispatch) => {
		dispatch(countryInit());
		CountryController.delete(countryId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(deleteCountryFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(deleteCountrySuccess(result.data.message));
				} else {
					dispatch(deleteCountryFailure('Something went wrong1'));
				}
			})
			.catch((error) => {
				dispatch(deleteCountryFailure('Something went wrong3'));
			});
	};
};
