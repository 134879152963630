// UserCarPostingForm.js
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import Layout from '../../../components/site/Layout/Base/Layout';
import FullWidthSection from '../../../components/site/FullWidthSection/FullWidthSection';
import UsedCarPostingForm from './UsedCarPostingForm';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
  createUsedCar,
  fetchUsedCarSingle,
  updateUsedCar,
  fetchColorList,
  fetchCarModelList,
  fetchCarSubModelPublicList,
  fetchBrandPublicList,
} from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
import PaymentPage from '../../../components/site/PaymentDashboard/PaymentPage';
import PayNowQR from '../../../components/site/PayNowQR/PayNowQR';

const IndividualUsedCarPosting = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    loading,
    error,
    success,
    createUsedCar,
    fetchUsedCarSingle,
    singleUsedCar,
    updateUsedCar,
    fetchColorList,
    colorList,
    fetchCarSubModelPublicList,
    carSubModelPublicList,
    carModelList,
    fetchCarModelList,
    fetchBrandPublicList,
    brandPublicList,
  } = props;
  const [showValue, setShowValue] = useState(null);
  const [showqrPage, setShowqrPage] = useState(false);
  // const [subModelData, setSubModelData] = useState([]);

  // useEffect(() => {
  //     setSubModelData(carSubModelPublicList)
  // }, [carSubModelPublicList]);

  const fetchData = async () => {
    await Promise.all([
      fetchColorList('all', true, true),
      fetchBrandPublicList('all'),
      fetchCarModelList('all', true, true),
      fetchCarSubModelPublicList(),

      // fetchBrandPublicList('all', true, true),
      // // fetchUsedCarList(1, false, 'latest'),
      // fetchCarModelList('all', true, true),
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          navigate('/user-dashboard');
        },
        onClose: () => {},
      });
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  useEffect(() => {
    if (id) {
      fetchUsedCarSingle(id, true);
    }
  }, [id]);

  const onSubmit = (values) => {
    if (id) {
      updateUsedCar(id, values, true);
    } else {
      setShowValue(values);
    }
  };
  const onCheckOutSubmit = (value, id) => {
    console.log('id====', id);
    let updateShowValue = { ...showValue };
    updateShowValue.totalPrice = value;
    updateShowValue.promo_code_id = id;
    console.log('updateShowValue-----', updateShowValue);
    setShowValue(updateShowValue);
    setShowqrPage(true);
  };
  const handleReferenceId = (value) => {
    console.log(value, showValue);
    showValue.refId = value;
    createUsedCar(showValue);
  };

  const renderContent = () => {
    if (id && !showqrPage) {
      return (
        <UsedCarPostingForm
          onSubmit={onSubmit}
          initialValues={singleUsedCar}
          colorList={colorList}
          carModelList={carModelList ?? []}
          carSubModelList={carSubModelPublicList ?? []}
          brandList={brandPublicList}
        />
      );
    }
    if (showValue && !showqrPage) {
      return (
        <PaymentPage
          data={showValue}
          onSubmit={onCheckOutSubmit}
          initialValues={showValue}
          colorList={colorList}
          carModelList={carModelList ?? []}
          carSubModelList={carSubModelPublicList ?? []}
          brandList={brandPublicList}
        />
      );
    }
    if (showqrPage) {
      return (
        <PayNowQR
          data={showValue}
          onSubmit={handleReferenceId}
          error={error}
          loading={loading}
        />
      );
    }
    return (
      <UsedCarPostingForm
        onSubmit={onSubmit}
        colorList={colorList}
        carModelList={carModelList ?? []}
        carSubModelList={carSubModelPublicList ?? []}
        brandList={brandPublicList}
      />
    );
  };

  let content = '';

  if (carModelList.length > 0 && colorList.length > 0) {
    content = renderContent();
  }

  return (
    <Layout>
      <FullWidthSection title="Post Car Advertisement" bgColor="#cccccc" />
      <div className="container mx-auto">
        {loading && <Loader />}
        {content}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.usedCar.loading,
    error: state.usedCar.error,
    success: state.usedCar.success,
    usedCarList: state.usedCar.usedCarList,
    singleUsedCar: state.usedCar.singleUsedCar,
    colorList: state.color.colorList,
    carModelList: state.carModel.carModelList,
    carSubModelPublicList: state.carSubModel.carSubModelPublicList,
    brandPublicList: state.brand.brandPublicList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUsedCar: (data) => dispatch(createUsedCar(data)),
    fetchUsedCarSingle: (id, isPublic) =>
      dispatch(fetchUsedCarSingle(id, isPublic)),
    updateUsedCar: (id, data, isPublic) =>
      dispatch(updateUsedCar(id, data, isPublic)),
    fetchColorList: (page, isProcess, isPublic) =>
      dispatch(fetchColorList(page, isProcess, isPublic)),
    fetchBrandPublicList: (page) => dispatch(fetchBrandPublicList(page)),
    fetchCarModelList: (page, isProcess, isPublic) =>
      dispatch(fetchCarModelList(page, isProcess, isPublic)),
    fetchCarSubModelPublicList: () => dispatch(fetchCarSubModelPublicList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualUsedCarPosting);
