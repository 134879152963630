import React, { useState } from 'react';
import { IoMdArrowDropdownCircle } from 'react-icons/io';
import { IoMdArrowDropupCircle } from 'react-icons/io';

const NewCarSpaceFeatureHomeTogglers = (props) => {
  const { arrayName, title, feature } = props;
  const [showDropIcon, setShowDropIcon] = useState(true);
  return (
    <div className="container mx-auto ">
      <div className="flex justify-between items-center p-2.5 border bg-[#F1F1F1] border-[#D9D9D9]">
        <p className="font-bold text-xl">{title}</p>
        <div className="text-end space-y-1">
          {showDropIcon ? (
            <button
              className="text-sky-500 px-4 flex justify-end items-center ml-auto cursor-pointer"
              onClick={() => {
                setShowDropIcon(!showDropIcon);
              }}
            >
              <IoMdArrowDropdownCircle />
            </button>
          ) : (
            <button
              className="text-sky-500 px-4 flex justify-end items-center ml-auto cursor-pointer"
              onClick={() => {
                setShowDropIcon(!showDropIcon);
              }}
            >
              <IoMdArrowDropupCircle />
            </button>
          )}
        </div>
      </div>
      {showDropIcon && (
        <>
          <div>
            {arrayName.map((values) => (
              <div
                className={`grid grid-cols-12 border border-[#D9D9D9] ${
                  feature && 'h-[700px]'
                }`}
              >
                <p
                  className={` border-r border-[#D9D9D9] p-3 font-bold  ${
                    feature ? 'col-span-4' : 'col-span-7 md:col-span-4'
                  }`}
                >
                  {values.name}
                </p>
                <p
                  className={`${
                    feature ? 'col-span-7' : 'col-span-5 md:col-span-8'
                  }  p-3 text-black `}
                >
                  {values.value}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default NewCarSpaceFeatureHomeTogglers;
