import * as actionTypes from '../actions/actionTypes';

const initialState = {
  addsOnsList: [],
  publicAddsOnsList: [],
  singleAddsOns: null,
  loading: false,
  error: null,
  success: null,
};

const addsOnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADDS_ONS_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case actionTypes.FETCH_ADDS_ONS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        addsOnsList: action.payload,
        error: null,
      };
    case actionTypes.PUBLIC_ADDS_ONS_SUCCESS:
      return {
        ...state,
        loading: false,
        publicAddsOnsList: action.payload,
        error: null,
      };
    case actionTypes.FETCH_ADDS_ONS_LIST_FAILURE:
    case actionTypes.FETCH_ADDS_ONS_SINGLE_FAILURE:
    case actionTypes.UPDATE_ADDS_ONS_FAILURE:
    case actionTypes.PUBLIC_ADDS_ONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_ADDS_ONS_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAddsOns: action.payload,
        error: null,
      };
    case actionTypes.UPDATE_ADDS_ONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    default:
      return state;
  }
};

export default addsOnsReducer;
