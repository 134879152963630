import React, { useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const TogglerHandle = ({ arrayName, title, id, onSelectValue, clearAll, queryData }) => {
	const [isOn, setIsOn] = useState(false);
	const [selectedValues, setSelectedValues] = useState([]);

	useEffect(() => {
		setSelectedValues([]);
	}, [clearAll]);

	useEffect(() => {

		if (queryData?.length > 0) {
			let updatedSelectedValues = [...selectedValues]

			const filteredItem = arrayName.find(
				(item) => item.value == parseInt(queryData[0])
			);

			if (filteredItem !== undefined) {
				updatedSelectedValues.push(filteredItem)
				setSelectedValues(updatedSelectedValues)
			}
		}
	}, []);

	const handleToggle = () => {
		setIsOn(!isOn);
	};

	const handleCheckboxChange = (item) => {
		const updatedSelectedValues = [...selectedValues];
		const index = updatedSelectedValues.findIndex((sinleItem) => {
			if (sinleItem.value !== undefined) {
				return sinleItem.value === item.value
			} else {
				return sinleItem == item.value
			}
		});

		if (index === -1) {
			updatedSelectedValues.push(item);
		} else {
			updatedSelectedValues.splice(index, 1);
		}

		setSelectedValues(updatedSelectedValues);
		onSelectValue(item);
	};

	return (
		<div className="">
			<div className="my-3 ">
				<div className="flex justify-between items-center">
					<p className="text-[#666] text-2xl font-bold my-2">{title}</p>
					<button type="button" onClick={handleToggle} className="">
						{isOn ? <IoIosArrowDown size={30} /> : <IoIosArrowUp size={30} />}
					</button>
				</div>
				<div className="relative inline-block text-left">
					{isOn && (
						<div>
							{arrayName.map((item, i) => (
								<div className="my-1" key={i}>
									{i < -1 ? (
										<p>No data found </p>
									) : (
										<>
											<input
												type="checkbox"
												placeholder=" "
												id={id}
												className="mr-2"
												checked={selectedValues.some((selectedItem) => {
													if (selectedItem.value !== undefined) {
														return selectedItem.value === item.value
													} else {
														return selectedItem == item.value
													}
												})}
												onChange={() => handleCheckboxChange(item)}
											/>
											<label htmlFor="">{item.label}</label>
										</>
									)}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TogglerHandle;
