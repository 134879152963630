import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { countryOptions } from '../../../constants';
import FileUpload from '../../common/FormFileds/Admin/FileUpload';
import FormField from '../../common/FormFileds/Admin/FormField';
import { connect } from 'react-redux';
import {
  fetchSiteSettingSingle,
  updateSiteSettings,
} from '../../../store/actions';
import { toast } from 'react-toastify';

const SiteSettingForm = (props) => {
  const {
    siteSingleSetting,
    fetchSiteSettingSingle,
    userData,
    updateSiteSettings,
    success,
    error,
  } = props;
  useEffect(() => {
    fetchSiteSettingSingle(userData?.id);
  }, [fetchSiteSettingSingle, userData?.id]);
  console.log('siteSingleSetting========', siteSingleSetting);
  let initVal = {
    name: '',
    email: '',
    contact_no: '',
    address_one: '',
    address_two: '',
    country_id: '',
    city_id: '',
    postal_code: '',
    image: '',
    isImageRequired: true,
  };
  const initialValues = siteSingleSetting;
  console.log(
    'userData.type, userData.id----------',
    userData.type,
    userData.id
  );

  const onSubmit = (values) => {
    console.log(values, userData.type, userData.id);
    updateSiteSettings(values, userData?.type, userData?.id);
  };
  const formik = useFormik({
    initialValues: initialValues || initVal,
    onSubmit,
  });
  useEffect(() => {
    formik.setValues(initialValues || initVal);
  }, [initialValues]);

  useEffect(() => {
    if (success) {
      console.log(success);
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          fetchSiteSettingSingle(userData?.id);
        },
        onClose: () => {},
      });
    }
    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="container mx-auto">
        <div className="w-5/6 mx-auto">
          <FileUpload formik={formik} id={'image'} />
        </div>

        <div className="p-6.5">
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField type="text" label="Name" id="name" formik={formik} />
            </div>
            <div className="w-full xl:w-1/2">
              <FormField type="text" label="Email" id="email" formik={formik} />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label="Phone No"
                id="contact_no"
                formik={formik}
              />
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
            <div className="w-full">
              <FormField
                type="text"
                label="Address Line 1"
                id="address_one"
                formik={formik}
              />
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-1 xl:flex-row">
            <div className="w-full">
              <FormField
                type="text"
                label="Address Line 2"
                id="address_two"
                formik={formik}
              />
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Country"
                id="country_id"
                options={countryOptions}
                formik={formik}
              />
            </div>
            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label="City"
                id="city_id"
                formik={formik}
              />
            </div>
            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label="Postal Code"
                id="postal_code"
                formik={formik}
              />
            </div>
          </div>{' '}
          <button
            type="submit"
            className={`flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-70 visible mt-12`}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.siteSetting.loading,
    error: state.siteSetting.error,
    success: state.siteSetting.success,
    userData: state.auth.userData,
    siteSingleSetting: state.siteSetting.siteSingleSetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSiteSettingSingle: (id) => dispatch(fetchSiteSettingSingle(id)),
    updateSiteSettings: (data, type, id) =>
      dispatch(updateSiteSettings(data, type, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSettingForm);
