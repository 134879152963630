import * as actionTypes from '../actions/actionTypes';

const initialState = {
	vehicleTypePublicList: [],
	vehicleTypeList: [],
	singleVehicleType: null,
	loading: false,
	error: null,
	success: null,
};

const vehicleTypeReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.VEHICLE_TYPE_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_VEHICLE_TYPE_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				vehicleTypeList: action.payload,
				error: null,
			};
		case actionTypes.FETCH_VEHICLE_TYPE_LIST_FAILURE:
		case actionTypes.FETCH_VEHICLE_TYPE_SINGLE_FAILURE:
		case actionTypes.CREATE_VEHICLE_TYPE_FAILURE:
		case actionTypes.UPDATE_VEHICLE_TYPE_FAILURE:
		case actionTypes.DELETE_VEHICLE_TYPE_FAILURE:
		case actionTypes.FETCH_VEHICLE_TYPE_PUBLIC_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_VEHICLE_TYPE_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleVehicleType: action.payload,
				error: null,
			};
		case actionTypes.CREATE_VEHICLE_TYPE_SUCCESS:
			return {
				...state,
				loading: false,
				vehicleTypeList: state.vehicleTypeList,
				error: null,
				success: action.payload,
			};
		case actionTypes.FETCH_VEHICLE_TYPE_PUBLIC_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				vehicleTypePublicList: action.payload,
				error: null,
			};
		case actionTypes.UPDATE_VEHICLE_TYPE_SUCCESS:
		case actionTypes.DELETE_VEHICLE_TYPE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		default:
			return state;
	}
};

export default vehicleTypeReducer;
