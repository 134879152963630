import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ForgotPass from '../containers/common/ForgotPass/ForgotPass';
import Login from '../containers/common/Login/Login';
import ResetPassword from '../containers/common/ResetPassword/ResetPassword';
import Register from '../containers/site/Auth/Register/Register';
import Brands from '../containers/site/Brands/Brands';
import Home from '../containers/site/Home/Home';
import NewCarHome from '../containers/site/NewCarHome/NewCarHome';
import NewCarMarchentProfile from '../containers/site/NewCarMarchentProfile/NewCarMarchentProfile';
// import PayNowQRHome from '../containers/site/PayNowQRHome/PayNowQRHome';
import SearchedUsedCarsHome from '../containers/site/SearchedUsedCars/SearchedUsedCarsHome';
import SellYourCar from '../containers/site/SellYourCar/SellYourCar';
import UsedCarHome from '../containers/site/UsedCar/UsedCarHome';
import UsedCarMarchentProfile from '../containers/site/UsedCarMarchentProfile/UsedCarMarchentProfile';
import UsedCarProduct from '../containers/site/UsedCarProduct/UsedCarProduct';
import GetInstantOffers from '../containers/site/GetInstantOffers/GetInstantOffers';
import NewSearchedCarsHome from '../containers/site/NewSearchedCars/NewSearchedCarsHome';
import NewCarProduct from '../containers/site/NewCarProduct/NewCarProduct';
import NewCarSpaceFeatureHome from '../containers/site/NewCarSpaceFeatureHome/NewCarSpaceFeatureHome';
import CarRequests from '../containers/site/CarRequests/CarRequests';
import News from '../containers/site/News/News';
import SingleNewsPage from '../containers/site/News/SingleNewsPage';

const PublicRoute = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/used_car_home" element={<UsedCarHome />} />
      <Route path="/searched_used_cars" element={<SearchedUsedCarsHome />} />
      <Route path="/searched_new_cars" element={<NewSearchedCarsHome />} />
      <Route path="/new-car-product" element={<NewCarProduct />} />
      <Route path="/new-car-product/:id" element={<NewCarProduct />} />
      {/* <Route path="/used-car-product" element={<UsedCarProduct />} /> */}
      <Route path="/get-instant-offers" element={<GetInstantOffers />} />
      <Route
        path="/newcar-merchant-profile/:id"
        element={<NewCarMarchentProfile />}
      />
      <Route
        path="/usedcar-merchant-profile/:id"
        element={<UsedCarMarchentProfile />}
      />

      <Route path="/used-car-product/:id" element={<UsedCarProduct />} />
      <Route path="/sell-your-car" element={<SellYourCar />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPass />} />
      <Route path="/brands" element={<Brands />} />
      <Route path="/new_car_home" element={<NewCarHome />} />
      <Route path="/car_requests" element={<CarRequests />} />
      <Route path="/news" element={<News />} />
      <Route path="/news/:slug" element={<SingleNewsPage />} />
      <Route
        path="/new-car-new_car_spec_features"
        element={<NewCarSpaceFeatureHome />}
      />

      <Route path="/admin/*">
        <Route path="login" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default PublicRoute;
