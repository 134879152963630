import React, { useState } from 'react'
import SubmodelTable from '../../../components/admin/Table/Table';

const NewCarSubModel = ({singleNewCar}) => {
    const [page, setPage] = useState(1)
  const columns = [
    {
      name: 'Id',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Sub Model Name',
      selector: (row) => row?.car_submodel?.key_info?.name,
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row) => row?.car_submodel?.key_info?.price,
      sortable: true,
    },
    {
      name: 'Depriciation',
      selector: (row) => row?.car_submodel?.key_info?.depreciation,
      sortable: true,
    },
    {
      name: 'Fuel Economy',
      selector: (row) => row?.car_submodel?.performance?.fuel_consumption,
      sortable: false,
    },
    
    {
        name: 'Power',
        selector: (row) =>  row?.car_submodel?.performance?.power,
        sortable: false,
      },
    {
    //   name: 'Actions',
    //   selector: (row) => (
    //     <ActionButton
    //       row={row}
    //       onEdit={(id) => handleEditDelete(id, 'edit')}
    //       // onDelete={(id) => handleEditDelete(id, 'delete')}
    //       // onBoost={(id) => handleBoost(id, row?.is_boost)}
    //       // showBoostBtn={true}
    //       isLoading={isLoading}
    //       // boostingId={boostingId}
    //     />
    //   ),
      // sortable: false,
      // width: '20%',
    },
  ];

const onPageChange =(p) => {

}
  return (
    <div>
         <SubmodelTable
          columns={columns}
          data={singleNewCar?.new_car_submodel == undefined ? [] : singleNewCar?.new_car_submodel }
          totalCount={singleNewCar?.new_car_submodel?.length}
          handlePageChange={onPageChange}
          defaultPage={page}
        />
    </div>
  )
}

export default NewCarSubModel;