import React, { useEffect } from 'react'
import moment from 'moment';
import { connect } from 'react-redux';
import { fetchOrderSingle } from '../../../store/actions';

const SingleInput = ({label, fieldValue}) => {
  return (
    <div>
    <label className='block text-[16px]' for="Name">{label}</label>
    <input
      className='block bg-gray-300 border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2'
      placeholder=''
      value={fieldValue}
      disabled
    />
  </div>
  )
}

const EditPayment = (props) => {
  const {editItem, cancelHandler, statusSaveHandler,setPaymentStatus, setStatusErr, statusErr, isLoading , isSelectDesabled = false, paymentItem, fetchOrderSingle} = props;

  useEffect(() => {
    fetchOrderSingle(paymentItem?.id)
  },[paymentItem?.id])
  
  return (
    <div className='px-6 py-4'>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
						<div className="w-full xl:w-1/2">
                <SingleInput label="Order ID" fieldValue={editItem?.order_id}/>
						</div>

						<div className="w-full xl:w-1/2">
               <SingleInput  label="Name" fieldValue={editItem?.user?.user_details?.first_name}/>
						</div>

						<div className="w-full xl:w-1/2">
              <SingleInput  label="Payment For" fieldValue={editItem?.type == 'used_car' ? 'Used Car listing' : 'New Car listing'}/>
						</div>
			</div>

      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
						<div className="w-full xl:w-1/2">
                <SingleInput label="Payment Type" fieldValue={editItem?.paymentMethod == "pay_now" ? "Pay Now" : editItem?.paymentMethod}/>
						</div>

						<div className="w-full xl:w-1/2">
               <SingleInput  label="Amount" fieldValue={editItem?.total_amount}/>
						</div>

						<div className="w-full xl:w-1/2">
              <SingleInput  label="Payment Date" fieldValue={moment(editItem?.orderDate).format("D/M/YYYY HH:mm:ssA")}/>
						</div>
			</div>

      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
						<div className="w-full xl:w-1/2">
                <SingleInput label="Payment Reference No" fieldValue={editItem?.payment_reference_no}/>
						</div>

						<div className="w-full xl:w-1/2">
               <SingleInput  label="Car Plate No" fieldValue={editItem?.used_car_listing?.car_plate_no}/>
						</div>
 
						<div className="w-full xl:w-1/2">
            <label className='block text-[16px]' for="Name">Status {!isSelectDesabled && <span>*</span>}</label>
                <select
									className='block border border-solid border-[#00000040] text-[16px] p-2 rounded w-full mt-2'
									name="status"
									id="cars"
                  onChange={(e) => {
                    setPaymentStatus(e.target.value)
                    setStatusErr('')
                  }}
                  disabled={isSelectDesabled}
                  defaultValue={editItem?.status}
								>
                    <option className='' value="pending">Pending</option>
                    <option className='' value="processing">Processing</option>
                    <option className='' value="completed">Completed</option>
                    <option className='' value="cancelled">Cancelled</option>
                    <option className='' value="refund">Refund</option>
                </select>
                <p className='text-xs text-red mt-2 font-bold'>{statusErr}</p>
						</div>
			</div>
      
      {
        !isSelectDesabled && 
        <div className='flex flex-row justify-end items-center pt-4'>
            <button onClick={cancelHandler} className='border border-1 border-gray-200 px-4 py-1 rounded-md'>Cancel</button>
            <button disabled={isLoading} onClick={statusSaveHandler} className='border border-1 border-primary bg-primary text-white px-4 py-1 rounded-md ms-4'>{isLoading ? 'Loading...' : 'Save'}</button>
        </div>
      }
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    editItem: state.sitePayment.singleOrder,
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderSingle: (id) => dispatch(fetchOrderSingle(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPayment);
