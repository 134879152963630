import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class CarSubModelController extends BaseController {
	static async list(page, modelId) {
		try {
			let pageQueryParam = page ? `?page=${page}` : '';
			let subModelQueryParam = ''


			if (modelId && modelId != '') {
				pageQueryParam = pageQueryParam + `&model=${modelId}`
			}

			let path = endpoint.CAR_SUB_MODEL_LIST_BY_MODEL

			if (!modelId) {
				path = endpoint.CAR_SUB_MODEL_LIST
			}
			return await this.axiosGet(
				`${path}${subModelQueryParam}${pageQueryParam}`,
				{ headers: this.getHeaders() }
			);
		} catch (error) {
			return error;
		}
	}

	static async publicList() {
		try {
			return await this.axiosGet(endpoint.CAR_SUB_MODEL_PUBLIC_LIST, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(carSubModelId) {
		try {
			return await this.axiosGet(
				`${endpoint.CAR_SUB_MODEL_SINGLE}/${carSubModelId}`,
				{ headers: this.getHeaders() }
			);
		} catch (error) {
			return error;
		}
	}

	static async create(carSubModelData) {
		try {
			return await this.axiosPost(
				endpoint.CAR_SUB_MODEL_CREATE,
				carSubModelData,
				{ headers: this.getHeaders() }
			);
		} catch (error) {
			return error;
		}
	}

	static async update(carSubModelId, updatedCarSubModelData) {
		try {
			return await this.axiosPost(
				`${endpoint.CAR_SUB_MODEL_UPDATE}/${carSubModelId}`,
				updatedCarSubModelData,
				{ headers: this.getHeaders() }
			);
		} catch (error) {
			return error;
		}
	}

	static async delete(carSubModelId) {
		try {
			return await this.axiosGet(
				`${endpoint.CAR_SUB_MODEL_DELETE}/${carSubModelId}`,
				{ headers: this.getHeaders() }
			);
		} catch (error) {
			return error;
		}
	}
}
