import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import FileUpload from '../../../components/common/FormFileds/Admin/FileUpload';
import AdminUsedCarSchema from '../../../schemas/AdminUsedCarSchema';
import { ownerIdTYpeOptions } from '../../../constants';
// import ImageGrid from '../../../components/common/ImageGrid';
import {
  deleteImageUsedCar,
  fetchPublicAddsOnsList,
  fetchPublicAdsTypeList,
} from '../../../store/actions';
import { connect } from 'react-redux';
import MultipleImgUpload from '../../../components/common/FormFileds/Admin/MultipleImgUpload';

const UsedCarForm = (props) => {
  const {
    initialValues,
    onSubmit,
    brandList,
    carModelList,
    userList,
    // fuelTypeList,
    // vehicleTypeList,
    // carCategoryList,
    // onModelChange,
    carSubModelList,
    colorList,
    publicAddsOnsList,
    fetchPublicAddsOnsList,
    fetchPublicAdsTypeList,
    publicAdsTypeList, // deleteimage,
  } = props;

  const [subModelOption, setSubModelOption] = useState([]);
  // const [updatedFlag, setUpdateFlag] = useState(false);
  // const [imageSources, setImageSources] = useState([]);
  const [modelList, setModelList] = useState([]);
  let [adTypeData, setAdTypeData] = useState([]);
  
  useEffect(() => {
    const ads = publicAdsTypeList?.filter((type) => type?.type == 'merchant');
    setAdTypeData(ads);
  }, [publicAdsTypeList]);

  // let serviceData = [
  //   { id: 'none', label: 'None', name: 'service_type', value: '3' },
  //   {
  //     id: 'photo_service',
  //     label: 'Photography Services ($500)',
  //     name: 'service_type',
  //     value: '1',
  //   },
  //   {
  //     id: 'photo__video_service',
  //     label: 'Photography & Videography ($300)',
  //     name: 'service_type',
  //     value: '2',
  //   },
  // ];

  let initVal = {
    user_id: '',
    ads_type_id: '',
    ads_on_id: '',
    car_plate_no: '',
    owner_id_type_id: '',
    color_id: '',
    owner_id: '',
    price: '',
    mileage: '',
    prefered_area: '',
    desc: '',
    chasis_number: '',
    allow_email: 0,
    image: '',
    sub_model_id: '',
    model_id: '',
    brand_id: '',
    promotions: '',
    // isImageRequired: true,
  };

  useEffect(() => {
    fetchPublicAddsOnsList(1);
    fetchPublicAdsTypeList(1);
  }, []);
  const formik = useFormik({
    initialValues: initialValues || initVal,
    validationSchema: AdminUsedCarSchema,
    onSubmit,
  });
  // console.log(formik.errors);
  // console.log(formik.values);

  const handleBrandChange = (e) => {
    const filteredModels = carModelList.filter(
      (model) => model.brand == e.target.value
    );

    setModelList(filteredModels);
    setSubModelOption([]);

    formik.setFieldValue("model_id", "")
		formik.setFieldValue("sub_model_id", "")
  };

  const handleModelChange = (e) => {
    const formattedSubModels = [];

    carSubModelList.forEach((subModel) => {
      if (subModel.model_id == e.target.value) {
        formattedSubModels.push({
          value: subModel.id,
          label: subModel?.key_info?.name,
          model: subModel.model_id,
        });
      }
    });
    setSubModelOption(formattedSubModels);
		formik.setFieldValue("sub_model_id", "")

  };

  return (
    <AdminForm formik={formik} onBtnCancelClick={props.onCancel}>
      <div className="p-6.5">
        <div className="p-6.5">
          <p className="mb-4">Ad Type</p>
          <div className="flex flex-wrap mb-6">
            <FormField
              type="radiobutton"
              radioData={adTypeData}
              formik={formik}
              name={'ads_type_id'}
            />
          </div>

          <p className="mb-4">Photography Services</p>
          <div className="flex flex-wrap mb-4 ">
            <FormField
              type="radiobutton"
              radioData={publicAddsOnsList}
              formik={formik}
              name={'ads_on_id'}
            />
          </div>

          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label={'Merchant'}
                id={'user_id'}
                options={userList}
                formik={formik}
                required
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label={'Car Plate No'}
                id={'car_plate_no'}
                formik={formik}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label={'Owner Id Type'}
                id={'owner_id_type_id'}
                options={ownerIdTYpeOptions}
                formik={formik}
              />
            </div>
          </div>

          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label={'Owner Id'}
                id={'owner_id'}
                formik={formik}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label={'Asking Price (Optional)'}
                id={'price'}
                formik={formik}
              />
            </div>
          </div>

          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Brand"
                id="brand_id"
                options={brandList}
                formik={formik}
                customOnChange={(e) => handleBrandChange(e)}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Model"
                id="model_id"
                options={modelList}
                formik={formik}
                customOnChange={(e) => handleModelChange(e)}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Sub Model"
                id="sub_model_id"
                options={subModelOption}
                formik={formik}
              />
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label={'Color'}
                id={'color_id'}
                options={colorList}
                formik={formik}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label={'Mileage (Optional)'}
                id={'mileage'}
                formik={formik}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label={'Preferred Viewing Area (Optional)'}
                id={'prefered_area'}
                formik={formik}
              />
            </div>
          </div>

          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-full">
              <FormField
                type="text"
                label={'Chassis number (Optional)'}
                id={'chasis_number'}
                formik={formik}
              />
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-2/2">
              <FormField
                type="textarea"
                label={'Promotions (up to 500 characters)'}
                id={'promotions'}
                formik={formik}
              />
            </div>
          </div>

          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-2/2">
              <FormField
                type="textarea"
                label={'Description (up to 500 characters)'}
                id={'desc'}
                formik={formik}
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <FormField
                type="checkbox"
                label={'Allow enquiry to be send to my email'}
                id={'allow_email'}
                formik={formik}
              />
            </div>
          </div>
          <div>
            {/* <FileUpload formik={formik} id={'image'} multiple={true} /> */}
            <MultipleImgUpload  formik={formik} id={'image'} multiple={true} />
          </div>
        </div>
      </div>
    </AdminForm>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.usedCar.loading,
    error: state.usedCar.error,
    success: state.usedCar.success,
    deleteimage: state.usedCar,
    publicAddsOnsList: state.addsOns.publicAddsOnsList,
    publicAdsTypeList: state.adsType.publicAdsTypeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteImageUsedCar: (id) => dispatch(deleteImageUsedCar(id)),
    fetchPublicAddsOnsList: (id) => dispatch(fetchPublicAddsOnsList(id)),
    fetchPublicAdsTypeList: (id) => dispatch(fetchPublicAdsTypeList(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UsedCarForm);
