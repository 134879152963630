import React from 'react';
import { Link } from 'react-router-dom';
import { formatNumberWithCommas } from '../../../helper/formatNumberWithCommas';

const SubmodelTable = ({ publicSingleNewCar }) => {
  return (
    <div className="container mx-auto ">
      <div className="p-4 overflow-x-auto">
        <table className="w-full table-auto border border-solid border-[#D9D9D9] ">
          <thead className="bg-[#D9D9D9]">
            <tr>
              <th className="py-2 text-start px-10">Sub model</th>
              <th className="py-2 text-start px-10">Price</th>
              <th className="py-2 text-start px-10">Depreciation</th>
              <th className="py-2 text-start px-10">Fuel Economy</th>
              <th className="py-2 text-start px-10">Power</th>
              <th className="py-2 text-start px-10">Transmission</th>
              <th className="py-2 text-start px-10">Info</th>
            </tr>
          </thead>
          <tbody>
            {publicSingleNewCar?.new_car_submodel?.map((item, index) => (
              <tr key={index}>
                <td className="flex flex-row justify-start items-center ps-0 md:ps-2 py-2 w-[200px] md:w-fit">
                  <input
                    type="checkbox"
                    className="product_table_checkbox ms-2"
                  />
                  <p className="ms-3">{item?.car_submodel?.key_info?.name}</p>
                </td>
                <td className="px-5 md:px-10 py-2 ">
                  <span className="text-[#0534FF] font-bold">
                    $
                    {formatNumberWithCommas(
                      item?.car_submodel?.key_info?.price
                    )}
                  </span>
                  <br />
                  (w/o COE)
                </td>
                <td className="px-10  relative">
                 <p className='absolute top-1.5'> {item?.car_submodel?.key_info?.depreciation}</p>
                </td>
                <td className="px-10 relative">
                  <p className='absolute top-1.5'>{item?.car_submodel?.performance?.fuel_consumption}</p>
                </td>
                <td className="px-10 relative">
                  <p className='absolute top-1.5'>{item?.car_submodel?.performance?.power}bhp</p>
                </td>
                <td className="px-10 relative">
                  <p className='absolute top-1.5'>{item?.car_submodel?.enginer_transmission?.transmission}</p>
                </td>
                <td className="px-10 py-2 underline text-[#0534FF] relative">
                  <Link to={'/new-car-new_car_spec_features'}className='absolute top-1.5'>
                    Specs & Features
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubmodelTable;
