import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import FileUpload from '../../../components/common/FormFileds/Admin/FileUpload';
import { countryOptions, ownerIdTYpeOptions } from '../../../constants';
// import ImageGrid from '../../../components/common/ImageGrid';
import {
  deleteImageUsedCar,
  fetchPublicAddsOnsList,
  fetchPublicAdsTypeList,
} from '../../../store/actions';
import { connect } from 'react-redux';
import AdminNewcarSchema from '../../../schemas/AdminNewcarSchema';
import { MultiSelect } from '../../../components/common/MultiSelect/MultiSelect';
import { SingleSelectInput } from '../../../components/common/SingleSelectInput/SingleSelectInput';
import MultipleImgUpload from '../../../components/common/FormFileds/Admin/MultipleImgUpload';

const NewCarForm = (props) => {
  const {
    initialValues,
    onSubmit,
    brandList,
    carModelList,
    userList,
    // fuelTypeList,
    // vehicleTypeList,
    // carCategoryList,
    // onModelChange,
    carSubModelList,
    colorList,
    publicAddsOnsList,
    fetchPublicAddsOnsList,
    fetchPublicAdsTypeList,
    publicAdsTypeList, // deleteimage,
    selectedColors, setSelectedColors,
    selectedSubModels, setSelectedSubModels,
    errors,
    countryList,
    from,
    isloading,
    userData
  } = props;

  const [subModelOption, setSubModelOption] = useState([]);
  // const [updatedFlag, setUpdateFlag] = useState(false);
  // const [imageSources, setImageSources] = useState([]);
  const [modelList, setModelList] = useState([]);
  let [adTypeData, setAdTypeData] = useState([]);
  const [defaultMerchant, setDefaultMerchant] = useState('');

  useEffect(() => {
    const ads = publicAdsTypeList?.filter((type) => type?.type == 'merchant');
    setAdTypeData(ads);
  }, [publicAdsTypeList]);

  const [initVal, setInitVal] = useState({
    user_id: '',
    ads_type_id: '',
    ads_on_id: '',
    price: '',
    desc: '',
    allow_email: 0,
    image: '',
    model_id: '',
    brand_id: '',
    built_in: '',
    promotions:''
    // isImageRequired: true,
  })

  useEffect(() => {
    fetchPublicAddsOnsList(1);
    fetchPublicAdsTypeList(1);
  }, []);

  // console.log('carSubModelList============', carSubModelList);

  const formik = useFormik({
    initialValues: initialValues || initVal,
    validationSchema: AdminNewcarSchema,
    onSubmit,
  });

  console.log(formik.errors);
  // console.log(formik.values);
  // console.log(initialValues)

  const handleBrandChange = (e) => {
    setSelectedSubModels([])
    const filteredModels = carModelList.filter(
      (model) => model.brand == e.target.value
    );

    setModelList(filteredModels);
  };

  const handleModelChange = (e) => {
    setSelectedSubModels([])
    const formattedSubModels = [];

    carSubModelList.forEach((subModel) => {
      if (subModel.model_id == e.target.value) {
        formattedSubModels.push({
          value: subModel.id,
          label: subModel?.key_info?.name,
          model: subModel.model_id,
        });
      }
    });
    setSubModelOption(formattedSubModels);
  };

  useEffect(() => {
    if (from == 'site') {
      setDefaultMerchant(userData?.username)
    }
  }, [from, userData?.id]);

  return (
    <AdminForm loading={isloading} formik={formik} onBtnCancelClick={props.onCancel}>
      <div className={`${from == 'site' ? 'p-0' : 'p-0 md:p-6.5'}`}>
        <div className="p-6.5">
          <p className="mb-4">Ad Type</p>
          <div className="flex flex-wrap mb-6">
            <FormField
              type="radiobutton"
              radioData={adTypeData}
              formik={formik}
              name={'ads_type_id'}
            />
          </div>

          <p className="mb-4">Photography Services</p>
          <div className="flex flex-wrap mb-4 ">
            <FormField
              type="radiobutton"
              radioData={publicAddsOnsList}
              formik={formik}
              name={'ads_on_id'}
            />
          </div>

          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              {
                from == 'site' ? <div className=''> <label className='mb-2.5 block text-black dark:text-white  '>Merchant</label> <SingleSelectInput isDisabled={true} defaultValue={defaultMerchant} /> </div> : <FormField
                  type="select"
                  label={'Merchant'}
                  id={'user_id'}
                  options={userList}
                  formik={formik}
                  required
                />
              }
            </div>
            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Brand"
                id="brand_id"
                options={brandList}
                formik={formik}
                customOnChange={(e) => handleBrandChange(e)}
              />
            </div>
            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Model"
                id="model_id"
                options={modelList}
                formik={formik}
                customOnChange={(e) => handleModelChange(e)}
              />
            </div>
          </div>

          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Country"
                id="built_in"
                formik={formik}
                options={countryList}
              />
            </div>

            <div className="w-full xl:w-1/2">

              <MultiSelect
                required={true}
                optionsData={subModelOption || []}
                selectedOptions={selectedSubModels}
                setSelectedOptions={setSelectedSubModels}
                label="Sub Model"
                placeholder="Select sub model"

              />
              {/* {(selectedSubModels?.length == 0 && errors?.subModelErr )? <p className='text-sm text-red-500'>Sub Model is required</p> : ''} */}
            </div>
            <div className="w-full xl:w-1/2">
              <MultiSelect
                required={true}
                optionsData={colorList}
                selectedOptions={selectedColors}
                setSelectedOptions={setSelectedColors}
                label="Color"
                placeholder="Select Color"
              />
              {/* {(selectedColors?.length == 0 && errors?.colorErr )? <p className='text-sm text-red-500'>Color is required</p> : ''} */}
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-2/2">
              <FormField
                type="textarea"
                label={'Promotions (up to 500 characters)'}
                id={'promotions'}
                formik={formik}
              />
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-2/2">
              <FormField
                type="textarea"
                label={'Description (up to 500 characters)'}
                id={'desc'}
                formik={formik}
              />
            </div>
          </div>

          <div className="flex flex-wrap mb-4">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <FormField
                type="checkbox"
                label={'Allow enquiry to be send to my email'}
                id={'allow_email'}
                formik={formik}
              />
            </div>
          </div>
          <div>
            {/* <FileUpload formik={formik} id={'image'} multiple={true} /> */}
            <MultipleImgUpload  formik={formik} id={'image'} multiple={true} />
          </div>
        </div> 
      </div>
    </AdminForm>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.usedCar.loading,
    error: state.usedCar.error,
    success: state.usedCar.success,
    deleteimage: state.usedCar,
    publicAddsOnsList: state.addsOns.publicAddsOnsList,
    publicAdsTypeList: state.adsType.publicAdsTypeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteImageUsedCar: (id) => dispatch(deleteImageUsedCar(id)),
    fetchPublicAddsOnsList: (id) => dispatch(fetchPublicAddsOnsList(id)),
    fetchPublicAdsTypeList: (id) => dispatch(fetchPublicAdsTypeList(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewCarForm);
