import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { IoSearchSharp } from 'react-icons/io5';
import {
  fetchBrandPublicList,
  fetchCarCategoryList,
  fetchCarModelList,
  fetchColorList,
  fetchFuelTypeList,
  fetchShortListedList,
  fetchSuggestedUsedCarList,
  fetchUsedCarList,
  fetchVehicleTypePublicList,
  usedCarShortListSave,
} from '../../../store/actions';

import FollowOnSocialMedia from '../../../components/site/FollowOnSocialMedia/FollowOnSocialMedia';

import SearchCar from '../../../components/site/SearchedCarComponents/SearchCar';
import SuggestedCarsSection from '../../../components/site/SuggestedCar';
import Layout from './../../../components/site/Layout/Base/Layout';
import { carPriceRanges, depreciationValue } from './../../../constants/index';
import FilterBlock from './FilterBlock';
import { toast } from 'react-toastify';
import { usedCarShortlistSaveSuccess } from '../../../store/actions/UsedCarAction';

const SearchedUsedCarsHome = (props) => {
  const [queryParams, setQueryParams] = useSearchParams();

  const [clearAll, setClearAll] = useState(false);
  const [filters, setFilters] = useState([]);
  // const [isOn, setIsOn] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [brandValue, setBrandValue] = useState('');
  const [modelValue, setModelValue] = useState('');
  const [priceRange, setPriceRange] = useState('');
  // const [depreciationRange, setDepreciationRange] = useState('');
  const [milageRange, setMilageRange] = useState('');
  const [engineCapacityRange, setEngineCapacityRange] = useState('');
  const [yearRange, setYearRange] = useState('');
  const [showUsedCar, setShowUsedCar] = useState([]);
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [currentUserShortlistCar, setCurrentUserShortlistCar] = useState([]);

  const [searchParam, setSearchParam] = useState({
    brand_id: queryParams.get('brand_id') ?? '',
    model_id: queryParams.get('model_id') ?? '',
    year_range: queryParams.get('year_range') ?? '',
    price_range: queryParams.get('price_range') ?? '',
    depreciation_range: queryParams.get('depreciation_range') ?? '',
    milage_range: '',
    listing_type: [],
    engine_capacity: [],
    color: [],
    fuel_type: [],
    transmission_type: [],
    category_id: [],
    vehicle_type:
      queryParams.get('vehicle_id') !== ''
        ? [parseInt(queryParams.get('vehicle_id'))]
        : [],
  });
  const {
    vehicleTypePublicList,
    fuelTypeList,
    carCategoryList,
    colorList,
    brandPublicList,
    usedCarList,
    carModelList,
    fetchVehicleTypePublicList,
    fetchFuelTypeList,
    fetchCarCategoryList,
    fetchColorList,
    fetchBrandPublicList,
    fetchUsedCarList,
    fetchCarModelList,
    fetchSuggestedUsedCarList,
    suggestedUsedCar,
    usedCarShortListSave,
    error,
    success,
    usedCarShortlistSaveSuccess,
    fetchShortListedList,
    userData,
    shortlistedList,
  } = props;

  useEffect(() => {
    setShowUsedCar([]);
  }, []);

  const fetchData = async () => {
    await Promise.all([
      fetchVehicleTypePublicList(),
      fetchFuelTypeList('all', true, true),
      fetchCarCategoryList('all', true, true),
      fetchColorList('all', true, true),
      fetchBrandPublicList('all'),
      // fetchUsedCarList(1, false, 'latest'),
      fetchCarModelList('all', true, true),
      fetchSuggestedUsedCarList(),
    ]);
  };
  const buildQueryString = (params) => {
    const queryString = new URLSearchParams(params).toString();
    return queryString ? `${queryString}` : '';
  };

  useEffect(() => {
    fetchData();
  }, [fetchUsedCarList]);

  //---------Page Condition------//
  useEffect(() => {
    const queryString = buildQueryString(searchParam);
    fetchUsedCarList(page, false, 'latest', queryString);
  }, [searchParam, page]);

  useEffect(() => {
    let filterArr = [];
    if (
      brandPublicList.length > 0 &&
      carModelList.length > 0 &&
      vehicleTypePublicList.length > 0
    ) {
      if (queryParams.get('brand_id') !== '') {
        let brand = brandPublicList.find(
          (item) => item.value == queryParams.get('brand_id')
        );
        filterArr.push(brand.label);
        setBrandValue(brand.label);
      }

      if (queryParams.get('model_id') !== '') {
        let model = carModelList.find(
          (item) => item.value == queryParams.get('model_id')
        );
        filterArr.push(model.label);
        setModelValue(model.label);
      }

      if (queryParams.get('vehicle_id') !== '') {
        let vehicleType = vehicleTypePublicList.find(
          (item) => item.value == queryParams.get('vehicle_id')
        );
        filterArr.push(vehicleType.label);
      }

      if (queryParams.get('year_range') !== '') {
        filterArr.push(queryParams.get('year_range'));
        setYearRange(queryParams.get('year_range'));
      }

      if (queryParams.get('price_range') !== '') {
        let priceRangeVal = carPriceRanges.find(
          (item) => item.value == queryParams.get('price_range')
        );
        filterArr.push(priceRangeVal.label);
        setPriceRange(priceRangeVal.label);
      }
      if (queryParams.get('depreciation_range') !== '') {
        depreciationValue.find(
          (item) => item.value == queryParams.get('depreciation_range')
        );
      }
    }
    setFilters(filterArr);
  }, [brandPublicList, carModelList, vehicleTypePublicList]);

  useEffect(() => {
    usedCarShortlistSaveSuccess({});
    if (userData?.id) {
      fetchShortListedList('all', 'used_car');
    }
  }, [userData?.id]);

  useEffect(() => {
    toast.success(success, {
      position: toast.POSITION.TOP_RIGHT,
      onOpen: () => {
        usedCarShortlistSaveSuccess({});
        fetchShortListedList('all', 'used_car');
      },
      onClose: () => { },
    });
  }, [error, success]);


  const shortlistHandler = (id, shortType) => {
    const existsShortList = shortlistedList?.find(
      (item) => item?.car_listing_id == id
    );

    if (!existsShortList?.car_listing_id) {
      const shortObj = {
        car_listing_id: id,
        type: 'used_car',
      };
      usedCarShortListSave(shortObj, 'save');
    } else {
      const shortObj = {
        id: existsShortList?.id,
        type: 'used_car',
      };
      usedCarShortListSave(shortObj, 'delete');
    }
  };
  const handleYearRangeFilterChange = (min, max) => {
    const newSearchParam = { ...searchParam };
    let updatedFilterValues = [...filters];
    let index = -1;
    let newYearRange = '';

    if (min === '') {
      newYearRange = max;
    } else if (max === '') {
      newYearRange = min;
    } else {
      if (min > max) {
        [min, max] = [max, min];
      }
      newYearRange = `${min}-${max}`;
    }

    if (yearRange !== '') {
      index = updatedFilterValues.indexOf(yearRange);
      updatedFilterValues.splice(index, 1);
      updatedFilterValues.push(newYearRange);
      setFilters(updatedFilterValues);
      newSearchParam.year_range = newYearRange;
      setSearchParam(newSearchParam);
    } else {
      if (newYearRange !== '') {
        updatedFilterValues.push(newYearRange);
        setYearRange(newYearRange);
        setFilters(updatedFilterValues);
        newSearchParam.year_range = newYearRange;
        setSearchParam(newSearchParam);
      }
    }
  };

  const handleDropDownFilterSelect = (selectedValues, flag) => {
    const newSearchParam = { ...searchParam };
    let updatedFilterValues = [...filters];
    let index = -1;

    if (
      brandValue !== '' ||
      modelValue !== '' ||
      priceRange !== '' ||
      milageRange !== '' ||
      engineCapacityRange !== ''
    ) {
      if (flag === 'brand') {
        index = updatedFilterValues.indexOf(brandValue);
      } else if (flag === 'model') {
        index = updatedFilterValues.indexOf(modelValue);
      } else if (flag === 'price') {
        index = updatedFilterValues.indexOf(priceRange);
      } else if (flag === 'milage') {
        index = updatedFilterValues.indexOf(milageRange);
      } else if (flag === 'engine_capacity') {
        index = updatedFilterValues.indexOf(engineCapacityRange);
      }

      if (index !== -1) {
        updatedFilterValues.splice(index, 1);
      }
    }

    updatedFilterValues.push(selectedValues.label);
    setFilters(updatedFilterValues);

    if (flag === 'brand') {
      newSearchParam.brand_id = selectedValues.value;
      setBrandValue(selectedValues.label);
    } else if (flag === 'model') {
      newSearchParam.model_id = selectedValues.value;
      setModelValue(selectedValues.label);
    } else if (flag === 'price') {
      newSearchParam.price_range = selectedValues.value;
      setPriceRange(selectedValues.label);
    } else if (flag === 'milage') {
      newSearchParam.milage_range = selectedValues.value;
      setMilageRange(selectedValues.label);
    } else if (flag === 'engine_capacity') {
      newSearchParam.engine_capacity = selectedValues.value;
      setEngineCapacityRange(selectedValues.label);
    }
    setSearchParam(newSearchParam);
  };

  const handleCheckBoxFilterSelect = (label, selectedValues) => {
    let updatedFilterValues = [...filters];
    const newSearchParam = { ...searchParam };
    let index = -1;

    index = updatedFilterValues.indexOf(selectedValues.label);
    if (index !== -1) {
      updatedFilterValues.splice(index, 1);
      let searchIndex = newSearchParam[label].indexOf(selectedValues.value);
      if (searchIndex !== -1) {
        newSearchParam[label].splice(searchIndex, 1);
      }
    } else {
      updatedFilterValues.push(selectedValues.label);
      newSearchParam[label].push(selectedValues.value);
    }

    setFilters(updatedFilterValues);
    setSearchParam(newSearchParam);
  };

  const handleClearAll = () => {
    setClearAll(!clearAll);
    setFilters([]);
    setSearchParam({
      brand_id: '',
      model_id: '',
      year_range: '',
      price_range: '',
      milage_range: '',
      listing_type: [],
      engine_capacity: [],
      color: [],
      fuel_type: [],
      transmission_type: [],
      category_id: [],
      vehicle_type: [],
    });
    setEngineCapacityRange('');
    setMilageRange('');
    setPriceRange('');
  };

  const loadmoreCarHandler = () => {
    setPage((prev) => prev + 1);
  };

  const getCarListingTypeOptions = () => {
    const carListingTypeOptions = [{ label: 'All', value: 'All' }];
    for (let index in showUsedCar) {
      let usedCar = showUsedCar[index];
      let isExist = carListingTypeOptions.some(
        (obj) => obj.value === 'Featured'
      );
      if (usedCar.is_feature === 1 && !isExist) {
        let option = {
          label: 'Featured',
          value: 'Featured',
        };
        carListingTypeOptions.push(option);
      }
    }
    return carListingTypeOptions;
  };

  const getColorList = () => {
    let colors = [];
    if (usedCarList?.color_ids?.length > 0 || searchParam.model_id !== '') {
      usedCarList.color_ids.forEach((color_id) => {
        const colorItem = colorList.find((color) => color.value === color_id);
        if (colorItem) {
          colors.push(colorItem);
        }
      });
    } else {
      colors = colorList;
    }
    return colors;
  };

  const getFuelTypeList = () => {
    let fuelTypes = [];
    if (usedCarList?.fuel_ids?.length > 0 || searchParam.model_id !== '') {
      usedCarList.fuel_ids.forEach((fuel_id) => {
        const fuelTypeItem = fuelTypeList.find(
          (fuel) => fuel.value === fuel_id
        );
        if (fuelTypeItem) {
          fuelTypes.push(fuelTypeItem);
        }
      });
    } else {
      fuelTypes = fuelTypeList;
    }

    return fuelTypes;
  };

  const getTransmissionType = () => {
    const transmissionTypes = [];
    for (let index in usedCarList?.rows) {
      let usedCar = usedCarList?.rows[index];
      let isExist = transmissionTypes.some(
        (obj) =>
          usedCar.transmission_type !== null &&
          obj.value === usedCar.transmission_type
      );
      if (!isExist) {
        if (usedCar?.transmission_type !== null) {
          let transmissionObj = {
            label: usedCar.transmission_type,
            value: usedCar.transmission_type,
          };
          transmissionTypes.push(transmissionObj);
        }
      }
    }
    return transmissionTypes;
  };

  const getCarCategoryList = () => {
    let carCategories = [];
    if (usedCarList?.category_ids?.length > 0 || searchParam.model_id !== '') {
      usedCarList?.category_ids.forEach((category_id) => {
        const categoryItem = carCategoryList?.find(
          (category) => category?.value === category_id
        );

        if (categoryItem && categoryItem?.type === 1) {
          carCategories?.push(categoryItem);
        }
      });
    
    } else {
      carCategories = carCategoryList;
     
    }

    return carCategories;
  };

  const getVehicleTypePublicList = () => {
    let vehicleTypes = [];
    if (usedCarList?.vehicle_ids?.length > 0 || searchParam.model_id !== '') {
      usedCarList.vehicle_ids.forEach((vehicle_id) => {
        const vehicleItem = vehicleTypePublicList.find(
          (vehicle) => vehicle.value === vehicle_id
        );
        if (vehicleItem) {
          vehicleTypes.push(vehicleItem);
        }
      });
    } else {
      vehicleTypes = carCategoryList;
    }
    return vehicleTypes;
  };

  const togglerData = [
    {
      arrayName: getCarListingTypeOptions(),
      id: 'listing_type',
      label: 'Listing',
      isOpen: openDropdown === 'listing',
      title: 'Listing',
    },
    {
      arrayName: getColorList(),
      id: 'color',
      label: 'Color',
      isOpen: openDropdown === 'Color',
      title: 'Color',
    },
    {
      arrayName: getFuelTypeList(),
      id: 'fuel_type',
      label: 'Fuel Type',
      isOpen: openDropdown === 'Fuel Type',
      title: 'Fuel Type',
    },
    {
      arrayName: getTransmissionType(),
      id: 'transmission_type',
      label: 'Transmission',
      isOpen: openDropdown === 'Transmission',
      title: 'Transmission',
    },
    {
      arrayName: getCarCategoryList(),
      id: 'category_id',
      label: 'Category',
      isOpen: openDropdown === 'Category',
      title: 'Category',
    },
    {
      arrayName: getVehicleTypePublicList(),
      id: 'vehicle_type',
      label: 'Vehicle Type',
      isOpen: openDropdown === 'Vehicle',
      title: 'Vehicle',
    },
  ];

  useEffect(() => {
    if (usedCarList?.rows && usedCarList?.rows.length > 0) {
      if (page > 1) {
        let updatedUsedCarList = [...showUsedCar];
        updatedUsedCarList.push(...usedCarList?.rows);
      
        setShowUsedCar(updatedUsedCarList);
      } else {
        setShowUsedCar(usedCarList?.rows);
      }
    }
  }, [usedCarList]);

  let resultCarBlock = [];

  if (usedCarList && usedCarList.rows && usedCarList.rows.length > 0) {
    resultCarBlock = (
      <>
        <div className="lg:flex lg:justify-between gap-3 items-center ">
          <div
            className="block lg:hidden border py-1 text-center text-[#0534FF] font-bold rounded cursor-pointer"
            onClick={() => setShowFilter(!showFilter)}
          >
            <button>Filter</button>
          </div>
          <p className="text-[666] opacity-60 w-full hidden lg:block">
            Showing {showUsedCar?.length} of {usedCarList?.count} Vehicles{' '}
          </p>
          <div className=" lg:flex  lg:justify-end gap-3 opacity-80 items-center w-full">
            <p className="py-1 text-center font-bold rounded my-2">Sort by</p>
            <Select
              options={[
                {
                  value: 'Relevance',
                  label: 'Relevance',
                },
              ]}
              type="text"
              id="Relevance"
              placeholder={'Relevance'}
              className=" my-2 shadow-sm sm:text-sm"
            />
          </div>
        </div>
        <p className="px-6 py-1.5 text-white bg-[#0BC18B] w-fit rotate-90 ms-auto absolute  -right-11">
          COMPARE
        </p>
        {showUsedCar?.map((car, index) => (
          <SearchCar
            shortlistedListData={shortlistedList}
            userData={userData}
            shortlistHandler={shortlistHandler}
            key={index}
            car={car}
          />
        ))}

        <div className="flex justify-center my-8">
          {showUsedCar?.length >= usedCarList?.count ? (
            ''
          ) : (
            <button
              onClick={() => loadmoreCarHandler()}
              className="bg-[#0958D9] text-white text-[20px] h-[46px] w-[230px] rounded"
            >
              Load More
            </button>
          )}
        </div>
      </>
    );
  }
  if (usedCarList?.rows?.length <= 0) {
    resultCarBlock = (
      <div className="container mx-auto">
        <div className=" mx-auto  ">
          <p className="font-semibold  text-center">
            <p className="text-3xl">No results found</p>
            <br />
            <p className="text-[#757575] text-xl">
              Please try a different search criteria <br />
            </p>
          </p>
        </div>
      </div>
    );
  }

  let renderFilter = '';
  if (brandPublicList.length > 0 && carModelList.length > 0) {
    renderFilter = (
      <FilterBlock
        filters={filters}
        brandList={brandPublicList}
        togglerData={togglerData}
        handleCheckBoxFilterSelect={handleCheckBoxFilterSelect}
        handleClearAll={handleClearAll}
        clearAll={clearAll}
        carModelList={carModelList}
        handleDropDownFilterSelect={handleDropDownFilterSelect}
        handleYearRangeFilterChange={(min, max) =>
          handleYearRangeFilterChange(min, max)
        }
        queryParams={searchParam}
      />
    );
  }

  return (
    <Layout>
      <hr className="border-t border-[#666] border-opacity-30 my-1.5" />
      <div className="container grid grid-cols-1  lg:grid-cols-4 gap-8 mx-auto my-10 overflow-hidden">
        <div>
          <div className={`hidden lg:block `}>{renderFilter}</div>
          <div className="px-4">
            {showFilter && <div className="col-span-1">{renderFilter}</div>}
          </div>
        </div>
        <div className={`lg:col-span-3 p-1 lg:px-0 `}>
          <div>{resultCarBlock}</div>
          {suggestedUsedCar && suggestedUsedCar.length > 0 && (
            <SuggestedCarsSection suggestedUsedCarList={suggestedUsedCar} />
          )}
        </div>
      </div>
      <FollowOnSocialMedia />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.usedCar.loading,
    error: state.usedCar.error,
    success: state.usedCar.success,
    vehicleTypePublicList: state.vehicleType.vehicleTypePublicList,
    fuelTypeList: state.fuelType.fuelTypeList,
    carCategoryList: state.carCategory.carCategoryList,
    colorList: state.color.colorList,
    brandPublicList: state.brand.brandPublicList,
    usedCarList: state.usedCar.usedCarList,
    carModelList: state.carModel.carModelList,
    suggestedUsedCar: state.usedCar.suggestedUsedCar,
    userData: state.auth.userData,
    shortlistedList: state.shortlisted.shortlistedList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVehicleTypePublicList: () => dispatch(fetchVehicleTypePublicList()),
    fetchFuelTypeList: (page, isProcess, isPublic) =>
      dispatch(fetchFuelTypeList(page, isProcess, isPublic)),
    fetchCarCategoryList: (page, isProcess, isPublic) =>
      dispatch(fetchCarCategoryList(page, isProcess, isPublic)),
    fetchColorList: (page, isProcess, isPublic) =>
      dispatch(fetchColorList(page, isProcess, isPublic)),
    fetchBrandPublicList: (page) => dispatch(fetchBrandPublicList(page)),
    fetchUsedCarList: (page, isProcess, isPublic, searchParam) =>
      dispatch(fetchUsedCarList(page, isProcess, isPublic, searchParam)),
    fetchCarModelList: (page, isProcess, isPublic) =>
      dispatch(fetchCarModelList(page, isProcess, isPublic)),

    fetchSuggestedUsedCarList: () => dispatch(fetchSuggestedUsedCarList()),
    usedCarShortListSave: (shortData, shortType) =>
      dispatch(usedCarShortListSave(shortData, shortType)),
    usedCarShortlistSaveSuccess: ({ }) =>
      dispatch(usedCarShortlistSaveSuccess({})),
    fetchShortListedList: (page, type) =>
      dispatch(fetchShortListedList(page, type)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchedUsedCarsHome);
