import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const FilterButtons = ({
  buttons,
  createBtnText,
  formPath,
  onFilterClick,
  stateValue,
}) => {
  const [activeFilter, setActiveFilter] = useState(
    buttons && buttons[0]?.filter
  );
  // const location = useLocation();
  // console.log(
  //   'location==================',
  //   location,
  //   location?.state?.activeTab,
  //   stateValue
  // );

  // console.log('stateValue=============', stateValue);
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    if (onFilterClick) {
      onFilterClick(filter);
    }
  };

  return (
    <div className="w-full flex px-2 lg:px-4 justify-between  ">
      <div className="w-fit lg:w-full flex justify-between items-center mb-4 md:mb-0 py-4 pr-4">
        {buttons ? (
          <div>
            {buttons?.map((button) => (
              <a
                key={button?.filter}
                href="#"
                className={`py-2 px-2 px-7 mx-4 lg:mx-2 w-[170px] cursor-pointer rounded ${
                  activeFilter === button?.filter
                    ? 'bg-[#0534FF] text-white '
                    : 'bg-[#fff] text-[#4096FF] border border-[#00000026]'
                }`}
                onClick={() => handleFilterClick(button?.filter)}
              >
                {button.label}
              </a>
            ))}
          </div> 
        ) : (
          <div></div>
        )}
        {createBtnText ? (
          <Link
            to={formPath}
            state={stateValue}
            className="h-[35px] w-[170px] flex justify-center items-center cursor-pointer bg-[#53A043] text-white "
          >
            {createBtnText}
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default FilterButtons;
