import BaseController from './BaseController';
import { endpoint } from "./EndPoint";

export default class RoleController extends BaseController {
	static async list(page) {
		try {
			const pageQueryParam = page ? `?page=${page}` : '';
			return await this.axiosGet(`${endpoint.ROLE_LIST}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(roleId) {
		try {
			return await this.axiosGet(`${endpoint.ROLE_SINGLE}/${roleId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(roleData) {
		try {
			return await this.axiosPost(endpoint.ROLE_CREATE, roleData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(roleId, updatedRoleData) {
		try {
			return await this.axiosPost(`${endpoint.ROLE_UPDATE}/${roleId}`, updatedRoleData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async delete(roleId) {
		try {
			return await this.axiosGet(`${endpoint.ROLE_DELETE}/${roleId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
