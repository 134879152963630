import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AdminForm from '../../AdminForm/AdminForm';
import FormField from '../../../common/FormFileds/Admin/FormField';

const AdditionalFeatures = (props) => {
  const { formik } = props;
  return (
    <div className="p-6.5">
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-full">
          <FormField
            type="textarea"
            label="Features"
            id="aditional_feature"
            formik={formik}
            placeholder=" "
						rows={10}
          />
          {/* <FormField
						type="checkbox"
						label="Bluetooth"
						id="bluetooth"
						placeholder=" "
						formik={formik}
					/> */}
        </div>
      </div>
    </div>
  );
};

export default AdditionalFeatures;
