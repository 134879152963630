import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class UsedCarListingController extends BaseController {
  static async list(page, isPublic, searchParam) {
    try {
      let pageQueryParam = page ? `?page=${page}` : '';
      let path = endpoint.USED_CAR_LIST;

      let headers = this.getHeaders();

      if (searchParam !== '') {
        pageQueryParam =
          searchParam !== ''
            ? pageQueryParam + '&' + searchParam
            : pageQueryParam;
      }
      if (isPublic === 'latest') {
        headers = {};
        path = endpoint.LATEST_USED_CAR_LIST;
      } else {
        path = isPublic
          ? endpoint.USED_CAR_PUBLIC_LIST
          : endpoint.USED_CAR_LIST;
      }

      return await this.axiosGet(`${path}${pageQueryParam}`, {
        headers: headers,
      });
    } catch (error) {
      return error;
    }
  }

  static async single(listingId, isPublic) {
    try {
      let path = endpoint.USED_CAR_SINGLE;
      if (isPublic) {
        path = endpoint.USED_CAR_PUBLIC_SINGLE;
      }

      if (isPublic === 'details') {
        path = endpoint.USED_CAR_DETAILS;
      }

      return await this.axiosGet(`${path}/${listingId}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async create(listingData) {
    try {
      return await this.axiosPost(endpoint.USED_CAR_CREATE, listingData, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async update(listingId, updatedListingData, isPublic) {
    try {
      let path = endpoint.USED_CAR_UPDATE;
      if (isPublic) {
        path = endpoint.USED_CAR_UPDATE_PUBLIC;
      }
      return await this.axiosPost(`${path}/${listingId}`, updatedListingData, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async delete(listingId) {
    try {
      return await this.axiosGet(`${endpoint.USED_CAR_DELETE}/${listingId}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async deleteImage(imageUrl) {
    try {
      return await this.axiosPost(endpoint.CAR_IMAGE_DELETE, imageUrl,
        {
          headers: this.getHeaders(),
        }
      );
    } catch (error) {
      return error;
    }
  }

  static async updateStatus(listingId) {
    try {
      return await this.axiosGet(
        `${endpoint.USED_CAR_STATUS_UPDATE}/${listingId}`,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }

  static async updateenquiry(listingId) {
    try {
      return await this.axiosGet(
        `${endpoint.USED_CAR_LIST_ENQUIRY}/${endpoint.USED_CAR_ENQUIRY_COUNT}/${listingId}`,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }

  static async merchantusedcar(userId, page, isPublic) {
    try {
      return await this.axiosGet(
        `${endpoint.MERCHANT_USED_CAR_LIST}/${userId}?page=${page}`,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }

  static async updateclickcount(userId) {
    try {
      return await this.axiosGet(
        `${endpoint.MERCHANT_UPDATE_CLICK_COUNT}/${userId}`,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }

  static async usedcarboost(carId, boostData) {
    try {
      return await this.axiosPost(
        `${endpoint.USED_CAR_BOOST}/${carId}`,
        boostData,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }

  static async searchusedcar(userId, modelName) {
    try {
      return await this.axiosGet(
        `${endpoint.SEARCH_USED_CAR}/${userId}?name=${modelName}`,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }
  static async searchedUsedCar(status, featured, page) {
    const queryParams = new URLSearchParams();
    if (status) {
      queryParams.append('status', status);
    }
    if (featured) {
      queryParams.append('featured', featured);
    }
    if (page) {
      queryParams.append('page', page);
    }
    const queryString = queryParams.toString();
    try {
      return await this.axiosGet(`${endpoint.USED_CAR_LIST}?${queryString}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async getSuggestedUsedCar(searchParam) {
    try {
      let pageQueryParam = '';
      let path = endpoint.SUGGESTED_USED_CAR_GET;
      pageQueryParam = searchParam !== '' ? '?' + searchParam : '';

      return await this.axiosGet(`${path}${pageQueryParam}`, {
        headers: {},
      });
    } catch (error) {
      return error;
    }
  }

  static async updatesellerenquiry(sellerId) {
    try {
      return await this.axiosGet(
        `${endpoint.SELLER_ENQUIRY_COUNT}/${sellerId}`,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }
  static async shortlistcreate(shortData, shortType) {
    try {
      if (shortType == 'save') {
        return await this.axiosPost(
          endpoint.USEDCAR_SHORTLIST_CREATE,
          shortData,
          {
            headers: this.getHeaders(),
          }
        );
      } else {
        return await this.axiosGet(
          `${endpoint.USEDCAR_SHORTLIST_DELETE}/${shortData?.id}`,
          {
            headers: this.getHeaders(),
          }
        );
      }
    } catch (error) {
      return error;
    }
  }

  static async multipleimgupload(multipleImgData) {
    try {
      return await this.axiosPost(endpoint.MULTIPLE_CAR_IMAGE_UPLOAD, multipleImgData, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

}
