import React from 'react';

import Loader from '../Loader/Loader';
import Alert from '../Alert/Alert';

const AuthForm = ({
  formik,
  loading,
  error,
  formBlock,
  buttonText,
  additionalText,
  additionalLink,
  additionalLinkText,
  registerButton,
  registerText,
  registerLink,
}) => {
  return (
    <form className="w-full" onSubmit={formik.handleSubmit}>
      {loading && <Loader />}

      {error && <Alert message={error} type="error" />}

      {formBlock}
      {additionalText && (
        <div className="my-2 ">
          <p>
            {additionalText}
            <a href={additionalLink} className="text-primary">
              {additionalLinkText}
            </a>
          </p>
        </div>
      )}
      <div className="mb-14">
        <button
          type="submit"
          className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
        >
          {buttonText}
        </button>
      </div>
      <div>
        {additionalText && (
          <div className="my-6 text-center">
            <p>
              {registerText}
              <a
                href={
                  registerButton === 'Return to login'
                    ? additionalLink
                    : registerLink
                }
                className="text-primary"
              >
                {registerButton}
              </a>
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default AuthForm;
