import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { options } from '../../../constants';
import { useLocation } from 'react-router-dom';
import SellerCreateSchema from '../../../schemas/SellerCreateSchema';
import FormField from '../../common/FormFileds/Admin/FormField';
import { countryOptions } from '../../../constants';
import { useNavigate } from 'react-router';

const CreateUserForm = (props) => {
  const { initialValues, onSubmit, userData, tabLabel, loading } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [tabLabelSet, setTabLabelSet] = useState(tabLabel);
  // console.log(merchant);
  let initVal = {
    username: '',
    name: '',
    email: '',
    contact_no: '',
    address: '',
    status: '',
    country_id: '',
    postal_code: '',
    merchant_id: '',
  };
  const formik = useFormik({
    initialValues: initialValues || initVal,
    validationSchema: SellerCreateSchema,
    onSubmit,
  });

  useEffect(() => {
    initVal.merchant_id = formik.values['merchant_id'] = userData.id;
  }, [userData]);

  // console.log(formik.values);
  useEffect(() => {
    formik.setValues(initialValues || initVal);
  }, [initialValues]);

  useEffect(() => {
    const { state } = location;
    if (state && state.activeTab !== undefined) {
      setTabLabelSet(state.activeTab);
      console.log('Active Tab:', tabLabelSet);
    }
  }, [location]);

  const handleCancel = () => {
    navigate('/user-dashboard', {
      state: {
        activeTab: tabLabelSet,
      },
    });

    console.log('Cancel==========', tabLabelSet);
  };
  console.log('Cancel==========', tabLabelSet);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6.5">
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label="User Name"
                id="username"
                formik={formik}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField type="text" label="Name" id="name" formik={formik} />
            </div>
            <div className="w-full xl:w-1/2">
              <FormField type="text" label="Email" id="email" formik={formik} />
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label="Address"
                id="address"
                formik={formik}
              />
            </div>
            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Country"
                id="country_id"
                formik={formik}
                options={countryOptions}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label="Phone No"
                id="contact_no"
                formik={formik}
              />
            </div>
          </div>
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2">
              <FormField
                type="text"
                label="Postal Code"
                id="postal_code"
                formik={formik}
              />
            </div>

            <div className="w-full xl:w-1/2">
              <FormField
                type="select"
                label="Status"
                id="status"
                options={options}
                formik={formik}
              />
            </div>
          </div>
          <div className="mt-10 flex justify-end gap-4.5">
            <button
              type="submit"
              disabled={loading && true}
              className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-70"
            >
              {loading ? 'Loading' : 'Save'}
            </button>{' '}
            <button
              className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black dark:border-strokedark dark:text-white"
              type="submit"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateUserForm;
