import BaseController from './BaseController';
import { endpoint } from "./EndPoint";

export default class VehicleTypeController extends BaseController {

	static async list(page, isPublic, searchParam) {
		try {
			let pageQueryParam = page ? `?page=${page}` : '';
			let path = isPublic ? endpoint.VEHICLE_TYPE_LIST_PUBLIC_LIST : endpoint.VEHICLE_TYPE_LIST
			pageQueryParam = searchParam !== '' ? pageQueryParam + '&' + searchParam : pageQueryParam;
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async publicList(page) {
		try {
			let pageQueryParam = page ? `?page=${page}` : '';
			let path = endpoint.VEHICLE_TYPE_LIST_PUBLIC_LIST
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(vehicleTypeId) {
		try {
			return await this.axiosGet(`${endpoint.VEHICLE_TYPE_SINGLE}/${vehicleTypeId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(vehicleTypeData) {

		try {
			return await this.axiosPost(endpoint.VEHICLE_TYPE_CREATE, vehicleTypeData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(vehicleTypeId, updatedVehicleTypeData) {
		try {
			return await this.axiosPost(`${endpoint.VEHICLE_TYPE_UPDATE}/${vehicleTypeId}`, updatedVehicleTypeData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async delete(vehicleTypeId) {
		try {
			return await this.axiosGet(`${endpoint.VEHICLE_TYPE_DELETE}/${vehicleTypeId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
