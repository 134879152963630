import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AdminForm from '../../AdminForm/AdminForm';
import FormField from '../../../common/FormFileds/Admin/FormField';

const Brakes = (props) => {
  const { formik } = props;
  return (
    <div className="p-6.5">
      <div className="mb-4.5  grid grid-cols-3 gap-6 xl:flex-row">
        <div className="w-full xl:w-full">
          <FormField
            type="text"
            label="Brakes (Front)"
            id="break_front"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-full">
          <FormField
            type="text"
            label="Brakes (Rear)"
            id="break_rear"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>
    </div>
  );
};

export default Brakes;
