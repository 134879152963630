import * as actionTypes from '../actions/actionTypes';

const initialState = {
	cityList: [],
	singleCity: null,
	loading: false,
	error: null,
	success: null,
};

const cityReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CITY_INIT:
			return {
				...state,
				loading: false,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_CITY_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				cityList: action.payload,
				error: null,
			};
		case actionTypes.FETCH_CITY_LIST_FAILURE:
		case actionTypes.FETCH_CITY_SINGLE_FAILURE:
		case actionTypes.CREATE_CITY_FAILURE:
		case actionTypes.UPDATE_CITY_FAILURE:
		case actionTypes.DELETE_CITY_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_CITY_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleCity: action.payload,
				error: null,
			};
		case actionTypes.CREATE_CITY_SUCCESS:
			return {
				...state,
				loading: false,
				cityList: state.cityList,
				error: null,
				success: action.payload,
			};
		case actionTypes.UPDATE_CITY_SUCCESS:
		case actionTypes.DELETE_CITY_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		default:
			return state;
	}
};

export default cityReducer;
