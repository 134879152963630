
//---------Calculation depreciation of each car-----------------
/*
Description
With PARF (if forfeited value in used car listing admin panel is unchecked) - Depreciation calculation: ((Used car pricing - PARF) / total days left from COE) * 365

Without PARF (if forfeited value in used car listing admin panel is checked)- Deprec calculation: (Used car price / COE total days left) * 12
*/

import { formatNumberWithCommas } from "./formatNumberWithCommas";
import { totalDayLeftFromCurrentDate } from "./regRemainingDayCalc"

export const depreciationCalc = (isForfeited, carPrice, parfPrice, coeDate) => {

    const totalDayLeft = totalDayLeftFromCurrentDate(coeDate)
    // console.log('depreciation Calc >>', isForfeited, carPrice, parfPrice, totalDayLeft)

    //If isForfeited checked
    if(isForfeited === 1){
        const result = (carPrice / totalDayLeft) * 365;
        const formatedResult = formatNumberWithCommas(result)
        if(result > 0){
            return formatedResult
        }
        else{
            return ''
        }
    }
    //If isForfeited not checked
    else{
        const totalPrice = Number(carPrice) - Number(parfPrice);
        const result =  (totalPrice / totalDayLeft)  * 365
        const formatedResult = formatNumberWithCommas(result)
        if(result > 0){
            return formatedResult
        }
        else{
            return ''
        }
    }
}