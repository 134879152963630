import React, { useEffect, useRef, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import moment from 'moment';
import placeholderImage from './../../../assets/images/car_slider_placeholder.png';

const ListingInfoCard = ({ id, imgSrc, title, subTitle, price, status, postedOn, refreshedOn, expiredOn, enquiryCount, editedCount, carPlateNo, handleDropDownClick, totalClick, is_feature }) => {
	// // let imgPath =
	// //   imgSrc !== '' ? `${process.env.REACT_APP_BASE_URL}/${imgSrc}` : imgSrc;
	// let imgPath = imgSrc;

	// // temporary will be remove later
	// if (imgSrc == '/static/media/car.22be97a1c789aca700e5.png') {
	// 	imgPath = imgSrc;
	// }

	const [imagePath, setImagePath] = useState(placeholderImage)
	const [toggle, setToggle] = useState(false);
	const handleToggle = () => {
		setToggle(!toggle);
	};

	useEffect(() => {
		// console.log(imgSrc);
		if (imgSrc !== undefined) {
			const images = JSON.parse(imgSrc)
			let img = images!== null && images.length > 0 ? images[0].image : placeholderImage
			setImagePath(img)
		}
	}, [imgSrc])

	return (
		<div
			className="flex flex-col md:flex-row border-radius-3 border border-solid border-[#D9D9D9] shadow-box mb-5"
			style={{ boxShadow: '0px 8px 13px -3px rgba(0, 0, 0, 0.07)' }}
		>
			<div className="w-full md:w-1/3 mb-2 md:mb-0">
				<img
					src={imagePath}
					alt="Your Image"
					className="w-full h-full object-cover"
				/>
			</div>
			<div className="w-full  p-4 lg:p-8">
				<div className="flex mb-4 justify-between ">
					<div>
						{
							carPlateNo && <span className=" p-2 mr-2 text-xs border border-[#00000026]">
								{carPlateNo}
							</span>
						}

						{
							is_feature === 1 ? <span className=" p-2 bg-[#0534FF] text-white text-xs border border-[#0534FF]">
								Featured
							</span> : ''
						}

					</div>
					<div className="flex justify-between items-center mb-4">
						<button
							className={`py-1 px-4 text-white text-xs mx-2 ${status === 'active'
								? 'bg-success'
								: status === 'inactive'
									? 'bg-danger'
									: 'bg-[#F8BC24]'
								}`}
						>
							{status === 'active'
								? 'Active'
								: status === 'inactive'
									? 'Inactive'
									: 'Pending'}
						</button>

						<DropDownMenu
							handleDropDownClick={(flag) => {
								handleToggle()
								handleDropDownClick(id, flag)
							}}
							status={status}
						/>
					</div>
				</div>



				<div className="w-full  flex flex-col xl:flex-row justify-between">
					{/* <div className="w-full md:w-1/3 mb-4 md:mb-0"></div> */}

					<div className='w-full md:w-1/3'>
						<p className="font-bold mb-1 text-title-sm">{title}</p>
						<p className="text-gray-500 font-bold text-title-xsm">${price}</p>
					</div>

					<div className='w-full  flex flex-row justify-between'>
						<div className="w-1/3  md:w-1/3 mb-4 md:mb-0 flex items-center ms-0 md:ms-4 lg:ms-19 ">
							<div className="">
								<p className="text-gray-500">Clicked: {totalClick ? totalClick : 0}</p>
								<p className="text-gray-500">Enquiry: {enquiryCount}</p>
								<p className="text-gray-500">Edited: {editedCount}</p>
							</div>
						</div>

						<div className="ms-7 lg:ms-19 w-full md:w-1/3 mb-4 md:mb-0 text-right">
							<div className="flex ">
								<span className="">Posted on:</span>
								<span className="ms-9">{moment(postedOn).format('DD MMM YYYY')}</span>
							</div>
							<div className="flex">
								<span className="">Refreshed on:</span>
								<span className="ms-4">{moment(refreshedOn).format('DD MMM YYYY')}</span>
							</div>

							<div className="flex ">
								<span className="">Expired on:</span>
								<span className="ms-9">{expiredOn ? moment(expiredOn).format('DD MMM YYYY') : ''}</span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
};

export default ListingInfoCard;
