import * as yup from 'yup';

const SellerCreateSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  contact_no: yup
    .string()
    .required('Contact number is required')
    .min(8, 'Contact number must be at least 8 characters'),
  address: yup.string().required('Address is required'),
  status: yup.string().required('Status is required'),
  country_id: yup.string().required('Country ID is required'),
  postal_code: yup
    .string()
    .required('Postal code is required')
    .min(4, 'Postal code must be at least 4 characters'),
  merchant_id: yup.string().required('Merchant ID is required'),
});

export default SellerCreateSchema;
