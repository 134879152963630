// carSubModelActions.js
import * as actionTypes from './actionTypes';
import CarSubModelController from '../../api/CarSubModelController'; // Update the import path

export const carSubModelInit = () => {
	return {
		type: actionTypes.CAR_SUB_MODEL_INIT,
	};
};

export const fetchCarSubModelListSuccess = (carSubModels) => {
	return {
		type: actionTypes.FETCH_CAR_SUB_MODEL_LIST_SUCCESS,
		payload: carSubModels,
	};
};

export const fetchCarSubModelListFailure = (error) => {
	return {
		type: actionTypes.FETCH_CAR_SUB_MODEL_LIST_FAILURE,
		payload: error,
	};
};

export const fetchCarSubModelPublicListSuccess = (carSubModels) => {
	return {
		type: actionTypes.FETCH_CAR_SUB_MODEL_PUBLIC_LIST_SUCCESS,
		payload: carSubModels,
	};
};

export const fetchCarSubModelPublicListFailure = (error) => {
	return {
		type: actionTypes.FETCH_CAR_SUB_MODEL_PUBLIC_LIST_FAILURE,
		payload: error,
	};
};

export const fetchCarSubModelSingleSuccess = (carSubModel) => {
	return {
		type: actionTypes.FETCH_CAR_SUB_MODEL_SINGLE_SUCCESS,
		payload: carSubModel,
	};
};

export const fetchCarSubModelSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_CAR_SUB_MODEL_SINGLE_FAILURE,
		payload: error,
	};
};

export const createCarSubModelSuccess = (message) => {
	return {
		type: actionTypes.CREATE_CAR_SUB_MODEL_SUCCESS,
		payload: message,
	};
};

export const createCarSubModelFailure = (error) => {
	return {
		type: actionTypes.CREATE_CAR_SUB_MODEL_FAILURE,
		payload: error,
	};
};

export const updateCarSubModelSuccess = (carSubModel) => {
	return {
		type: actionTypes.UPDATE_CAR_SUB_MODEL_SUCCESS,
		payload: carSubModel,
	};
};

export const updateCarSubModelFailure = (error) => {
	return {
		type: actionTypes.UPDATE_CAR_SUB_MODEL_FAILURE,
		payload: error,
	};
};

export const deleteCarSubModelSuccess = (carSubModelId) => {
	return {
		type: actionTypes.DELETE_CAR_SUB_MODEL_SUCCESS,
		payload: carSubModelId,
	};
};

export const deleteCarSubModelFailure = (error) => {
	return {
		type: actionTypes.DELETE_CAR_SUB_MODEL_FAILURE,
		payload: error,
	};
};

export const fetchCarSubModelList = (page, isProcess = false, modelId) => {
	return async (dispatch) => {
		dispatch(carSubModelInit());
		CarSubModelController.list(page, modelId)
			.then((result) => {
				if (result instanceof Error) {
					dispatch(fetchCarSubModelListFailure('Something went wrong'));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchCarSubModelListSuccess(result.data.data));
				} else {
					dispatch(fetchCarSubModelListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchCarSubModelListFailure('Something went wrong'));
			});
	};
};

export const fetchCarSubModelPublicList = () => {
	return async (dispatch) => {
		dispatch(carSubModelInit());
		CarSubModelController.publicList()
			.then((result) => {
				if (result instanceof Error) {
					dispatch(fetchCarSubModelPublicListFailure('Something went wrong'));
				} else if (result.data.status !== 'Failed') {
					const processData = result.data.data.map(item => ({
						value: item.id,
						label: item?.key_info?.name,
						model: item.model_id
					}));
					dispatch(fetchCarSubModelPublicListSuccess(processData));
				} else {
					dispatch(fetchCarSubModelPublicListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchCarSubModelPublicListFailure('Something went wrong'));
			});
	};
};

export const createCarSubModel = (carSubModelData) => {
	console.log(carSubModelData);
	return async (dispatch) => {
		dispatch(carSubModelInit());

		let formatData = {};
		formatData.model_id = carSubModelData.model_id;
		formatData.key_info = {
			name: carSubModelData.name,
			price: carSubModelData.price,
			instalment: carSubModelData.instalment,
			depreciation: carSubModelData.depreciation,
			coe_price: carSubModelData.coe_price,
			road_tax: carSubModelData.road_tax,
			omv: carSubModelData.omv,
			arf: carSubModelData.arf,
			ves: carSubModelData.ves,
			total_basic: carSubModelData.total_basic,
			premium: carSubModelData.premium,
		};
		formatData.measurement = {
			seating_capacity: carSubModelData.seating_capacity,
			wheelbase: carSubModelData.wheelbase,
			dimension_length: carSubModelData.dimension_length,
			turning_radius: carSubModelData.turning_radius,
			dimension_width: carSubModelData.dimension_width,
			dimension_height: carSubModelData.dimension_height,
			kerb_weight: carSubModelData.kerb_weight,
			tank_capacity: carSubModelData.tank_capacity,
			cargo_capacity: carSubModelData.cargo_capacity,
		};
		formatData.enginer_transmission = {
			engine_capacity: carSubModelData.engine_capacity,
			engine_type: carSubModelData.engine_type,
			battery_type: carSubModelData.battery_type,
			fuel_type_id: carSubModelData.fuel_type_id,
			drive_type: carSubModelData.drive_type,
			transmission_type: carSubModelData.transmission_type,
			transmission: carSubModelData.transmission,
		};
		formatData.performance = {
			power: carSubModelData.power,
			engine_power: carSubModelData.engine_power,
			motor_power: carSubModelData.motor_power,
			torque: carSubModelData.torque,
			acceleration: carSubModelData.acceleration,
			top_speed: carSubModelData.top_speed,
			fuel_consumption: carSubModelData.fuel_consumption,
			co2_emission: carSubModelData.co2_emission,
			battery_capacity: carSubModelData.battery_capacity,
			drive_range: carSubModelData.drive_range,
			energy_consumption: carSubModelData.energy_consumption,
			dc_charging_rate_max: carSubModelData.dc_charging_rate_max,
			dc_charging_rate_total: carSubModelData.dc_charging_rate_total,
			ac_charging_rate_max: carSubModelData.ac_charging_rate_max,
			ac_charging_rate_total: carSubModelData.ac_charging_rate_total,
		};

		formatData.component = {
			suspension_front: carSubModelData.suspension_front,
			suspension_back: carSubModelData.suspension_back,
			break_front: carSubModelData.break_front,
			break_rear: carSubModelData.break_rear,
			wheel_rim_size: carSubModelData.wheel_rim_size,
		};

		formatData.other_component = {
			head_light: carSubModelData.head_light,
			is_auto_head_light: carSubModelData.is_auto_head_light,
			daytime_lights: carSubModelData.daytime_lights,
			rain_sensing_wipers: carSubModelData.rain_sensing_wipers,
			electric_folding_side_mirror:
				carSubModelData.electric_folding_side_mirror,
			multi_function_steering_wheel:
				carSubModelData.multi_function_steering_wheel,
			paddle_shifters: carSubModelData.paddle_shifters,
			keyless_entry: carSubModelData.keyless_entry,
			keyless_engine_start: carSubModelData.keyless_engine_start,
			multi_zone_aircon: carSubModelData.multi_zone_aircon,
			rear_aircon: carSubModelData.rear_aircon,
			seat_upholstery: carSubModelData.seat_upholstery,
			driver_electric_seat: carSubModelData.driver_electric_seat,
			passenger_electric_seat: carSubModelData.passenger_electric_seat,
			ventilated_seats: carSubModelData.ventilated_seats,
			electric_tailgate: carSubModelData.electric_tailgate,
			car_roof: carSubModelData.car_roof,
			bluetooth: carSubModelData.bluetooth,
			wireless_charging: carSubModelData.wireless_charging,
			navigation_system: carSubModelData.navigation_system,
			apple_carplay: carSubModelData.apple_carplay,
			android_auto: carSubModelData.android_auto,
			infotainment: carSubModelData.infotainment,
			sound_system: carSubModelData.sound_system,
			no_of_airbags: carSubModelData.no_of_airbags,
			isofix: carSubModelData.isofix,
			tyre_presure_monitor: carSubModelData.tyre_presure_monitor,
			electric_park_brake: carSubModelData.electric_park_brake,
			hill_start_assist: carSubModelData.hill_start_assist,
			emergency_brake_assist: carSubModelData.emergency_brake_assist,
			parking_sensors: carSubModelData.parking_sensors,
			park_assist: carSubModelData.park_assist,
			reverse_camera: carSubModelData.reverse_camera,
			view_camera: carSubModelData.view_camera,
			cruise_control: carSubModelData.cruise_control,
			lane_departure_warning: carSubModelData.lane_departure_warning,
			blind_spot_monitor: carSubModelData.blind_spot_monitor,
			aditional_feature: carSubModelData.aditional_feature
		};

		CarSubModelController.create(formatData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						console.log(result);
						if (
							result.response.data &&
							!Array.isArray(result.response.data.message)
						) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(createCarSubModelFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(createCarSubModelSuccess(result.data.message));
				} else {
					dispatch(createCarSubModelFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(createCarSubModelFailure('Something went wrong'));
			});
	};
};

export const updateCarSubModel = (carSubModelId, updatedCarSubModelData) => {
	return async (dispatch) => {
		dispatch(carSubModelInit());

		let formatData = {};
		formatData.model_id = updatedCarSubModelData.model_id;
		formatData.key_info = {
			name: updatedCarSubModelData.name,
			price: updatedCarSubModelData.price,
			instalment: updatedCarSubModelData.instalment,
			depreciation: updatedCarSubModelData.depreciation,
			coe_price: updatedCarSubModelData.coe_price,
			road_tax: updatedCarSubModelData.road_tax,
			omv: updatedCarSubModelData.omv,
			arf: updatedCarSubModelData.arf,
			ves: updatedCarSubModelData.ves,
			total_basic: updatedCarSubModelData.total_basic,
			premium: updatedCarSubModelData.premium,
		};
		formatData.measurement = {
			seating_capacity: updatedCarSubModelData.seating_capacity,
			wheelbase: updatedCarSubModelData.wheelbase,
			dimension_length: updatedCarSubModelData.dimension_length,
			turning_radius: updatedCarSubModelData.turning_radius,
			dimension_width: updatedCarSubModelData.dimension_width,
			dimension_height: updatedCarSubModelData.dimension_height,
			kerb_weight: updatedCarSubModelData.kerb_weight,
			tank_capacity: updatedCarSubModelData.tank_capacity,
			cargo_capacity: updatedCarSubModelData.cargo_capacity,
		};
		formatData.enginer_transmission = {
			engine_capacity: updatedCarSubModelData.engine_capacity,
			engine_type: updatedCarSubModelData.engine_type,
			battery_type: updatedCarSubModelData.battery_type,
			fuel_type_id: updatedCarSubModelData.fuel_type_id,
			drive_type: updatedCarSubModelData.drive_type,
			transmission_type: updatedCarSubModelData.transmission_type,
			transmission: updatedCarSubModelData.transmission,
		};
		formatData.performance = {
			power: updatedCarSubModelData.power,
			engine_power: updatedCarSubModelData.engine_power,
			motor_power: updatedCarSubModelData.motor_power,
			torque: updatedCarSubModelData.torque,
			acceleration: updatedCarSubModelData.acceleration,
			top_speed: updatedCarSubModelData.top_speed,
			fuel_consumption: updatedCarSubModelData.fuel_consumption,
			co2_emission: updatedCarSubModelData.co2_emission,
			battery_capacity: updatedCarSubModelData.battery_capacity,
			drive_range: updatedCarSubModelData.drive_range,
			energy_consumption: updatedCarSubModelData.energy_consumption,
			dc_charging_rate_max: updatedCarSubModelData.dc_charging_rate_max,
			dc_charging_rate_total: updatedCarSubModelData.dc_charging_rate_total,
			ac_charging_rate_max: updatedCarSubModelData.ac_charging_rate_max,
			ac_charging_rate_total: updatedCarSubModelData.ac_charging_rate_total,
		};

		formatData.component = {
			suspension_front: updatedCarSubModelData.suspension_front,
			suspension_back: updatedCarSubModelData.suspension_back,
			break_front: updatedCarSubModelData.break_front,
			break_rear: updatedCarSubModelData.break_rear,
			wheel_rim_size: updatedCarSubModelData.wheel_rim_size,
		};

		formatData.other_component = {
			head_light: updatedCarSubModelData.head_light,
			is_auto_head_light: updatedCarSubModelData.is_auto_head_light,
			daytime_lights: updatedCarSubModelData.daytime_lights,
			rain_sensing_wipers: updatedCarSubModelData.rain_sensing_wipers,
			electric_folding_side_mirror:
				updatedCarSubModelData.electric_folding_side_mirror,
			multi_function_steering_wheel:
				updatedCarSubModelData.multi_function_steering_wheel,
			paddle_shifters: updatedCarSubModelData.paddle_shifters,
			keyless_entry: updatedCarSubModelData.keyless_entry,
			keyless_engine_start: updatedCarSubModelData.keyless_engine_start,
			multi_zone_aircon: updatedCarSubModelData.multi_zone_aircon,
			rear_aircon: updatedCarSubModelData.rear_aircon,
			seat_upholstery: updatedCarSubModelData.seat_upholstery,
			driver_electric_seat: updatedCarSubModelData.driver_electric_seat,
			passenger_electric_seat: updatedCarSubModelData.passenger_electric_seat,
			ventilated_seats: updatedCarSubModelData.ventilated_seats,
			electric_tailgate: updatedCarSubModelData.electric_tailgate,
			car_roof: updatedCarSubModelData.car_roof,
			bluetooth: updatedCarSubModelData.bluetooth,
			wireless_charging: updatedCarSubModelData.wireless_charging,
			navigation_system: updatedCarSubModelData.navigation_system,
			apple_carplay: updatedCarSubModelData.apple_carplay,
			android_auto: updatedCarSubModelData.android_auto,
			infotainment: updatedCarSubModelData.infotainment,
			sound_system: updatedCarSubModelData.sound_system,
			no_of_airbags: updatedCarSubModelData.no_of_airbags,
			isofix: updatedCarSubModelData.isofix,
			tyre_presure_monitor: updatedCarSubModelData.tyre_presure_monitor,
			electric_park_brake: updatedCarSubModelData.electric_park_brake,
			hill_start_assist: updatedCarSubModelData.hill_start_assist,
			emergency_brake_assist: updatedCarSubModelData.emergency_brake_assist,
			parking_sensors: updatedCarSubModelData.parking_sensors,
			park_assist: updatedCarSubModelData.park_assist,
			reverse_camera: updatedCarSubModelData.reverse_camera,
			view_camera: updatedCarSubModelData.view_camera,
			cruise_control: updatedCarSubModelData.cruise_control,
			lane_departure_warning: updatedCarSubModelData.lane_departure_warning,
			blind_spot_monitor: updatedCarSubModelData.blind_spot_monitor,
			aditional_feature: updatedCarSubModelData.aditional_feature
		};

		CarSubModelController.update(carSubModelId, formatData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (
							result.response.data &&
							!Array.isArray(result.response.data.message)
						) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(updateCarSubModelFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(updateCarSubModelSuccess(result.data.message));
				} else {
					dispatch(updateCarSubModelFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(updateCarSubModelFailure('Something went wrong'));
			});
	};
};

export const deleteCarSubModel = (carSubModelId) => {
	return async (dispatch) => {
		dispatch(carSubModelInit());
		CarSubModelController.delete(carSubModelId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response
						? result.response.data
							? result.response.data.message
							: 'Something went wrong'
						: 'Something went wrong';
					dispatch(deleteCarSubModelFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchCarSubModelList()); // Assuming you have a fetchCarSubModelList action
					dispatch(deleteCarSubModelSuccess(result.data.message));
				} else {
					dispatch(deleteCarSubModelFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(deleteCarSubModelFailure('Something went wrong'));
			});
	};
};

export const fetchCarSubModelSingle = (carSubModelId) => {
	return async (dispatch) => {
		dispatch(carSubModelInit());
		CarSubModelController.single(carSubModelId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response
						? result.response.data
							? result.response.data.message
							: 'Something went wrong'
						: 'Something went wrong';
					dispatch(fetchCarSubModelSingleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchCarSubModelSingleSuccess(result.data.data));
				} else {
					dispatch(fetchCarSubModelSingleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchCarSubModelSingleFailure('Something went wrong'));
			});
	};
};
