import React from 'react';
import { FaCheck } from 'react-icons/fa6';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const AdvertisingCards = ({
  img,
  title,
  subTitle,
  amount,
  mostPopular,
  buttonText,
  redirectUrl,
}) => {
  return (
    <div
      className={`shadow-4  rounded-lg  m-2 ${mostPopular && 'p-0  '} h-180`}
    >
      <div
        className={`flex justify-center items-center  gap-4 py-3 ${
          mostPopular && 'bg-[#06F]'
        }`}
      >
        <FaStar color="#fff" />
        <p className={`text-white  ${mostPopular ? 'block' : 'hidden'}`}>
          Most Popular
        </p>
      </div>

      <div className={` p-6 ${mostPopular && 'mt-0'} rounded-lg`}>
        <img
          src={img}
          alt=""
          className={`mx-auto w-40 h-40 ${mostPopular && 'h-35 w-35'}`}
        />
        <div className="my-5 px-4">
          <p className="font-bold text-2xl">{title}</p>
          <p className="text-sm text-[#656565]">{subTitle}</p>
        </div>
        <div className="flex justify-center items-center lg:gap-12 gap-5 my-5">
          <p className="font-bold text-5xl">{amount}</p>
          <p className="text-sm p-0 m-0">
            One Time <br /> Payment
          </p>
        </div>
        <div className="my-2">
          <div className="flex justify-start items-center gap-2 my-2.5">
            <FaCheck color="#10dcab" />
            <p>List till Buyer Found</p>
          </div>
          <div className="flex justify-start items-center gap-2 my-2.5">
            <FaCheck color="#10dcab" />
            <p>Free Paperwork Handling</p>
          </div>
          <div className="flex justify-start items-center gap-2 my-2.5">
            <FaCheck color="#10dcab" />
            <p>Fresh Auto Refresh</p>
          </div>
          <div className="flex justify-start items-center gap-2 my-2.5">
            <FaCheck color="#10dcab" />
            <p>Unlimited Changes</p>
          </div>
        </div>
        <div
          className={`text-center mt-16  md:my-5 lg:my-15 flex items-end justify-center `}
        >
          <Link
            to={redirectUrl}
            className={`px-12 md:px-3 py-3 md:mt-5  bg-[#0534FF] text-white  rounded-lg ${
              mostPopular && 'py-1 text-nowrap	'
            }`}
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdvertisingCards;
