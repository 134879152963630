import React from 'react';
import {
  RiFacebookBoxFill,
  RiYoutubeFill,
  RiInstagramLine,
  RiTiktokFill,
} from 'react-icons/ri';
import { BsTiktok } from 'react-icons/bs';
const FollowOnSocialMedia = () => {
  return (
    <div className="container text-center border-[#E8E7E6] border p-4 mt-15 mb-8 mx-auto">
      <div className="text-center my-6">
        <p className="font-bold text-3xl text-[#242D3D]">
          Follow us on social media
        </p>
        <p className="text-[#666]">All the latest news for you</p>
      </div>
      <div className="grid grid-cols-4  mx-auto md:w-[600px] lg:w-[600px] w-full gap-5 md:gap-25 lg:gap-25">
        <div className="mx-auto ">
          <a href="https://www.facebook.com/" target="blank">
            <RiFacebookBoxFill size={45} className="mx-2 opacity-75" />
            <p>Facebook</p>{' '}
          </a>
        </div>
        <div className="mx-auto ">
          {' '}
          <a href="https://www.youtube.com/" target="blank">
            <RiYoutubeFill size={45} className="ml-2 opacity-75" />
            <p>YouTube</p>{' '}
          </a>
        </div>
        <div className="mx-auto ">
          <a href="https://www.instagram.com/" target="blank">
            <RiInstagramLine size={45} className="ml-3 opacity-75" />
            <p>Instagram</p>
          </a>
        </div>
        <div className="mx-auto -ml-.5">
          {' '}
          <a href="https://www.tikTok.com/" target="blank">
            <BsTiktok size={45} className="opacity-75 " />
            <p>TikTok</p>{' '}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FollowOnSocialMedia;
