import React, { useState } from 'react';
import { connect } from 'react-redux';
import icon from '../../../assets/images/web/advertisingIcon.png';
import sellyourcar from '../../../assets/images/web/sell-your-car.png';
import star from '../../../assets/images/web/start.png';
import Layout from '../../../components/site/Layout/Base/Layout';
import AdvertisingCards from '../../../components/site/SellyourCarComponent/AdvertisingCards';
import WhyChooseUs from '../../../components/site/SellyourCarComponent/WhyChooseUs';
import { useEffect } from 'react';

const SellYourCar = (props) => {
  const [redirectUrl, setRedirectUrl] = useState('/login');

  useEffect(() => {
    if (props.isAuth && props.userData) {
      // console.log(props.userData);
      if (props.userData.type == 'customer') {
        // console.log('herer');
        setRedirectUrl('/user-car-posting-individual');
      } else if (props.userData.type == 'merchant') {
        // console.log('not here');
        setRedirectUrl('/user-dashboard');
      }
    }
  }, [props.isAuth]);

  return (
    <Layout>
      <div className="container mx-auto">
        <img
          src={sellyourcar}
          alt="sell-your-car"
          className="w-screen md:h-[300px] lg:h-[474px] "
        />
      </div>
      <div className="container mx-auto grid md:grid-cols-3 grid-cols-1 gap-4 md:gap-.5 lg:grid-cols-3 my-7">
        <div>
          <WhyChooseUs />
        </div>
        <div>
          <AdvertisingCards
            img={icon}
            title="Normal Ad"
            subTitle="List till Buyer Found"
            amount="$48"
            buttonText="Create Normal Ad"
            redirectUrl={redirectUrl}
          />
        </div>
        <div>
          <AdvertisingCards
            mostPopular
            img={star}
            title="Featured"
            subTitle="Buyer Guaranteed"
            amount="$98 "
            buttonText="Create Featured Ad"
            redirectUrl={redirectUrl}
          />
        </div>
      </div>
      <div className="container mx-auto my-15">
        <p className="text-center font-extrabold text-5xl my-20">
          How It Works
        </p>
        <div className="flex flex-col md:flex-row lg:flex-row items-center justify-evenly gap-10 flex-wrap">
          <div className="text-center flex flex-col  items-center gap-4 justify-center my-5">
            <p className="text-white w-15 h-15 bg-primary rounded-full text-center flex justify-center items-center text-xl font-bold  my-4">
              1
            </p>
            <p className="md:w-28   font-semibold">
              Fill The Form & Make Payment
            </p>
          </div>
          <div className=" text-center flex  flex-col items-center gap-4 justify-start my-5">
            <p className="text-white w-15 h-15 bg-primary rounded-full text-center flex justify-center items-center text-xl font-bold  my-4">
              2
            </p>
            <p className="md:w-28   font-semibold">Find Your Buyer</p>
          </div>
          <div className="text-center flex flex-col  items-center gap-4 justify-center my-5">
            <p className="text-white w-15 h-15 bg-primary rounded-full text-center flex justify-center items-center text-xl font-bold  my-4">
              3
            </p>
            <p className="md:w-28   font-semibold">We Handle The Paperwork</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuthenticated,
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, null)(SellYourCar);
