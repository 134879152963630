import React from 'react';
import { FaCar } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
// import { SoldImage } from '../../../../assets/images/svgImages';
import sold from '../../../assets/images/web/sold 1.png';
import dealer from '../../../assets/images/web/dealer 1.png';
import help from '../../../assets/images/web/help 1.png';
import car from '../../../assets/images/web//electric-car 1.png';
const RightNowInCarInc = () => {
  return (
    <div className="container mx-auto my-2 px-3  lg:mt-8 mt-15 mb-28">
      <div>
        <p className="font-bold text-center text-3xl my-6">
          Right Now In Car Inc
        </p>
        <div className=" grid lg:grid-cols-4 grid-cols-1 gap-3 sm:grid-cols-2">
          <div className="text-center border-[#E8E7E6] border   py-8">
            <img src={car} className="mx-auto my-2" size={40} />
            <p className="space-x-5 text-[#0534FF] font-bold tracking-widest text-2xl">
              518
            </p>
            <p className="tracking-widest my-2">VEHICLE LISTED</p>
          </div>
          <div className="text-center border-[#E8E7E6] border   py-8">
            <img src={sold} className="mx-auto my-2" />
            <p className="space-x-5 text-[#0534FF] font-bold tracking-widest text-2xl">
              128
            </p>
            <p className="tracking-widest my-2">VEHICLE SOLD</p>
          </div>
          <div className="text-center border-[#E8E7E6] border   py-8">
            <img src={dealer} className="mx-auto my-2" />
            <p className="space-x-5 text-[#0534FF] font-bold tracking-widest text-2xl">
              18
            </p>
            <p className="tracking-widest my-2">CAR DEALERS</p>
          </div>
          <div className="text-center border-[#E8E7E6] border   py-8">
            <img src={help} className="mx-auto my-2" />
            <p className="space-x-5 text-[#0534FF] font-bold tracking-widest text-2xl">
              12,008
            </p>
            <p className="tracking-widest my-2">CAR ENQUIRIES</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightNowInCarInc;
