import moment from 'moment';

// -----------Converting long decimal number to short -----------------//
// ----------such as 44500.12312312 to 44.5k----------------//
export function formatNumberAbbreviated(number) {
    const billion = 1e9;
    const million = 1e6;
    const thousand = 1e3;

    if (Math.abs(number) >= billion) {
        return (number / billion).toFixed(1) + 'b';
    } else if (Math.abs(number) >= million) {
        return (number / million).toFixed(1) + 'm';
    } else if (Math.abs(number) >= thousand) {
        return (number / thousand).toFixed(1) + 'k';
    } else {
        return number.toString();
    }
}

export const mileagePerYearCalc = (mileage, regDate) => {

    // Calculate the difference in days without considering time zones
    const dateA = regDate
    const dateB = moment(); // Assuming this is today's date

    const daysDifference = moment(dateB).diff(moment(dateA), 'days');
    
   const MileageCarPerDay = Number(mileage) / daysDifference

   const MileageCarPerYear = Number(MileageCarPerDay) * 365

//    console.log("MileageCarPerDay  MileageCarPerYear >>>>>",MileageCarPerDay , Math.ceil(MileageCarPerYear))

   if(isNaN(MileageCarPerDay) || Number(MileageCarPerYear) < 0){
     return 0
   }
   else{
    return formatNumberAbbreviated(Math.ceil(MileageCarPerYear))
   }

}