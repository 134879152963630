import * as yup from 'yup';

const AdminUpdateNewCarSchema = yup.object().shape({
	desc: yup.string().required('Description is required'),
	remarks: yup.string().nullable(true),
	status: yup.string().required('Status is required'),
	vehicle_type_id: yup.string().nullable(true),

});

export default AdminUpdateNewCarSchema;
