// loginSchema.js
import * as yup from 'yup';

const IndividualRegisterSchema = yup.object({
	name: yup.string().required('Name is required'),
	username: yup.string().required('Username is required'),
	contact_no: yup.string()
		.matches(/^[0-9]+$/, 'Invalid contact number')
		.min(8, 'Contact number must be at least 8 characters')
		.required('Contact number is required'),
	email: yup.string().email('Invalid email address').required('Email is required'),
	password: yup.string().required('Password is required'),
});

export default IndividualRegisterSchema;
