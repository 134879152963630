import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import placeholderImage from './../../../assets/images/car_slider_placeholder.png';
import { formatNumberWithCommas } from '../../../helper/formatNumberWithCommas';

const CarListingCard = ({ data, from }) => {
  const [imageLoadError, setImageLoadError] = useState(false);
  const [imagePath, setImagePath] = useState(placeholderImage)

  useEffect(() => {
    const images = JSON.parse(data?.images)
    let img = images?.length > 0 ? images[0]?.image : placeholderImage
    setImagePath(img)
  }, [data])


  // Function to handle image loading error
  const handleImageError = () => {
    setImageLoadError(true);
  };

  return (
    <div className="border-[#E8E7E6] p-2 border w-full md:each-slide-effect  shadow-4 h-[420px]">
      {!imageLoadError ? (
        <Link
          className="block flex-shrink-0"
          to={`${from == 'newCar' ? '/new-car-product' : '/used-car-product'}/${data.id
            }`}
        >
          <div className="flex items-center justify-center h-64  w-full">
            <img
              src={imagePath}
              alt=""
              className="object-fill  slider_img"
              onError={handleImageError}
            />
          </div>
        </Link>
      ) : (
        <Link
          className="block flex-shrink-0"
          to={`${from == 'newCar' ? '/new-car-product' : '/used-car-product'}/${data.id
            }`}
        >
          <div className="w-full h-auto bg-gray-300 flex items-center justify-center">
            <img
              src={placeholderImage}
              alt="Placeholder"
              className="w-full h-full"
            />
          </div>
        </Link>
      )}
      <div className="px-3 w-full">
        <div className="flex justify-between items-center border-[#E8E7E6]  border-b w-full">
          <div className=" my-3">
            <div className="flex justify-start items-center text-sm">
              <p className="font-bold mx-auto  text-xl">$ {data.price} </p>
              {from === 'newCar' && data?.new_car_submodel?.length > 0 && (
                <p className="text-2xl font-bold text-[#222B5E]">
                  {formatNumberWithCommas(
                    data?.new_car_submodel[0]?.car_submodel?.key_info?.price
                  )}
                </p>
              )}
            </div>
            <p className="">Price</p>
          </div>
        </div>
        <div className="my-2 ">
          <Link
            className="block flex-shrink-0"
            to={`${from == 'newCar' ? '/new-car-product' : '/used-car-product'
              }/${data.id}`}
          >
            <p className="font-bold text-base h-7  mt-0">
              {data.car_model ? data.car_model?.name : '-'}
            </p>
          </Link>
          <p className="mt-9">
            COE till
            {data.coe_expiry ? moment(data.coe_expiry).format('MM/YYYY') : '-'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarListingCard;
