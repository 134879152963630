// actions.js
import * as actionTypes from './actionTypes';
import BrandController from './../../api/BrandController';

export const brandInit = () => {
  return {
    type: actionTypes.BRAND_INIT,
  };
};

export const fetchBrandListSuccess = (brands) => {
  return {
    type: actionTypes.FETCH_BRAND_LIST_SUCCESS,
    payload: brands,
  };
};

export const fetchBrandListFailure = (error) => {
  return {
    type: actionTypes.FETCH_BRAND_LIST_FAILURE,
    payload: error,
  };
};

export const fetchBrandPublicListSuccess = (brands) => {
  return {
    type: actionTypes.FETCH_BRAND_PUBLIC_LIST_SUCCESS,
    payload: brands,
  };
};

export const fetchBrandPublicListFailure = (error) => {
  return {
    type: actionTypes.FETCH_BRAND_PUBLIC_LIST_FAILURE,
    payload: error,
  };
};

export const fetchBrandSingleSuccess = (brand) => {
  return {
    type: actionTypes.FETCH_BRAND_SINGLE_SUCCESS,
    payload: brand,
  };
};

export const fetchBrandSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_BRAND_SINGLE_FAILURE,
    payload: error,
  };
};

export const createBrandSuccess = (message) => {
  return {
    type: actionTypes.CREATE_BRAND_SUCCESS,
    payload: message,
  };
};

export const createBrandFailure = (error) => {
  return {
    type: actionTypes.CREATE_BRAND_FAILURE,
    payload: error,
  };
};

export const updateBrandSuccess = (brand) => {
  return {
    type: actionTypes.UPDATE_BRAND_SUCCESS,
    payload: brand,
  };
};

export const updateBrandFailure = (error) => {
  return {
    type: actionTypes.UPDATE_BRAND_FAILURE,
    payload: error,
  };
};

export const deleteBrandSuccess = (brandId) => {
  return {
    type: actionTypes.DELETE_BRAND_SUCCESS,
    payload: brandId,
  };
};

export const deleteBrandFailure = (error) => {
  return {
    type: actionTypes.DELETE_BRAND_FAILURE,
    payload: error,
  };
};

export const fetchBrandList = (
  page,
  isProcess = false,
  isPublic = false,
  searchParam = ''
) => {
  return async (dispatch) => {
    dispatch(brandInit());
    BrandController.list(page, isPublic, searchParam)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(createBrandFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          if (isProcess) {
            const processData = result.data.data.map((item) => ({
              value: item.id,
              label: item.name,
              image: item.image,
              is_feature: item.is_feature,
              status: item.status,
            }));
            dispatch(fetchBrandListSuccess(processData));
          } else {
            dispatch(fetchBrandListSuccess(result.data.data));
          }
        } else {
          dispatch(fetchBrandListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchBrandListFailure('Something went wrong'));
      });
  };
};

export const fetchBrandPublicList = (page) => {
  return async (dispatch) => {
    dispatch(brandInit());
    BrandController.publicList(page)
      .then((result) => {
        if (result instanceof Error) {
          dispatch(fetchBrandPublicListFailure('Something went wrong'));
        } else if (result.data.status !== 'Failed') {
          const processData = result.data.data.map((item) => ({
            value: item.id,
            label: item.name,
            image: item.image,
            is_feature: item.is_feature,
            status: item.status,
          }));

          dispatch(fetchBrandPublicListSuccess(processData));
        } else {
          dispatch(fetchBrandPublicListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchBrandPublicListFailure('Something went wrong'));
      });
  };
};

export const fetchBrandSingle = (brandId) => {
  return async (dispatch) => {
    dispatch(brandInit());
    BrandController.single(brandId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchBrandSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchBrandSingleSuccess(result.data.data));
        } else {
          dispatch(fetchBrandSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchBrandSingleFailure('Something went wrong'));
      });
  };
};

export const createBrand = (brandData) => {
  return async (dispatch) => {
    dispatch(brandInit());

    let formData = new FormData();
    formData.append('name', brandData.name);
    formData.append('status', brandData.status);
    formData.append('is_feature', brandData.is_feature);

    if (brandData.image && brandData.image.length > 0) {
      brandData.image.forEach((file, index) => {
        formData.append('image', file);
      });
    }

    BrandController.create(formData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(createBrandFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(createBrandSuccess(result.data.message));
        } else {
          dispatch(createBrandFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(createBrandFailure('Something went wrong'));
      });
  };
};

export const updateBrand = (brandId, updatedBrandData) => {
  return async (dispatch) => {
    dispatch(brandInit());
    console.log('updatedBrandData------------------',updatedBrandData);
    let formData = new FormData();
    formData.append('name', updatedBrandData.name);
    formData.append('status', updatedBrandData.status);
    formData.append('is_feature', updatedBrandData.is_feature);
    if (
      updatedBrandData.image != undefined &&
      typeof updatedBrandData.image !== 'string'
    ) {
      updatedBrandData.image.forEach((file, index) => {
        formData.append('image', file);
      });
    }
    BrandController.update(brandId, formData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            console.log('formData====================',formData)
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updateBrandFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updateBrandSuccess(result.data.message));
        } else {
          dispatch(updateBrandFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updateBrandFailure('Something went wrong'));
      });
  };
};

export const deleteBrand = (brandId) => {
  return async (dispatch) => {
    dispatch(brandInit());
    BrandController.delete(brandId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(deleteBrandFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(deleteBrandSuccess(result.data.message));
        } else {
          dispatch(deleteBrandFailure('Something went wrong1'));
        }
      })
      .catch((error) => {
        dispatch(deleteBrandFailure('Something went wrong3'));
      });
  };
};
