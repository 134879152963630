import * as actionTypes from '../actions/actionTypes';

const initialState = {
  subscriptionList: [],
  singleSubscription: null,
  loading: false,
  error: null,
  success: null,
};

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBSCRIPTION_INIT:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    case actionTypes.SUBSCRIPTION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.SUBSCRIPTION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionList: state.subscriptionList,
        error: null,
        success: action.payload,
      };

    default:
      return state;
  }
};

export default SubscriptionReducer;
