// carModelActions.js
import * as actionTypes from "./actionTypes";
import CarModelController from "../../api/CarModelController"; // Update the import path

export const carModelInit = () => {
	return {
		type: actionTypes.CAR_MODEL_INIT,
	};
};

export const fetchCarModelListDropDownSuccess = (carModels) => {
	return {
		type: actionTypes.FETCH_CAR_MODEL_LIST_DROPDOWN_SUCCESS,
		payload: carModels,
	};
}

export const fetchCarModelListSuccess = (carModels) => {
	return {
		type: actionTypes.FETCH_CAR_MODEL_LIST_SUCCESS,
		payload: carModels,
	};
};

export const fetchCarModelListFailure = (error) => {
	return {
		type: actionTypes.FETCH_CAR_MODEL_LIST_FAILURE,
		payload: error,
	};
};

export const fetchCarModelSingleSuccess = (carModel) => {
	return {
		type: actionTypes.FETCH_CAR_MODEL_SINGLE_SUCCESS,
		payload: carModel,
	};
};

export const fetchCarModelSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_CAR_MODEL_SINGLE_FAILURE,
		payload: error,
	};
};

export const createCarModelSuccess = (message) => {
	return {
		type: actionTypes.CREATE_CAR_MODEL_SUCCESS,
		payload: message,
	};
};

export const createCarModelFailure = (error) => {
	return {
		type: actionTypes.CREATE_CAR_MODEL_FAILURE,
		payload: error,
	};
};

export const updateCarModelSuccess = (carModel) => {
	return {
		type: actionTypes.UPDATE_CAR_MODEL_SUCCESS,
		payload: carModel,
	};
};

export const updateCarModelFailure = (error) => {
	return {
		type: actionTypes.UPDATE_CAR_MODEL_FAILURE,
		payload: error,
	};
};

export const deleteCarModelSuccess = (carModelId) => {
	return {
		type: actionTypes.DELETE_CAR_MODEL_SUCCESS,
		payload: carModelId,
	};
};

export const deleteCarModelFailure = (error) => {
	return {
		type: actionTypes.DELETE_CAR_MODEL_FAILURE,
		payload: error,
	};
};

export const fetchCarModelList = (page, isProcess = false, isPublic = false) => {
	return async (dispatch) => {
		dispatch(carModelInit());
		CarModelController.list(page, isPublic)
			.then((result) => {
				if (result instanceof Error) {
					dispatch(fetchCarModelListFailure('Something went wrong'));
				} else if (result.data.status !== 'Failed') {
					if (isProcess) {
						const processData = result.data.data.map(item => ({
							value: item.id,
							label: item.name,
							brand: item.brand_id
						}));

						dispatch(fetchCarModelListSuccess(processData));
					} else {
						dispatch(fetchCarModelListSuccess(result.data.data));
					}
				} else {
					dispatch(fetchCarModelListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchCarModelListFailure('Something went wrong'));
			});
	};
};


export const fetchCarModelListDropDown = () => {
	return async (dispatch) => {
		dispatch(carModelInit());
		CarModelController.list('all', true)
			.then((result) => {
				if (result instanceof Error) {
					dispatch(fetchCarModelListFailure('Something went wrong'));
				} else if (result.data.status !== 'Failed') {
					const processData = result.data.data.map(item => ({
						value: item.id,
						label: item.name,
						brand: item.brand_id
					}));

					dispatch(fetchCarModelListDropDownSuccess(processData));
				} else {
					dispatch(fetchCarModelListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchCarModelListFailure('Something went wrong'));
			});
	};
};

export const createCarModel = (carModelData) => {
	return async (dispatch) => {
		dispatch(carModelInit());
		CarModelController.create(carModelData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(createCarModelFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(createCarModelSuccess(result.data.message));
				} else {
					dispatch(createCarModelFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(createCarModelFailure('Something went wrong'));
			});
	};
};

export const updateCarModel = (carModelId, updatedCarModelData) => {
	return async (dispatch) => {
		dispatch(carModelInit());
		CarModelController.update(carModelId, updatedCarModelData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(updateCarModelFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(updateCarModelSuccess(result.data.message));
				} else {
					dispatch(updateCarModelFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(updateCarModelFailure('Something went wrong'));
			});
	};
};

export const deleteCarModel = (carModelId) => {
	return async (dispatch) => {
		dispatch(carModelInit());
		CarModelController.delete(carModelId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(deleteCarModelFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchCarModelList()); // Assuming you have a fetchCarModelList action
					dispatch(deleteCarModelSuccess(result.data.message));
				} else {
					dispatch(deleteCarModelFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(deleteCarModelFailure('Something went wrong'));
			});
	};
};

export const fetchCarModelSingle = (carModelId) => {
	return async (dispatch) => {
		dispatch(carModelInit());
		CarModelController.single(carModelId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(fetchCarModelSingleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchCarModelSingleSuccess(result.data.data));
				} else {
					dispatch(fetchCarModelSingleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchCarModelSingleFailure('Something went wrong'));
			});
	};
};

