import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AdminForm from '../../AdminForm/AdminForm';
import FormField from '../../../common/FormFileds/Admin/FormField';

const Interior = (props) => {
  const { formik } = props;
  return (
    <div className="p-6.5">
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Multi-Function Steering Wheel"
            id="multi_function_steering_wheel"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Paddle Shifters"
            id="paddle_shifters"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Keyless Entry"
            id="keyless_entry"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Keyless Engine Start"
            id="keyless_engine_start"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Multi-zone Aircon"
            placeholder=" "
            id="multi_zone_aircon"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Rear Aircon"
            placeholder=" "
            id="rear_aircon"
            formik={formik}
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Seat Upholstery"
            id="seat_upholstery"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Driver's Electric Seat"
            placeholder=" "
            id="driver_electric_seat"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Passenger's Electric Seat"
            placeholder=" "
            id="passenger_electric_seat"
            formik={formik}
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Ventilated Seats"
            id="ventilated_seats"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Electric Tailgate"
            placeholder=" "
            id="electric_tailgate"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Sunroof/Moonroof/Panoramic roof"
            placeholder=" "
            id="car_roof"
            formik={formik}
          />
        </div>
      </div>
    </div>
  );
};

export default Interior;
