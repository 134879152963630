import React from 'react'

export const SearchInput = ({searchHandler, label}) => {
  return (
    <div  className="mb-4 relative">
    <label
        htmlFor="Search"
        className={`absolute text-gray-700 text-sm font-semibold -top-3 left-4 bg-white px-1`}
    >
        {label}
    </label>
    <input
        type="text"
        id="search"
        name="search"
        // value=""
        placeholder='Name'
        onChange={(e) => searchHandler(e.target.value)}
        className='w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary '
    />
</div>
  )
}
