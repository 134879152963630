import React from 'react';

const Alert = ({ message, type }) => {
	const getAlertClass = () => {
		switch (type) {
			case 'success':
				return 'bg-success text-white';
			case 'error':
				return 'bg-[#F87171] text-white';
			default:
				return 'text-gray-700';
		}
	};

	return (
		<div className={`${getAlertClass()} px-4 py-3 rounded relative mb-8`} role="alert">
			<span className="block sm:inline"> {message}</span>
		</div>
	);
};

export default Alert;
