import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import CarModelSchema from '../../../schemas/CarModelSchema';
import { options } from '../../../constants';
// import Select from 'react-select';
import { MultiSelect } from '../../../components/common/MultiSelect/MultiSelect';

const CarModelForm = (props) => {
	const { initialValues, onSubmit, countryList, brandList, hideSave, carModelDropDown, setSelectedOptions, selectedOptions } = props;
	// let [selectedOptions, setSelectedOptions] = useState('')

	let initVal = {
		name: '',
		brand_id: '',
		status: '',
		predecessor: '',
		built_in: '',
		prefered_models: ''
	};

	const formik = useFormik({
		initialValues: initialValues || initVal,
		validationSchema: CarModelSchema,
		onSubmit,
	});

console.log(formik.errors);
	
	useEffect(() => {
		if (initialValues?.prefered_models) {
			let editedPreferedModels = initialValues?.prefered_models.split(",")
			console.log(editedPreferedModels);
			let initiaPreferedModel = [];
			for (const preferedModelId of editedPreferedModels) {
				let findPreferedModels = carModelDropDown?.find((item) => {
					return item?.value == preferedModelId
				})
				initiaPreferedModel?.push(findPreferedModels)
			}
			setSelectedOptions(initiaPreferedModel)
		}
	}, [initialValues?.prefered_models])

console.log(selectedOptions);

	const handleModelSelect = (selectOption) => {
		const selectedValues = selectOption.map(option => option.value);
		setSelectedOptions(selectedValues);
		formik.setFieldValue('prefered_models', selectedValues);
	};

	return (
		<AdminForm formik={formik} onBtnCancelClick={props.onCancel} hideSave={hideSave}>
			<div className="p-6.5">
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Brand"
							id="brand_id"
							options={brandList}
							formik={formik}
							disabled={hideSave ? true : false}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label="Name"
							id="name"
							formik={formik}
							disabled={hideSave && true}
						/>
					</div>
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Status"
							id="status"
							options={options}
							formik={formik}
							disabled={hideSave && true}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField
							type="select"
							label="Built In"
							id="built_in"
							options={countryList}
							formik={formik}
							disabled={hideSave && true}
						/>
					</div>

					<div className="w-full xl:w-1/2">
						<FormField
							type="text"
							label="Predecessor"
							id="predecessor"
							formik={formik}
							disabled={hideSave && true}
						/>
					</div>
				</div>

				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<MultiSelect
							required={false}
							optionsData={carModelDropDown || []}
							selectedOptions={selectedOptions}
							setSelectedOptions={setSelectedOptions}
							label="Prefered Model"
							placeholder="Select Models"
							isDisabled={hideSave}
						/>
					</div>
				</div>
			</div>
		</AdminForm>
	);
};

export default CarModelForm;
