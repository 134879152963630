import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AdminForm from '../../AdminForm/AdminForm';
import FormField from '../../../common/FormFileds/Admin/FormField';

const Wheels = (props) => {
  const { formik } = props;

  return (
    <div className="p-6.5">
      <div className="mb-4.5  grid grid-cols-3 gap-6 xl:flex-row">
        <div className="w-full xl:w-full">
          <FormField
            type="number"
            label="Rim Size"
            id="wheel_rim_size"
            placeholder=" "
            formik={formik}
          />
        </div>
      </div>
    </div>
  );
};

export default Wheels;
