import React, { useEffect, useState } from 'react';
import { BiTachometer } from 'react-icons/bi';
import {
  FaRegUserCircle,
  FaCaretRight,
  FaRegQuestionCircle,
} from 'react-icons/fa';
import { IoCalendarOutline } from 'react-icons/io5';
import LakeViewImg from '../../../assets/images/web/product_lakeViewimg.png';
import { formatNumberWithCommas } from '../../../helper/formatNumberWithCommas';
import moment from 'moment';
import { registrationTimeLeft } from '../../../helper/regRemainingDayCalc';
import { mileagePerYearCalc } from '../../../helper/mileagePerYearCalc';
import { IoMdClose } from 'react-icons/io';
import { IoCalendarClearOutline } from 'react-icons/io5';
import AdType from './AdType';
import { depreciationCalc } from '../../../helper/depreciationCalc';

const UsedCarInformation = ({ carDetails, sellerEnqueryCountHandler }) => {
  const [parfPopupShow, setParfPopupShow] = useState(false);

  return (
    <div className="container mx-auto w-full">
      <div className=" border border-solid border-[#D9D9D9]  mt-7 ">
        <div className="bg-[#f1f1f1] p-4 w-full hidden lg:block  border border-solid border-[#D9D9D9]">
          <div className="flex flex-row justify-between items-center">
            <p className="text-[18px] font-bold">Information</p>

            <div className="flex flex-row justify-evenly items-center">
              {carDetails?.ads_type?.name && (
                <AdType ad={carDetails?.ads_type?.name} />
              )}
              {carDetails?.category?.name?.toLowerCase() == 'export cars' && (
                <div className="flex flex-row justify-center items-center ms-4 bg-[#E6F4FF] border border-solid border-[#91CAFF] ronded h-[24px]">
                  <p className="text-[#1677FF] text-[12px] px-2">
                    Exporters are welcome
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col  justify-between">
          <div className="p-4 w-full lg:w-1/3 ">
            <div className="flex flex-row justify-start items-center">
              <div>
                <BiTachometer size={48} />
              </div>
              <div className="ms-11">
                <p className="text-[14px] font-bold">Mileage</p>
                <p className="text-[12px] ">
                  {carDetails?.mileage
                    ? `${formatNumberWithCommas(
                        carDetails?.mileage
                      )} km (${mileagePerYearCalc(
                        carDetails?.mileage,
                        carDetails?.registration_date
                      )} km/ yr)`
                    : ''}
                </p>
              </div>
            </div>

            <div className="flex flex-row justify-start items-center mt-7">
              <div>
                <IoCalendarOutline size={48} />
              </div>
              <div className="ms-11">
                <p className="text-[14px] font-bold">Registration</p>
                <p className="text-[12px] ">
                  {carDetails?.registration_date !== null
                    ? moment(carDetails?.registration_date).format(
                        'DD MMM YYYY'
                      )
                    : '-'}
                  <br />
                  {carDetails?.coe_expiry !== null
                    ? `(${registrationTimeLeft(carDetails?.coe_expiry)})`
                    : '-'}
                </p>
              </div>
            </div>

            <div className="flex flex-row justify-start items-center mt-7">
              <div>
                <IoCalendarClearOutline size={48} />
              </div>
              <div className="ms-11">
                <p className="text-[14px] font-bold">Original Registration</p>
                <p className="text-[12px] ">
                  {carDetails?.original_registration_date
                    ? moment(carDetails?.original_registration_date).format(
                        'DD MMM YYYY'
                      )
                    : '-'}
                </p>
              </div>
            </div>

            <div className="flex flex-row justify-start items-center mt-7">
              <div>
                <FaRegUserCircle size={48} />
              </div>
              <div className="ms-11">
                <p className="text-[14px] font-bold">No. of Owner</p>
                <p className="text-[12px] ">{carDetails?.no_of_owner}</p>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-2/3  lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col">
            <div className="w-full lg:w-1/2 ">
              <div className="bg-[#f1f1f1] p-4 border border-solid border-[#D9D9D9] ">
                <p className="text-[#0534FF] text-[18px] font-bold">
                  Key Information
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  Depreciation
                </p>
                <p className="text-[#0534FF] text-[12px] ">{`${
                  depreciationCalc(
                    carDetails?.is_forFeited,
                    carDetails?.price,
                    carDetails?.arf,
                    carDetails?.coe_expiry
                  )
                    ? `$ ${depreciationCalc(
                        carDetails?.is_forFeited,
                        carDetails?.price,
                        carDetails?.arf,
                        carDetails?.coe_expiry
                      )}`
                    : '0'
                } / yr`}</p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  Dereg Value
                </p>
                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.dereg_value
                    ? `$ ${formatNumberWithCommas(
                        carDetails?.dereg_value
                      )} as of today`
                    : '0'}
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  COE
                </p>
                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.coe
                    ? `$ ${formatNumberWithCommas(carDetails?.coe)}`
                    : '0'}
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  OMV
                </p>
                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.omv
                    ? `$ ${formatNumberWithCommas(carDetails?.omv)}`
                    : '0'}
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold  flex items-center w-[150px]">
                  PARF Rebate
                  <span
                    onClick={() => setParfPopupShow(true)}
                    className="inline ms-2 mt-[2px] cursor-pointer"
                  >
                    <FaRegQuestionCircle color="#0534FF" size={16} />
                  </span>
                </p>

                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.arf
                    ? `$ ${formatNumberWithCommas(carDetails?.arf)}`
                    : '0'}{' '}
                  {carDetails?.is_forFeited == 1 && carDetails?.is_forFeited
                    ? '(Forfeited)'
                    : ''}
                </p>
                {parfPopupShow && (
                  <div className="tooltiptext">
                    <div className="flex justify-end cursor-pointer ">
                      <span onClick={() => setParfPopupShow(false)}>
                        <IoMdClose size={20} />
                      </span>
                    </div>
                    <p>
                      The Additional Registration Fee (ARF) is a tax imposed
                      upon registration of vechcle. It is calculated based on a
                      percentage of the Open Market Value (OMV) of the vechicle.
                      The ARF determines your PARF rebate which is components of
                      your de-registration value.
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full lg:w-1/2 ">
              <div className="bg-[#f1f1f1] p-4 border border-solid border-[#D9D9D9] ">
                <p className="text-[#0534FF] text-[18px] font-bold">
                  Features & Spec
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  Transmission
                </p>
                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.transmission_type}
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  Type of Vehicle
                </p>
                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.vehicle_type?.name}
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  Engine Cap
                </p>
                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.engine_capacity} cc
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  Power
                </p>
                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.power}{' '}
                  {carDetails?.fuel_type?.name == 'Electric' ? 'kWh' : 'bhp'}
                </p>
              </div>

              <div className=" p-4 border border-solid border-[#D9D9D9] flex flex-row justify-start items-center">
                <p className="text-[#0534FF] text-[18px] font-bold w-[150px]">
                  Fuel Type
                </p>
                <p className="text-[#0534FF] text-[12px] ">
                  {carDetails?.fuel_type?.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col border border-solid border-[#D9D9D9] mt-18 lg:mt-0 md:mt-11  w-full">
        <div className="p-4 w-full lg:w-4/6">
          <div>
            <p className="text-[14px] font-bold">Description</p>
            <p className="text-[12px] mt-2">{carDetails?.desc}</p>
          </div>

          <div>
            <p className="text-base font-medium mt-17">Contact Seller(s)</p>
            <div className="lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col flex-wrap mt-2">
              {carDetails?.used_cars_sellers?.map((item, index) => (
                <p
                  className="cursor-pointer text-sm mt-2 md:mt-0"
                  onClick={() => sellerEnqueryCountHandler(item)}
                  key={index}
                >
                  {`${item?.seller?.user?.username} (${item?.seller?.user?.user_details?.phone})`}{' '}
                  {carDetails?.used_cars_sellers?.length - 1 == index ? (
                    ''
                  ) : (
                    <span className="mx-2 hidden md:inline">|</span>
                  )}
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/3 h-full">
          <div className="mt-13 lg:mt-0 md:mt-0 p-4 border border-solid border-[#D9D9D9] flex flex-row justify-between items-center">
            <p className="text-[#0534FF] text-[16px] font-bold">Running Cost</p>
            <FaCaretRight size={24} color="#0534FF" />
          </div>
          <img src={LakeViewImg} className="w-full h-fit" />
        </div>
      </div>
    </div>
  );
};

export default UsedCarInformation;
