// Layout.js
import React, { useEffect, useState } from 'react';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {

  const { pathname } = useLocation();
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    setContentLoaded(false); // Reset contentLoaded flag on route change
  }, [pathname]);

  useEffect(() => {
    if (contentLoaded) {
      window.scrollTo(0, 0); // Scroll to top after content has been loaded
    } 
  }, [contentLoaded]);

  return (
    <div 
    onLoad={() => setContentLoaded(true)}
     className="p-2 overflow-hidden">
      <div className=" ">
        <Header />
        <main
        //   className="bg-gray-200"
        // className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200"
        >
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
