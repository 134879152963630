import React from 'react';

const SectionHeader = ({ title, buttonText, onButtonClick }) => {


	return (
		<div className="flex justify-between items-center py-4 px-6.5">
			<h2 className="text-title-md2 font-semibold text-black dark:text-white">
				{title}
			</h2>

			{buttonText && (
				<button className="bg-primary py-2 px-4 text-gray font-medium rounded" onClick={onButtonClick}>
					{buttonText}
				</button>
			)}

		</div>
	);
};

export default SectionHeader;
