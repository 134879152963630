// fuelTypeActions.js
import * as actionTypes from "./actionTypes";
import FuelTypeController from "../../api/FuelTypeController";

export const fuelTypeInit = () => {
	return {
		type: actionTypes.FUEL_TYPE_INIT,
	};
};

export const fetchFuelTypeListSuccess = (fuelTypes) => {
	return {
		type: actionTypes.FETCH_FUEL_TYPE_LIST_SUCCESS,
		payload: fuelTypes,
	};
};

export const fetchFuelTypeListFailure = (error) => {
	return {
		type: actionTypes.FETCH_FUEL_TYPE_LIST_FAILURE,
		payload: error,
	};
};

export const fetchFuelTypeSingleSuccess = (fuelType) => {
	return {
		type: actionTypes.FETCH_FUEL_TYPE_SINGLE_SUCCESS,
		payload: fuelType,
	};
};

export const fetchFuelTypeSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_FUEL_TYPE_SINGLE_FAILURE,
		payload: error,
	};
};

export const createFuelTypeSuccess = (message) => {
	return {
		type: actionTypes.CREATE_FUEL_TYPE_SUCCESS,
		payload: message,
	};
};

export const createFuelTypeFailure = (error) => {
	return {
		type: actionTypes.CREATE_FUEL_TYPE_FAILURE,
		payload: error,
	};
};

export const updateFuelTypeSuccess = (fuelType) => {
	return {
		type: actionTypes.UPDATE_FUEL_TYPE_SUCCESS,
		payload: fuelType,
	};
};

export const updateFuelTypeFailure = (error) => {
	return {
		type: actionTypes.UPDATE_FUEL_TYPE_FAILURE,
		payload: error,
	};
};

export const deleteFuelTypeSuccess = (fuelTypeId) => {
	return {
		type: actionTypes.DELETE_FUEL_TYPE_SUCCESS,
		payload: fuelTypeId,
	}
};

export const deleteFuelTypeFailure = (error) => {
	return {
		type: actionTypes.DELETE_FUEL_TYPE_FAILURE,
		payload: error,
	}
};

export const fetchFuelTypeList = (page, isProcess = false, isPublic = false) => {
	return async (dispatch) => {
		dispatch(fuelTypeInit());
		FuelTypeController.list(page, isPublic)
			.then((result) => {
				if (result instanceof Error) {
					dispatch(fetchFuelTypeListFailure('Something went wrong'));
				} else if (result.data.status !== 'Failed') {
					if (isProcess) {
						const processData = result.data.data.map(item => ({
							value: item.id,
							label: item.name
						}));
						dispatch(fetchFuelTypeListSuccess(processData));
					} else {
						dispatch(fetchFuelTypeListSuccess(result.data.data));
					}
				} else {
					dispatch(fetchFuelTypeListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchFuelTypeListFailure('Something went wrong'));
			});
	};
};

export const createFuelType = (fuelTypeData) => {
	return async (dispatch) => {
		dispatch(fuelTypeInit());
		FuelTypeController.create(fuelTypeData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(createFuelTypeFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(createFuelTypeSuccess(result.data.message));
				} else {
					dispatch(createFuelTypeFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(createFuelTypeFailure('Something went wrong'));
			});
	};
};

export const updateFuelType = (fuelTypeId, updatedFuelTypeData) => {
	return async (dispatch) => {
		dispatch(fuelTypeInit());
		FuelTypeController.update(fuelTypeId, updatedFuelTypeData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(updateFuelTypeFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(updateFuelTypeSuccess(result.data.message));
				} else {
					dispatch(updateFuelTypeFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(updateFuelTypeFailure('Something went wrong'));
			});
	};
};

export const deleteFuelType = (fuelTypeId) => {
	return async (dispatch) => {
		dispatch(fuelTypeInit());
		FuelTypeController.delete(fuelTypeId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(deleteFuelTypeFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchFuelTypeList()); // Assuming you have a fetchFuelTypeList action
					dispatch(deleteFuelTypeSuccess(result.data.message));
				} else {
					dispatch(deleteFuelTypeFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(deleteFuelTypeFailure('Something went wrong'));
			});
	};
};

export const fetchFuelTypeSingle = (fuelTypeId) => {
	return async (dispatch) => {
		dispatch(fuelTypeInit());
		FuelTypeController.single(fuelTypeId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(fetchFuelTypeSingleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchFuelTypeSingleSuccess(result.data.data));
				} else {
					dispatch(fetchFuelTypeSingleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchFuelTypeSingleFailure('Something went wrong'));
			});
	};
};

