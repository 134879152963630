import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';
import UserSvgIcon from '../Icon/UserSvgIcon';
import CarSvgIcon from '../Icon/CarSvgIcon';
import DashboardSvgIcon from '../Icon/DashboardSvgIcon';
import { PiPackageFill } from 'react-icons/pi';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
	const location = useLocation();
	const { pathname } = location;

	const trigger = useRef(null);
	const sidebar = useRef(null);

	const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
	const [sidebarExpanded, setSidebarExpanded] = useState(
		storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
	);

	const handleButtonClick = (e, handleClick) => {
		e.preventDefault();
		if (sidebarExpanded) {
			handleClick();
		} else {
			setSidebarExpanded(true);
		}
	};

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }) => {
			if (!sidebar.current || !trigger.current) return;
			if (
				!sidebarOpen ||
				sidebar.current.contains(target) ||
				trigger.current.contains(target)
			)
				return;
			setSidebarOpen(false);
		};
		document.addEventListener('click', clickHandler);
		return () => document.removeEventListener('click', clickHandler);
	}, [sidebarOpen]);

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!sidebarOpen || keyCode !== 27) return;
			setSidebarOpen(false);
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	}, [sidebarOpen]);

	useEffect(() => {
		localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
		if (sidebarExpanded) {
			document.querySelector('body')?.classList.add('sidebar-expanded');
		} else {
			document.querySelector('body')?.classList.remove('sidebar-expanded');
		}
	}, [sidebarExpanded]);

	return (
		<aside
			ref={sidebar}
			className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-black duration-300  dark:bg-boxdark lg:static lg:translate-x-0 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'
				}`}
		>
			<SidebarHeader
				sidebarOpen={sidebarOpen}
				setSidebarOpen={setSidebarOpen}
				trigger={trigger}
			/>

			<div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
				<nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
					<div>
						<h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
							MENU
						</h3>

						<ul className="mb-6 flex flex-col gap-1.5">
							<li>
								<NavLink
									to="/dashboard"
									className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('dashboard') &&
										'bg-graydark dark:bg-meta-4'
										}`}
								>
									<DashboardSvgIcon />
									Dashboard
								</NavLink>
							</li>

							<SidebarMenu
								link="#"
								label="Car Management"
								handleButtonClick={(e, handleClick) =>
									handleButtonClick(e, handleClick)
								}
								pathname={location.pathname}
								icon={<CarSvgIcon />}
								dynamicItems={[
									{ id: 1, label: 'Brands', link: '/admin/brands' },
									{
										id: 2,
										label: 'Vehicle Types',
										link: '/admin/vehicle-types',
									},
									{ id: 3, label: 'Car Colors', link: '/admin/color' },
									{ id: 4, label: 'Car Category', link: '/admin/car-category' },
									{ id: 5, label: 'Fuel Type', link: '/admin/fuel-type' },
									{ id: 6, label: 'Models', link: '/admin/models' },
									{ id: 7, label: 'Sub Models', link: '/admin/sub-model' },
									{ id: 8, label: 'Ads Type', link: '/admin/ads-type' },
									{ id: 9, label: 'Adds Ons', link: '/admin/adds-ons' },
								]}
							/>

							<SidebarMenu
								link="#"
								label="User Management"
								handleButtonClick={(e, handleClick) =>
									handleButtonClick(e, handleClick)
								}
								pathname={location.pathname}
								icon={<UserSvgIcon />}
								dynamicItems={[
									{ id: 1, label: 'Admin Users', link: '/admin/user' },
									{ id: 2, label: 'Merchant', link: '/admin/merchant' },
									{ id: 3, label: 'Customer', link: '/admin/customer' },
								]}
							/>

							<SidebarMenu
								link="#"
								label="Listing Management"
								handleButtonClick={(e, handleClick) =>
									handleButtonClick(e, handleClick)
								}
								pathname={location.pathname}
								icon={<UserSvgIcon />}
								dynamicItems={[
									{ id: 1, label: 'Used Car Listing', link: '/admin/used-car' },
									{ id: 2, label: 'New Car Listing', link: '/admin/new-car' },
									{ id: 3, label: 'Promo Codes', link: '/admin/promo-codes' },
								]}
							/>

							<SidebarMenu
								link="#"
								label="Payment Management"
								handleButtonClick={(e, handleClick) =>
									handleButtonClick(e, handleClick)
								}
								pathname={location.pathname}
								icon={<PiPackageFill size={20} />}
								dynamicItems={[
									{ id: 1, label: 'Payment', link: '/admin/payment' },
								]}
							/>

							<SidebarMenu
								link="#"
								label="Report"
								handleButtonClick={(e, handleClick) =>
									handleButtonClick(e, handleClick)
								}
								pathname={location.pathname}
								icon={<UserSvgIcon />}
								dynamicItems={[
									{ id: 1, label: 'Promo Code Count', link: '/admin/promo-codes-order-count' },
								]}
							/>

							<SidebarMenu
								link="#"
								label="Settings"
								handleButtonClick={(e, handleClick) =>
									handleButtonClick(e, handleClick)
								}
								pathname={location.pathname}
								icon={<UserSvgIcon />}
								dynamicItems={[
									{
										id: 1,
										label: 'Company Setting',
										link: '/admin/company-setting',
									},
								]}
							/>
						</ul>
					</div>
				</nav>
			</div>
		</aside>
	);
};

export default Sidebar;
