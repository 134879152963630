import * as actionTypes from "./actionTypes";
import ColorController from "../../api/ColorController";

export const colorInit = () => {
	return {
		type: actionTypes.COLOR_INIT,
	};
};

export const fetchColorListSuccess = (colors) => {
	return {
		type: actionTypes.FETCH_COLOR_LIST_SUCCESS,
		payload: colors,
	};
};

export const fetchColorListFailure = (error) => {
	return {
		type: actionTypes.FETCH_COLOR_LIST_FAILURE,
		payload: error,
	};
};

export const fetchColorSingleSuccess = (color) => {
	return {
		type: actionTypes.FETCH_COLOR_SINGLE_SUCCESS,
		payload: color,
	};
};

export const fetchColorSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_COLOR_SINGLE_FAILURE,
		payload: error,
	};
};

export const createColorSuccess = (message) => {
	return {
		type: actionTypes.CREATE_COLOR_SUCCESS,
		payload: message,
	};
};

export const createColorFailure = (error) => {
	return {
		type: actionTypes.CREATE_COLOR_FAILURE,
		payload: error,
	};
};

export const updateColorSuccess = (color) => {
	return {
		type: actionTypes.UPDATE_COLOR_SUCCESS,
		payload: color,
	};
};

export const updateColorFailure = (error) => {
	return {
		type: actionTypes.UPDATE_COLOR_FAILURE,
		payload: error,
	};
};


export const deleteColorSuccess = (colorId) => {
	return {
		type: actionTypes.DELETE_COLOR_SUCCESS,
		payload: colorId,
	}
};

export const deleteColorFailure = (error) => {
	return {
		type: actionTypes.DELETE_COLOR_FAILURE,
		payload: error,
	}
};


export const fetchColorList = (page, isProcess = false, isPublic = false) => {
	return async (dispatch) => {
		dispatch(colorInit());
		ColorController.list(page, isPublic)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(createColorFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					if (isProcess) {
						const processData = result.data.data.map(item => ({
							value: item.id,
							label: item.name
						}));
						dispatch(fetchColorListSuccess(processData));
					} else {
						dispatch(fetchColorListSuccess(result.data.data));
					}
				} else {
					dispatch(fetchColorListFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchColorListFailure('Something went wrong'));
			});

	};
};

export const fetchColorSingle = (colorId) => {
	return async (dispatch) => {
		dispatch(colorInit());
		ColorController.single(colorId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(fetchColorSingleFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(fetchColorSingleSuccess(result.data.data));
				} else {
					dispatch(fetchColorSingleFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(fetchColorSingleFailure('Something went wrong'));
			});
	};
};

export const createColor = (colorData) => {
	return async (dispatch) => {
		dispatch(colorInit());
		ColorController.create(colorData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message
						} else {
							errorMsg = 'Something went wrong'
						}
					} else {
						errorMsg = 'Something went wrong'
					}
					dispatch(createColorFailure(errorMsg))
				} else if (result.data.status !== 'Failed') {
					dispatch(createColorSuccess(result.data.message));
				} else {
					dispatch(createColorFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(createColorFailure('Something went wrong'));
			});

	};
};


export const updateColor = (colorId, updatedColorData) => {
	return async (dispatch) => {
		dispatch(colorInit());
		ColorController.update(colorId, updatedColorData)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = '';
					if (result.response) {
						if (result.response.data && !Array.isArray(result.response.data.message)) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = 'Something went wrong';
						}
					} else {
						errorMsg = 'Something went wrong';
					}
					dispatch(updateColorFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					dispatch(updateColorSuccess(result.data.message));
				} else {
					dispatch(updateColorFailure('Something went wrong'));
				}
			})
			.catch((error) => {
				dispatch(updateColorFailure('Something went wrong'));
			});

	};
};


export const deleteColor = (colorId) => {
	return async (dispatch) => {
		dispatch(colorInit());
		ColorController.delete(colorId)
			.then(result => {
				if (result instanceof Error) {
					let errorMsg = result.response ? (result.response.data ? result.response.data.message : 'Something went wrong') : 'Something went wrong';
					dispatch(deleteColorFailure(errorMsg));
				} else if (result.data.status !== 'Failed') {
					fetchColorList()
					dispatch(deleteColorSuccess(result.data.message));
				} else {
					dispatch(deleteColorFailure('Something went wrong'));
				}
			})
			.catch(error => {
				dispatch(deleteColorFailure('Something went wrong'));
			})
	};
};
