import BaseController from './BaseController';
import { endpoint } from "./EndPoint";

export default class BrandController extends BaseController {
	static async list(page, isPublic, searchParam) {
		try {
			let pageQueryParam = page ? `?page=${page}` : '';
			let path = isPublic ? endpoint.BRAND_LIST_PUBLIC_LIST : endpoint.BRAND_LIST
			pageQueryParam = searchParam !== '' ? pageQueryParam + '&' + searchParam : pageQueryParam;
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async publicList(page) {
		try {
			let pageQueryParam = page ? `?page=${page}` : '';
			let path = endpoint.BRAND_LIST_PUBLIC_LIST
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(brandId) {
		try {
			return await this.axiosGet(`${endpoint.BRAND_SINGLE}/${brandId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(brandData) {
		try {
			return await this.axiosPost(endpoint.BRAND_CREATE, brandData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(brandId, updatedBrandData) {
		try {
			return await this.axiosPost(`${endpoint.BRAND_UPDATE}/${brandId}`, updatedBrandData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async delete(brandId) {
		try {
			return await this.axiosGet(`${endpoint.BRAND_DELETE}/${brandId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
