import * as Yup from 'yup';

const SubCarModelSchema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	price: Yup.number().required('Price is required'),
	instalment: Yup.number().required('Instalment is required'),
	depreciation: Yup.number().required('Depreciation is required'),
	coe_price: Yup.number().required('COE price is required'),
	road_tax: Yup.number().required('Road tax is required'),
	omv: Yup.number().required('OMV is required'),
	arf: Yup.number().required('ARF is required'),
	ves: Yup.number().required('VES is required'),
	total_basic: Yup.number().required('Total basic is required'),
	premium: Yup.number().required('Premium is required'),
	engine_capacity: Yup.number().required('Engine capacity is required'),
	engine_type: Yup.string().required('Engine type is required'),
	battery_type: Yup.string().required('Battery type is required'),
	fuel_type_id: Yup.number().required('Fuel type ID is required'),
	drive_type: Yup.string().required('Drive type is required'),
	transmission_type: Yup.string().required('Transmission type is required'),
	transmission: Yup.string().required('Transmission is required'),
	power: Yup.number().required('Power is required'),
	engine_power: Yup.number().required('Engine power is required'),
	motor_power: Yup.number().required('Motor power is required'),
	torque: Yup.number().required('Torque is required'),
	acceleration: Yup.number().required('Acceleration is required'),
	top_speed: Yup.number().required('Top speed is required'),
	fuel_consumption: Yup.number().required('Fuel consumption is required'),
	co2_emission: Yup.number().required('CO2 emission is required'),
	battery_capacity: Yup.number().required('Battery capacity is required'),
	drive_range: Yup.number().required('Drive range is required'),
	energy_consumption: Yup.number().required('Energy consumption is required'),
	dc_charging_rate_max: Yup.number().required(
		'DC charging rate max is required'
	),
	dc_charging_rate_total: Yup.number().required(
		'DC charging rate total is required'
	),
	ac_charging_rate_max: Yup.number().required(
		'AC charging rate max is required'
	),
	ac_charging_rate_total: Yup.number().required(
		'AC charging rate total is required'
	),
	suspension_front: Yup.string().required('Suspension front is required'),
	suspension_back: Yup.string().required('Suspension back is required'),
	break_front: Yup.string().required('Break front is required'),
	break_rear: Yup.string().required('Break rear is required'),
	wheel_rim_size: Yup.string().required('Wheel rim size is required'),
	head_light: Yup.string().required('Headlight is required'),
	is_auto_head_light: Yup.boolean().required('Auto headlight is required'),
	daytime_lights: Yup.string().required('Daytime lights are required'),
	electric_folding_side_mirror: Yup.boolean().required(
		'Electric folding side mirror is required'
	),
	rain_sensing_wipers: Yup.boolean().required(
		'Rain sensing wipers are required'
	),
	multi_function_steering_wheel: Yup.boolean().required(
		'Multi-function steering wheel is required'
	),
	paddle_shifters: Yup.boolean().required('Paddle shifters are required'),
	keyless_entry: Yup.boolean().required('Keyless entry is required'),
	keyless_engine_start: Yup.boolean().required(
		'Keyless engine start is required'
	),
	multi_zone_aircon: Yup.boolean().required('Multi-zone aircon is required'),
	rear_aircon: Yup.boolean().required('Rear aircon is required'),
	seat_upholstery: Yup.string().required('Seat upholstery is required'),
	driver_electric_seat: Yup.boolean().required(
		'Driver electric seat is required'
	),
	passenger_electric_seat: Yup.boolean().required(
		'Passenger electric seat is required'
	),
	ventilated_seats: Yup.boolean().required('Ventilated seats are required'),
	electric_tailgate: Yup.boolean().required('Electric tailgate is required'),
	car_roof: Yup.string().required('Car roof is required'),
	bluetooth: Yup.boolean().required('Bluetooth is required'),
	apple_carplay: Yup.string().required('Apple CarPlay is required'),
	android_auto: Yup.string().required('Android Auto is required'),
	wireless_charging: Yup.boolean().required('Wireless charging is required'),
	navigation_system: Yup.boolean().required('Navigation system is required'),
	infotainment: Yup.string().required('Infotainment is required'),
	sound_system: Yup.string().required('Sound system is required'),
	no_of_airbags: Yup.string().required('No. of Airbags is required'),
	parking_sensors: Yup.string().required('Parking sensors is required'),
	park_assist: Yup.string().required('Park assist is required'),
	view_camera: Yup.string().required('View camera is required'),
	cruise_control: Yup.string().required('Cruise control is required'),
	aditional_feature: Yup.string().required('Additional feature is required'),
});

export default SubCarModelSchema;
