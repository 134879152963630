import * as yup from 'yup';

const allowedExtensions = ['jpg', 'jpeg', 'png'];
const allowedAdsOnIdValues = ['1', '2', '4', '5']; 

const UsedCarSchema = yup.object().shape({
	ads_type_id: yup.string().required('Ad Type is required'),
	ads_on_id: yup.string().required('Service Type is required'),
	car_plate_no: yup.string().required('Car Plate Number is required'),
	owner_id_type_id: yup.string().required('Owner ID Type is required'),
	owner_id: yup.string().required('Owner ID is required'),
	brand_id: yup.string().nullable(true),
	model_id: yup.string().nullable(true),
	sub_model_id: yup.string().nullable(true),
	color_id: yup.string().nullable(true),
	price: yup
		.number()
		.typeError('Asking Price must be a valid number')
		.nullable(true),
	mileage: yup.string(),
	prefered_area: yup.string().optional(),
	desc: yup.string().required('Description is required'),
	allow_email: yup.boolean().required('Is Enquiry is required'),
	isImageRequired: yup.boolean().default(false),
	image: yup
		.mixed()
		.test('required', 'Image is required', function (value) {
			const adsOnId = this.resolve(yup.ref('ads_on_id'));
			const isImageRequired = this.resolve(yup.ref('isImageRequired'));
		
			if (value ) {
				return true
			} else if (allowedAdsOnIdValues.includes(adsOnId)) {
				return true
			} else if(!allowedAdsOnIdValues.includes(adsOnId) && !isImageRequired) {
				return true
			}else {
				return this.createError({ message: 'Image is required' });
			}

			return true
		})
		.test(
			'fileType',
			'Invalid file type. Please select a valid image (JPEG, JPG, PNG).',
			(value) => {
				if (!value) {
					return true;
				}
				const fileExtension = value[0].name.split('.').pop().toLowerCase();
				return allowedExtensions.includes(fileExtension);
			}
		),
});

export default UsedCarSchema;
