import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class ColorController extends BaseController {
	static async list(page, isPublic) {
		try {
			const pageQueryParam = page ? `?page=${page}` : '';
			let path = isPublic ? endpoint.COLOR_LIST_PUBLIC_LIST : endpoint.COLOR_LIST
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(colorId) {
		try {
			return await this.axiosGet(`${endpoint.COLOR_SINGLE}/${colorId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(colorData) {
		try {
			return await this.axiosPost(endpoint.COLOR_CREATE, colorData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(colorId, updatedColorData) {
		try {
			return await this.axiosPost(`${endpoint.COLOR_UPDATE}/${colorId}`, updatedColorData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async delete(colorId) {
		try {
			return await this.axiosGet(`${endpoint.COLOR_DELETE}/${colorId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
