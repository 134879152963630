import BaseController from './BaseController';
import { endpoint } from "./EndPoint";

export default class SettingController extends BaseController {
	static async single() {
		try {
			return await this.axiosGet(`${endpoint.SETTING_SINGLE}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async publicsetting() {
		try {
			return await this.axiosGet(`${endpoint.SETTING_PUBLIC}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
	
	static async update(updateSettingData) {
		try {
			return await this.axiosPost(`${endpoint.SETTING_UPDATE}`, updateSettingData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
