import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Brands from '../containers/admin/Brands';
import CarCategory from '../containers/admin/CarCategory/index';
import CarModel from '../containers/admin/CarModel';
import Color from '../containers/admin/Color';
import Dashboard from '../containers/admin/Dashboard/Dashboard';
import FuelType from '../containers/admin/FuelType/index';
import CompanySetting from '../containers/admin/Setting/index';
import UsedCar from '../containers/admin/UsedCar';
import Customer from '../containers/admin/User/Customer/index';
import Merchant from '../containers/admin/User/Merchant/index';
import User from '../containers/admin/User/User';
import VehicleType from '../containers/admin/VehicleType';
import Logout from '../containers/common/Logout/Logout';
// import CustomTab from '../components/admin/CustomTab/CustomTab';
import SubModel from '../containers/admin/SubModel';

import SalesPersonPost from '../components/site/CarListing/SalesPersonPost';
import ViewPayment from '../components/site/CarListing/ViewPayment';
import AddsOns from '../containers/admin/AddsOns';
import AdsType from '../containers/admin/AdsType';
import Payment from '../containers/admin/Payment';
import UserDashboard from '../containers/site/Dashboard/Dashboard';
import IndividualUsedCarPosting from '../containers/site/UsedCarPosting/IndividualUsedCarPosting';
import UsedCarPosting from '../containers/site/UsedCarPosting/UsedCarPosting';
import NewCar from '../containers/admin/NewCar';
import NewCarPosting from '../containers/site/NewCarPosting/NewCarPosting';
import PromoCodes from '../containers/admin/PromoCodes';
import PromoCodesOrderCounter from '../containers/admin/PromoCodesOrderCounter';
import PromoCodeWiseOrderList from '../containers/admin/PromoCodesOrderCounter/PromoCodeWiseOrderList';


const PrivateRoute = (props) => {
	const pathName = useLocation().pathname;

	let redirect = null;

	if (
		pathName.startsWith('/login') ||
		pathName.startsWith('/register') ||
		pathName.startsWith('/admin/login')
	) {
		redirect = <Navigate to={props.redirectTo} />;
	}

	const routes = [{ path: '/logout', element: <Logout /> }];

	if (props.userData != undefined && props.userData.type === 'user') {
		routes.push(
			{
				path: '/dashboard',
				element: <Dashboard />,
			},
			{
				path: '/admin/brands',
				element: <Brands />,
			},
			{
				path: '/admin/vehicle-types',
				element: <VehicleType />,
			},
			{
				path: '/admin/color',
				element: <Color />,
			},
			{
				path: '/admin/user',
				element: <User />,
			},
			{
				path: '/admin/merchant',
				element: <Merchant />,
			},
			{
				path: '/admin/customer',
				element: <Customer />,
			},
			{
				path: '/admin/car-category',
				element: <CarCategory />,
			},
			{
				path: '/admin/fuel-type',
				element: <FuelType />,
			},
			{
				path: '/admin/company-setting',
				element: <CompanySetting />,
			},
			{
				path: '/admin/models',
				element: <CarModel />,
			},
			{
				path: '/admin/sub-model',
				element: <SubModel />,
			},
			{
				path: '/admin/Used-car',
				element: <UsedCar />,
			},
			{
				path: '/admin/payment',
				element: <Payment />,
			},
			{
				path: '/admin/ads-type',
				element: <AdsType />,
			},
			{
				path: '/admin/adds-ons',
				element: <AddsOns />,
			},
			{
				path: '/admin/new-car',
				element: <NewCar />,
			},
			{
				path: '/admin/promo-codes',
				element: <PromoCodes />,
			},
			{
				path: '/admin/promo-codes-order-count',
				element: <PromoCodesOrderCounter />,
			},
			{
				path: '/admin/promo-code-wise-order-list/:id?',
				element: <PromoCodeWiseOrderList />,
			},
		);
	} else if (
		props.userData != undefined &&
		(props.userData.type === 'customer' || props.userData.type === 'merchant')
	) {
		routes.push(
			{
				path: '/user-dashboard',
				element: <UserDashboard />,
			},
			{
				path: '/used-car-post/:id?',
				element: <UsedCarPosting />,
			},
			{
				path: '/sales-parson-form',
				element: <SalesPersonPost />,
			},
			{
				path: '/sales-parson-form/:id?',
				element: <SalesPersonPost />,
			},
			{
				path: '/view_payment/:id',
				element: <ViewPayment />,
			},
			{
				path: '/new-car-post/:id?',
				element: <NewCarPosting />,
			},
		);
	}

	if (props.userData != undefined && props.userData.type === 'customer') {
		routes.push({
			path: '/user-car-posting-individual',
			element: <IndividualUsedCarPosting />,
		});
	}

	return (
		<>
			{redirect}
			<Routes>
				{routes.map((route) => (
					<Route key={route.path} path={route.path} element={route.element} />
				))}
			</Routes>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		redirectTo: state.auth.redirectTo,
		userData: state.auth.userData,
	};
};

export default connect(mapStateToProps, null)(PrivateRoute);
