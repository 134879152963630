import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


import Layout from '../../../components/admin/Layout/Base/Layout';
import VehicleTypeForm from './VehicleTypeForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import VehicleTypeTable from '../../../components/admin/Table/Table';
import { fetchVehicleTypeList, createVehicleType, deleteVehicleType, fetchVehicleTypeSingle, updateVehicleType } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
// import Alert from '../../../components/common/Alert/Alert';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';


const VehicleTypes = (props) => {
	const {
		loading,
		error,
		success,
		vehicleTypeList,
		fetchVehicleTypeList,
		createVehicleType,
		deleteVehicleType,
		fetchVehicleTypeSingle,
		singleVehicleType,
		updateVehicleType
	} = props;

	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1)

	useEffect(() => {
		setShowTable(true)
	}, [vehicleTypeList])

	useEffect(() => {
		fetchVehicleTypeList(page);
	}, [fetchVehicleTypeList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchVehicleTypeList(page);
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {

				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id)
			setShowTable(false)
			setButtonLabel('List')
			fetchVehicleTypeSingle(id)
		} else if (action === 'delete') {
			deleteVehicleType(id);
		}
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null)
	};

	const onPageChange = (page) => {
		setPage(page)
	};

	const onSubmit = (values) => {
		if (editingId) {
			updateVehicleType(editingId, values)
		} else {
			createVehicleType(values);
		}
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row.status ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					showDeleteButton={true}
					onDelete={id => handleEditDelete(id, 'delete')}
				/>
			),
			sortable: false
		},
	];

	const renderContent = () => {
		if (loading) {
			return <Loader />
		}

		if (showTable && !loading) {
			return (
				<VehicleTypeTable
					defaultPage={page}
					columns={columns} data={vehicleTypeList.rows == undefined ? [] : vehicleTypeList.rows} totalCount={vehicleTypeList.count} handlePageChange={onPageChange}
				/>
			);
		}

		if (editingId && !loading) {
			return <VehicleTypeForm onSubmit={onSubmit} initialValues={singleVehicleType} onCancel={handleButtonClick} />;
		}

		if (!loading) {
			return <VehicleTypeForm onSubmit={onSubmit} onCancel={handleButtonClick} />;
		}
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Vehicle Types" buttonText={buttonLabel} onButtonClick={handleButtonClick} />
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.vehicleType.loading,
		error: state.vehicleType.error,
		success: state.vehicleType.success,
		vehicleTypeList: state.vehicleType.vehicleTypeList,
		singleVehicleType: state.vehicleType.singleVehicleType
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchVehicleTypeList: (page) => dispatch(fetchVehicleTypeList(page)),
		createVehicleType: (data) => dispatch(createVehicleType(data)),
		deleteVehicleType: (id) => dispatch(deleteVehicleType(id)),
		fetchVehicleTypeSingle: (id) => dispatch(fetchVehicleTypeSingle(id)),
		updateVehicleType: (id, data) => dispatch(updateVehicleType(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypes);
