import React, { useEffect, useState } from 'react';
import Layout from '../../../components/site/Layout/Base/Layout';
import BackAndShareBar from '../../../components/site/ProductComponents/BackAndShareBar';
import MarchantHeaderInfo from '../../../components/site/MerchantProfile/MarchantHeaderInfo';
import MerchantHeaderImg from '../../../assets/images/web/merchantimg.png';
import MerchantCarTab from '../../../components/site/MerchantProfile/MerchantCarTab';
import MarchantNewAndUsedCars from '../../../components/site/MerchantProfile/MarchantNewAndUsedCars';
import { getMerchantProfile, fetchMerchantUsedCar } from '../../../store/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const UsedCarMarchentProfile = (props) => {
	const { id } = useParams();
	const {
		merchantUsedCar,
		merchantprofile,
		getMerchantProfile,
		fetchMerchantUsedCar
	} = props;

	const [merchantCars, setMerchantCars] = useState([])
	const [carTab, setCarTab] = useState("")
	const [page, setPage] = useState(1)

	useEffect(() => {
		getMerchantProfile(id)
	}, [id, page])

	useEffect(() => {
		fetchMerchantUsedCar(id, page)
	}, [id, page])

	useEffect(() => {
		setCarTab("used car")
	}, [])

	const carTabControl = (tabName) => {
		setCarTab(tabName)
		if (tabName == "used car") {
			setMerchantCars(merchantUsedCar?.rows)
		}
		else {
			setMerchantCars([])
		}
	}

	// console.log("merchant car data ====>>>", merchantprofile)

	const loadmoreCarHandler = () => {
		setPage((prev) => prev + 1)
	}

	useEffect(() => {
		// console.log('Called----------')
		setMerchantCars([])
		if (merchantUsedCar?.rows && merchantUsedCar?.rows.length > 0) {
			if (page > 1) {
				let updatedUsedCarList = [...merchantCars]
				updatedUsedCarList.push(...merchantUsedCar?.rows)
				// console.log(updatedUsedCarList);
				setMerchantCars(updatedUsedCarList)
			} else {
				setMerchantCars(merchantUsedCar?.rows)
			}

		}
	}, [merchantUsedCar, merchantUsedCar?.rows.length])


	return (
		<Layout>
			<BackAndShareBar heartShow={false} />
			<div className='lg:container lg:mx-auto md:container md:mx-auto  px-0 md:px-4 lg:px-4 '>
				<div className='bg-[#f7f7f5]  lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col'>
					<div className='w-full lg:w-1/2 '>
						<img src={MerchantHeaderImg} alt="" />
					</div>
					<MarchantHeaderInfo merchantprofile={merchantprofile} />
				</div>
			</div>
			<div className='lg:container lg:mx-auto md:container md:mx-auto  px-0 md:px-4 lg:px-4 '>
				<MerchantCarTab totalUsedCar={merchantUsedCar?.count} carTab={carTab} carData={merchantCars} carTabControl={carTabControl} />
				<div className='bg-[#CACACA] h-[2px] my-7 mx-2 md:mx-0'></div>
				<MarchantNewAndUsedCars totalUsedCar={merchantUsedCar?.count} carData={merchantCars} loadmoreCarHandler={loadmoreCarHandler} />
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		merchantprofile: state.merchant.merchantProfile,
		merchantUsedCar: state.usedCar.merchantUsedCar,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getMerchantProfile: (id) => dispatch(getMerchantProfile(id)),
		fetchMerchantUsedCar: (id, page) => dispatch(fetchMerchantUsedCar(id, page)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsedCarMarchentProfile);
