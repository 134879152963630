import React from 'react';
import InstantOfferImg from '../../../assets/images/web/instantOffers.png';

const InstantOfferHeader = () => {
  return (
    <div className="w-full container mx-auto">
      <img className="w-full" src={InstantOfferImg} />
    </div>
  );
};

export default InstantOfferHeader;
