import SitePaymentController from '../../api/SitePaymentController';
import * as actionTypes from './actionTypes';

export const sitePaymentInit = () => {
  return {
    type: actionTypes.SITE_PAYMENT_INIT,
  };
};

export const fetchSitePaymentListSuccess = (payments) => {
  return {
    type: actionTypes.FETCH_SITE_PAYMENT_LIST_SUCCESS,
    payload: payments,
  };
};

export const fetchSitePaymentListFailure = (error) => {
  return {
    type: actionTypes.FETCH_SITE_PAYMENT_LIST_FAILURE,
    payload: error,
  };
};
export const createSitePaymentFailure = (error) => {
  return {
    type: actionTypes.CREATE_SITE_PAYMENT_LIST_FAILURE,
    payload: error,
  };
};

export const fetchSitePaymentSingleSuccess = (order) => {
  return {
    type: actionTypes.FETCH_PAYMENT_SINGLE_SUCCESS,
    payload: order,
  };
};

export const fetchSitePaymentSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_PAYMENT_SINGLE_FAILURE,
    payload: error,
  };
};

export const fetchSitePaymentList = (page) => {
  return async (dispatch) => {
    dispatch(sitePaymentInit());
    SitePaymentController.list(page)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(createSitePaymentFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchSitePaymentListSuccess(result.data.data));
        } else {
          dispatch(fetchSitePaymentListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchSitePaymentListFailure('Something went wrong'));
      });
  };
};

export const fetchOrderSingle = (orderId) => {
  return async (dispatch) => {
    dispatch(sitePaymentInit());
    SitePaymentController.single(orderId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchSitePaymentSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchSitePaymentSingleSuccess(result.data.data));
        } else {
          dispatch(fetchSitePaymentSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchSitePaymentSingleFailure('Something went wrong'));
      });
  };
};
