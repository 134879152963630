import React, { useEffect, useState } from 'react';
import { IoMdArrowBack, IoMdShare, IoMdHeartEmpty } from 'react-icons/io';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchShortListedList, usedCarShortListSave } from '../../../store/actions';
import { toast } from 'react-toastify';
import { usedCarShortlistSaveSuccess } from '../../../store/actions/UsedCarAction';
import { IoMdHeart } from "react-icons/io";

const BackAndShareBar = (props) => {

  const {
    loading,
    error,
    success,
    heartShow = true, from = '', carDetails,
    usedCarShortListSave,
    usedCarShortlistSaveSuccess,
    fetchShortListedList,
    userData,
    shortlistedList
  } = props;

  const [copyTxtShow, setCopyTxtShow] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    if (from == 'newcar_spacefeature') {
      navigate(-2);
    }
    else {
      navigate(-1);
    }

  };

  const shareBtnClick = async () => {
    setCopyTxtShow(true);
    const link = window.location.href;

    await navigator.clipboard.writeText(link);

    setTimeout(() => setCopyTxtShow(false), 500);
  };

  useEffect(() => {
    toast.success(success, {
      position: toast.POSITION.TOP_RIGHT,
      onOpen: () => {
        usedCarShortlistSaveSuccess({})
        fetchShortListedList('all', 'used_car')
      },
      onClose: () => {

      },
    });
  }, [error, success])

  useEffect(() => {
    if (userData?.id) {
      fetchShortListedList('all', 'used_car')
    }
  }, [])

  const shortlistHandler = (id) => {

    const existsCar = shortlistedList?.find((item) => item?.car_listing_id == id)

    if (!existsCar?.car_listing_id) {
      const shortObj = {
        car_listing_id: id,
        type: "used_car"
      }
      usedCarShortListSave(shortObj, 'save')

    }
    else {
      const shortObj = {
        id: existsCar?.id,
        type: "used_car"
      }
      usedCarShortListSave(shortObj, 'delete')
    }

  }

  return (
    <div className="container mx-auto py-4 flex flex-row justify-between items-center">
      <div
        onClick={handleBack}
        className="cursor-pointer flex flex-row justify-center items-center  w-fit sm:0 pr-4"
      >
        <div className="bg-[#0534FF] w-[24px] h-[24px] flex flex-row justify-center items-center rounded cursor-pointer">
          <IoMdArrowBack color="white" />
        </div>
        <p className="text-[#0534FF] text-[16px] font-bold ps-4">
          Back to results
        </p>
      </div>

      <div className="w-fit flex flex-row justify-between items-center">
        {copyTxtShow && <p>Link Copied !</p>}
        {heartShow && (
          <>
            {
              shortlistedList?.find((item) => item?.car_listing_id == carDetails?.id)?.id ? <div onClick={() => shortlistHandler(carDetails?.id)} className="pr-4 cursor-pointer">
                <IoMdHeart color="red" size={24} />
              </div> : <div onClick={() => shortlistHandler(carDetails?.id)} className="pr-4 cursor-pointer">
                <IoMdHeartEmpty color="black" size={24} />
              </div>
            }

          </>
        )}

        <div
          onClick={shareBtnClick}
          className="bg-[#0534FF] w-[130px] h-[43px] flex flex-row justify-center items-center rounded cursor-pointer"
        >
          <p className="text-white text-[16px] font-bold mr-2">Share</p>
          <IoMdShare color="white" size={20} />
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    loading: state.usedCar.loading,
    error: state.usedCar.error,
    success: state.usedCar.success,
    userData: state.auth.userData,
    shortlistedList: state.shortlisted.shortlistedList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    usedCarShortListSave: (shortData, shortType) =>
      dispatch(usedCarShortListSave(shortData, shortType)),
    usedCarShortlistSaveSuccess: ({ }) =>
      dispatch(usedCarShortlistSaveSuccess({})),
    fetchShortListedList: (page, type) =>
      dispatch(fetchShortListedList(page, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackAndShareBar);


