import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class NewCarListingController extends BaseController {
  static async newcarlist(page) {
    try {
      return await this.axiosGet(`${endpoint.NEW_CAR_LIST}?page=${page}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async list(page, isPublic, searchParam) {
    try {
      let pageQueryParam = page ? `?page=${page}` : '';
      let path = endpoint.NEW_CAR_LIST;

      let headers = this.getHeaders();
      if (isPublic === 'latest') {
        pageQueryParam =
          searchParam !== ''
            ? pageQueryParam + '&' + searchParam
            : pageQueryParam;
        headers = {};
        path = endpoint.NEW_CAR_LIST_LATEST;
      } else {
        path = isPublic ? endpoint.NEW_CAR_SEARCH_LIST : endpoint.NEW_CAR_LIST;
      }
      if (isPublic === 'buyNewCar') {
        path = endpoint.NEW_CAR_PUBLIC_LIST;
      }
      return await this.axiosGet(`${path}${pageQueryParam}`, {
        headers: headers,
      });
    } catch (error) {
      return error;
    }
  }

  static async create(listingData, from) {
    try {
      const url_path =
        from == 'site' ? endpoint.NEW_CAR_SITE_CREATE : endpoint.NEW_CAR_CREATE;
      return await this.axiosPost(url_path, listingData, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async single(carid, isPublic) {
    try {
      const url_path = isPublic
        ? endpoint.SINGLE_NEW_CAR
        : endpoint.NEW_CAR_SINGLE;
      return await this.axiosGet(`${url_path}/${carid}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async update(listingId, updatedListingData, isPublic) {
    try {
      const url_path = isPublic
        ? endpoint.NEW_CAR_SITE_UPDATE
        : endpoint.NEW_CAR_UPDATE;
      return await this.axiosPost(
        `${url_path}/${listingId}`,
        updatedListingData,
        {
          headers: this.getHeaders(),
        }
      );
    } catch (error) {
      return error;
    }
  }
  static async publicSingle(newCarId) {
    try {
      return await this.axiosGet(
        `${endpoint.NEW_CAR_SEARCH_LIST}/${newCarId}`,
        {
          headers: this.getHeaders(),
        }
      );
    } catch (error) {
      return error;
    }
  }

  static async updateenquiry(listingId) {
		try {
			return await this.axiosGet(
				`${endpoint.NEW_CAR_ENQUIRY_COUNT}/${listingId}`,
				{ headers: this.getHeaders() }
			);
		} catch (error) {
			return error;
		}
	}
  
}
