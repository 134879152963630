import moment from 'moment';

//-----------Calculating remaining time of COE expiry,, from current date to CEO expire Date---------///
export const registrationTimeLeft = (targetDate) => {
    // console.log(targetDate)
    const dateA = moment();
    const dateB = moment(targetDate)
    const startDate = moment(dateA);
    const endDate = moment(dateB);
  
    const years = endDate.diff(startDate, "years");
    startDate.add(years, "years");
  
    const months = endDate.diff(startDate, "months");
    startDate.add(months, "months");
  
    const  days = endDate.diff(startDate, "days");

    if (years < 0 || months < 0 || days < 0) {
      return 'COE expired';
    } else {
        
      return `${years} years ${months} months ${days} days left`;
    }
  };
  
// --------------This function is for calculating total day from current date to future date-------------//
export function totalDayLeftFromCurrentDate(targetDate) {
    // Current date
    const currentDate = moment();

    // Future date
    const futureDate = moment(targetDate);

    // Calculate the difference in days
    const daysRemaining = futureDate.diff(currentDate, 'days');

    return daysRemaining;
}

