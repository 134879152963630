// Dashboard.js
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CarListing from '../../../components/site/CarListing/CarListing';
import FullWidthSection from '../../../components/site/FullWidthSection/FullWidthSection';
import { formatNumberWithCommas } from '../../../helper/formatNumberWithCommas';
import {
	fetchNewCarSearchList,
	fetchSellerList,
	fetchSitePaymentList,
	fetchUsedCarList,
	getMerchantSellers,
	usedCarStatusUpdate,
} from '../../../store/actions';
import { fetchNewCarSingleSuccess } from '../../../store/actions/NewCarsActions';
import { fetchUsedCarSingleSuccess } from '../../../store/actions/UsedCarAction';
import Layout from './../../../components/site/Layout/Base/Layout';

const Dashboard = (props) => {
	const {
		usedCarLoading,
		usedCarError,
		usedCarSuccess,
		usedCarList,
		fetchUsedCarList,
		usedCarStatusUpdate,
		userData,
		fetchSellerList,
		sellerList,
		getMerchantSellers,
		fetchUsedCarSingleSuccess,
		sitePaymentList,
		fetchSitePaymentList,
		fetchNewCarSearchList,
		newCarList,
		fetchNewCarSingleSuccess,
	} = props;

	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [sellerPage, setSellerPage] = useState(1);
	const [postingListingsData, setPostingListingsData] = useState([]);
	const [statusRequestUpdate, setStatusRequestUpdate] = useState(false);
	const [newCarlistingData, setNewCarlistingData] = useState([]);

	const updateListingsData = (rows) => {
		setPostingListingsData([]);
		if (rows?.length > 0) {
			rows?.forEach((item) => {
				// let imgPath =
				// 	item.car_listing_images.length > 0
				// 		? item.car_listing_images[0].image
				// 		: '';
				setPostingListingsData((prevData) => [
					...prevData,
					{
						id: item.id,
						imgSrc: item.images,
						carPlateNo: item.car_plate_no,
						title: item.car_model ? item.car_model?.name : '-',
						subTitle: item.is_feature ? 'Featured' : 'Normal',
						price: item.price,
						status: item.status,
						postedOn: moment(item.createdAt).format('DD MMMM YYYY'),
						enquiryCount: item.totalEnquiry,
						refreshedOn: moment(item.updatedAt).format('DD MMMM YYYY'),
						editedCount: item.totaledit,
						totalClick: item?.totalClick,
						is_feature: item?.is_feature,
						expiredOn:
							item.expired_at !== null
								? moment(item.expired_at).format('DD MMMM YYYY')
								: '',
					},
				]);
			});
		}
	};

	const updateNewCarListingsData = (rows) => {
		setNewCarlistingData([]);
		if (rows?.length > 0) {
			rows?.forEach((item) => {
				// let imgPath =
				// 	item?.new_car_listing_images?.length > 0
				// 		? item?.new_car_listing_images[0]?.image
				// 		: '';
				setNewCarlistingData((prevData) => [
					...prevData,
					{
						id: item.id,
						imgSrc: item.images,
						carPlateNo: item.car_plate_no,
						title: item.car_model ? item.car_model?.name : '-',
						subTitle: item.is_feature ? 'Featured' : 'Normal',
						price:
							item?.new_car_submodel?.length > 1
								? `${formatNumberWithCommas(
									item?.new_car_submodel[0]?.car_submodel?.key_info?.price
								)} - $${formatNumberWithCommas(
									item?.new_car_submodel[item?.new_car_submodel?.length - 1]
										?.car_submodel?.key_info?.price
								)}`
								: `${formatNumberWithCommas(
									item?.new_car_submodel[0]?.car_submodel?.key_info?.price
								)}`,
						status: item.status,
						postedOn: moment(item.createdAt).format('DD MMMM YYYY'),
						enquiryCount: item.totalEnquiry,
						refreshedOn: moment(item.updatedAt).format('DD MMMM YYYY'),
						editedCount: item.totaledit,
						totalClick: item?.totalClick,
						is_feature: item?.is_feature,
						expiredOn:
							item.expired_at !== null
								? moment(item.expired_at).format('DD MMMM YYYY')
								: '',
					},
				]);
			});
		}
	};

	const onSellerPageChange = (sellerPage) => {
		setSellerPage(sellerPage);
	};

	const onBillingPageChange = (page) => {
		setPage(page)
	}

	useEffect(() => {
		updateListingsData(usedCarList);
		getMerchantSellers(userData?.id);
		fetchUsedCarSingleSuccess({});
	}, [usedCarList]);

	useEffect(() => {
		updateNewCarListingsData(newCarList);
		fetchNewCarSingleSuccess({});
	}, [newCarList, newCarList?.length]);

	useEffect(() => {
		if (usedCarSuccess && statusRequestUpdate) {
			toast.success(usedCarSuccess, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					if (statusRequestUpdate) {
						fetchUsedCarList('all', false, true);
						setStatusRequestUpdate(false);
					}
				},
				onClose: () => { },
			});
		}

		if (usedCarError) {
			toast.error(usedCarError, {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	}, [usedCarError, usedCarSuccess]);

	const handleFilterClick = (index, tabNo) => {
		// console.log('Filter Clicked ==>', index, tabNo);

		if (tabNo == 0) {
			let filteredRows = usedCarList;
			if (index !== 'all') {
				filteredRows = usedCarList.filter((item) => item.status === index);
			}
			updateListingsData(filteredRows);
		} else {
			let filteredRows = newCarList;
			if (index !== 'all') {
				filteredRows = newCarList.filter((item) => item.status === index);
			}
			updateNewCarListingsData(filteredRows);
		}
	};

	const handleDropDownClick = (id, flag, tabNo) => {
		// console.log('Drop Down CLicked ====>>', id, flag);
		if (tabNo == 0) {
			if (flag === 'edit' && id !== undefined) {
				navigate('/used-car-post/' + id);
			} else if (flag === 'status' && id !== undefined) {
				setStatusRequestUpdate(true);
				usedCarStatusUpdate(id);
			}
		} else {
			if (flag === 'edit' && id !== undefined) {
				navigate('/new-car-post/' + id);
			}
			// else if (flag === 'status' && id !== undefined) {
			//   setStatusRequestUpdate(true);
			//   usedCarStatusUpdate(id);
			// }
		}
	};

	const fetchData = async () => {
		await Promise.all([
			fetchNewCarSearchList('all', false, true),
			fetchUsedCarList('all', false, true),
			// fetchShortListedList(1, 'used_car'),
			fetchSellerList(sellerPage, userData.id),
		]);
	};

	useEffect(() => {
		fetchSitePaymentList(page);
	}, [page]);

	useEffect(() => {
		fetchSellerList(sellerPage, userData.id)
	}, [sellerPage]);

	useEffect(() => {
		fetchData();
	}, []);

	const renderContent = () => {
		if (postingListingsData) {
			return (
				<CarListing
					sellerList={sellerList}
					usedCarData={postingListingsData}
					newCarData={newCarlistingData}
					onTabChange={handleFilterClick}
					handleDropDownClick={(id, flag, tabNo) =>
						handleDropDownClick(id, flag, tabNo)
					}
					userData={userData}
					sitePaymentList={sitePaymentList}
					onSellerPageChange={onSellerPageChange}
					onBillingPageChange={onBillingPageChange}
					sellerPage={sellerPage}
				/>
			);
		}
	};

	return (
		<Layout>
			<FullWidthSection title="My Dashboard" bgColor="#cccccc" />
			{renderContent()}
		</Layout>
	);
};
const mapStateToProps = (state) => {
	return {
		usedCarLoading: state.usedCar.loading,
		usedCarError: state.usedCar.error,
		usedCarSuccess: state.usedCar.success,
		usedCarList: state.usedCar.usedCarList,
		userData: state.auth.userData,
		sellerList: state.seller.sellerList,
		sitePaymentList: state.sitePayment.sitePaymentList,
		newCarList: state.newcars.searchNewCarList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUsedCarList: (page, isProcess, isPublic) =>
			dispatch(fetchUsedCarList(page, isProcess, isPublic)),
		usedCarStatusUpdate: (id) => dispatch(usedCarStatusUpdate(id)),
		fetchSellerList: (page, id) => dispatch(fetchSellerList(page, id)),
		getMerchantSellers: (id) => dispatch(getMerchantSellers(id)),
		fetchUsedCarSingleSuccess: () => dispatch(fetchUsedCarSingleSuccess({})),
		fetchSitePaymentList: (page) => dispatch(fetchSitePaymentList(page)),
		fetchNewCarSearchList: (page, isProcess, isPublic) =>
			dispatch(fetchNewCarSearchList(page, isProcess, isPublic)),
		fetchNewCarSingleSuccess: () => dispatch(fetchNewCarSingleSuccess({})),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
