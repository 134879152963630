// Authentication Action Types
export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';

// Brand
export const BRAND_INIT = 'BRAND_INIT';
export const FETCH_BRAND_PUBLIC_LIST_SUCCESS =
  'FETCH_BRAND_PUBLIC_LIST_SUCCESS';
export const FETCH_BRAND_LIST_SUCCESS = 'FETCH_BRAND_LIST_SUCCESS';
export const FETCH_BRAND_LIST_FAILURE = 'FETCH_BRAND_LIST_FAILURE';
export const FETCH_BRAND_PUBLIC_LIST_FAILURE =
  'FETCH_BRAND_PUBLIC_LIST_FAILURE';
export const FETCH_BRAND_SINGLE_SUCCESS = 'FETCH_BRAND_SINGLE_SUCCESS';
export const FETCH_BRAND_SINGLE_FAILURE = 'FETCH_BRAND_SINGLE_FAILURE';
// export const CREATE_BRAND_REQUEST = 'CREATE_BRAND_REQUEST';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_FAILURE = 'CREATE_BRAND_FAILURE';
// export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAILURE = 'UPDATE_BRAND_FAILURE';
// export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_FAILURE = 'DELETE_BRAND_FAILURE';

// Vehicle Type
export const VEHICLE_TYPE_INIT = 'VEHICLE_TYPE_INIT';
export const FETCH_VEHICLE_TYPE_LIST_SUCCESS =
  'FETCH_VEHICLE_TYPE_LIST_SUCCESS';
export const FETCH_VEHICLE_TYPE_LIST_FAILURE =
  'FETCH_VEHICLE_TYPE_LIST_FAILURE';
export const FETCH_VEHICLE_TYPE_SINGLE_SUCCESS =
  'FETCH_VEHICLE_TYPE_SINGLE_SUCCESS';
export const FETCH_VEHICLE_TYPE_SINGLE_FAILURE =
  'FETCH_VEHICLE_TYPE_SINGLE_FAILURE';
export const CREATE_VEHICLE_TYPE_SUCCESS = 'CREATE_VEHICLE_TYPE_SUCCESS';
export const CREATE_VEHICLE_TYPE_FAILURE = 'CREATE_VEHICLE_TYPE_FAILURE';
export const UPDATE_VEHICLE_TYPE_SUCCESS = 'UPDATE_VEHICLE_TYPE_SUCCESS';
export const UPDATE_VEHICLE_TYPE_FAILURE = 'UPDATE_VEHICLE_TYPE_FAILURE';
export const DELETE_VEHICLE_TYPE_SUCCESS = 'DELETE_VEHICLE_TYPE_SUCCESS';
export const DELETE_VEHICLE_TYPE_FAILURE = 'DELETE_VEHICLE_TYPE_FAILURE';
export const FETCH_VEHICLE_TYPE_PUBLIC_LIST_SUCCESS =
  'FETCH_VEHICLE_TYPE_PUBLIC_LIST_SUCCESS';
export const FETCH_VEHICLE_TYPE_PUBLIC_LIST_FAILURE =
  'FETCH_VEHICLE_TYPE_PUBLIC_LIST_FAILURE';

// Color
export const COLOR_INIT = 'COLOR_INIT';
export const FETCH_COLOR_LIST_SUCCESS = 'FETCH_COLOR_LIST_SUCCESS';
export const FETCH_COLOR_LIST_FAILURE = 'FETCH_COLOR_LIST_FAILURE';
export const FETCH_COLOR_SINGLE_SUCCESS = 'FETCH_COLOR_SINGLE_SUCCESS';
export const FETCH_COLOR_SINGLE_FAILURE = 'FETCH_COLOR_SINGLE_FAILURE';
export const CREATE_COLOR_SUCCESS = 'CREATE_COLOR_SUCCESS';
export const CREATE_COLOR_FAILURE = 'CREATE_COLOR_FAILURE';
export const UPDATE_COLOR_SUCCESS = 'UPDATE_COLOR_SUCCESS';
export const UPDATE_COLOR_FAILURE = 'UPDATE_COLOR_FAILURE';
export const DELETE_COLOR_SUCCESS = 'DELETE_COLOR_SUCCESS';
export const DELETE_COLOR_FAILURE = 'DELETE_COLOR_FAILURE';

// Role
export const ROLE_INIT = 'ROLE_INIT';
export const FETCH_ROLE_LIST_SUCCESS = 'FETCH_ROLE_LIST_SUCCESS';
export const FETCH_ROLE_LIST_FAILURE = 'FETCH_ROLE_LIST_FAILURE';
export const FETCH_ROLE_SINGLE_SUCCESS = 'FETCH_ROLE_SINGLE_SUCCESS';
export const FETCH_ROLE_SINGLE_FAILURE = 'FETCH_ROLE_SINGLE_FAILURE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

// Admin user
export const ADMIN_INIT = 'ADMIN_INIT';
export const FETCH_ADMIN_LIST_SUCCESS = 'FETCH_ADMIN_LIST_SUCCESS';
export const FETCH_ADMIN_LIST_FAILURE = 'FETCH_ADMIN_LIST_FAILURE';
export const FETCH_ADMIN_SINGLE_SUCCESS = 'FETCH_ADMIN_SINGLE_SUCCESS';
export const FETCH_ADMIN_SINGLE_FAILURE = 'FETCH_ADMIN_SINGLE_FAILURE';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';

// Customer
export const CUSTOMER_INIT = 'CUSTOMER_INIT';
export const FETCH_CUSTOMER_LIST_SUCCESS = 'FETCH_CUSTOMER_LIST_SUCCESS';
export const FETCH_CUSTOMER_LIST_FAILURE = 'FETCH_CUSTOMER_LIST_FAILURE';
export const FETCH_CUSTOMER_SINGLE_SUCCESS = 'FETCH_CUSTOMER_SINGLE_SUCCESS';
export const FETCH_CUSTOMER_SINGLE_FAILURE = 'FETCH_CUSTOMER_SINGLE_FAILURE';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

// Merchant
export const MERCHANT_INIT = 'MERCHANT_INIT';
export const FETCH_MERCHANT_LIST_SUCCESS = 'FETCH_MERCHANT_LIST_SUCCESS';
export const FETCH_MERCHANT_LIST_FAILURE = 'FETCH_MERCHANT_LIST_FAILURE';
export const FETCH_MERCHANT_SINGLE_SUCCESS = 'FETCH_MERCHANT_SINGLE_SUCCESS';
export const FETCH_MERCHANT_SINGLE_FAILURE = 'FETCH_MERCHANT_SINGLE_FAILURE';
export const CREATE_MERCHANT_SUCCESS = 'CREATE_MERCHANT_SUCCESS';
export const CREATE_MERCHANT_FAILURE = 'CREATE_MERCHANT_FAILURE';
export const UPDATE_MERCHANT_SUCCESS = 'UPDATE_MERCHANT_SUCCESS';
export const UPDATE_MERCHANT_FAILURE = 'UPDATE_MERCHANT_FAILURE';
export const MERCHANT_PROFILE_GET = 'merchant_profile_get';
export const MERCHANT_ADD_CLICK_COUNT = 'merchant_add_click_count';
export const SET_MERCHANT_SELLERS = 'set_merchant_sellers';

// Country
export const COUNTRY_INIT = 'COUNTRY_INIT';
export const FETCH_COUNTRY_LIST_SUCCESS = 'FETCH_COUNTRY_LIST_SUCCESS';
export const FETCH_COUNTRY_LIST_FAILURE = 'FETCH_COUNTRY_LIST_FAILURE';
export const FETCH_COUNTRY_SINGLE_SUCCESS = 'FETCH_COUNTRY_SINGLE_SUCCESS';
export const FETCH_COUNTRY_SINGLE_FAILURE = 'FETCH_COUNTRY_SINGLE_FAILURE';
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_FAILURE = 'CREATE_COUNTRY_FAILURE';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_FAILURE = 'UPDATE_COUNTRY_FAILURE';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_FAILURE = 'DELETE_COUNTRY_FAILURE';

// City
export const CITY_INIT = 'CITY_INIT';
export const FETCH_CITY_LIST_SUCCESS = 'FETCH_CITY_LIST_SUCCESS';
export const FETCH_CITY_LIST_FAILURE = 'FETCH_CITY_LIST_FAILURE';
export const FETCH_CITY_SINGLE_SUCCESS = 'FETCH_CITY_SINGLE_SUCCESS';
export const FETCH_CITY_SINGLE_FAILURE = 'FETCH_CITY_SINGLE_FAILURE';
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';
export const CREATE_CITY_FAILURE = 'CREATE_CITY_FAILURE';
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';
export const UPDATE_CITY_FAILURE = 'UPDATE_CITY_FAILURE';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_FAILURE = 'DELETE_CITY_FAILURE';

// CarCategory
export const CAR_CATEGORY_INIT = 'CAR_CATEGORY_INIT';
export const FETCH_CAR_CATEGORY_LIST_SUCCESS =
  'FETCH_CAR_CATEGORY_LIST_SUCCESS';
export const FETCH_CAR_CATEGORY_LIST_FAILURE =
  'FETCH_CAR_CATEGORY_LIST_FAILURE';
export const FETCH_CAR_CATEGORY_SINGLE_SUCCESS =
  'FETCH_CAR_CATEGORY_SINGLE_SUCCESS';
export const FETCH_CAR_CATEGORY_SINGLE_FAILURE =
  'FETCH_CAR_CATEGORY_SINGLE_FAILURE';
export const CREATE_CAR_CATEGORY_SUCCESS = 'CREATE_CAR_CATEGORY_SUCCESS';
export const CREATE_CAR_CATEGORY_FAILURE = 'CREATE_CAR_CATEGORY_FAILURE';
export const UPDATE_CAR_CATEGORY_SUCCESS = 'UPDATE_CAR_CATEGORY_SUCCESS';
export const UPDATE_CAR_CATEGORY_FAILURE = 'UPDATE_CAR_CATEGORY_FAILURE';
export const DELETE_CAR_CATEGORY_SUCCESS = 'DELETE_CAR_CATEGORY_SUCCESS';
export const DELETE_CAR_CATEGORY_FAILURE = 'DELETE_CAR_CATEGORY_FAILURE';

// FuelType
export const FUEL_TYPE_INIT = 'FUEL_TYPE_INIT';
export const FETCH_FUEL_TYPE_LIST_SUCCESS = 'FETCH_FUEL_TYPE_LIST_SUCCESS';
export const FETCH_FUEL_TYPE_LIST_FAILURE = 'FETCH_FUEL_TYPE_LIST_FAILURE';
export const FETCH_FUEL_TYPE_SINGLE_SUCCESS = 'FETCH_FUEL_TYPE_SINGLE_SUCCESS';
export const FETCH_FUEL_TYPE_SINGLE_FAILURE = 'FETCH_FUEL_TYPE_SINGLE_FAILURE';
export const CREATE_FUEL_TYPE_SUCCESS = 'CREATE_FUEL_TYPE_SUCCESS';
export const CREATE_FUEL_TYPE_FAILURE = 'CREATE_FUEL_TYPE_FAILURE';
export const UPDATE_FUEL_TYPE_SUCCESS = 'UPDATE_FUEL_TYPE_SUCCESS';
export const UPDATE_FUEL_TYPE_FAILURE = 'UPDATE_FUEL_TYPE_FAILURE';
export const DELETE_FUEL_TYPE_SUCCESS = 'DELETE_FUEL_TYPE_SUCCESS';
export const DELETE_FUEL_TYPE_FAILURE = 'DELETE_FUEL_TYPE_FAILURE';

// Setting
export const SETTING_INIT = 'SETTING_INIT';
export const FETCH_SETTING_SINGLE_SUCCESS = 'FETCH_SETTING_SINGLE_SUCCESS';
export const FETCH_SETTING_SINGLE_FAILURE = 'FETCH_SETTING_SINGLE_FAILURE';
export const UPDATE_SETTING_SUCCESS = 'UPDATE_SETTING_SUCCESS';
export const UPDATE_SETTING_FAILURE = 'UPDATE_SETTING_FAILURE';
export const FETCH_PUBLIC_SETTING_SINGLE_SUCCESS =
  'fetch_public_setting_single_success';
// CarModel
export const CAR_MODEL_INIT = 'CAR_MODEL_INIT';
export const FETCH_CAR_MODEL_LIST_SUCCESS = 'FETCH_CAR_MODEL_LIST_SUCCESS';
export const FETCH_CAR_MODEL_LIST_FAILURE = 'FETCH_CAR_MODEL_LIST_FAILURE';
export const FETCH_CAR_MODEL_LIST_DROPDOWN_SUCCESS =
  'FETCH_CAR_MODEL_LIST_DROPDOWN_SUCCESS';
export const FETCH_CAR_MODEL_SINGLE_SUCCESS = 'FETCH_CAR_MODEL_SINGLE_SUCCESS';
export const FETCH_CAR_MODEL_SINGLE_FAILURE = 'FETCH_CAR_MODEL_SINGLE_FAILURE';
export const CREATE_CAR_MODEL_SUCCESS = 'CREATE_CAR_MODEL_SUCCESS';
export const CREATE_CAR_MODEL_FAILURE = 'CREATE_CAR_MODEL_FAILURE';
export const UPDATE_CAR_MODEL_SUCCESS = 'UPDATE_CAR_MODEL_SUCCESS';
export const UPDATE_CAR_MODEL_FAILURE = 'UPDATE_CAR_MODEL_FAILURE';
export const DELETE_CAR_MODEL_SUCCESS = 'DELETE_CAR_MODEL_SUCCESS';
export const DELETE_CAR_MODEL_FAILURE = 'DELETE_CAR_MODEL_FAILURE';

// Car Sub-Model
export const CAR_SUB_MODEL_INIT = 'CAR_SUB_MODEL_INIT';
export const FETCH_CAR_SUB_MODEL_LIST_SUCCESS =
  'FETCH_CAR_SUB_MODEL_LIST_SUCCESS';
export const FETCH_CAR_SUB_MODEL_LIST_FAILURE =
  'FETCH_CAR_SUB_MODEL_LIST_FAILURE';
export const FETCH_CAR_SUB_MODEL_SINGLE_SUCCESS =
  'FETCH_CAR_SUB_MODEL_SINGLE_SUCCESS';
export const FETCH_CAR_SUB_MODEL_SINGLE_FAILURE =
  'FETCH_CAR_SUB_MODEL_SINGLE_FAILURE';
export const CREATE_CAR_SUB_MODEL_SUCCESS = 'CREATE_CAR_SUB_MODEL_SUCCESS';
export const CREATE_CAR_SUB_MODEL_FAILURE = 'CREATE_CAR_SUB_MODEL_FAILURE';
export const UPDATE_CAR_SUB_MODEL_SUCCESS = 'UPDATE_CAR_SUB_MODEL_SUCCESS';
export const UPDATE_CAR_SUB_MODEL_FAILURE = 'UPDATE_CAR_SUB_MODEL_FAILURE';
export const DELETE_CAR_SUB_MODEL_SUCCESS = 'DELETE_CAR_SUB_MODEL_SUCCESS';
export const DELETE_CAR_SUB_MODEL_FAILURE = 'DELETE_CAR_SUB_MODEL_FAILURE';
export const FETCH_CAR_SUB_MODEL_PUBLIC_LIST_SUCCESS =
  'FETCH_CAR_SUB_MODEL_PUBLIC_LIST_SUCCESS';
export const FETCH_CAR_SUB_MODEL_PUBLIC_LIST_FAILURE =
  'FETCH_CAR_SUB_MODEL_PUBLIC_LIST_FAILURE';

// UsedCar
export const USED_CAR_INIT = 'USED_CAR_INIT';
export const FETCH_USED_CAR_LIST_SUCCESS = 'FETCH_USED_CAR_LIST_SUCCESS';
export const FETCH_USED_CAR_LIST_FAILURE = 'FETCH_USED_CAR_LIST_FAILURE';
export const FETCH_USED_CAR_SINGLE_SUCCESS = 'FETCH_USED_CAR_SINGLE_SUCCESS';
export const FETCH_USED_CAR_SINGLE_FAILURE = 'FETCH_USED_CAR_SINGLE_FAILURE';
export const CREATE_USED_CAR_SUCCESS = 'CREATE_USED_CAR_SUCCESS';
export const CREATE_USED_CAR_FAILURE = 'CREATE_USED_CAR_FAILURE';
export const UPDATE_USED_CAR_SUCCESS = 'UPDATE_USED_CAR_SUCCESS';
export const UPDATE_USED_CAR_FAILURE = 'UPDATE_USED_CAR_FAILURE';
export const DELETE_USED_CAR_SUCCESS = 'DELETE_USED_CAR_SUCCESS';
export const DELETE_USED_CAR_FAILURE = 'DELETE_USED_CAR_FAILURE';
export const USED_CAR_UPDATE_STATUS_FAILURE = 'USED_CAR_UPDATE_STATUS_FAILURE';
export const USED_CAR_UPDATE_STATUS_SUCCESS = 'USED_CAR_UPDATE_STATUS_SUCCESS';
export const USED_CAR_ENQUIRY_COUNT = 'used_car_enquiry_count';
export const GET_MERCHANT_USED_CAR = 'get_merchant_used_car';
export const GET_SEARCH_USED_CAR = 'get_search_used_car';

export const USED_CAR_SHORT_LIST_SAVE_SUCCESS =
  'USED_CAR_SHORT_LIST_SAVE_SUCCESS';
export const USED_CAR_SHORT_LIST_SAVE_FAILURE =
  'USED_CAR_SHORT_LIST_SAVE_FAILURE';

// Seller
export const SELLER_INIT = 'SELLER_LIST_INIT';
export const FETCH_SELLER_LIST_SUCCESS = 'FETCH_SELLER_LIST_SUCCESS';
export const FETCH_SELLER_LIST_FAILURE = 'FETCH_SELLER_LIST_FAILURE';
export const CREATE_SELLER_SUCCESS = 'CREATE_SELLER_SUCCESS';
export const CREATE_SELLER_FAILURE = 'CREATE_SELLER_FAILURE';
export const FETCH_SELLER_LIST_SINGLE_SUCCESS =
  'FETCH_SELLER_LIST_SINGLE_SUCCESS';
export const FETCH_SELLER_LIST_SINGLE_FAILURE =
  'FETCH_SELLER_LIST_SINGLE_FAILURE';
export const UPDATE_SELLER_SUCCESS = 'UPDATE_SELLER_SUCCESS';
export const UPDATE_SELLER_FAILURE = 'UPDATE_SELLER_FAILURE';
export const FETCH_SUGGESTED_USED_CAR_LIST_FAILURE =
  'FETCH_SUGGESTED_USED_CAR_LIST_FAILURE';
export const FETCH_SUGGESTED_USED_CAR_LIST_SUCCESS =
  'FETCH_SUGGESTED_USED_CAR_LIST_SUCCESS';

//sell your car
export const GET_INSTANT_OFFER_INIT = 'GET_INSTANT_OFFER_INIT';
export const GET_INSTANT_OFFER_SUCCESS = 'GET_INSTANT_OFFER_SUCCESS';
export const GET_INSTANT_OFFER_FAILURE = 'GET_INSTANT_OFFER_FAILURE';

//Payment
export const PAYMENT_INIT = 'PAYMENT_INIT';
export const PAYMENT_LIST_FETCHED = 'PAYMENT_LIST_FETCHED';
export const FETCH_PROMO_CODE_COUNT_LIST_FAILURE = 'FETCH_PROMO_CODE_COUNT_LIST_FAILURE';
export const FETCH_PROMO_CODE_COUNT_LIST_SUCCESS = 'FETCH_PROMO_CODE_COUNT_LIST_SUCCESS';
export const FETCH_ORDER_LIST_BY_PROMO_CODE_FAILURE = 'FETCH_ORDER_LIST_BY_PROMO_CODE_FAILURE';
export const FETCH_ORDER_LIST_BY_PROMO_CODE_SUCCESS = 'FETCH_ORDER_LIST_BY_PROMO_CODE_SUCCESS';

export const SITE_PAYMENT_INIT = 'SITE_PAYMENT_INIT';
export const FETCH_SITE_PAYMENT_LIST = 'FETCH_SITE_PAYMENT_LIST';
export const FETCH_SITE_PAYMENT_LIST_SUCCESS =
  'FETCH_SITE_PAYMENT_LIST_SUCCESS';
export const FETCH_SITE_PAYMENT_LIST_FAILURE =
  'FETCH_SITE_PAYMENT_LIST_FAILURE';
export const CREATE_SITE_PAYMENT_LIST_FAILURE =
  'CREATE_SITE_PAYMENT_LIST_FAILURE';
export const FETCH_PAYMENT_SINGLE_SUCCESS = 'FETCH_PAYMENT_SINGLE_SUCCESS';
export const FETCH_PAYMENT_SINGLE_FAILURE = 'FETCH_PAYMENT_SINGLE_FAILURE';

//Adds Ons
export const ADDS_ONS_INIT = 'ADDS_ONS_INIT';
export const FETCH_ADDS_ONS_LIST_SUCCESS = 'FETCH_ADDS_ONS_LIST_SUCCESS';
export const FETCH_ADDS_ONS_LIST_FAILURE = 'FETCH_ADDS_ONS_LIST_FAILURE';
export const FETCH_ADDS_ONS_SINGLE_SUCCESS = 'FETCH_ADDS_ONS_SINGLE_SUCCESS';
export const FETCH_ADDS_ONS_SINGLE_FAILURE = 'FETCH_ADDS_ONS_SINGLE_FAILURE';
export const UPDATE_ADDS_ONS_SUCCESS = 'UPDATE_ADDS_ONS_SUCCESS';
export const UPDATE_ADDS_ONS_FAILURE = 'UPDATE_ADDS_ONS_FAILURE';
export const PUBLIC_ADDS_ONS_SUCCESS = 'PUBLIC_ADDS_ONS_SUCCESS';
export const PUBLIC_ADDS_ONS_FAILURE = 'PUBLIC_ADDS_ONS_FAILURE';

export const SUBSCRIPTION_INIT = 'SUBSCRIPTION_INIT';
export const SUBSCRIPTION_CREATE_SUCCESS = 'SUBSCRIPTION_CREATE_SUCCESS';
export const SUBSCRIPTION_CREATE_FAILURE = 'SUBSCRIPTION_CREATE_FAILURE';

// ads Type

export const ADS_TYPE_INIT = 'ADS_TYPE_INIT';
export const FETCH_ADS_TYPE_LIST_SUCCESS = 'FETCH_ADS_TYPE_LIST_SUCCESS';
export const FETCH_ADS_TYPE_LIST_FAILURE = 'FETCH_ADS_TYPE_LIST_FAILURE';
export const FETCH_ADS_TYPE_SINGLE_SUCCESS = 'FETCH_ADS_TYPE_SINGLE_SUCCESS';
export const FETCH_ADS_TYPE_SINGLE_FAILURE = 'FETCH_ADS_TYPE_SINGLE_FAILURE';
export const UPDATE_ADS_TYPE_SUCCESS = 'UPDATE_ADS_TYPE_SUCCESS';
export const UPDATE_ADS_TYPE_FAILURE = 'UPDATE_ADS_TYPE_FAILURE';
export const PUBLIC_ADS_TYPE_FAILURE = 'PUBLIC_ADS_TYPE_FAILURE';
export const PUBLIC_ADS_TYPE_SUCCESS = 'PUBLIC_ADS_TYPE_SUCCESS';

// site settings
export const SITE_SETTING_INIT = 'SITE_SETTING_INIT';
export const FETCH_SITE_SETTING_SINGLE_SUCCESS =
  'FETCH_SITE_SETTING_SINGLE_SUCCESS';
export const FETCH_SITE_SETTING_SINGLE_FAILURE =
  'FETCH_SITE_SETTING_SINGLE_FAILURE';
export const UPDATE_SITE_SETTING_SUCCESS = 'UPDATE_SITE_SETTING_SUCCESS';
export const UPDATE_SITE_SETTING_FAILURE = 'UPDATE_SITE_SETTING_FAILURE';
// New Car
export const NEW_CAR_INIT = 'NEW_CAR_INIT';
export const FETCH_NEW_CAR_LIST_SUCCESS = 'FETCH_NEW_CAR_LIST_SUCCESS';
export const FETCH_NEW_CAR_LIST_FAILURE = 'FETCH_NEW_CAR_LIST_FAILURE';
export const CREATE_NEW_CAR_SINGLE_SUCCESS = 'CREATE_NEW_CAR_SINGLE_SUCCESS';
export const CREATE_NEW_CAR_SINGLE_FAILURE = 'CREATE_NEW_CAR_SINGLE_FAILURE';
export const FETCH_NEW_CAR_SINGLE_SUCCESS = 'FETCH_NEW_CAR_SINGLE_SUCCESS';
export const FETCH_NEW_CAR_SINGLE_FAILURE = 'FETCH_NEW_CAR_SINGLE_FAILURE';
export const UPDATE_NEW_CAR_SUCCESS = 'UPDATE_NEW_CAR_SUCCESS';
export const UPDATE_NEW_CAR_FAILURE = 'UPDATE_NEW_CAR_FAILURE';
export const FETCH_NEW_CAR_SEARCH_LIST_SUCCESS =
  'FETCH_NEW_CAR_SEARCH_LIST_SUCCESS';
export const FETCH_NEW_CAR_SEARCH_LIST_FAILURE =
  'FETCH_NEW_CAR_SEARCH_LIST_FAILURE';
export const FETCH_PUBLIC_NEW_CAR_SINGLE_SUCCESS =
  'FETCH_PUBLIC_NEW_CAR_SINGLE_SUCCESS';
export const FETCH_PUBLIC_NEW_CAR_SINGLE_FAILURE =
  'FETCH_PUBLIC_NEW_CAR_SINGLE_FAILURE';

// promo code
export const PROMO_CODE_INIT = 'PROMO_CODE_INIT';
export const CREATE_PROMO_CODE_SUCCESS = 'CREATE_PROMO_CODE_SUCCESS';
export const CREATE_PROMO_CODE_FAILURE = 'CREATE_PROMO_CODE_FAILURE';
export const FETCH_PROMO_CODE_SUCCESS = 'FETCH_PROMO_CODE_SUCCESS';
export const FETCH_PROMO_CODE_FAILURE = 'FETCH_PROMO_CODE_FAILURE';
export const FETCH_PROMO_CODE_SINGLE_SUCCESS =
  'FETCH_PROMO_CODE_SINGLE_SUCCESS';
export const FETCH_PROMO_CODE_SINGLE_FAILURE =
  'FETCH_PROMO_CODE_SINGLE_FAILURE';
export const UPDATE_PROMO_CODE_SUCCESS = 'UPDATE_PROMO_CODE_SUCCESS';
export const UPDATE_PROMO_CODE_FAILURE = 'UPDATE_PROMO_CODE_FAILURE';
export const CHECK_PROMO_CODE_VALIDATION_SUCCESS =
  'CHECK_PROMO_CODE_VALIDATION_SUCCESS';
export const CHECK_PROMO_CODE_VALIDATION_FAILURE =
  'CHECK_PROMO_CODE_VALIDATION_FAILURE';

// shortlisted
export const SHORTLISTED_INIT = 'SHORTLISTED_INIT';
export const FETCH_SHORTLISTED_SUCCESS = 'FETCH_SHORTLISTED_SUCCESS';
export const FETCH_SHORTLISTED_FAILURE = 'FETCH_SHORTLISTED_FAILURE';

export const USED_CAR_SEARCH_LIST_SUCCESS = 'USED_CAR_SEARCH_LIST_SUCCESS';
export const USED_CAR_SEARCH_LIST_FAILURE = 'USED_CAR_SEARCH_LIST_FAILURE';


//image 
export const IMAGE_INIT = 'IMAGE_INIT';
export const DELETE_CAR_IMAGE_SUCCESS = 'DELETE_CAR_IMAGE_SUCCESS';
export const DELETE_CAR_IMAGE_FAILURE = 'DELETE_CAR_IMAGE_FAILURE';

export const MULTIPLE_IMAGE_INIT = 'MULTIPLE_IMAGE_INIT';
export const CAR_MULTIPLE_IMAGE_SUCCESS = 'CAR_MULTIPLE_IMAGE_SUCCESS';
export const CAR_MULTIPLE_IMAGE_FAILURE = 'CAR_MULTIPLE_IMAGE_FAILURE';