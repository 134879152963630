import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchShortListedList } from '../../../store/actions';
import NewSearchCar from '../NewSearchedCarComponents/NewSearchCar';
import SearchCar from '../SearchedCarComponents/SearchCar';
import Loader from '../../common/Loader/Loader';
import {
  usedCarShortListSave,
  usedCarShortlistSaveSuccess,
} from '../../../store/actions/UsedCarAction';
import { toast } from 'react-toastify';

const TabCarData = ({
  fetchShortListedList,
  shortlistedList,
  loading,
  userData,
  usedCarShortlistSaveSuccess,
  usedCarShortListSave,
  success,
  error,
  carSuccess,
  carError,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabValue, setTabValue] = useState('used_car');
  const [showCars, setShowCars] = useState([]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchShortListedList(page, tabValue);
  }, [fetchShortListedList, tabValue, page]);
  useEffect(() => {
    usedCarShortlistSaveSuccess({});
    if (userData?.id) {
      fetchShortListedList('all', 'used_car');
    }
  }, [userData?.id, fetchShortListedList, usedCarShortlistSaveSuccess]);
  useEffect(() => {
    if (shortlistedList?.rows) {
      setShowCars((prevCars) => {
        if (page === 1) {
          return shortlistedList?.rows;
        } else {
          return [...prevCars, ...shortlistedList?.rows];
        }
      });
    }
  }, [shortlistedList, page]);

  const shortlistHandler = (id, shortType) => {
    const existsShortList =
      shortlistedList &&
      shortlistedList?.find((item) => item?.car_listing_id == id);
    if (!existsShortList?.car_listing_id) {
      const shortObj = {
        car_listing_id: id,
        type: 'used_car',
      };
      usedCarShortListSave(shortObj, 'save');
    } else {
      const shortObj = {
        id: existsShortList?.id,
        type: 'used_car',
      };
      usedCarShortListSave(shortObj, 'delete');
    }
  };

  const loadmoreCarHandler = () => {
    setPage((prev) => prev + 1);
  };
  useEffect(() => {
    toast.success(carSuccess, {
      position: toast.POSITION.TOP_RIGHT,
      onOpen: () => {
        usedCarShortlistSaveSuccess({});
        fetchShortListedList('all', 'used_car');
      },
      onClose: () => {},
    });
  }, [carError, carSuccess]);
  const steps = [
    {
      value: 'used_car',
      label: 'Used Cars',
      content: (
        <div>
          {showCars?.length > 0 ? (
            showCars?.map(
              (car) =>
                car.used_car_listing && (
                  <SearchCar
                    key={car.id}
                    userData={userData}
                    car={car.used_car_listing}
                    shortlistedListData={showCars}
                    shortlistHandler={shortlistHandler}
                  />
                )
            )
          ) : (
            <div className="text-2xl m-auto container text-center font-semibold my-4">
              No Shortlisted Cars Available
            </div>
          )}
        </div>
      ),
    },
    {
      value: 'new_car',
      label: 'New Cars',
      content: (
        <div>
          {shortlistedList?.rows?.length > 0 ? (
            shortlistedList.rows.map((car) =>
              car?.new_car ? (
                <NewSearchCar key={car.id} car={car.new_car} />
              ) : null
            )
          ) : (
            <div className="text-2xl m-auto container text-center font-semibold my-4">
              No Shortlisted Cars Available
            </div>
          )}
        </div>
      ),
    },
  ];

  const renderButton = (index, label, value) => {
    const isActive = index === activeTab;
    return (
      <button
        key={index}
        onClick={() => {
          setActiveTab(index);
          setTabValue(value);
          console.log(value);
        }}
        className={`py-2 px-2 mx-4 lg:mx-2 w-[170px] cursor-pointer rounded ${
          isActive
            ? 'bg-[#0534FF] text-white '
            : 'bg-[#fff] text-[#4096FF] border border-[#00000026]'
        }`}
      >
        {label}
      </button>
    );
  };

  return (
    <div>
      <div className="tabs">
        <div className="tab-buttons flex ">
          {steps.map((step, index) =>
            renderButton(index, step.label, step.value)
          )}
        </div>
        <div>
          {loading ? (
            <div className="my-10">
              <Loader />
            </div>
          ) : (
            <div className="tab-content">{steps[activeTab]?.content}</div>
          )}
        </div>
        <div className="flex justify-center my-8">
          {showCars?.length >= shortlistedList?.count ? (
            ''
          ) : (
            <button
              onClick={loadmoreCarHandler}
              className="bg-[#0958D9] text-white text-[20px] h-[46px] w-[230px] rounded"
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carLoading: state.usedCar.loading,
    carError: state.usedCar.error,
    carSuccess: state.usedCar.success,
    loading: state.shortlisted.loading,
    error: state.shortlisted.error,
    success: state.shortlisted.success,
    shortlistedList: state.shortlisted.shortlistedList,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShortListedList: (page, type) =>
      dispatch(fetchShortListedList(page, type)),
    usedCarShortListSave: (shortData, shortType) =>
      dispatch(usedCarShortListSave(shortData, shortType)),
    usedCarShortlistSaveSuccess: ({}) =>
      dispatch(usedCarShortlistSaveSuccess({})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabCarData);
