import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Layout from '../../../components/admin/Layout/Base/Layout';
import AddsOnsForm from './AddsOnsForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import AddsOnsTable from '../../../components/admin/Table/Table';
import { fetchAddsOnsList, fetchAddsOnsSingle,  updateAddsOns,  } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';

 
const AddsOns = (props) => {
	const {
		loading,
		error,
		success,
		addsOnsList,
		fetchAddsOnsList,
		deleteAddsOns,
		fetchAddsOnsSingle,
		singleAddsOns,
		updateAddsOns
	} = props;
// console.log('singleAddsOns >>>',    singleAddsOns)
	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1)

	useEffect(() => {
		setShowTable(true)
	}, [addsOnsList])

	useEffect(() => {
		fetchAddsOnsList(page);
	}, [fetchAddsOnsList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchAddsOnsList(page);
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
			setEditingId(id)
			setShowTable(false)
			setButtonLabel('List')
			fetchAddsOnsSingle(id)
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null)
	};

	const onPageChange = (page) => {
		setPage(page)
	};

	const onSubmit = (values) => {
		updateAddsOns(editingId, values)
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row?.name,
			sortable: true
		},
		
		{
			name: 'Price',
			selector: row => row?.price,
			sortable: true
		},
		
		{
			name: 'Status',
			selector: row => row.status == 1 ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					// showDeleteButton={true}
					// onDelete={id => handleEditDelete(id, 'delete')}
				/>
			),
			sortable: false
		},
	];

	const renderContent = () => {
		if (showTable && !loading) {
			return (
				<AddsOnsTable
				defaultPage={page}
					columns={columns} data={addsOnsList.rows == undefined ? [] : addsOnsList.rows} totalCount={addsOnsList.count} handlePageChange={onPageChange}
				/>
			);
		}

		if (editingId && !loading) {
			return <AddsOnsForm onSubmit={onSubmit} initialValues={singleAddsOns} onCancel={handleButtonClick} />;
		}

		return <Loader />
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Adds Ons" 
						// buttonText={buttonLabel} onButtonClick={handleButtonClick}
						 />
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.addsOns.loading,
		error: state.addsOns.error,
		success: state.addsOns.success,
		addsOnsList: state.addsOns.addsOnsList,
		singleAddsOns: state.addsOns.singleAddsOns
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAddsOnsList: (page) => dispatch(fetchAddsOnsList(page)),
		fetchAddsOnsSingle: (id) => dispatch(fetchAddsOnsSingle(id)),
		updateAddsOns: (id, data) => dispatch(updateAddsOns(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddsOns);
