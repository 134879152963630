import React from 'react';
import advertising from '../../../assets/images/web/advertising.png';
import audit from '../../../assets/images/web/audit.png';
import customerservice from '../../../assets/images/web/customer-service.png';
import buyer from '../../../assets/images/web/buyer.png';
import './WhyChooseUs.css';
const WhyChooseUs = () => {
  return (
    <div className=" m-auto">
      <p className="font-bold text-2xl my-2 text-center p-6">Why Choose Us?</p>
      {/* <div className="px-4">
        <div className="flex justify-center items-center gap-7 my-10">
          <img src={advertising} alt="" className="w-13 ml-5 md:ml-0 lg:ml-0" />
          <p className="font-bold    md:w-35 ml-2 md:ml-0 lg:ml-0 ">
            List till Buyer Found
          </p>
        </div>
        <div className="flex justify-center items-center gap-7 my-10 ">
          <img src={audit} alt="" className="w-16  md:ml-0 lg:ml-0" />
          <p className="font-bold   md:w-35 ">Free Paperwork Handling</p>
        </div>
        <div className="flex justify-center items-center gap-7 my-10">
          <img
            src={customerservice}
            alt=""
            className="w-13 ml-10 md:ml-0 lg:ml-0"
          />
          <p className="font-bold   md:w-35  ">
            Personalized <br />
            Customer Service
          </p>
        </div>
        <div className="flex justify-center items-center gap-7 my-10">
          <img src={buyer} alt="" className="w-13 ml-10 md:ml-0 lg:ml-0" />
          <p className="font-bold ml-1  md:w-35 ">Ready Pool of Car Buyers</p>
        </div>
      </div> */}

      <div className="grid grid-cols-3 gap-8  p-6">
        <div className="md:-mt-5 ms-auto ">
          <img src={advertising} alt="" className="w-13 my-8 " />
          <img src={audit} alt="" className="w-13 my-8 " />
          <img src={customerservice} alt="" className="w-13 my-8 " />
          <img src={buyer} alt="" className="w-13 my-8 " />
        </div>
        <div className="me-auto col-span-2">
          <p className="font-bold   my-12 md:my-4 lg:mb-10 md:w-35 w-full">
            List till Buyer Found
          </p>
          <p className="font-bold mt-15 mb-7 md:my-8 md:w-35 w-full">
            Free Paperwork Handling
          </p>{' '}
          <p className="font-bold mt-15 sm:mt-12 mb-7 md:my-8 md:w-35 w-full Service">
            Personalized Customer Service
          </p>
          <p className="font-bold mt-15 sm:mt-12 mb-7 md:my-8 md:w-35 w-full Service">
            Ready Pool of Car Buyers
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
