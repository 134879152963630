import React from 'react';
import { GoQuestion } from "react-icons/go";
import { IoArrowBackOutline, IoArrowForward  } from "react-icons/io5";

const LoanCalculator = () => { 
  return (
    <div className='w-full lg:w-4/6 p-4 border border-solid border-[#D9D9D9] lg:border-white '>
        <h4 className='text-[20px] font-bold w-fit'>Loan Calculator</h4>

        <div className='mt-7'>
            <div className='flex flex-row justify-between items-center flex-wrap md:flex-nowrap lg:flex-nowrap'>
                <div className='w-full md:w-1/2 lg:w-1/2'>
                    <label className='block text-[12px]' htmlFor="cars">Select Sub Model</label>
                    <select className='block border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2' name="cars" id="cars">
                        <option className='' value="volvo">Porsche Panamera PDK 2.9 (A)</option>
                    </select>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/2 ms-0 md:ms-4 lg:ms-4  mt-4 lg:mt-0 md:mt-0'>
                <div className='flex flex-row justify-between items-center'>
                        <label className='block text-[12px]' htmlFor="cars">Loan Period </label>
                        <GoQuestion />
                    </div>
                    <select className='block border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2' name="cars" id="cars">
                        <option className='' value="volvo">Porsche Panamera PDK 2.9 (A)</option>
                    </select>
                </div>
            </div>

            <div className='flex flex-row justify-between items-center mt-7 flex-wrap md:flex-nowrap lg:flex-nowrap'>
                <div className='w-full md:w-1/2 lg:w-1/2'>
                    <label className='block text-[12px]' htmlFor="cars">Select Sub Model</label>
                    
                    <input className='block border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2' placeholder='$400,188 (w/o COE)'/>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/2 ms-0 md:ms-4 lg:ms-4  mt-4 lg:mt-0 md:mt-0'>
                    <div className='flex flex-row justify-between items-center'>
                        <label className='block text-[12px]' htmlFor="cars">Interest Rate </label>
                        <GoQuestion />
                    </div>
                    <input className='block border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2' placeholder='$400,188 (w/o COE)'/>
                </div>
            </div>

            <div className=' mt-7'>
            <p className='text-[12px] '>How much do you want to borrow?</p>
                
                <div className='flex flex-row justify-center items-center mt-7'>
                    <div className='border border-solid border-[#e8e7e6] w-[155px] h-[40px] rounded flex justify-center items-center rounded'>
                        <p className=''>$21,750</p>
                    </div>
                </div>

                <div className='flex flex-row justify-center items-center mt-2'>
                    <div className='bg-[#4871D9] w-[37px] h-[37px] lg:w-[46px] lg:h-[46px] rounded-full flex justify-center items-center pointer'>
                        <IoArrowBackOutline size={20} color='white'/>
                    </div>
                    <div className='w-4/5 mx-2 lg:mx-4 md:mx-4'>
                        <input className='w-full' type='range' onChange={(e) => console.log('range data', e.target.value)} />
                        
                    </div>
                    <div className='bg-[#4871D9] w-[37px] h-[37px]  lg:w-[46px] lg:h-[46px]  rounded-full flex justify-center items-center cursor-pointer'>
                        <IoArrowForward size={20} color='white' />
                    </div>
                </div>
                <div className='w-4/5 lg:w-full mx-auto px-0 lg:px-17   flex flex-row justify-between items-center'>
                    <p className='text-[12px]'>30%</p>
                    <p className='text-[12px]'>100%</p>
                </div>
            </div>
 
            
            <div className='flex justify-start items-center mt-7 '>
                <p className='text-[12px] text-[#242D3D]'>Estimated Monthly Instalment</p>
                <div className='w-[185px] h-[60px] flex justify-center items-center bg-[#ebf7fd] rounded-lg ms-11'>
                    <p className='text-[28px] font-bold'>$1,460</p>
                </div>
            </div>
        </div>
    </div>
  );
};

export default LoanCalculator;
