import React, { useEffect, useState } from 'react';
import FollowOnSocialMedia from '../../../components/site/FollowOnSocialMedia/FollowOnSocialMedia';
import Layout from '../../../components/site/Layout/Base/Layout';
import { fetchBrandList } from '../../../store/actions';
import { connect } from 'react-redux';
import BrandBrowse from '../../../components/site/CarsComponents/BrandBrowse';
// import Logo from '../../../assets/images/admin/admin_logo.png';
import './Brands.css';
const Brands = (props) => {
  const { brandList, fetchBrandList } = props;
  // const [searchParam, setSearchParam] = useState({
  //   status: 1,
  // });

  const fetchData = async () => {
    await Promise.all([fetchBrandList('all', true, true)]);
  };
  useEffect(() => {
    fetchData();
  }, [true]);
  console.log(brandList);
  return (
    <Layout>
      <div className="container mx-auto">
        <p className="text-4xl font-bold text-center my-10">Brands</p>
        <div className="text-start grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 sm:grid-cols-2  grid-cols-1 my-15 p-3">
          {brandList.map((brand, i) => (
            <BrandBrowse brand={brand} key={i} allBrands />
          ))}
        </div>
      </div>
      <FollowOnSocialMedia />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    brandList: state.brand.brandList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBrandList: (page, isProcess, isPublic) =>
      dispatch(fetchBrandList(page, isProcess, isPublic)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
