import React from 'react';
import FormField from '../../../common/FormFileds/Admin/FormField';

const Performance = (props) => {
  const { formik } = props;

  return (
    <div className="p-6.5">
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Power (bhp)"
            id="power"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Engine Power (bhp)"
            id="engine_power"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Motor Power (bhp)"
            id="motor_power"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Torque (Nm)"
            id="torque"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Acceleration (0-100km/h)"
            placeholder=" "
            id="acceleration"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Top Speed (km/h)"
            id="top_speed"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>

      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Fuel Consumption (km/L)"
            id="fuel_consumption"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            placeholder=" "
            label="CO2 Emission (g/km)"
            id="co2_emission"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Battery Capacity (kWh)"
            id="battery_capacity"
            placeholder=" "
            formik={formik}
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="Drive Range (km)"
            id="drive_range"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            placeholder=" "
            label="Energy Consumption (km/kWh)"
            id="energy_consumption"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="DC Charging Rate (Max)"
            id="dc_charging_rate_max"
            placeholder=" "
            formik={formik}
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="DC Charging Rate (Total)"
            id="dc_charging_rate_total"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            placeholder=" "
            label="AC Charging Rate (Max)"
            id="ac_charging_rate_max"
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="number"
            label="AC Charging Time (Total)"
            id="ac_charging_rate_total"
            placeholder=" "
            formik={formik}
          />
        </div>
      </div>
    </div>
  );
};

export default Performance;
