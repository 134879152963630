import React, { useState, useEffect, useRef } from 'react';
import FileUploadSvgIcon from '../../../admin/Icon/FileUploadSvgIcon';
import { RiDeleteBin6Line } from 'react-icons/ri';
 
const MultipleImgUpload = ({ formik, id, multiple = false, brand, isDisabled = false }) => {
  const [preview, setPreview] = useState([]);
  const [storeImg, setSoreImg] = useState([]);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [draggedCount, setDraggedCount] = useState(0);

  useEffect(() => {
    if(storeImg?.length > 0 ){
      formik.setFieldValue('image', Array.from(storeImg));
    }
  }, [storeImg?.length, draggedCount]);

  const handlePreview = (previewfiles) => {
    if(preview?.length > 0){

      previewfiles?.forEach((file, index) => {
          const imgId = preview?.length + index
          const reader = new FileReader();
          reader.onload = () => {
            // setPreview((prevImages) => [...prevImages, {imgUrl: reader.result, id: imgId, file}]);
            setPreview((prevImages) => [...prevImages, reader.result]);
          };
          reader.readAsDataURL(file);
        });
    }
    else{
      previewfiles?.forEach((file, index) => {
          const reader = new FileReader();
          reader.onload = () => {
            // setPreview((prevImages) => [...prevImages, {imgUrl: reader.result, id: index, file}]);
            setPreview((prevImages) => [...prevImages, reader.result]);

          };
          reader.readAsDataURL(file);
        });
    }
  }

  const handleFileChange = (e) => {
    const files = e.target.files;

    const previewfiles = Array.from(e.target.files);

    if (files && files.length > 0) {
      handlePreview(previewfiles)
      setSoreImg((prevImages) => [...prevImages, ...files]);
    }

  };

  const imgDeleteHandler = (id) => {
    
    const filterStoreImg = storeImg?.filter((item, index) => index !== id)
    const previewFilter = preview?.filter((item, index) => index !== id)

    setPreview(previewFilter)
    setSoreImg(filterStoreImg)
  }

  const handleDrag = () => {
    let previwImgArray = [...preview];
    const draggedItemContent = previwImgArray.splice(dragItem.current, 1)[0];
    previwImgArray.splice(dragOverItem.current, 0, draggedItemContent);


    let storeImgArray = [...storeImg];
    const draggedItemContent2 = storeImgArray.splice(dragItem.current, 1)[0];
    storeImgArray.splice(dragOverItem.current, 0, draggedItemContent2);
    
    
    dragItem.current = null;
    dragOverItem.current = null;

    setPreview(previwImgArray)
    setSoreImg(storeImgArray)
    setTimeout(() => setDraggedCount((prev) => prev + 1), 200)
    
  }

  return (
   <div className='w-full'>
       <div
          id="FileUpload"
          className="relative mb-15 block w-full cursor-pointer border-2 border-dashed border-stroke bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
        >
      <input
        type="file"
        id={id}
        name={id}
        // value={formik.values[id]}
        disabled={isDisabled ? true : false}
        onBlur={formik.handleBlur}
        accept="image/*"
        className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
        onChange={handleFileChange}
        multiple={multiple}
      />
      <div className="flex flex-col items-center justify-center space-y-3">
       
       <span className="flex h-10 w-10 items-center justify-center border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
            <FileUploadSvgIcon />
          </span>

        {
          !isDisabled && <p>
          <span className="text-primary">Click to upload</span> or drag and drop
        </p>
        }
        {
          !isDisabled && <><p className="my-1">PNG, JPG, JPEG </p>
          {brand && <p className="">Image size should be 105 x 105 pixels.</p>}
          {formik.touched.image && formik.errors.image && (
            <p className="text-sm text-[#F87171] mt-1">{formik.errors.image}</p>
          )}</>
        }
        
      </div>
      
      </div>
      
      {
      (  preview?.length > 0 && Array.isArray(preview) && !brand )&&
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            {
              preview?.map((item, index) => (
                <div
                draggable
                onDragStart={(e) => (dragItem.current = index)}
                onDragEnter={(e) => (dragOverItem.current = index)}
                onDragEnd={handleDrag}
                onDragOver={(e) => e.preventDefault()}

                className="w-full h-40 flex items-center justify-center shadow-4 relative cursor-pointer group">
                <>
                    <RiDeleteBin6Line
                        onClick={() => imgDeleteHandler(index)}
                        size={30}
                        className={`absolute m-auto left-0 right-0 top-0 bottom-0   z-50 invisible group-hover:visible cursor-pointer duration-200 transition-all`}
                    />
                    <img
                        src={item}
                        alt={`Cover `}
                        className="grid_img cursor-pointer group-hover:opacity-50 bg-blend-luminosity transition-all duration-50  z-10  "
                        // onError={handleImageError}
                    />
                </>

                </div>
              ))
            }
        </div>
      }
   </div>
  );
};

export default MultipleImgUpload;
