import React, { useState } from 'react'
import PaymentList from '../Payment/PaymentList';
import EditPayment from '../Payment/EditPayment';
import { paymentStatusUpdate } from '../../../store/actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const NewCarPayment = (props) => {
    const {paymentStatusUpdate, singleNewCar} = props
    const [showEdit, setShowEdit] = useState(false);
    const [editItem, setEditItem] = useState({})
	const [paymentStatus, setPaymentStatus] = useState('')
    const [statusErr, setStatusErr] = useState('');
	const [isLoading, setIsLoading] = useState(false);

    const paymentEditHandler = (item) => {
        setEditItem(item)
        setShowEdit(true)
    }
    const cancelHandler = () => {
        setShowEdit(false)
    }
    const statusSaveHandler = async () => {
		if(paymentStatus){
			setIsLoading(true)
			const paymentStatusObj = {
				status: paymentStatus
			}
			paymentStatusUpdate(editItem?.id, paymentStatusObj);
			setTimeout(() => {
				toast.success('success', {
					position: toast.POSITION.TOP_RIGHT,
					onOpen: () => {
					  setIsLoading(false)
					  cancelHandler()
					},
					onClose: () => {
					},
				  });
			}, 1000)
		}
		else{
			setStatusErr('Select status')
		}
	}

  return (
    <div>
        {
            showEdit ?  <EditPayment isLoading={isLoading} setStatusErr={setStatusErr} statusErr={statusErr} setPaymentStatus={setPaymentStatus}  statusSaveHandler={statusSaveHandler} editItem={editItem} cancelHandler={cancelHandler}/> :  <PaymentList from="newcar" paymentsData={singleNewCar?.order_data} paymentEditHandler={paymentEditHandler} viewBtnShow={false} />
        }
       
    </div>
  )
}


const mapStateToProps = (state) => {
	return {
        // paymentList: state.payment.paymentList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
        paymentStatusUpdate: (paymentId, paymentStatus) => dispatch(paymentStatusUpdate(paymentId, paymentStatus)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCarPayment);
