import React from 'react';
import InsuranceImg from '../../../assets/images/web/InsuranceImg.png'

const InterestTable = () => {

    const tableData = [
        {bankName: 'OCBC'},
        {bankName: 'OCBC'},
        {bankName: 'OCBC'},
        {bankName: 'Maybank'},
        {bankName: 'Tokyo Century Leasing'},
        {bankName: 'Hong Leong Finance'},

    ]
  return (
    <div className='w-full lg:w-1/2 lg:ms-2  ms-0  mt-4 lg:mt-0 md:mt-0'> 
        <table className="table-auto border-collapse border border-slate-400 w-full ...">
            <thead>
                <tr className='text-[12px]'>
                <th className="border border-slate-300 p-2 ...">Car Loan Interest Rate</th>
                <th className="border border-slate-300 p-2 ...">New Cars</th>
                <th className="border border-slate-300 p-2 ...">Used Cars</th>
                <th className="border border-slate-300 p-2 ...">Cars with renewed COE</th>
                <th className="border border-slate-300 p-2 ...">Used Commercial Vehicles</th>
                </tr>
            </thead>
            <tbody>
               {
                tableData.map((item, index) => (
                    <tr className={`${ index % 2 === 0 ? 'bg-[#f2fafe]' : 'bg-white'} text-[10px]`} key={index}>
                    <td className="border border-slate-300 p-2 ...">{item?.bankName}</td>
                    <td className="border border-slate-300 p-2 text-center ...">2.78%</td>
                    <td className="border border-slate-300 p-2 text-center ...">2.78%</td>
                    <td className="border border-slate-300 p-2 text-center ...">2.98%</td>
                    <td className="border border-slate-300 p-2 text-center ...">N/A</td>
                    </tr>
                ))
               }
               
            </tbody>
        </table>
        <div className='w-full'>
             <img src={InsuranceImg} className='w-full' />
        </div>
    </div>
  );
};

export default InterestTable;
