import { combineReducers } from 'redux';

// reducers
import authReducer from './AuthReducer';
import brandReducer from './BrandReducer';
import vehicleTypeReducer from './VehicleTypeReducer';
import colorReducer from './ColorReducer';
import roleReducer from './RoleReducer';
import adminReducer from './AdminReducer';
import CustomerReducer from './CustomerReducer';
import MerchantReducer from './MerchantReducer';
import CountryReducer from './CountryReducer';
import CityReducer from './CityReducer';
import CarCategoryReducer from './CarCategoryReducer';
import FuelTypeReducer from './FuelTypeReducer';
import SettingReducer from './SettingReducer';
import CarModelReducer from './CarModelReducer';
import CarSubModelReducer from './CarSubModelReducer';
import UsedCarReducer from './UsedCarReducer';
import SellerReducer from './SellerReducer';
import paymentReducer from './PaymentReducer';
import sitePaymentReducer from './SitePaymentReducer';
import addsOnsReducer from './AddsOnsReducers';
import SubscriptionReducer from './SubscriptionReducer';
import AdsTypeReducer from './AdsTypeReducer';
import SiteSettingReducer from './SiteSettingReducer';
import newCarReducer from './NewCarsReducers';
import PromoReducer from './PromoCodeReducer';
import ShortlistedReducer from './ShortlistedReducer';

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  brand: brandReducer,
  vehicleType: vehicleTypeReducer,
  color: colorReducer,
  role: roleReducer,
  adminUser: adminReducer,
  customer: CustomerReducer,
  merchant: MerchantReducer,
  country: CountryReducer,
  city: CityReducer,
  carCategory: CarCategoryReducer,
  fuelType: FuelTypeReducer,
  setting: SettingReducer,
  carModel: CarModelReducer,
  carSubModel: CarSubModelReducer,
  usedCar: UsedCarReducer,
  seller: SellerReducer,
  payment: paymentReducer,
  sitePayment: sitePaymentReducer,
  addsOns: addsOnsReducer,
  adsType: AdsTypeReducer,
  subscription: SubscriptionReducer,
  siteSetting: SiteSettingReducer,
  newcars: newCarReducer,
  promoCodes: PromoReducer,
  shortlisted: ShortlistedReducer,
});

export default rootReducer;
