// BoxComponent.js

import React from 'react';
import './Box.css';

const Box = props => {
	return (
		<div className="w-[170px] md:w-[230px] h-[140px] flex flex-column justify-center items-center border border-solid border-[#e4e4e4] rounded mt-7 lg:mt-0 md:mt-0 cursor-pointer" onClick={props.userTypeHandler}>
			<div className="w-full">
				<img src={props.imageSrc} alt={props.userType} className="box-image"/>
				<p className="box-text">{props.userType}</p>
			</div>
		</div>
	);
};

export default Box;
