import BaseController from './BaseController';
import { endpoint } from './EndPoint';
export default class SellerController extends BaseController {
	static async list(page, id) {
		try {

			
			const pageQueryParam = page ? `&page=${page}` : '';
			return await this.axiosGet(
				`${endpoint.SELLER_LIST}?merchant_id=${id}${pageQueryParam}`,
				{
					headers: this.getHeaders(),
				}
			);
		} catch (error) {
			return error;
		}
	}
	static async single(userId) {
		try {
			return await this.axiosGet(`${endpoint.SELLER_SINGLE}/${userId}`, {
				headers: this.getHeaders(),
			});
		} catch (error) {
			return error;
		}
	}
	static async create(sellerData) {
		try {
			return await this.axiosPost(endpoint.CREATE_SELLER, sellerData, {
				headers: this.getHeaders(),
			});
		} catch (error) {
			return error;
		}
	}

	static async update(userId, updatedUserData) {
		try {
			return await this.axiosPost(
				`${endpoint.UPDATE_SELLER}/${userId}`,
				updatedUserData,
				{
					headers: this.getHeaders(),
				}
			);
		} catch (error) {
			return error;
		}
	}
}
