import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class CarModelController extends BaseController {
	static async list(page, isPublic) {
		try {
			const pageQueryParam = page ? `?page=${page}` : '';
			let path = isPublic ? endpoint.CAR_MODEL_LIST_PUBLIC_LIST : endpoint.CAR_MODEL_LIST
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(carModelId) {
		try {
			return await this.axiosGet(`${endpoint.CAR_MODEL_SINGLE}/${carModelId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(carModelData) {
		try {
			return await this.axiosPost(endpoint.CAR_MODEL_CREATE, carModelData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(carModelId, updatedCarModelData) {
		try {
			return await this.axiosPost(`${endpoint.CAR_MODEL_UPDATE}/${carModelId}`, updatedCarModelData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async delete(carModelId) {
		try {
			return await this.axiosGet(`${endpoint.CAR_MODEL_DELETE}/${carModelId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
