import * as actionTypes from '../actions/actionTypes';

const initialState = {
	fuelTypeList: [],
	singleFuelType: null,
	loading: false,
	error: null,
	success: null,
};

const fuelTypeReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FUEL_TYPE_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_FUEL_TYPE_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				fuelTypeList: action.payload,
				error: null,
			};
		case actionTypes.FETCH_FUEL_TYPE_LIST_FAILURE:
		case actionTypes.FETCH_FUEL_TYPE_SINGLE_FAILURE:
		case actionTypes.CREATE_FUEL_TYPE_FAILURE:
		case actionTypes.UPDATE_FUEL_TYPE_FAILURE:
		case actionTypes.DELETE_FUEL_TYPE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_FUEL_TYPE_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleFuelType: action.payload,
				error: null,
			};
		case actionTypes.CREATE_FUEL_TYPE_SUCCESS:
			return {
				...state,
				loading: false,
				fuelTypeList: state.fuelTypeList,
				error: null,
				success: action.payload,
			};
		case actionTypes.UPDATE_FUEL_TYPE_SUCCESS:
		case actionTypes.DELETE_FUEL_TYPE_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		default:
			return state;
	}
};

export default fuelTypeReducer;
