import React, { useEffect, useState } from 'react';
import Layout from '../../../components/site/Layout/Base/Layout';
import ProductHeaderSlider from '../../../components/site/ProductComponents/ProductHeaderSlider';
import BackAndShareBar from '../../../components/site/ProductComponents/BackAndShareBar';
import ProductPriceAndContactInfo from '../../../components/site/ProductComponents/ProductPriceAndContactInfo';
import SubmodelTable from '../../../components/site/ProductComponents/SubmodelTable';
import LoanCalculator from '../../../components/site/ProductComponents/LoanCalculator';
import InterestTable from '../../../components/site/ProductComponents/InterestTable';
import MoreAndNewCar from '../../../components/site/ProductComponents/MoreAndNewCar';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { fetchPublicNewCarSingle } from '../../../store/actions';
import { useLocation } from 'react-router-dom';

const NewCarProduct = (props) => {
	const { publicSingleNewCar, fetchPublicNewCarSingle } = props;
	const [imageList, setImageList] = useState([])

	const { id } = useParams();
	const cars = [
		{
			id: 78,
			price: 2500,
			is_feature: 0,
			car_model: {
				name: 'Toyota Prius ',
			},
			user: {
				id: 2,
				email: 'jane@example.com',
				image:
					'https://carincstorage.blob.core.windows.net/images/2024-01-31//drone.jpg',
				user_details: {
					first_name: 'jane_smith',
					phone: '987-654-3210',
				},
				user_company: null,
			},
			car_listing_images: [
				{
					image:
						'https://zuorder.blob.core.windows.net/images/2024-02-23//image%2022.png',
				},
			],
		},
		{
			id: 65,
			price: 0,
			is_feature: 0,
			car_model: {
				name: 'Toyota Vios',
			},
			user: {
				id: 2,
				email: 'jane@example.com',
				image:
					'https://carincstorage.blob.core.windows.net/images/2024-01-31//drone.jpg',
				user_details: {
					first_name: 'jane_smith',
					phone: '987-654-3210',
				},
				user_company: null,
			},
			car_listing_images: [
				{
					image:
						'https://zuorder.blob.core.windows.net/images/2024-02-17//newCar.png',
				},
			],
		},
		{
			id: 48,
			price: 0,
			is_feature: 0,
			car_model: null,
			user: {
				id: 2,
				email: 'jane@example.com',
				image:
					'https://carincstorage.blob.core.windows.net/images/2024-01-31//drone.jpg',
				user_details: {
					first_name: 'jane_smith',
					phone: '987-654-3210',
				},
				user_company: null,
			},
			car_listing_images: [],
		},
		{
			id: 65,
			price: 0,
			is_feature: 0,
			car_model: {
				name: 'Toyota Vios',
			},
			user: {
				id: 2,
				email: 'jane@example.com',
				image:
					'https://carincstorage.blob.core.windows.net/images/2024-01-31//drone.jpg',
				user_details: {
					first_name: 'jane_smith',
					phone: '987-654-3210',
				},
				user_company: null,
			},
			car_listing_images: [
				{
					image:
						'https://zuorder.blob.core.windows.net/images/2024-02-17//newCar.png',
				},
			],
		},
		{
			id: 48,
			price: 0,
			is_feature: 0,
			car_model: null,
			user: {
				id: 2,
				email: 'jane@example.com',
				image:
					'https://carincstorage.blob.core.windows.net/images/2024-01-31//drone.jpg',
				user_details: {
					first_name: 'jane_smith',
					phone: '987-654-3210',
				},
				user_company: null,
			},
			car_listing_images: [],
		},
	];

	useEffect(() => {
		fetchPublicNewCarSingle(id);
	}, [id]);



	useEffect(() => {
		if (publicSingleNewCar !== null && publicSingleNewCar !== undefined && publicSingleNewCar?.images !== undefined) {
			setImageList(JSON.parse(publicSingleNewCar?.images))
		}
	}, [publicSingleNewCar])


	return (
		<Layout>
			<BackAndShareBar />
			<div className="lg:container lg:mx-auto md:container md:mx-auto  lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col px-0 md:px-4 lg:px-4">
				<ProductHeaderSlider
					carImgList={imageList}
				/>
				<ProductPriceAndContactInfo
					carDetails={publicSingleNewCar}
					from="newCar"
				/>
			</div>
			<SubmodelTable publicSingleNewCar={publicSingleNewCar} />

			<div className="lg:container lg:mx-auto md:container md:mx-auto   px-2 md:px-4 lg:px-4 mt-4">
				<div className="lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col  border border-solid border-[#D9D9D9]">
					<LoanCalculator />
					<InterestTable />
				</div>
			</div>

			<MoreAndNewCar moreCarsData={cars} suggestedUsedCar={cars} />
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.usedCar.loading,
		error: state.usedCar.error,
		success: state.usedCar.success,
		publicSingleNewCar: state.newcars.publicSingleNewCar,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchPublicNewCarSingle: (id) => dispatch(fetchPublicNewCarSingle(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCarProduct);
