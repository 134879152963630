import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AdminForm from '../../AdminForm/AdminForm';
import FormField from '../../../common/FormFileds/Admin/FormField';

const Exterior = (props) => {
  const { formik } = props;
  return (
    <div className="p-6.5">
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Head Lights"
            id="head_light"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Auto Head Lights"
            id="is_auto_head_light"
            formik={formik}
            placeholder=" "
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="text"
            label="Daytime Running Lights"
            id="daytime_lights"
            formik={formik}
            placeholder=" "
          />
        </div>
      </div>
      <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Electric Folding Side Mirrors"
            id="electric_folding_side_mirror"
            placeholder=" "
            formik={formik}
          />
        </div>
        <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Rain Sensing Wipers"
            placeholder=" "
            id="rain_sensing_wipers"
            formik={formik}
          />
        </div>
      </div>
    </div>
  );
};

export default Exterior;
