import React from 'react';
import SuggestedCars from '../SearchedCarComponents/SuggestedCars';
import likeCar from '../../../assets/images/web/likeCar.png';
import likeLogo from '../../../assets/images/web/likeLogo.png';
import Slider from 'react-slick';

function LargeNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: 'block',
				background: '#1B283A50',
				height: '50px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '50px',
				position: 'absolute',
				right: '-8%',
				zIndex: 1,
				borderRadius: '100%',
			}}
			onClick={onClick}
		></div>
	);
}
function LargePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: 'block',
				background: '#1B283A50',
				height: '50px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '50px',
				position: 'absolute',
				left: '-7%',
				zIndex: 1,
				borderRadius: '100%',
			}}
			onClick={onClick}
		></div>
	);
}

const MoreAndNewCar = (props) => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <LargeNextArrow />,
		prevArrow: <LargePrevArrow />,
	};

	let moreCarsComponent = null;
	if (props.moreCarsData?.length > 0) {
		moreCarsComponent = (
			<div className="container mt-7 p-4 w-full mx-auto">
				<h4 className="text-[30px] font-bold text-center">
					More cars from this dealer
				</h4>
				<div className="hidden md:block">
					<div className="grid grid-cols-5 md:grid-cols-2 lg:grid-cols-5 gap-5 mt-7 mx-auto">
						{props.moreCarsData.map((item, index) => (
							<SuggestedCars data={item} key={index} />
						))}
					</div>
				</div>

				<div className="block md:hidden lg:hidden mt-7">
					<Slider {...settings}>
						{props.moreCarsData.map((item, index) => (
							<SuggestedCars data={item} key={index} />
						))}
					</Slider>
				</div>
			</div>
		);
	}

	let suggestedCarsComponent = null;
	if (props.suggestedUsedCar?.length > 0) {
		suggestedCarsComponent = (
			<div className="container mt-7 p-4 w-full mx-auto">
				<h4 className="text-[30px] font-bold text-center">
					You may like these new cars
				</h4>
				<div className="hidden md:block">
					<div className="grid grid-cols-5 md:grid-cols-2 lg:grid-cols-5 gap-5 mt-7 ">
						{props.suggestedUsedCar.map((item, index) => (
							<SuggestedCars key={index} data={item} />
						))}
					</div>
				</div>

				<div className="block md:hidden lg:hidden mt-7">
					<Slider {...settings}>
						{props.suggestedUsedCar.map((item, index) => (
							<SuggestedCars data={item} key={index} />
						))}
					</Slider>
				</div>
			</div>
		);
	}

	return (
		<div className="container w-full  mx-auto">
			{moreCarsComponent}
			{suggestedCarsComponent}
		</div>
	);
};

export default MoreAndNewCar;
