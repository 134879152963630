import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import { FaFilter } from 'react-icons/fa';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import Layout from '../../../components/admin/Layout/Base/Layout';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import UsedCarTable from '../../../components/admin/Table/Table';
import Loader from '../../../components/common/Loader/Loader';
import { activityOptions, featuredTypeOptions } from '../../../constants';
import {
	createUsedCar,
	fetchAdminSearchUsedCar,
	fetchBrandList,
	fetchCarCategoryList,
	fetchCarModelList,
	fetchCarSubModelList,
	fetchColorList,
	fetchFuelTypeList,
	fetchMerchantList,
	fetchUsedCarList,
	fetchUsedCarSingle,
	fetchVehicleTypeList,
	updateUsedCar,
	usedCarBoosting,
} from '../../../store/actions';
import CreateUsedCarForm from './CreateUsedCarForm';
import UsedCarForm from './UsedCarForm';
import { deleteImageCarSuccess } from '../../../store/actions/UsedCarAction';
// import { useSearchParams } from 'react-router-dom';


const UsedCar = (props) => {
	const {
		loading,
		error,
		success,
		createUsedCar,
		merchantList,
		usedCarList,
		fetchUsedCarList,
		fetchUsedCarSingle,
		singleUsedCar,
		updateUsedCar,
		fetchBrandList,
		fetchCarModelList,
		brandList,
		carModelList,
		fetchFuelTypeList,
		fuelTypeList,
		fetchVehicleTypeList,
		vehicleTypeList,
		fetchCarCategoryList,
		carCategoryList,
		fetchCarSubModelList,
		carSubModelList,
		fetchColorList,
		colorList,
		usedCarBoosting,
		fetchMerchantList,
		initialValues,
		getSearchUsedCar,
		fetchAdminSearchUsedCar,
		deleteImageCarSuccess
	} = props;
	const [showTable, setShowTable] = useState(true);
	const [editingId, setEditingId] = useState(null);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [isLoading, setIsLoading] = useState(false);
	const [boostingId, setBoostingId] = useState('');

	const [subModelData, setSubModelData] = useState([]);
	const [page, setPage] = useState(1);
	// const [status, setStatus] = useState('');
	// const [isFeatured, setIsFeatured] = useState('');
	const [errorText, setErrorText] = useState('');
	// const [queryParams, setQueryParams] = useSearchParams();
	const [searchParam, setSearchParam] = useState({
		status: '',
		featured: '',
	});

	useEffect(() => {
		deleteImageCarSuccess({})
	}, []);

	const buildQueryString = (params) => {
		const queryString = new URLSearchParams(params).toString();
		return queryString ? `${queryString}` : '';
	};

	// console.log('usedCarList ===>', usedCarList)
	const fetchData = async () => {
		let queryString = '';
		if (searchParam.status !== '' || searchParam.feature !== '') {
			queryString = buildQueryString(searchParam);
		}
		const promises = [
			fetchMerchantList('all'),
			fetchCarSubModelList('all', true),
			fetchUsedCarList(page, false, false, queryString),
			fetchBrandList('all', true),
			fetchCarModelList('all', true),
			fetchFuelTypeList('all', true),
			fetchVehicleTypeList('all', true),
			fetchCarCategoryList('all', true),
			fetchColorList('all', true, true),
		];

		return Promise.all(promises);
	};

	useEffect(() => {
		fetchData();
	}, [fetchUsedCarList, page, showTable]);

	useEffect(() => {
		setSubModelData(carSubModelList);
	}, [carSubModelList]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchUsedCarList(page, false, false, '');
					setShowTable(true);
					setButtonLabel('Add');
				},
				onClose: () => {
					// setShowTable(true);
				},
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	}, [success, error]);

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id);
			setShowTable(false);
			setButtonLabel('List');
			fetchUsedCarSingle(id);
		}
	};
	const handleBoost = (id, val) => {
		setBoostingId(id);
		setIsLoading(true);
		const boostData = {
			is_boost: !val,
		};
		usedCarBoosting(id, boostData);
		setTimeout(() => {
			fetchUsedCarList(page, false, false, '');
			setIsLoading(false);
		}, 1000);
	};
	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null);
	};

	const handleFilterChange = (flag, value) => {
		const newSearchParam = { ...searchParam };
		if (flag === 'status') {
			newSearchParam.status = value
		}

		if (flag === 'feature') {
			newSearchParam.featured = value
		}
		setSearchParam(newSearchParam);
	}

	const onPageChange = (page) => {
		setPage(page);
	};

	
	const onSubmit = (values) => {
		console.log(' values', values)
		// return
		if (editingId) {
			const stringiFyImg = JSON.stringify(values?.images)
			const newEditValue = { ...values, images: stringiFyImg }
			updateUsedCar(editingId, newEditValue);
		} else {
			console.log(values);
			createUsedCar(values);
		}
	};
	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true,
		},
		{
			name: 'Brand',
			selector: (row) => row.brand?.name,
			sortable: true,
		},
		{
			name: 'Name',
			selector: (row) =>
				row.user?.user_company !== null
					? row.user?.user_company?.name
					: row.user?.user_details?.first_name,
			sortable: true,
		},
		{
			name: 'Model',
			selector: (row) => row.car_model?.name,
			sortable: true,
		},
		{
			name: 'Ads Type',
			selector: (row) => row.add_on?.name,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => {
				switch (row.status) {
					case 'active':
						return 'Active';
					case 'pending':
						return 'Pending';
					case 'inactive':
						return 'Inactive';
					default:
						return '';
				}
			},
			sortable: false,
		},
		{
			name: 'Feature',
			selector: (row) => (row.is_feature ? 'Featured' : 'Not Featured'),
			sortable: false,
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={(id) => handleEditDelete(id, 'edit')}
					onDelete={(id) => handleEditDelete(id, 'delete')}
					onBoost={(id) => handleBoost(id, row?.is_boost)}
					showBoostBtn={true}
					isLoading={isLoading}
					boostingId={boostingId}
				/>
			),
			sortable: false,
			width: '20%',
		},
	];

	const handleStatus = () => {
		if (searchParam.status == '' &&  searchParam.featured == '') {
			setErrorText('Please Select at least one');
		} else {

			const queryString = buildQueryString(searchParam);
			fetchUsedCarList(1, false, false, queryString)
			setErrorText('');
			setPage(1)
		}
	};

	const renderContent = () => {
		if (loading) {
			return <Loader />;
		}

		if (showTable && !loading) {
			return (
				<>
					<div className="w-full flex  items-center my-5">
						<div className=" flex w-full xl:w-1/2   xl:flex-row ">
							<div className="w-full xl:w-1/2 px-2 relative ">
								<label
									htmlFor="Search"
									className={`absolute text-gray-700 text-sm font-semibold -top-3 left-4 bg-white px-1`}
								>
									Status
								</label>
								<select
									id="status"
									value={searchParam.status}
									required
									onChange={(e) => handleFilterChange('status', e.target.value)}
									className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
								>
									{activityOptions.map((option) => (
										<option key={option?.value} value={option?.value}>
											{option?.label}
										</option>
									))}
								</select>
							</div>
							<div className="w-full xl:w-1/2 px-2 relative ">
								{' '}
								<label
									htmlFor="Search"
									className={`absolute text-gray-700 text-sm font-semibold -top-3 left-4 bg-white px-1`}
								>
									Featured
								</label>
								<select
									id="isFeatured"
									value={searchParam.featured}
									required
									onChange={(e) => handleFilterChange('feature', e.target.value)}
									className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
								>
									{featuredTypeOptions.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</select>
							</div>
						</div>
						<button
							onClick={handleStatus}
							className={`p-3 mx-2 font-medium rounded border border-stroke`}
						>
							<FaFilter />
						</button>
						<span>
							{errorText ? <p className="text-red ml-8">{errorText}</p> : ''}
						</span>
					</div>

					<>
						<UsedCarTable
							columns={columns}
							data={usedCarList?.rows == undefined ? [] : usedCarList?.rows}
							totalCount={usedCarList?.count}
							handlePageChange={onPageChange}
							defaultPage={page}
						/>
					</>
				</>
			);
		}
console.log(singleUsedCar);
		if (editingId && !loading && singleUsedCar !== undefined) {
			return (
				<Tabs>
					<TabList>
						<Tab>Details</Tab>
						<Tab>Payments</Tab>
					</TabList>
					<TabPanel>
						<UsedCarForm
							onSubmit={onSubmit}
							initialValues={singleUsedCar}
							onCancel={handleButtonClick}
							brandList={brandList}
							carModelList={carModelList ?? []}
							fuelTypeList={fuelTypeList}
							vehicleTypeList={vehicleTypeList}
							carCategoryList={carCategoryList}
							carSubModelList={subModelData ?? []}
							// onModelChange={(e) => handleModelChange(e)}
							colorList={colorList}
						/>
					</TabPanel>
					<TabPanel>
						<p> Payments</p>
					</TabPanel>
				</Tabs>
			);
		}

		if (!loading) {
			return (
				<CreateUsedCarForm
					onSubmit={onSubmit}
					onCancel={handleButtonClick}
					brandList={brandList}
					carModelList={carModelList ?? []}
					fuelTypeList={fuelTypeList}
					vehicleTypeList={vehicleTypeList}
					carCategoryList={carCategoryList}
					carSubModelList={subModelData ?? []}
					// onModelChange={(e) => handleModelChange(e)}
					colorList={colorList}
					userList={merchantList}
				/>
			);
		}
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader
							title="Used Cars"
							buttonText={buttonLabel}
							onButtonClick={handleButtonClick}
						/>
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.usedCar.loading,
		error: state.usedCar.error,
		success: state.usedCar.success,
		usedCarList: state.usedCar.usedCarList,
		singleUsedCar: state.usedCar.singleUsedCar,
		brandList: state.brand.brandList,
		carModelList: state.carModel.carModelList,
		fuelTypeList: state.fuelType.fuelTypeList,
		vehicleTypeList: state.vehicleType.vehicleTypeList,
		carCategoryList: state.carCategory.carCategoryList,
		carSubModelList: state.carSubModel.carSubModelList,
		colorList: state.color.colorList,
		merchantList: state.merchant.merchantList,
		merchantUsedCar: state.usedCar.merchantUsedCar,
		getSearchUsedCar: state.usedCar.getSearchUsedCar,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createUsedCar: (data) => dispatch(createUsedCar(data)),
		fetchUsedCarList: (page, isProcess, isPublic, search) => dispatch(fetchUsedCarList(page, isProcess, isPublic, search)),
		fetchUsedCarSingle: (id) => dispatch(fetchUsedCarSingle(id)),
		updateUsedCar: (id, data) => dispatch(updateUsedCar(id, data)),
		fetchBrandList: (page, isProcess) =>
			dispatch(fetchBrandList(page, isProcess)),
		fetchCarModelList: (page, isProcess) =>
			dispatch(fetchCarModelList(page, isProcess)),
		fetchFuelTypeList: (page, isProcess) =>
			dispatch(fetchFuelTypeList(page, isProcess)),
		fetchVehicleTypeList: (page, isProcess) =>
			dispatch(fetchVehicleTypeList(page, isProcess)),
		fetchCarCategoryList: (page, isProcess) =>
			dispatch(fetchCarCategoryList(page, isProcess)),
		fetchCarSubModelList: (page, isProcess) =>
			dispatch(fetchCarSubModelList(page, isProcess)),
		fetchColorList: (page, isProcess, isPublic) =>
			dispatch(fetchColorList(page, isProcess, isPublic)),
		usedCarBoosting: (id, data) => dispatch(usedCarBoosting(id, data)),
		fetchMerchantList: (page) => dispatch(fetchMerchantList(page)),
		fetchAdminSearchUsedCar: (status, featured, page) =>
			dispatch(fetchAdminSearchUsedCar(status, featured, page)),
		deleteImageCarSuccess: (data) => dispatch(deleteImageCarSuccess(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsedCar);
