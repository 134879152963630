// adminReducer.js
import * as actionTypes from "../actions/actionTypes";

const initialState = {
	loading: false,
	adminList: [],
	singleAdmin: null,
	error: null,
	success: null,
};

const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADMIN_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};

		case actionTypes.FETCH_ADMIN_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				adminList: action.payload,
				error: null,
			};

		case actionTypes.FETCH_ADMIN_LIST_FAILURE:
			return {
				...state,
				loading: false,
				adminList: [],
				error: action.payload,
			};

		case actionTypes.FETCH_ADMIN_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleAdmin: action.payload,
				error: null,
			};

		case actionTypes.FETCH_ADMIN_SINGLE_FAILURE:
			return {
				...state,
				loading: false,
				singleAdmin: null,
				error: action.payload,
			};

		case actionTypes.CREATE_ADMIN_SUCCESS:
			return {
				...state,
				loading: false,
				success: action.payload,
				error: null,
			};

		case actionTypes.CREATE_ADMIN_FAILURE:
			return {
				...state,
				loading: false,
				success: null,
				error: action.payload,
			};

		case actionTypes.UPDATE_ADMIN_SUCCESS:
		case actionTypes.UPDATE_ADMIN_FAILURE:
			return {
				...state,
				loading: false,
				success: action.payload,
				error: null,
			};
		default:
			return state;
	}
};

export default adminReducer;
