import React from 'react';
import Layout from '../../../components/site/Layout/Base/Layout';
import InstantOfferHeader from '../../../components/site/GetInstantOffersComponents/InstantOfferHeader';
import HowItWorks from '../../../components/site/GetInstantOffersComponents/HowItWorks';
import GetOfferNow from '../../../components/site/GetInstantOffersComponents/GetOfferNow';

const GetInstantOffers = () => {
  return (
    <Layout>
      <InstantOfferHeader/>
      <div className='container mx-auto lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col justify-center  px-4 lg:px-32 mt-7'>
        <HowItWorks/>
        <GetOfferNow/>
      </div>
    </Layout>
  );
};

export default GetInstantOffers;
