import React from 'react';
import CarSubModelTable from '../../../components/admin/Table/Table';

const SubModels = (props) => {
	return (
		<>
			<CarSubModelTable
				columns={props.columns}
				data={props.data}
				totalCount={props.totalCount}
				handlePageChange={props.onPageChange}
			/>
		</>
	);
};

export default SubModels;
