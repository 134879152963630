import React, { useEffect, useState } from 'react'
// import DynamicFormFields from '../../../../components/common/FormFileds/Auth/DynamicFormFields';
// import FormField from '../../../../components/common/FormFileds/Admin/FormField';
// import ActionButton from '../../../../components/admin/ActionButton/ActionButton';
import AnalyticsTable from '../../../../components/admin/Table/Table';
import { SearchInput } from '../../../../components/common/SearchInput/SearchInput';
import { SingleSelectInput } from '../../../../components/common/SingleSelectInput/SingleSelectInput';
import { fetchMerchantUsedCar, fetchSearchUsedCar } from '../../../../store/actions';
import { connect } from 'react-redux';
import { MdFilterListAlt } from "react-icons/md";

const MerchantAnalytics = (props) => {
    const {singleMerchant, merchantUsedCar,
         fetchMerchantUsedCar, fetchSearchUsedCar} = props

    // console.log('singleMerchant >>>', singleMerchant)
    // console.log('merchantUsedCar >>>', merchantUsedCar)

    const [page, setPage] = useState(1);
    const selectOptions = [
        {label: 'Used Car', value: 'usedcar'},
        // {label: 'New Car', value: 'newcar'},
        // {label: 'Car Request', value: 'carrequest'}
    ]

    const searchHandler = (value) => {
        // console.log('value >>>', value)
       if(value.length >= 2 ){
        fetchSearchUsedCar(singleMerchant?.id, value)
       }
       else if (value.length == 0){
        fetchMerchantUsedCar(singleMerchant?.id, 1)
       }
    }
console.log(singleMerchant?.id);
    const onListingTypeSelect = (listingType) => {
        console.log('listingType >>>', listingType)

    }
    const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Brand',
			selector: row => row?.brand?.name,
			sortable: true
		},
		{
			name: 'Model',
			selector: row => row?.car_model?.name,
			sortable: true
		},
		{
			name: 'Ads Type',
			selector: row => row?.ads_type?.name,
			sortable: false
		},
		{
			name: 'Status',
			selector: row => row?.status,
			sortable: false
		},
        {
			name: 'Feature',
			selector: row => row?.is_feature  == 0 ? 'Not Featured' : 'Featured',
			sortable: false
		},
		// {
		// 	name: 'Actions',
		// 	selector: (row) => (
		// 		<ActionButton
		// 			row={row}
		// 			onEdit={id => console.log('Hi Edit')}
		// 			showDeleteButton={false}
		// 			onDelete={id => console.log('Hi Delete')}
		// 		/>
		// 	),
		// 	sortable: false
		// },
	];

    const onPageChange = (currentPage) => {
        setPage(currentPage)
    }
    useEffect(() => {
        fetchMerchantUsedCar(singleMerchant?.id, page)
    },[singleMerchant?.id, page])

  return (
    <div className="p-6">
         <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2 border border-stroke border-2 border-gray-700 rounded-md pl-4 pr-2 py-2">
                <p className='text-base font-normal'>Number of Active Listings</p>
                <p  className='text-base font-semibold mt-2'>{singleMerchant?.total_active}</p>
            </div>
            <div className="w-full xl:w-1/2 border border-stroke border-2 border-gray-700 rounded-md pl-4 pr-2 py-2">
                <p className='text-base font-normal'>Number of Pending Listings</p>
                <p  className='text-base font-semibold mt-2'>{singleMerchant?.total_pending}</p>
            </div>
            <div className="w-full xl:w-1/2 border border-stroke border-2 border-gray-700 rounded-md pl-4 pr-2 py-2">
                <p className='text-base font-normal'>Amount Overdue</p>
                <p  className='text-base font-semibold mt-2'>0</p>
            </div>
        </div>
        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full xl:w-1/2 border border-stroke border-2 border-gray-700 rounded-md pl-4 pr-2 py-2">
                <p className='text-base font-normal'>Total Number of Enquiries</p>
                <p  className='text-base font-semibold mt-2'>{singleMerchant?.total_enquiry}</p>
            </div>
            <div className="w-full xl:w-1/2 border border-stroke border-2 border-gray-700 rounded-md pl-4 pr-2 py-2">
                <p className='text-base font-normal'>Total Number of Merchant Edits</p>
                <p  className='text-base font-semibold mt-2'>{singleMerchant?.total_edits}</p>
            </div>
            <div className="w-full xl:w-1/2 border border-stroke border-2 border-gray-700 rounded-md pl-4 pr-2 py-2">
                <p className='text-base font-normal'>Total Number of Views</p>
                <p  className='text-base font-semibold mt-2'>{singleMerchant?.total_click}</p>
            </div>
        </div>

        <div className="mb-4.5 flex flex-col gap-6 xl:flex-row ">
            <div className="w-full xl:w-1/2 ">
               <SearchInput searchHandler={searchHandler} label='Search' />
            </div>
            <div className="w-full xl:w-1/2 flex flex-row ">
                <div className='w-full'>
                    <SingleSelectInput label='Listing Type' options={selectOptions} onSelect={onListingTypeSelect} />
                </div>
                <MdFilterListAlt size={30} style={{marginTop: '10px', marginLeft: '5px'}} />
            </div>
        </div>

        <div>
            <AnalyticsTable
                defaultPage={page}
                columns={columns}
                data={merchantUsedCar?.rows === undefined ? [] : merchantUsedCar?.rows}
                totalCount={merchantUsedCar?.count}
                handlePageChange={onPageChange}
            />
        </div>
    </div>
        
  )
}

const mapStateToProps = (state) => {
	return {
        merchantUsedCar: state.usedCar.merchantUsedCar,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
        fetchMerchantUsedCar: (id, page) => dispatch(fetchMerchantUsedCar(id, page)),
        fetchSearchUsedCar: (id, modelName) => dispatch(fetchSearchUsedCar(id, modelName))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantAnalytics);