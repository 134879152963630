import * as yup from 'yup';

const CarModelSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	status: yup.number().integer().required('Status is required'),
	brand_id: yup.number().integer().required('Brand is required'),
	predecessor: yup.string().optional('Predecessor is required'),
	built_in: yup.string().required('Built-in is required'),
});

export default CarModelSchema;