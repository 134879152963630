import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import Layout from '../../../components/admin/Layout/Base/Layout';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import PromoTable from '../../../components/admin/Table/Table';
import Loader from '../../../components/common/Loader/Loader';
import useSearch from '../../../hooks/useSearch';
import { buildQueryString } from '../../../helper/buildQueryString';

import {
	fetchPromoCodeWiseOrderList
} from '../../../store/actions';


const PromoCodesOrderCounter = (props) => {
	const {
		loading,
		error,
		success,
		PaymentListPromoCodeWise,
		fetchPromoCodeWiseOrderList,
		
	} = props;

	const navigate = useNavigate();

	const [showTable, setShowTable] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		setShowTable(true);
	}, [PaymentListPromoCodeWise]);

	useEffect(() => {
		fetchPromoCodeWiseOrderList(page, buildQueryString(queryParam));
	}, [fetchPromoCodeWiseOrderList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchPromoCodeWiseOrderList(page, buildQueryString(queryParam));
					setShowTable(true);
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				},
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	}, [success, error]);

	const handleEditDelete = (id, action) => {
		if (action === 'view') {
			navigate(`/admin/promo-code-wise-order-list/${id}`);
		}
	};

	const handleButtonClick = () => {
		setShowTable(showTable);
	};

	const onPageChange = (page) => {
		setPage(page);
	};

	let queryParam = { promo_code: '' }
	const { searchParam, handleFieldSearch, handleClearSearch, handleFilterClick } = useSearch(fetchPromoCodeWiseOrderList, page, queryParam);

	const searchFields = [
		{ name: 'promo_code', label: 'Promo Code', type: 'text' },
	];
	

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true,
		},
		{
			name: 'Code',
			selector: (row) => row?.promo_codes?.code,
			sortable: true,
		},
		{
			name: 'Qty Used',
			selector: (row) => row.order_count,
			sortable: true,
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					notEdit
					showDeleteButton={false}
					showViewButton={true}
					onView={() => handleEditDelete(row?.promo_code_id, 'view')}
				/>
			),
			sortable: false,
		},
	];

	const renderContent = () => {
		if (loading) {
			return <Loader />;
		}

		if (showTable && !loading) {
			return (
				<PromoTable
					defaultPage={page}
					columns={columns}
					searchFields={searchFields}
					data={PaymentListPromoCodeWise.rows == undefined ? [] : PaymentListPromoCodeWise.rows}
					totalCount={PaymentListPromoCodeWise.count}
					handlePageChange={onPageChange}
					handleFieldSearch={handleFieldSearch}
					handleFilterClick={handleFilterClick}
					handleClearSearch={handleClearSearch}
					searchParam={searchParam}
				/>
			);
		}

	
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader
							title="Promo Codes Count"
							onButtonClick={handleButtonClick}
						/>
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.payment.loading,
		error: state.payment.error,
		success: state.payment.success,
		PaymentListPromoCodeWise: state.payment.PaymentListPromoCodeWise,
		OrderListByPromoCode: state.payment.OrderListByPromoCode,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchPromoCodeWiseOrderList: (page, searchParam) => dispatch(fetchPromoCodeWiseOrderList(page, searchParam)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodesOrderCounter);

