import React, { useState, useEffect } from 'react';
import { CiHeart } from 'react-icons/ci';
import { Link, useNavigate } from 'react-router-dom';
import placeholderImage from '../../../assets/images/car_slider_placeholder.png';
import { formatNumberWithCommas } from '../../../helper/formatNumberWithCommas';

const NewSearchCar = ({ subModel, car }) => {
  const navigate = useNavigate();
  const [imageList, setImageList] = useState([])


  const ProductDetailsHandler = () => {
    navigate(`/new-car-product/${car?.id}`);
  };

  useEffect(() => {
    setImageList(JSON.parse(car.images))
  }, [car])

  return (
    <div className="  border  border-[#E8E7E6]  shadow-lg rounded  h-fit  w-full mt-2 mb-3 pt-3 ">
      <div className="grid lg:grid-cols-5 grid-cols-1 gap-2 px-3">
        <div className="w-full flex justify-start items-center gap-1  col-span-2">
          <div className="w-full  ">
            <img
              // onClick={ProductDetailsHandler}
              onClick={ProductDetailsHandler}
              src={
                imageList?.[0]?.image
                  ? imageList?.[0]?.image
                  : placeholderImage
              }
              alt=""
              className="slider_img  cursor-pointer"
            />
          </div>
          {/* </Link>  */}
          {/* <div className="w-[150px] h-fit ">
            <div className='h-25'>
              <img
                src={
                  car?.new_car_listing_images?.[1]?.image
                    ? car?.new_car_listing_images?.[1]?.image
                    : placeholderImage
                }
                alt=""
                className="search_img cursor-pointer"
              />
            </div>

            <div className='h-25'>
              <img
                src={
                  car?.new_car_listing_images?.[2]?.image
                    ? car?.new_car_listing_images?.[2]?.image
                    : placeholderImage
                }
                alt=""
                className="search_img cursor-pointer"
              />
            </div>

            <div className='h-25'>
              <img
                src={
                  car?.new_car_listing_images?.[3]?.image
                    ? car?.new_car_listing_images?.[3]?.image
                    : placeholderImage
                }
                alt=""
                className=" search_img cursor-pointer"
              />
            </div>
          </div> */}
          <div className="w-[150px] h-fit">
          {[...Array(4)].map((_, index) => {
            // Skip rendering the first item
            if (index === 0) {
              return null;
            }

            const image = imageList?.[index];
            return (
              <div className='h-25' key={index}>
                <img
                  src={image?.image ? image.image : placeholderImage}
                  alt=""
                  className="search_img cursor-pointer"
                />
              </div>
            );
          })}
          </div>
        </div>

        <div className="  col-span-3  px-3">
          <div className="flex flex-row justify-start items-center my-2">
            {car?.is_feature === 1 && (
              <div className="flex flex-row justify-center items-center border border-1 rounded bg-[#0534FF] text-white h-[24px] border-[#0534FF] mr-4">
                <p className="text-[12px] font-normal px-4 ">Featured</p>
              </div>
            )}
          </div>
          <div className="flex justify-between items-center gap-2 my-1">
            {car?.new_car_submodel?.length > 0 && (
              <p className="text-2xl font-bold text-[#222B5E]">
                {`$${formatNumberWithCommas(
                  car?.new_car_submodel[0]?.car_submodel?.key_info?.price
                )}`}
                {car?.new_car_submodel?.length > 1 && (
                  <>
                    {' '}
                    - $
                    {formatNumberWithCommas(
                      car?.new_car_submodel[car?.new_car_submodel.length - 1]
                        ?.car_submodel?.key_info?.price
                    )}
                  </>
                )}
              </p>
            )}
            <div className="flex justify-center ms-auto items-center gap-2 border border-[#ffffff52] rounded-full px-2 py-1 cursor-pointer">
              <CiHeart size={20} className="" color="#2A65BA" />
              <p className="text-[#2A65BA] mb-1">Save</p>
            </div>
          </div>
          <Link
            className="block flex-shrink-0 "
            to={`/new-car-product/${car?.id}`}
          >
            <p
              // onClick={ProductDetailsHandler}
              className="text-2xl font-bold text-[#242D3D] my-6 cursor-pointer"
            >
              {car?.car_model?.name}
            </p>{' '}
          </Link>
          <div className="mt-9 mb-3">
            <p className="my-3  ">Built in: {car?.built_in_name?.name}</p>
            {car?.new_car_submodel?.length > 0 && (
              <>
                {car?.new_car_submodel?.map((item, index) => (
                  <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 2xl:grid-cols-8 gap-2 items-center my-3" key={index}>
                    <div className="flex justify-start items-center gap-2 col-span-2 md:col-span-4 2xl:col-span-4">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="w-5 h-5 "
                        size={80}
                      // disabled
                      />
                      <p className="text-[#242D3D]">
                        {item?.car_submodel?.key_info?.name}
                      </p>
                    </div>
                    {/* <div className="flex justify-start items-center gap-4 "> */}
                    <p className="text-[#0534FF] font-semibold col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 sm:text-end 2xl:col-span-1 order-2 sm:order-1">
                      $
                      {formatNumberWithCommas(
                        item?.car_submodel?.key_info?.price
                      )}
                    </p>
                    <p className="text-[#242D3D] col-span-1 md:col-span-4 lg:col-span-3 2xl:text-end order-1 sm:order-2">
                      {item?.car_submodel?.performance?.fuel_consumption}km/L |{' '}
                      {item?.car_submodel?.performance?.power}bhp |{' '}
                      {item?.car_submodel?.enginer_transmission?.transmission?.substring(
                        0,
                        11
                      )}
                    </p>
                    {/* </div> */}
                  </div>
                ))}
              </>
            )}
          </div>

          <hr className="border-[#E8E7E6] border  " />
          <div className="flex justify-between items-center gap-8 mt-3">
            <p>
              {car?.user?.user_company?.name} -
              <span className="text-[#2A65BA] cursor-pointer">
                See all {car?.user?.carCount} cars
              </span>
            </p>
            <div className="w-[46px] ">
              <img
                className="cursor-pointer"
                // onClick={merchantProfileNavigate}
                src={car?.user?.image ? car?.user?.image : placeholderImage}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {car?.new_car_submodel?.length > 0 && (
        <div className="bg-stroke rounded-b mt-2">
          <div className="p-2 px-3">
            <p className="text-[#666666] ">
              <span className="font-bold">Promotion : </span>
              Drive home an Alfa Romeo and enjoy $10,000 special launch
              promotion!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewSearchCar;
