// Login.js
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

//components
import AuthForm from '../../../components/common/AuthForm/AuthForm';
import DynamicFormFields from '../../../components/common/FormFileds/Auth/DynamicFormFields';
import Layout from '../../../components/common/Layout/Auth/Layout';

//Schemas
import LoginSchema from '../../../schemas/LoginSchema';

import { authLogin } from '../../../store/actions';

import { toast } from 'react-toastify';


const Login = (props) => {
  const fields = [
    { name: 'email', label: 'Email', type: 'text' },
    { name: 'password', label: 'Password', type: 'password' },
  ];

	const { loading, error, success } = props;

  // useEffect(() => {
  // 	if (props.isAuth) {
  // 		<Navigate to={props.redirectTo} />;
  // 	}
  // }, [props.isAuth]);
	useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {},
        onClose: () => {
          // setShowTable(true)
          // setButtonLabel('Add')
        },
      });
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  const onSubmit = (values) => {
    props.authLogin(values);
  };

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: LoginSchema,
    onSubmit: onSubmit,
  });

  return (
    <Layout>
      <div className="w-full lg:w-3/4 p-6">
        <AuthForm
          formik={formik}
          loading={props.loading}
          // error={props.error}
          formBlock={<DynamicFormFields formik={formik} fields={fields} />}
          buttonText="Sign in"
          additionalText="Forget your account?"
          additionalLink="/forgot-password"
          registerLink="/register"
          additionalLinkText="Forgot Password"
          registerButton="Register now"
          registerText="Don’t have an account yet?"
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
    redirectTo: state.auth.redirectTo,
    isAuth: state.auth.isAuthenticated,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    authLogin: (data) => dispatch(authLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(Login);
