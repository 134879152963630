import React from 'react'
import NewCarForm from '../../admin/NewCar/NewCarForm';

const NewCarUpdateForm = (props) => {
  const { onCancel,
		onSubmit,
		brandList,
		carModelList,
    userList,
		carSubModelList,
		colorList,
		selectedColors, setSelectedColors,
		selectedSubModels, setSelectedSubModels,
		countryList,
    initialValues,
    vehicleTypeList,
    carCategoryList,
    isloading
  } = props
  return (
    <NewCarForm
    onSubmit={onSubmit}
    brandList={brandList}
    carModelList={carModelList ?? []}
    carSubModelList={carSubModelList ?? []}
    colorList={colorList}
    userList={userList}
    selectedColors={selectedColors}
    setSelectedColors={setSelectedColors}
    selectedSubModels={selectedSubModels} 
    setSelectedSubModels={setSelectedSubModels}
    countryList={countryList}
    onCancel={onCancel}
    initialValues={initialValues}
    vehicleTypeList={vehicleTypeList}
    carCategoryList={carCategoryList}
    from="site"
    isloading={isloading}

    />
  )
}

export default NewCarUpdateForm;