import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  success: null,
  singleOrder: null,
  sitePaymentList: {},
};

const sitePaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SITE_PAYMENT_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case actionTypes.FETCH_SITE_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sitePaymentList: action.payload,
      };
    case actionTypes.FETCH_SITE_PAYMENT_LIST_FAILURE:
    case actionTypes.FETCH_PAYMENT_SINGLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_PAYMENT_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleOrder: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default sitePaymentReducer;
