// adminActions.js
import * as actionTypes from "./actionTypes";
import AdminUserController from "../../api/UserController";

export const adminInit = () => {
	return {
		type: actionTypes.ADMIN_INIT,
	};
};

export const fetchAdminListSuccess = (admins) => {
	return {
		type: actionTypes.FETCH_ADMIN_LIST_SUCCESS,
		payload: admins,
	};
};

export const fetchAdminListFailure = (error) => {
	return {
		type: actionTypes.FETCH_ADMIN_LIST_FAILURE,
		payload: error,
	};
};

export const fetchAdminSingleSuccess = (admin) => {
	return {
		type: actionTypes.FETCH_ADMIN_SINGLE_SUCCESS,
		payload: admin,
	};
};

export const fetchAdminSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_ADMIN_SINGLE_FAILURE,
		payload: error,
	};
};

export const createAdminSuccess = (message) => {
	return {
		type: actionTypes.CREATE_ADMIN_SUCCESS,
		payload: message,
	};
};

export const createAdminFailure = (error) => {
	return {
		type: actionTypes.CREATE_ADMIN_FAILURE,
		payload: error,
	};
};

export const updateAdminSuccess = (admin) => {
	return {
		type: actionTypes.UPDATE_ADMIN_SUCCESS,
		payload: admin,
	};
};

export const updateAdminFailure = (error) => {
	return {
		type: actionTypes.UPDATE_ADMIN_FAILURE,
		payload: error,
	};
};

export const fetchAdminList = (page) => {
	return async (dispatch) => {
		dispatch(adminInit());
		AdminUserController.list(page, 'user')
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response
						? result.response.data
							? result.response.data.message
							: "Something went wrong"
						: "Something went wrong";
					dispatch(fetchAdminListFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					dispatch(fetchAdminListSuccess(result.data.data));
				} else {
					dispatch(fetchAdminListFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(fetchAdminListFailure("Something went wrong"));
			});
	};
};

export const fetchAdminSingle = (adminId) => {
	return async (dispatch) => {
		dispatch(adminInit());
		AdminUserController.single(adminId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response
						? result.response.data
							? result.response.data.message
							: "Something went wrong"
						: "Something went wrong";
					dispatch(fetchAdminSingleFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					dispatch(fetchAdminSingleSuccess(result.data.data));
				} else {
					dispatch(fetchAdminSingleFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(fetchAdminSingleFailure("Something went wrong"));
			});
	};
};

export const createAdmin = (adminData, type) => {
	return async (dispatch) => {
		dispatch(adminInit());
		AdminUserController.create(adminData, type)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = "";
					if (result.response) {
						if (
							result.response.data &&
							!Array.isArray(result.response.data.message)
						) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = "Something went wrong";
						}
					} else {
						errorMsg = "Something went wrong";
					}
					dispatch(createAdminFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					dispatch(createAdminSuccess(result.data.message));
				} else {
					dispatch(createAdminFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(createAdminFailure("Something went wrong"));
			});
	};
};

export const updateAdmin = (adminId, updatedAdminData, type) => {
	return async (dispatch) => {
		dispatch(adminInit());
		AdminUserController.update(adminId, updatedAdminData, type)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = "";
					if (result.response) {
						if (
							result.response.data &&
							!Array.isArray(result.response.data.message)
						) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = "Something went wrong";
						}
					} else {
						errorMsg = "Something went wrong";
					}
					dispatch(updateAdminFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					dispatch(updateAdminSuccess(result.data.message));
				} else {
					dispatch(updateAdminFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(updateAdminFailure("Something went wrong"));
			});

	};
};

