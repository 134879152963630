import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../components/common/Layout/Auth/Layout';
import AuthForm from '../../../components/common/AuthForm/AuthForm';
import { useFormik } from 'formik';
import { forgetPassword } from '../../../store/actions';
import DynamicFormFields from '../../../components/common/FormFileds/Auth/DynamicFormFields';
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ForgotPass = (props) => {
  const { loading, error, success } = props;

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {},
        onClose: () => {
          // setShowTable(true)
          // setButtonLabel('Add')
        },
      });
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  const forgetPasswordHandler = (values) => {
    console.log(values);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(values.email)) {
      props.forgetPassword(values);
    } else {
      toast.error('Please enter a valid email', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const formik = useFormik({
    initialValues: { email: '' },
    // validationSchema: LoginSchema,
    onSubmit: forgetPasswordHandler,
  });
  const fields = [{ name: 'email', label: 'Email', type: 'text' }];
  console.log('email -------->', formik.email);
  return (
    <Layout>
      <div className="w-full lg:w-3/4 p-6">
        <AuthForm
          formik={formik}
          loading={props.loading}
          error={props.error}
          formBlock={<DynamicFormFields formik={formik} fields={fields} />}
          buttonText="Reset password"
          additionalText=" "
          additionalLink="/login"
          registerButton="Return to login"
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
    redirectTo: state.auth.redirectTo,
    isAuth: state.auth.isAuthenticated,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    forgetPassword: (data) => dispatch(forgetPassword(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(ForgotPass);