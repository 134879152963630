import moment from 'moment';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import tempImg from '../../../assets/images/web/car.png';
import BillingTable from '../../../components/admin/Table/Table';
import ActionButton from '../../admin/ActionButton/ActionButton';
import FilterButtons from '../FilterButtons/FilterButtons ';
import ListingInfoCard from '../ListingInfoCard/ListingInfoCard';
import MenuTabs from '../MenuTabs/MenuTabs';
import TabCarData from '../TabCarData/TabCarData';
import SiteSettingForm from './SiteSettingForm';

const CarListing = ({
  usedCarData,
  onTabChange,
  handleDropDownClick,
  userData,
  sellerList,
  sitePaymentList,
  onSellerPageChange,
  onBillingPageChange,
  sellerPage,
  newCarData,
  shortlistedList,
}) => {
  const [editingId, setEditingId] = useState(null);
  const [tabNo, setTabNo] = useState(0);

	let navigate = useNavigate();
	const filterHandler = (filterStatus) => {
		onTabChange(filterStatus, tabNo);
	};
	const dropDownHandler = (id, flag) => {
		handleDropDownClick(id, flag, tabNo);
	};
	const requestListingsData = [
		{
			images: tempImg,
			carPlateNo: 'SMZ4654446',
			title: 'Toyota Vios 1.5 A J',
			subTitle: 'Featured',
			price: '$26,000',
			status: 'active',
			postedOn: '26 Sep 2023',
			refreshedOn: '26 Sep 2023',
			expiredOn: '26 Sep 2023',
			enquiryCount: 12,
			editedCount: 6,
		},
		{
			images: tempImg,
			carPlateNo: 'SMZ4654446',
			title: 'Honda Civic LX',
			subTitle: 'New',
			price: '$30,000',
			status: 'inactive',
			postedOn: '27 Sep 2023',
			refreshedOn: '27 Sep 2023',
			expiredOn: '27 Sep 2023',
			enquiryCount: 8,
			editedCount: 4,
		},
		{
			images: tempImg,
			carPlateNo: 'SMZ4654446',
			title: 'Chevrolet Camaro SS',
			subTitle: 'New',
			price: '$35,000',
			status: 'active',
			postedOn: '29 Sep 2023',
			refreshedOn: '29 Sep 2023',
			expiredOn: '29 Sep 2023',
			enquiryCount: 20,
			editedCount: 12,
		},
	];
	// console.log('merchant car =>>>', usedCarData);
	const postingfilterButtons = [
		{ label: 'All', filter: 'all' },
		{ label: 'Active', filter: 'active' },
		{ label: 'Pending', filter: 'pending' },
		{ label: 'Inactive', filter: 'inactive' },
	];

  const requestfilterButtons = [
    { label: 'All', filter: 'all' },
    { label: 'Active', filter: 'active' },
    { label: 'Pending', filter: 'pending' },
    { label: 'Inactive', filter: 'inactive' },
  ];
  const billingfilterButtons = [
    { label: 'All', filter: 'all' },
    { label: 'Pending', filter: 'Pending' },
    { label: 'Paid', filter: 'Paid' },
    { label: 'Refund', filter: 'Refund' },
    { label: 'Void', filter: 'Void' },
  ];
  const shortlistedButtons = [
    { label: 'Used Cars', filter: 'Used Cars' },
    { label: 'New Cars', filter: 'New Cars' },
  ];

  let handleEditDelete = (id, action) => {
    const findEditItem = sitePaymentList?.rows?.find((item) => item?.id == id);
    if (id && action === 'edit') {
      setEditingId(id);
      navigate(`/sales-parson-form/${id}`, {
        state: {
          activeTab: 4,
        },
      });
    }
    if (action === 'view') {
      navigate(`/view_payment/${findEditItem?.id}`, {
        state: {
          activeTab: 3,
        },
      });
    }
  };
  const columns = [
    {
      name: 'Receipt Date',
      selector: (row) => moment(row?.orderDate).format('DD MMM YY'),
      sortable: false,
    },
    {
      name: 'Order #',
      selector: (row) => row?.order_id,
      sortable: true,
    },
    {
      name: 'Services',
      selector: (row) =>
        row?.type == 'used_car' ? 'Used Car listing' : 'New Car listing',
      sortable: false,
    },
    {
      name: 'Fee',
      selector: (row) => row?.total_amount,
      sortable: false,
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <ActionButton
          showViewButton
          notEdit
          row={row}
          onView={() => handleEditDelete(row?.id, 'view')}
        />
      ),
      sortable: false,
    },
  ];
  const salespersonRow = [
    {
      name: 'Name',
      selector: (seller) => seller?.user?.user_details?.first_name,
      sortable: false,
    },
    {
      name: 'Mobile Number',
      selector: (seller) => seller.user.user_details.phone,
      sortable: false,
    },
    {
      name: 'Total Enquiries',
      selector: (seller) => seller?.total_enquiries,
      sortable: false,
    },
    {
      name: 'Total Listings',
      selector: (seller) => seller?.total_listings,
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (seller) => (
        <ActionButton
          row={seller}
          onEdit={(id) => handleEditDelete(id, 'edit')}
        />
      ),
      sortable: false,
    },
  ];
  const renderContent = (buttons, data, createBtnText, formPath) => {
    return (
      <>
        <div className="container mx-auto overflow-x-auto">
          <FilterButtons
            buttons={buttons}
            createBtnText={createBtnText}
            formPath={formPath}
            onFilterClick={filterHandler}
          />
        </div>
        <div className="container mx-auto p-4">
          {data?.map((listing, index) => (
            <ListingInfoCard
              key={index}
              {...listing}
              handleDropDownClick={(id, flag) => dropDownHandler(id, flag)}
            />
          ))}
        </div>
      </>
    );
  };

  const billingTab = (buttons, data, createBtnText, formPath) => {
    return (
      <>
        <div className="container mx-auto overflow-x-auto">
          <FilterButtons
            buttons={buttons}
            createBtnText={createBtnText}
            formPath={formPath}
            onFilterClick={onTabChange}
          />
        </div>
        <div className="p-5 mx-auto container">
          <BillingTable
            defaultPage={sellerPage}
            columns={columns}
            data={data?.rows}
            totalCount={data?.count}
            handlePageChange={onBillingPageChange}
          />
        </div>
      </>
    );
  };
  const salesTab = (data, createBtnText, formPath, stateValue) => {
    return (
      <>
        <div className="container mx-auto overflow-x-auto">
          <FilterButtons
            createBtnText={createBtnText}
            formPath={formPath}
            onFilterClick={onTabChange}
            stateValue={stateValue}
          />
        </div>

        <div className="p-5 mx-auto container">
          <BillingTable
            defaultPage={sellerPage}
            columns={salespersonRow}
            data={data?.rows}
            totalCount={data.count}
            handlePageChange={onSellerPageChange}
          />
        </div>
      </>
    );
  };
  const shortlistedTab = (buttons, data, createBtnText, stateValue) => {
    return (
      <>
        <div className="p-5 mx-auto container">
          <TabCarData
            stateValue={stateValue}
            shortlistedList={shortlistedList}
            newCarData={newCarData}
          />
        </div>
      </>
    );
  };

  let tabs = [];
  if (userData.type === 'merchant') {
    tabs = [
      {
        label: 'User Car Posting',
        content: renderContent(
          postingfilterButtons,
          usedCarData,
          'Create Used Car Post',
          '/used-car-post'
        ),
      },
      {
        label: 'New Car Posting',
        content: renderContent(
          requestfilterButtons,
          newCarData,
          'Create New Car Post',
          '/new-car-post'
        ),
      },
      { label: 'Car Request', content: <div>Car Request</div> },
      {
        label: 'Billing',
        content: billingTab(billingfilterButtons, sitePaymentList, '', ''),
      },
      {
        label: 'Salesperson',
        content: salesTab(
          sellerList,
          'Create Salesperson',
          `/sales-parson-form/`,
          { activeTab: 4 }
        ),
      },
      {
        label: 'Setting',
        content: (
          <div>
            <SiteSettingForm />
          </div>
        ),
      },
    ];
  } else if (userData.type === 'customer') {
    tabs = [
      {
        label: 'My Posting',
        content: renderContent(
          postingfilterButtons,
          usedCarData,
          // requestListingsData,
          '',
          ''
        ),
      },
      {
        label: 'My Requests',
        content: renderContent(
          requestfilterButtons,
          requestListingsData,
          '',
          ''
        ),
      },
      {
        label: 'Shortlisted',
        content: shortlistedTab(shortlistedButtons, sitePaymentList, '', {
          activeTab: 2,
        }),
      },
      {
        label: 'Billing',
        content: billingTab(billingfilterButtons, sitePaymentList, '', ''),
      },

      {
        label: 'Setting',
        content: (
          <div>
            <SiteSettingForm />
          </div>
        ),
      },
    ];
  }
  return <MenuTabs setTabNo={setTabNo} tabs={tabs} />;
};

export default CarListing;
