import * as actionTypes from '../actions/actionTypes';

const initialState = {
  shortlistedList: [],
  singleShortList: null,
  loading: false,
  error: null,
  success: null,
};

const ShortlistedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHORTLISTED_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case actionTypes.FETCH_SHORTLISTED_SUCCESS:
      return {
        ...state,
        loading: false,
        shortlistedList: action.payload,
        error: null,
      };

    case actionTypes.FETCH_SHORTLISTED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ShortlistedReducer;
