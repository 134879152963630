import BaseController from './BaseController';
import { endpoint } from "./EndPoint";

export default class UserController extends BaseController {
	static async list(page, type) {
		try {

			const typeQueryParam = type ? `?type=${type}` : '';
			const pageQueryParam = page ? `&page=${page}` : '';

			return await this.axiosGet(`${endpoint.USER_LIST}${typeQueryParam}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(userId) {
		try {
			return await this.axiosGet(`${endpoint.USER_SINGLE}/${userId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(userData, type) {
		try {
			let path = ''
			if (type === 'merchant') {
				path = endpoint.USER_MERCHANT_CREATE
			} else if (type === 'customer') {
				path = endpoint.USER_CUSTOMER_CREATE
			} else if (type === 'user') {
				path = endpoint.USER_CREATE
			}
			return await this.axiosPost(path, userData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(userId, updatedUserData, type) {
		try {
			let path = ''
			if (type === 'merchant') {
				path = endpoint.USER_MERCHANT_UPDATE
			} else if (type === 'customer') {
				path = endpoint.USER_CUSTOMER_UPDATE
			} else if (type === 'user') {
				path = endpoint.USER_UPDATE
			}
			return await this.axiosPost(`${path}/${userId}`, updatedUserData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async merchantprofile(userId) {
		try {
			return await this.axiosGet(`${endpoint.MERCHANT_PROFILE_GET}/${userId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async getsellers(userId) {
		try {
			return await this.axiosGet(`${endpoint.MERCHANT_SELLERS_GET}?merchant_id=${userId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
