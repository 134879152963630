import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getInstantOfferOwnerIdTypes } from '../../../constants';
import { postGetInstantOffer } from '../../../store/actions';
 
const GetOfferNow = () => {
    const [showThanks, setShowThanks] = useState(false);
		const [name, setName] = useState(null);
		const [contactNumber, setContactNumber] = useState(null);
		const [carplate, setCarplate] = useState(null);
		const [idType, setIdType] = useState(null);
		const [lastFourDigits, setLastFourDigits] = useState(null);

    const offerSubmitHandler = async () => {
				let data = {
					name: name,
					contact_no: contactNumber,
					car_plate: carplate,
					id_type: idType,
					last_digits: lastFourDigits
				};
				let result = await postGetInstantOffer(data);
				if (!result) {
					toast.error('Something went wrong', {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					setShowThanks(true)
				}
    }

  return (
    <div className='container mx-auto w-full p-4 ms-0 lg:ms-20'>
        <h4 className='text-center text-[26px] md:text-[36px] font-black'>Get Instant Offer Now</h4>
        <p className='text-[20px] md:text-[24px] text-center'>From our Trusted Partners</p>

        {
            !showThanks && <div className='ms-0 md:ms-25 lg:ms-0 border border-solid border-[#D9D9D9] rounded p-5 mt-7 each-slide-effect shadow-4'>
            <div className='w-full'>
                <label className='block text-[16px]  md:text-[20px]' for="Name">Name</label>
                <input
									className='block border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2'
									placeholder=''
									onChange={(e) => setName(e.target.value)}
								/>
            </div>
  
            <div className='w-full mt-3'>
                <label className='block text-[16px]  md:text-[20px]' for="Name">Contact No.</label>
                <input
									className='block border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2'
									placeholder=''
									onChange={(e) => setContactNumber(e.target.value)}
								/>
            </div>
  
            <div className='w-full mt-3'>
                <label className='block text-[16px]  md:text-[20px]' for="Name">Carplate</label>
                <input
									className='block border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2'
									placeholder=''
									onChange={(e) => setCarplate(e.target.value)}
								/>
            </div>
  
            <div className='w-full mt-3'>
                <label className='block text-[16px]  md:text-[20px]' for="Name">Owner ID Type:</label>
                <select
									className='block border border-solid border-[#00000040] text-[16px]  md:text-[20px] p-2 rounded w-full mt-2'
									name="cars"
									id="cars"
									onChange={(e) => setIdType(e.target.value)}
								>
                    <option className='' value="volvo">Select</option>
										{getInstantOfferOwnerIdTypes.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
                </select>
            </div>
  
            <div className='w-full mt-3'>
                <label className='block text-[16px]  md:text-[20px]' for="Name">Owner ID No. Last 4 characters (eg. 123A)</label>
                <input
									className='block border border-solid border-[#00000040] text-[14px] p-2 rounded w-full mt-2'
									placeholder=''
									onChange={(e) => setLastFourDigits(e.target.value)}
								/>
            </div>
  
             {/* <div className='bg-[#f9f9f9] p-4 border border-solid border-[#D9D9D9] mt-7 w-fit rounded'>
                <div className='flex justify-start item-center'>
                  <input type='checkbox' />
                  <label className='m-2'>I'm not a robot</label>
                </div>
             </div> */}
  
             <div onClick={offerSubmitHandler} className='bg-[#0958D9] w-[225px] h-[46px] flex flex-row justify-center items-center rounded-sm rounded mt-7 cursor-pointer'>
                  <p className='text-white text-[16px] font-bold mr-2'>Get Offer</p>
              </div> 
  
          </div>
        }
        {
            showThanks && <div className='ms-0 md:ms-25 lg:ms-0 border border-solid border-[#D9D9D9] rounded p-7 mt-4 each-slide-effect shadow-4 h-[500px] flex justify-center items-center'>
            <div>
                <h4 className='text-center text-[36px] font-black text-[#10DCAB]'>Thank You</h4>
                <p className='text-[22px] text-center mt-4'>We have received your information.
                    Our partners will be contacting you soon.</p>
            </div>
        </div>
        }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postGetInstantOffer: (data) => dispatch(postGetInstantOffer(data)),
  };
};

export default connect(null, mapDispatchToProps)(GetOfferNow);
