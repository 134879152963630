import React from 'react';
// import car1 from '../../../assets/images/web/used_car/image 2.png';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SingleCarSlide from '../CarsComponents/SingleCarSlide';

const CarSlide = ({ carList, headText, onSearch, from, newCar }) => {
  return (
    <div
      className={`container mx-auto mb-5 p-3 lg:mt-15 my-4 ${newCar && 'my-0'}`}
    >
      <div>
        <p className="font-bold text-center text-3xl mt-8 mb-10">{headText}</p>
      </div>
      <Swiper
        // modules={[EffectFade]}
        effect="fade"
        spaceBetween={50}
        slidesPerView={3}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          280: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: 1,
          },
          639: {
            slidesPerView: 2,
          },
          865: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 2,
          },
          1120: {
            slidesPerView: 3,
          },
          1300: {
            slidesPerView: 4,
          },
          1500: {
            slidesPerView: 4,
          },
          1700: {
            slidesPerView: 4,
          },
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {carList.map((car, index) => (
          <SwiperSlide key={index}>
            <SingleCarSlide data={car} from={from} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className="font-semibold flex items-center justify-center mb-14 mt-10 border-b w-fit mx-auto border-[#0534FF] cursor-pointer"
        onClick={onSearch}
      >
        <p className="mx-1">View more deals </p>
        {/* <p>({carList?.length} Cars)</p> */}
        <p>(10,000 Cars)</p>
        <IoIosArrowRoundForward size={40} />
      </div>
    </div>
  );
};

export default CarSlide;
