import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Layout from '../../../components/admin/Layout/Base/Layout';
import SubModelForm from './SubModelForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import CarSubModelTable from '../../../components/admin/Table/Table';
import { fetchCarSubModelSingle, updateCarSubModel, fetchCarModelList, createCarSubModel, fetchFuelTypeList, fetchCarSubModelList } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';



const Brands = (props) => {
    const { loading, error, success, deleteBrand, fetchCarSubModelSingle, singleCarSubModel, updateCarSubModel,
        carModelList, fetchCarModelList, fetchFuelTypeList, fuelTypeList, fetchCarSubModelList, carSubModelList, createCarSubModel, } = props;

    const [showTable, setShowTable] = useState(true);
    const [buttonLabel, setButtonLabel] = useState('Add');
    const [editingId, setEditingId] = useState(null);
    const [page, setPage] = useState(1)
    const [subModelEditData, setSubModelEditData] = useState({})

    const fetchData = async () => {
        await Promise.all([
            fetchCarSubModelList(page, false, 0),
            fetchFuelTypeList('all', true, true),
            fetchCarModelList('all', true, true),
        ]);
    };

    useEffect(() => {
        fetchData();
    }, [true, page]);

    useEffect(() => {
        if (singleCarSubModel) {
            console.log(singleCarSubModel);
            setSubModelEditData({
                model_id: singleCarSubModel.model_id,
                name: singleCarSubModel.key_info.name,
                price: singleCarSubModel.key_info.price,
                instalment: singleCarSubModel.key_info.instalment,
                depreciation: singleCarSubModel.key_info.depreciation,
                coe_price: singleCarSubModel.key_info.coe_price,
                road_tax: singleCarSubModel.key_info.road_tax,
                omv: singleCarSubModel.key_info.omv,
                arf: singleCarSubModel.key_info.arf,
                ves: singleCarSubModel.key_info.ves,
                total_basic: singleCarSubModel.key_info.total_basic,
                premium: singleCarSubModel.key_info.premium,

                seating_capacity: singleCarSubModel.measurement.seating_capacity,
                wheelbase: singleCarSubModel.measurement.wheelbase,
                dimension_length: singleCarSubModel.measurement.dimension_length,
                turning_radius: singleCarSubModel.measurement.turning_radius,
                dimension_width: singleCarSubModel.measurement.dimension_width,
                dimension_height: singleCarSubModel.measurement.dimension_height,
                kerb_weight: singleCarSubModel.measurement.kerb_weight,
                tank_capacity: singleCarSubModel.measurement.tank_capacity,
                cargo_capacity: singleCarSubModel.measurement.cargo_capacity,

                engine_capacity: singleCarSubModel.enginer_transmission.engine_capacity,
                engine_type: singleCarSubModel.enginer_transmission.engine_type,
                battery_type: singleCarSubModel.enginer_transmission.battery_type,
                fuel_type_id: singleCarSubModel.enginer_transmission.fuel_type_id,
                drive_type: singleCarSubModel.enginer_transmission.drive_type,
                transmission_type: singleCarSubModel.enginer_transmission.transmission_type,
                transmission: singleCarSubModel.enginer_transmission.transmission,

                power: singleCarSubModel.performance.power,
                engine_power: singleCarSubModel.performance.engine_power,
                motor_power: singleCarSubModel.performance.motor_power,
                torque: singleCarSubModel.performance.torque,
                acceleration: singleCarSubModel.performance.acceleration,
                top_speed: singleCarSubModel.performance.top_speed,
                fuel_consumption: singleCarSubModel.performance.fuel_consumption,
                co2_emission: singleCarSubModel.performance.co2_emission,
                battery_capacity: singleCarSubModel.performance.battery_capacity,
                drive_range: singleCarSubModel.performance.drive_range,
                energy_consumption: singleCarSubModel.performance.energy_consumption,
                dc_charging_rate_max: singleCarSubModel.performance.dc_charging_rate_max,
                dc_charging_rate_total: singleCarSubModel.performance.dc_charging_rate_total,
                ac_charging_rate_max: singleCarSubModel.performance.ac_charging_rate_max,
                ac_charging_rate_total: singleCarSubModel.performance.ac_charging_rate_total,

                suspension_front: singleCarSubModel.component.suspension_front,
                suspension_back: singleCarSubModel.component.suspension_back,
                break_front: singleCarSubModel.component.break_front,
                break_rear: singleCarSubModel.component.break_rear,
                wheel_rim_size: singleCarSubModel.component.wheel_rim_size,

                head_light: singleCarSubModel.other_component.head_light,
                is_auto_head_light: singleCarSubModel.other_component.is_auto_head_light,
                daytime_lights: singleCarSubModel.other_component.daytime_lights,
                rain_sensing_wipers: singleCarSubModel.other_component.rain_sensing_wipers,
                electric_folding_side_mirror: singleCarSubModel.other_component.electric_folding_side_mirror,
                multi_function_steering_wheel: singleCarSubModel.other_component.multi_function_steering_wheel,
                paddle_shifters: singleCarSubModel.other_component.paddle_shifters,
                keyless_entry: singleCarSubModel.other_component.keyless_entry,
                keyless_engine_start: singleCarSubModel.other_component.keyless_engine_start,
                multi_zone_aircon: singleCarSubModel.other_component.multi_zone_aircon,
                rear_aircon: singleCarSubModel.other_component.rear_aircon,
                seat_upholstery: singleCarSubModel.other_component.seat_upholstery,
                driver_electric_seat: singleCarSubModel.other_component.driver_electric_seat,
                passenger_electric_seat: singleCarSubModel.other_component.passenger_electric_seat,
                ventilated_seats: singleCarSubModel.other_component.ventilated_seats,
                electric_tailgate: singleCarSubModel.other_component.electric_tailgate,
                car_roof: singleCarSubModel.other_component.car_roof,
                bluetooth: singleCarSubModel.other_component.bluetooth,
                wireless_charging: singleCarSubModel.other_component.wireless_charging,
                navigation_system: singleCarSubModel.other_component.navigation_system,
                apple_carplay: singleCarSubModel.other_component.apple_carplay,
                android_auto: singleCarSubModel.other_component.android_auto,
                infotainment: singleCarSubModel.other_component.infotainment,
                sound_system: singleCarSubModel.other_component.sound_system,
								no_of_airbags: singleCarSubModel.other_component.no_of_airbags,
								isofix: singleCarSubModel.other_component.isofix,
								tyre_presure_monitor: singleCarSubModel.other_component.tyre_presure_monitor,
								electric_park_brake: singleCarSubModel.other_component.electric_park_brake,
								hill_start_assist: singleCarSubModel.other_component.hill_start_assist,
								emergency_brake_assist: singleCarSubModel.other_component.emergency_brake_assist,
								parking_sensors: singleCarSubModel.other_component.parking_sensors,
								park_assist: singleCarSubModel.other_component.park_assist,
								reverse_camera: singleCarSubModel.other_component.reverse_camera,
								view_camera: singleCarSubModel.other_component.view_camera,
								cruise_control: singleCarSubModel.other_component.cruise_control,
								lane_departure_warning: singleCarSubModel.other_component.lane_departure_warning,
								blind_spot_monitor: singleCarSubModel.other_component.blind_spot_monitor,
								aditional_feature: singleCarSubModel.other_component.aditional_feature
            })
        }
    }, [singleCarSubModel])

    useEffect(() => {
        if (success) {
            toast.success(success, {
                position: toast.POSITION.TOP_RIGHT,
                onOpen: () => {
                    fetchCarSubModelList(page, false, 0);
                    setShowTable(true)
                    setButtonLabel('Add')
                },
                onClose: () => {
                }
            });
        }

        if (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [success, error])

    const handleEditDelete = (id, action) => {
        console.log(id);
        if (action === 'edit') {
            console.log(id);
            setEditingId(id)
            setShowTable(false)
            setButtonLabel('List')
            fetchCarSubModelSingle(id)
        } else if (action === 'delete') {
            deleteBrand(id);
        }
    };

    const handleButtonClick = () => {
        setShowTable(!showTable);
        setButtonLabel(showTable ? 'List' : 'Add');
        setEditingId(null)
    };

    const onPageChange = (page) => {
        setPage(page)
    };

    const onSubmit = (values) => {
        if (editingId) {
            updateCarSubModel(editingId, values)
        } else {
            createCarSubModel(values);
        }
    };

    const columns = [
        {
            name: 'Id',
            selector: (row, index) => index + 1,
            sortable: true
        },
        {
            name: 'Model Name',
            selector: (row) => row.car_model.name,
            sortable: true,
        },
        {
            name: 'Sub Model Name',
            selector: (row) => row.key_info.name,
            sortable: true,
        },
        {
            name: 'Price',
            selector: (row) => row.key_info.price,
            sortable: true,
        },
        {
            name: 'Depreciation',
            selector: (row) => row.key_info.depreciation,
            sortable: true,
        },
        {
            name: 'Fuel Economy',
            selector: (row) => row.performance.fuel_consumption,
            sortable: true,
        },
        {
            name: 'Power',
            selector: (row) => row.performance.power,
            sortable: true,
        },
        {
            name: 'Actions',
            selector: (row) => (
                <ActionButton
                    row={row}
                    onEdit={id => handleEditDelete(id, 'edit')}
                    showDeleteButton={false}
                />
            ),
            sortable: false
        },
    ];

    const renderContent = () => {
        if (showTable) {
            return <CarSubModelTable  defaultPage={page} columns={columns} data={carSubModelList.rows == undefined ? [] : carSubModelList.rows} handlePageChange={onPageChange} totalCount={carSubModelList.count}/>
        }

        if (editingId) {
            return <SubModelForm onSubmit={onSubmit} initialValues={subModelEditData} onCancel={handleButtonClick} carModelList={carModelList} fuelTypeList={fuelTypeList} />
        }


        return <SubModelForm onSubmit={onSubmit} onCancel={handleButtonClick} carModelList={carModelList} fuelTypeList={fuelTypeList} />;
    };

    return (
        <Layout>
            <div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
                <div className="flex flex-col gap-1">
                    <div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
                        <SectionHeader title="Sub Model" buttonText={buttonLabel} onButtonClick={handleButtonClick} />
                        {loading && <Loader />}
                        {renderContent()}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.carSubModel.loading,
        error: state.carSubModel.error,
        success: state.carSubModel.success,
        fuelTypeList: state.fuelType.fuelTypeList,
        carSubModelList: state.carSubModel.carSubModelList,
        singleCarSubModel: state.carSubModel.singleCarSubModel,
        carModelList: state.carModel.carModelList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCarSubModelSingle: (id) => dispatch(fetchCarSubModelSingle(id)),
        updateCarSubModel: (id, data) => dispatch(updateCarSubModel(id, data)),

        createCarSubModel: (data) => dispatch(createCarSubModel(data)),
        fetchFuelTypeList: (page, isProcess, isPublic) => dispatch(fetchFuelTypeList(page, isProcess, isPublic)),
        fetchCarSubModelList: (page, isProcess, id) => dispatch(fetchCarSubModelList(page, isProcess, id)),
        fetchCarModelList: (page, isProcess, isPublic) => dispatch(fetchCarModelList(page, isProcess, isPublic)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
