import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
import FormField from '../../../components/common/FormFileds/Admin/FormField';
import FileUpload from '../../../components/common/FormFileds/Admin/FileUpload';
import BrandSchema from '../../../schemas/BrandSchema';
import { options } from '../../../constants';


const BrandForm = (props) => {
	const { initialValues, onSubmit } = props;

	let initVal = {
		name: '',
		status: '',
		is_feature: 0,
		image: '',
		isImageRequired: true
	}
	const formik = useFormik({
		initialValues: initialValues || initVal,
		validationSchema: BrandSchema,
		onSubmit,
	});

	useEffect(() => {
		formik.setValues(initialValues || initVal);
	}, [initialValues]);

	return (
		<AdminForm formik={formik} onBtnCancelClick={props.onCancel}>
			<div className="p-6.5">

				<FileUpload formik={formik} id={"image"} brand/>
				<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
					<div className="w-full xl:w-1/2">
						<FormField type="text" label={"Name"} id={"name"} formik={formik} />
					</div>

					<div className="w-full xl:w-1/2">
						<FormField type="select" label={"Status"} id={"status"} options={options} formik={formik} />
					</div>

					<div className="w-full xl:w-1/2">
						<FormField type="checkbox" label={"Featured"} id={"is_feature"} formik={formik} />
					</div>
				</div>


			</div>
		</AdminForm>
	);
};

export default BrandForm;
