import SubscriptionController from '../../api/SubscriptionController';
import * as actionTypes from './actionTypes';

export const subsCriptionInit = () => {
  return {
    type: actionTypes.SUBSCRIPTION_INIT,
  };
};

export const createSubscriptionSuccess = (message) => {
  return {
    type: actionTypes.SUBSCRIPTION_CREATE_SUCCESS,
    payload: message,
  };
};

export const createSubscriptionFailure = (error) => {
  return {
    type: actionTypes.SUBSCRIPTION_CREATE_FAILURE,
    payload: error,
  };
};

export const createSubscription = (subscriptionData) => {
  return async (dispatch) => {
    dispatch(subsCriptionInit());
    SubscriptionController.create(subscriptionData)
      .then((result) => {
        console.log(subscriptionData);
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(createSubscriptionFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(createSubscriptionSuccess(result.data.message));
        } else {
          dispatch(createSubscriptionFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(createSubscriptionFailure('Something went wrong'));
      });
  };
};
