import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Layout from '../../../components/admin/Layout/Base/Layout';
import NewCarForm from './NewCarForm';
import CreateNewCarForm from './CreateNewCarForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import NewCarTable from '../../../components/admin/Table/Table';
import {
  createNewCar,
  createUsedCar,
  fetchBrandList,
    fetchCarCategoryList,
    fetchCarModelList,
    fetchCarSubModelList,
    fetchColorList,
    fetchCountryList,
    fetchFuelTypeList,
    fetchMerchantList,
    fetchNewCarList,
    fetchNewCarSingle,
    fetchVehicleTypeList,
    updateNewCar
} from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NewCarPayment from './NewCarPayment';
import NewCarSubModel from './NewCarSubModel';
import { deleteImageCarSuccess } from '../../../store/actions/UsedCarAction';

const NewCar = (props) => {
  const {
    loading,
    error,
    success,
    fetchNewCarList,
    newCarList,
    fetchBrandList,
    brandList, 
    fetchCarModelList,
    carModelList,
    fetchFuelTypeList,
    fuelTypeList,
    fetchVehicleTypeList,
    vehicleTypeList,
    fetchCarCategoryList,
    carCategoryList,
    fetchColorList,
    colorList,
    merchantList,
    carSubModelList,
    fetchCarSubModelList,
    createNewCar,
    fetchMerchantList,
    singleNewCar,
    fetchNewCarSingle,
    updateNewCar,
    fetchCountryList,
		countryList,
    deleteImageCarSuccess
  } = props;
  const [showTable, setShowTable] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [buttonLabel, setButtonLabel] = useState('Add');
  const [isLoading, setIsLoading] = useState(false);
  const [boostingId, setBoostingId] = useState('');
  const [editItem, setEditItem] = useState({})
  const [selectedColors, setSelectedColors] = useState([])
  const [selectedSubModels, setSelectedSubModels] = useState([])

  const [subModelData, setSubModelData] = useState([]);
  const [page, setPage] = useState(1);
  const [errors, setErrors] = useState({subModelErr:'',colorErr: ''});

  useEffect(() => {
    deleteImageCarSuccess({})
    // setShowTable(true);
  }, []);

  const fetchData = async () => {
    await Promise.all([
        fetchNewCarList(page),
        fetchBrandList('all', true),
        fetchCarModelList('all', true),
        fetchFuelTypeList('all', true),
        fetchVehicleTypeList('all', true),
        fetchCarCategoryList('all', true),
        fetchColorList('all', true, true),
        fetchMerchantList('all'),
        fetchCarSubModelList('all', true),
        fetchCountryList('all', true),
    ]);
  };

  useEffect(() => {
    fetchData();
  }, [fetchNewCarList, page]);

  useEffect(() => {
    setSubModelData(carSubModelList);
  }, [carSubModelList]);

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: toast.POSITION.TOP_RIGHT,
        onOpen: () => {
          fetchNewCarList(page);
          setShowTable(true);
          setButtonLabel('Add');
        },
        onClose: () => {
          // setShowTable(true);
        },
      });
    }

    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [success, error]);

  const handleEditDelete = (id, action) => {
    setSelectedColors([])
    setSelectedSubModels([])
    if (action === 'edit') {
      setEditingId(id);
      setShowTable(false);
      setButtonLabel('List');
      fetchNewCarSingle(id);
    }
  }; 
  
  // useEffect(() => {
  //   if(selectedSubModels?.length > 0 ){
  //     setErrors({subModelErr: ''})
  //   }
  //   if (selectedColors?.length > 0 ){
  //     setErrors({colorErr: ''})
  //   }
  // },[selectedColors?.length, selectedSubModels?.length])

  const handleButtonClick = () => {
    setSelectedColors([])
    setSelectedSubModels([])
    setShowTable(!showTable);
    setButtonLabel(showTable ? 'List' : 'Add');
    setEditingId(null);
  };

  const onSubmit = (values) => {
   const sub_model_id = selectedSubModels?.map((item) => item?.value)
   const color_id = selectedColors?.map((item) => item?.value)

   const newCarObj = {
    ...values,
    submodel: sub_model_id,
    colors: color_id
   }

    // console.log('newCarObj', newCarObj)
    // return
    if (editingId) {
      const stringiFyImg = JSON.stringify(newCarObj?.images)
      const newEditValue = {...values, images: stringiFyImg}
      console.log('newCarObj', newEditValue)

      updateNewCar(editingId, newEditValue);
    } else {
      // console.log(values);
      createNewCar(newCarObj);
    }
  };
// console.log('singleNewCar', singleNewCar)
  const onPageChange = (page) => {
    setPage(page);
  };

  const columns = [
    {
      name: 'Id',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Brand',
      selector: (row) => row.brand?.name,
      sortable: true,
    },
    {
      name: 'Model',
      selector: (row) => row.car_model?.name,
      sortable: true,
    },
    {
      name: 'Ads Type',
      selector: (row) => row.add_on?.name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => {
        switch (row.status) {
          case 'active':
            return 'Active';
          case 'pending':
            return 'Pending';
          case 'inactive':
            return 'Inactive';
          default:
            return '';
        }
      },
      sortable: false,
    },
    {
      name: 'Feature',
      selector: (row) => (row.is_feature ? 'Featured' : 'Not Featured'),
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <ActionButton
          row={row}
          onEdit={(id) => handleEditDelete(id, 'edit')}
          // onDelete={(id) => handleEditDelete(id, 'delete')}
          // onBoost={(id) => handleBoost(id, row?.is_boost)}
          // showBoostBtn={true}
          isLoading={isLoading}
          // boostingId={boostingId}
        />
      ),
      sortable: false,
      width: '20%',
    },
  ];

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (showTable && !loading) {
      return ( 
        <NewCarTable
          columns={columns}
          data={newCarList?.rows == undefined ? [] : newCarList?.rows }
          totalCount={newCarList?.count}
          handlePageChange={onPageChange}
          defaultPage={page}
        />
      );
    }

    if (editingId && !loading) {
      return (
        <Tabs>
          <TabList>
            <Tab>Details</Tab>
            <Tab>Sub Models</Tab>
            <Tab>Payments</Tab>
          </TabList>
          <TabPanel>
            <NewCarForm
              onSubmit={onSubmit}
              initialValues={singleNewCar}
              onCancel={handleButtonClick}
              brandList={brandList}
              carModelList={carModelList ?? []}
              fuelTypeList={fuelTypeList}
              vehicleTypeList={vehicleTypeList}
              carCategoryList={carCategoryList}
              carSubModelList={subModelData ?? []}
              // onModelChange={(e) => handleModelChange(e)}
              colorList={colorList}
              selectedColors={selectedColors}
              setSelectedColors={setSelectedColors}
              selectedSubModels={selectedSubModels} 
              setSelectedSubModels={setSelectedSubModels}
              countryList={countryList}
            />
          </TabPanel>
          <TabPanel>
            <NewCarSubModel singleNewCar={singleNewCar}/>
          </TabPanel>
          <TabPanel>
            <NewCarPayment singleNewCar={singleNewCar} />
          </TabPanel>
        </Tabs>
      );
    }

    if (!loading) {
      return (
        <CreateNewCarForm
          onSubmit={onSubmit}
          onCancel={handleButtonClick}
          brandList={brandList}
          carModelList={carModelList ?? []}
          fuelTypeList={fuelTypeList}
          vehicleTypeList={vehicleTypeList}
          carCategoryList={carCategoryList}
          carSubModelList={subModelData ?? []}
          // onModelChange={(e) => handleModelChange(e)}
          colorList={colorList}
          userList={merchantList}
          selectedColors={selectedColors}
          setSelectedColors={setSelectedColors}
          selectedSubModels={selectedSubModels} 
          setSelectedSubModels={setSelectedSubModels}
          errors={errors}
          countryList={countryList}
        />
      );
    }
  };

  return (
    <Layout>
      <div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
        <div className="flex flex-col gap-1">
          <div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
            <SectionHeader
              title="New Cars"
              buttonText={buttonLabel}
              onButtonClick={handleButtonClick}
            />
            {renderContent()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.newcars.loading,
    error: state.newcars.error,
    success: state.newcars.success,
    newCarList: state.newcars.newCarList,
    brandList: state.brand.brandList,

    carModelList: state.carModel.carModelList,
    fuelTypeList: state.fuelType.fuelTypeList,
    vehicleTypeList: state.vehicleType.vehicleTypeList,
    carCategoryList: state.carCategory.carCategoryList,
    colorList: state.color.colorList,
    merchantList: state.merchant.merchantList,
    carSubModelList: state.carSubModel.carSubModelList,
    singleNewCar: state.newcars.singleNewCar,
    countryList: state.country.countryList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNewCarList: (page) => dispatch(fetchNewCarList(page)),
    fetchBrandList: (page, isProcess) =>
      dispatch(fetchBrandList(page, isProcess)),
    fetchCarModelList: (page, isProcess) =>
    dispatch(fetchCarModelList(page, isProcess)),
    fetchFuelTypeList: (page, isProcess) =>
    dispatch(fetchFuelTypeList(page, isProcess)),
  fetchVehicleTypeList: (page, isProcess) =>
    dispatch(fetchVehicleTypeList(page, isProcess)),
  fetchCarCategoryList: (page, isProcess) =>
    dispatch(fetchCarCategoryList(page, isProcess)),
    fetchColorList: (page, isProcess, isPublic) =>
      dispatch(fetchColorList(page, isProcess, isPublic)),
    fetchMerchantList: (page) => dispatch(fetchMerchantList(page)),
    fetchCarSubModelList: (page, isProcess) =>
      dispatch(fetchCarSubModelList(page, isProcess)),
      createNewCar: (data) => dispatch(createNewCar(data)),
      fetchNewCarSingle: (id) => dispatch(fetchNewCarSingle(id)),
      updateNewCar: (id, data) => dispatch(updateNewCar(id, data)),
      fetchCountryList: (page, isProcess) => dispatch(fetchCountryList(page, isProcess)),
      deleteImageCarSuccess: (data) => dispatch(deleteImageCarSuccess(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCar);
