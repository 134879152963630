import React from 'react';
import Layout from '../../../components/site/Layout/Base/Layout';
import BackAndShareBar from '../../../components/site/ProductComponents/BackAndShareBar';
import MarchantHeaderInfo from '../../../components/site/MerchantProfile/MarchantHeaderInfo';
import MerchantHeaderImg from '../../../assets/images/web/merchantimg.png';
import MerchantCarTab from '../../../components/site/MerchantProfile/MerchantCarTab';
import MarchantNewAndUsedCars from '../../../components/site/MerchantProfile/MarchantNewAndUsedCars';

const NewCarMarchentProfile = () => {
  return (
    <Layout>
      <BackAndShareBar heartShow={false} />
      <div className='lg:container lg:mx-auto md:container md:mx-auto  lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col px-0 md:px-4 lg:px-4 bg-[#f7f7f5]'>
            <div className='w-full lg:w-1/2'>
                <img src={MerchantHeaderImg} alt="" />
            </div>
            <MarchantHeaderInfo/>
        </div>
        <MerchantCarTab/>
        <div className='bg-[#CACACA] h-[2px] my-7 mx-4'></div>
        <MarchantNewAndUsedCars/>
    </Layout>
  );
};

export default NewCarMarchentProfile;
