import React from 'react';
import DynamicFormFields from '../../common/FormFileds/Auth/DynamicFormFields';


const MerchantRegistrationFormFields = (props) => {
	const fields = [
		{ name: 'company_name', label: 'Company Name', type: 'text' },
		{ name: 'uen', label: 'Company UEN', type: 'text' },
		{ name: 'name', label: 'Contact Name', type: 'text' },
		{ name: 'contact_no', label: 'Mobile Contact', type: 'text' },
		{ name: 'email', label: 'Email', type: 'text' },
	];

	return <DynamicFormFields formik={props.formik} fields={fields} />
};

export default MerchantRegistrationFormFields;
