import * as actionTypes from '../actions/actionTypes'


const initialState = {
	brandPublicList: [],
	brandList: [],
	singleBrand: null,
	loading: false,
	error: null,
	success: null
};

const brandReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.BRAND_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_BRAND_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				brandList: action.payload,
				error: null,
			};
		case actionTypes.FETCH_BRAND_LIST_FAILURE:
		case actionTypes.FETCH_BRAND_SINGLE_FAILURE:
		case actionTypes.CREATE_BRAND_FAILURE:
		case actionTypes.UPDATE_BRAND_FAILURE:
		case actionTypes.DELETE_BRAND_FAILURE:
		case actionTypes.FETCH_BRAND_PUBLIC_LIST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_BRAND_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleBrand: action.payload,
				error: null,
			};
		case actionTypes.CREATE_BRAND_SUCCESS:
			return {
				...state,
				loading: false,
				brandList: state.brandList,
				error: null,
				success: action.payload
			};
		case actionTypes.FETCH_BRAND_PUBLIC_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				brandPublicList: action.payload,
				error: null,
			};
		case actionTypes.UPDATE_BRAND_SUCCESS:
		case actionTypes.DELETE_BRAND_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};

		default:
			return state;
	}
};

export default brandReducer;
