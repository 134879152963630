// customerActions.js
import * as actionTypes from './actionTypes';
import UserController from '../../api/UserController';

export const merchantInit = () => {
  return {
    type: actionTypes.MERCHANT_INIT,
  };
};

export const fetchMerchantListSuccess = (merchants) => {
  return {
    type: actionTypes.FETCH_MERCHANT_LIST_SUCCESS,
    payload: merchants,
  };
};

export const fetchMerchantListFailure = (error) => {
  return {
    type: actionTypes.FETCH_MERCHANT_LIST_FAILURE,
    payload: error,
  };
};

export const fetchMerchantSingleSuccess = (merchant) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SINGLE_SUCCESS,
    payload: merchant,
  };
};

export const fetchMerchantSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_MERCHANT_SINGLE_FAILURE,
    payload: error,
  };
};

export const fetchMerchantProfile = (merchants) => {
  return {
    type: actionTypes.MERCHANT_PROFILE_GET,
    payload: merchants,
  };
};

export const fetchMerchantSellers = (sellers) => {
  return {
    type: actionTypes.SET_MERCHANT_SELLERS,
    payload: sellers,
  };
};

export const createMerchantSuccess = (message) => {
  return {
    type: actionTypes.CREATE_MERCHANT_SUCCESS,
    payload: message,
  };
};

export const createMerchantFailure = (error) => {
  return {
    type: actionTypes.CREATE_MERCHANT_FAILURE,
    payload: error,
  };
};

export const updateMerchantSuccess = (merchant) => {
  return {
    type: actionTypes.UPDATE_MERCHANT_SUCCESS,
    payload: merchant,
  };
};

export const updateMerchantFailure = (error) => {
  return {
    type: actionTypes.UPDATE_MERCHANT_FAILURE,
    payload: error,
  };
};

export const fetchMerchantList = (page) => {
  return async (dispatch) => {
    dispatch(merchantInit());
    UserController.list(page, 'merchant')
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchMerchantListFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchMerchantListSuccess(result.data.data));
        } else {
          dispatch(fetchMerchantListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchMerchantListFailure('Something went wrong'));
      });
  };
};

export const fetchMerchantSingle = (merchantId) => {
  return async (dispatch) => {
    dispatch(merchantInit());
    UserController.single(merchantId)
      .then((result) => {
        if (result instanceof Error) {
          // console.log(result.response.data);
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchMerchantSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          try {
            let addressObj = JSON.parse(result.data.data.address);
            result.data.data.address_one = addressObj.address_line1;
            result.data.data.address_two = addressObj.address_line2;
          } catch (error) {
            result.data.data.address_one = '';
            result.data.data.address_two = '';
          }

          dispatch(fetchMerchantSingleSuccess(result.data.data));
        } else {
          dispatch(fetchMerchantSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchMerchantSingleFailure('Something went wrong'));
      });
  };
};

export const createMerchant = (merchantData, type) => {
  return async (dispatch) => {
    dispatch(merchantInit());

    const addressJson = {
      address_line1: merchantData.address_one,
      address_line2: merchantData.address_two,
    };

    let formData = new FormData();

    formData.append('name', merchantData.name);
    formData.append('email', merchantData.email);
    formData.append('contact_no', merchantData.contact_no);
    formData.append('address', JSON.stringify(addressJson));
    formData.append('country_id', merchantData.country_id);
    formData.append('city_id', merchantData.city_id);
    formData.append('postal_code', merchantData.postal_code);
    formData.append('status', merchantData.status);
    formData.append('approve_status', merchantData.approve_status);
    formData.append('company_name', merchantData.company_name);
    formData.append('company_phone', merchantData.company_phone);
    formData.append('uen', merchantData.company_uen);
    formData.append('company_url', merchantData.company_url);

    if (merchantData.image && merchantData.image.length > 0) {
      merchantData.image.forEach((file, index) => {
        formData.append('image', file);
      });
    }

    UserController.create(formData, type)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(createMerchantFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(createMerchantSuccess(result.data.message));
        } else {
          dispatch(createMerchantFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(createMerchantFailure('Something went wrong'));
      });
  };
};

export const updateMerchant = (merchantId, updatedMerchantData, type) => {
  return async (dispatch) => {
    dispatch(merchantInit());

    const addressJson = {
      address_line1: updatedMerchantData.address_one,
      address_line2: updatedMerchantData.address_two,
    };

    let formData = new FormData();

    formData.append('name', updatedMerchantData.name);
    formData.append('email', updatedMerchantData.email);
    formData.append('contact_no', updatedMerchantData.contact_no);
    formData.append('address', JSON.stringify(addressJson));
    formData.append('country_id', updatedMerchantData.country_id);
    formData.append('city_id', updatedMerchantData.city_id);
    formData.append('postal_code', updatedMerchantData.postal_code);
    formData.append('status', updatedMerchantData.status);
    formData.append('approve_status', updatedMerchantData.approve_status);
    formData.append('company_name', updatedMerchantData.company_name);
    formData.append('company_phone', updatedMerchantData.company_phone);
    formData.append('uen', updatedMerchantData.company_uen);
    formData.append('company_url', updatedMerchantData.company_url);
    if (
      updatedMerchantData.image != undefined &&
      typeof updatedMerchantData.image !== 'string'
    ) {
      updatedMerchantData.image.forEach((file, index) => {
        formData.append('image', file);
      });
    }
    UserController.update(merchantId, formData, type)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updateMerchantFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updateMerchantSuccess(result.data.message));
        } else {
          dispatch(updateMerchantFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updateMerchantFailure('Something went wrong'));
      });
  };
};

export const getMerchantProfile = (merchantId) => {
  return async (dispatch) => {
    dispatch(merchantInit());
    UserController.merchantprofile(merchantId)
      .then((result) => {
        console.log('Merchant Profile Data =====>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          console.log('Merchant profile fetch failed');
        } else if (result.data.status !== 'Failed') {
          try {
            let addressObj = JSON.parse(result.data.data.address);
            result.data.data.address_one = addressObj.address_line1;
            result.data.data.address_two = addressObj.address_line2;
          } catch (error) {
            result.data.data.address_one = '';
            result.data.data.address_two = '';
          }

          dispatch(fetchMerchantProfile(result.data.data));
        } else {
          console.log('Merchant profile Something went wrong');
        }
      })
      .catch((error) => {
        console.log('Merchant profile Something went wrong');
      });
  };
};


export const getMerchantSellers = (merchantId) => {
  return async (dispatch) => {
    dispatch(merchantInit());
    UserController.getsellers(merchantId)
      .then((result) => {
        // console.log('Merchant sellers Data =====>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          console.log('Merchant sellers fetch failed');
        } else if (result.data.status !== 'Failed') {

          dispatch(fetchMerchantSellers(result.data.data));
        } else {
          console.log('Merchant sellers Something went wrong');
        }
      })
      .catch((error) => {
        console.log('Merchant sellers Something went wrong');
      });
  };
};