// merchantReducer.js
import * as actionTypes from "../actions/actionTypes";

const initialState = {
	loading: false,
	merchantList: [],
	singleMerchant: null,
	error: null,
	success: null,
	merchantProfile: null,
	sellers: []
};

const merchantReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.MERCHANT_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};

		case actionTypes.FETCH_MERCHANT_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				merchantList: action.payload,
				error: null,
			};

		case actionTypes.FETCH_MERCHANT_LIST_FAILURE:
			return {
				...state,
				loading: false,
				merchantList: [],
				error: action.payload,
			};

		case actionTypes.FETCH_MERCHANT_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleMerchant: action.payload,
				error: null,
			};

		case actionTypes.FETCH_MERCHANT_SINGLE_FAILURE:
			return {
				...state,
				loading: false,
				singleMerchant: null,
				error: action.payload,
			};

		case actionTypes.CREATE_MERCHANT_SUCCESS:
		case actionTypes.UPDATE_MERCHANT_SUCCESS:
			return {
				...state,
				loading: false,
				success: action.payload,
				error: null,
			};

		case actionTypes.CREATE_MERCHANT_FAILURE:
		case actionTypes.UPDATE_MERCHANT_FAILURE:
			return {
				...state,
				loading: false,
				success: null,
				error: action.payload,
			};
		case actionTypes.MERCHANT_PROFILE_GET:
			return {
				...state,
				loading: false,
				merchantProfile: action.payload,
				error: null,
			};
		case actionTypes.SET_MERCHANT_SELLERS:
			return {
				...state,
				loading: false,
				sellers: action.payload,
				error: null,
			};
		default:
			return state;
	}
};

export default merchantReducer;
