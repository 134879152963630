import * as yup from 'yup';

const MerchantRegisterSchema = yup.object({
	company_name: yup.string().required('Company Name is required'),
	uen: yup.string().required('Company UEN is required'),
	name: yup.string().required('Contact Name is required'),
	contact_no: yup.string()
		.matches(/^[0-9]+$/, 'Invalid contact number')
		.min(8, 'Contact number must be at least 8 characters')
		.required('Contact number is required'),
	email: yup.string().email('Invalid email address').required('Email is required'),
});

export default MerchantRegisterSchema;
