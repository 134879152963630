import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class FuelTypeController extends BaseController {
	static async list(page, isPublic) {
		try {
			const pageQueryParam = page ? `?page=${page}` : '';
			let path = isPublic ? endpoint.FUEL_TYPE_LIST_PUBLIC_LIST : endpoint.FUEL_TYPE_LIST
			return await this.axiosGet(`${path}${pageQueryParam}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async single(fuelTypeId) {
		try {
			return await this.axiosGet(`${endpoint.FUEL_TYPE_SINGLE}/${fuelTypeId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async create(fuelTypeData) {
		try {
			return await this.axiosPost(endpoint.FUEL_TYPE_CREATE, fuelTypeData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async update(fuelTypeId, updatedFuelTypeData) {
		try {
			return await this.axiosPost(`${endpoint.FUEL_TYPE_UPDATE}/${fuelTypeId}`, updatedFuelTypeData, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}

	static async delete(fuelTypeId) {
		try {
			return await this.axiosGet(`${endpoint.FUEL_TYPE_DELETE}/${fuelTypeId}`, { headers: this.getHeaders() });
		} catch (error) {
			return error;
		}
	}
}
