// actions.js
import * as actionTypes from './actionTypes';
import UsedCarListingController from './../../api/UsedCarListingController';

export const usedCarInit = () => {
  return {
    type: actionTypes.USED_CAR_INIT,
  };
};

export const imageInit = () => {
  return {
    type: actionTypes.IMAGE_INIT,
  };
};

export const multipleImageInit = () => {
  return {
    type: actionTypes.MULTIPLE_IMAGE_INIT,
  };
};

export const fetchUsedCarListSuccess = (brands) => {
  return {
    type: actionTypes.FETCH_USED_CAR_LIST_SUCCESS,
    payload: brands,
  };
};

export const fetchSuggestedUsedCarListFailure = (error) => {
  return {
    type: actionTypes.FETCH_SUGGESTED_USED_CAR_LIST_FAILURE,
    payload: error,
  };
};

export const fetchSuggestedUsedCarListSuccess = (brands) => {
  return {
    type: actionTypes.FETCH_SUGGESTED_USED_CAR_LIST_SUCCESS,
    payload: brands,
  };
};

export const fetchUsedCarListFailure = (error) => {
  return {
    type: actionTypes.FETCH_USED_CAR_LIST_FAILURE,
    payload: error,
  };
};

export const fetchUsedCarSingleSuccess = (brand) => {
  return {
    type: actionTypes.FETCH_USED_CAR_SINGLE_SUCCESS,
    payload: brand,
  };
};

export const fetchUsedCarSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_USED_CAR_SINGLE_FAILURE,
    payload: error,
  };
};

export const createUsedCarSuccess = (message) => {
  return {
    type: actionTypes.CREATE_USED_CAR_SUCCESS,
    payload: message,
  };
};

export const createUsedCarFailure = (error) => {
  return {
    type: actionTypes.CREATE_USED_CAR_FAILURE,
    payload: error,
  };
};

export const updateUsedCarSuccess = (brand) => {
  return {
    type: actionTypes.UPDATE_USED_CAR_SUCCESS,
    payload: brand,
  };
};

export const updateUsedCarFailure = (error) => {
  return {
    type: actionTypes.UPDATE_USED_CAR_FAILURE,
    payload: error,
  };
};

export const usedCarUpdateStatusSuccess = (brand) => {
  return {
    type: actionTypes.USED_CAR_UPDATE_STATUS_SUCCESS,
    payload: brand,
  };
};

export const usedCarUpdateStatusFailure = (error) => {
  return {
    type: actionTypes.USED_CAR_UPDATE_STATUS_FAILURE,
    payload: error,
  };
};

export const deleteUsedCarSuccess = (usedCarId) => {
  return {
    type: actionTypes.DELETE_USED_CAR_SUCCESS,
    payload: usedCarId,
  };
};

export const deleteUsedCarFailure = (error) => {
  return {
    type: actionTypes.DELETE_USED_CAR_FAILURE,
    payload: error,
  };
};

export const deleteImageCarSuccess = (usedCarId) => {
  return {
    type: actionTypes.DELETE_CAR_IMAGE_SUCCESS,
    payload: usedCarId,
  };
};

export const deleteImageCarFailure = (error) => {
  return {
    type: actionTypes.DELETE_CAR_IMAGE_FAILURE,
    payload: error,
  };
};


export const multipleImageCarSuccess = (multipleimgdata) => {
  return {
    type: actionTypes.CAR_MULTIPLE_IMAGE_SUCCESS,
    payload: multipleimgdata,
  };
};

export const multipleImageCarFailure = (error) => {
  return {
    type: actionTypes.CAR_MULTIPLE_IMAGE_FAILURE,
    payload: error,
  };
};

export const setMerchantUsedCar = (cars) => {
  return {
    type: actionTypes.GET_MERCHANT_USED_CAR,
    payload: cars,
  };
};
export const getSearchUsedCarSuccess = (cars) => {
  return {
    type: actionTypes.USED_CAR_SEARCH_LIST_SUCCESS,
    payload: cars,
  };
};
export const getSearchUsedCarFailure = (cars) => {
  return {
    type: actionTypes.USED_CAR_SEARCH_LIST_FAILURE,
    payload: cars,
  };
};

export const usedCarShortlistSaveSuccess = (usedCarId) => {
  return {
    type: actionTypes.USED_CAR_SHORT_LIST_SAVE_SUCCESS,
    payload: usedCarId,
  };
};

export const usedCarShortlistSaveFailure = (error) => {
  return {
    type: actionTypes.USED_CAR_SHORT_LIST_SAVE_FAILURE,
    payload: error,
  };
};

export const fetchUsedCarList = (
  page,
  isProcess = false,
  isPublic = false,
  searchParam = ''
) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.list(page, isPublic, searchParam)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchUsedCarListFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchUsedCarListSuccess(result.data.data));
        } else {
          dispatch(fetchUsedCarListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchUsedCarListFailure('Something went wrong'));
      });
  };
};

export const usedCarStatusUpdate = (id) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.updateStatus(id)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(usedCarUpdateStatusFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(usedCarUpdateStatusSuccess('Success'));
        } else {
          dispatch(usedCarUpdateStatusFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(usedCarUpdateStatusFailure('Something went wrong'));
      });
  };
};

export const fetchUsedCarSingle = (usedCarId, isPublic = false) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.single(usedCarId, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchUsedCarSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          console.log('result.data.status-----', result.data);
          result.data.data.email = result.data.data.user
            ? result.data.data.user?.email
            : '';
          result.data.data.phone = result.data.data.user
            ? result.data.data.user.user_details
              ? result.data.data.user.user_details?.phone
              : ''
            : '';
          result.data.data.name = result.data.data.user
            ? result.data.data.user.user_details
              ? result.data.data.user.user_details?.first_name
              : ''
            : '';
          dispatch(fetchUsedCarSingleSuccess(result.data.data));
        } else {
          dispatch(fetchUsedCarSingleFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchUsedCarSingleFailure('Something went wrong'));
      });
  };
};

export const createUsedCar = (data) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    let formData = new FormData();

    if (data.user_id != null) {
      formData.append('user_id', data.user_id);
    }
    formData.append('ads_type_id', data.ads_type_id);
    // if (data.ads_on_id !== '') {
    formData.append('ads_on_id', data.ads_on_id);
    // }
    if (data.model_id != '') {
      formData.append('model_id', data.model_id);
    }
    if (data.sub_model_id != '') {
      formData.append('sub_model_id', data.sub_model_id);
    }
    if (data.brand_id != '') {
      formData.append('brand_id', data.brand_id);
    }
    if (data.color_id != '') {
      formData.append('color_id', data.color_id);
    }
    formData.append('car_plate_no', data.car_plate_no);
    formData.append('owner_type_id', data.owner_id_type_id);
    formData.append('owner_id', data.owner_id);
    if (data.price !== '') {
      formData.append('asking_price', data.price);
    }

    if (data.mileage !== '') {
      formData.append('mileage', data.mileage);
    }
    formData.append('view_area', data.prefered_area);
    formData.append('allow_email', data.allow_email);
    formData.append('desc', data.desc);
    formData.append('chasis_number', data.chasis_number);

    if (data.sellers && data?.sellers?.length > 0) {
      formData.append('sellers', data.sellers);
    }
    if (data.promotions != null) {
      formData.append('promotions', data.promotions);
    }
    if (data.image && data.image.length > 0) {
      data.image.forEach((file) => {
        formData.append('images', file);
      });
    }

    if (data.totalPrice) {
      formData.append('totalPrice', data.totalPrice);
    }
    if (data.promo_code_id) {
      formData.append('promo_code_id', data.promo_code_id);
    }
    
    if (data.refId) {
      formData.append('payment_reference_id', data.refId);
    }

    
    UsedCarListingController.create(formData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(createUsedCarFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(createUsedCarSuccess(result.data.message));
        } else {
          dispatch(createUsedCarFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(createUsedCarFailure('Something went wrong'));
      });
  };
};
 
export const updateUsedCar = (usedCarId, data, isPublic = false) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    
    console.log('action data =====>>>', data)
    
    let newFormatedData = {...data}

    if (data.brand_id === "") {
      newFormatedData = {...newFormatedData, brand_id: null}   
    }

    if (data.model_id === "") {
      newFormatedData = {...newFormatedData, model_id: null}   
    }

    if (data.sub_model_id === "") {
      newFormatedData = {...newFormatedData, sub_model_id: null}
    }

    if (data.color_id === "") {
      newFormatedData = {...newFormatedData, color_id: null}
    }

    if (data.mileage === "") {
      newFormatedData = {...newFormatedData, mileage: 0}
    }

    if (data?.sellers) {
      newFormatedData = {...newFormatedData, sellers: data?.sellers?.toString()}
    }

    if (data?.category_id === "") {
      newFormatedData = {...newFormatedData, category_id: null}
    }

    if (data?.vehicle_type_id === "") {
      newFormatedData = {...newFormatedData, vehicle_type_id: null}
    }

    if (data?.transmission_type === "") {
      newFormatedData = {...newFormatedData, transmission_type: null}
    }

    if (data?.engine_capacity === "") {
      newFormatedData = {...newFormatedData, engine_capacity: 0}
    }

    if (data?.power === "") {
      newFormatedData = {...newFormatedData, power: 0}
    }

    if (data?.coe_expiry === "") {
      newFormatedData = {...newFormatedData, coe_expiry: null}
    }

    if (data?.ads_expire_at === "") {
      newFormatedData = {...newFormatedData, ads_expire_at: null}
    }

    if (data?.original_registration_date === "") {
      newFormatedData = {...newFormatedData, original_registration_date: null}
    }

    if (data?.registration_date === "") {
      newFormatedData = {...newFormatedData, registration_date: null}
    }

    if (data?.dereg_value === "") {
      newFormatedData = {...newFormatedData, dereg_value: 0}
    }

    if (data?.omv === "") {
      newFormatedData = {...newFormatedData, omv: 0}
    }

    if (data?.arf === "") {
      newFormatedData = {...newFormatedData, arf: 0}
    }

    if (data?.depreciation === "") {
      newFormatedData = {...newFormatedData, depreciation: 0}
    }

    if (data.price === "") {
      newFormatedData = {...newFormatedData, asking_price: 0, price: 0}
    }

    if (data.price !== "" && data?.price) {
      newFormatedData = {...newFormatedData, asking_price: data.price}
    }

    if (data.prefered_area) {
      newFormatedData = {...newFormatedData, view_area: data.prefered_area}
    }

    newFormatedData = {...newFormatedData, owner_type_id: data.owner_id_type_id}
    newFormatedData = {...newFormatedData, owner_id: data.owner_id}

    if (typeof data.images === 'string' && data.images.trim() === '"[]"') {
      delete newFormatedData.images;
    }

    

    console.log('newFormatedData =====>>>', newFormatedData)
    
    UsedCarListingController.update(usedCarId, newFormatedData, isPublic)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updateUsedCarFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updateUsedCarSuccess(result.data.message));
        } else {
          dispatch(updateUsedCarFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updateUsedCarFailure('Something went wrong'));
      });
  };
};

export const deleteUsedCar = (usedCarId) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.delete(usedCarId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(deleteUsedCarFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(deleteUsedCarSuccess(result.data.message));
        } else {
          dispatch(deleteUsedCarFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(deleteUsedCarFailure('Something went wrong'));
      });
  };
};
export const deleteImageUsedCar = (imgUrl) => {
  return async (dispatch) => {
    dispatch(imageInit());
    UsedCarListingController.deleteImage(imgUrl)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(deleteImageCarFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(deleteImageCarSuccess(result.data.message));
        } else {
          dispatch(deleteImageCarFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(deleteImageCarFailure('Something went wrong'));
      });
  };
};

export const enquiryCountUsedCar = (usedCarId) => {
  return async (dispatch) => {
    // dispatch(usedCarInit());
    UsedCarListingController.updateenquiry(usedCarId)
      .then((result) => {
        console.log('Enquiry Called Result ======>>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
        } else if (result.data.status !== 'Failed') {
          console.log('enquiry called failed');
        } else {
          console.log('enquiry called Something went wrong');
        }
      })
      .catch((error) => {
        console.log('enquiry called Something went wrong');
      });
  };
};

export const fetchMerchantUsedCar = (usedCarId, page) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.merchantusedcar(usedCarId, page)
      .then((result) => {
        console.log('Merchant Used Car Result =====>>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          console.log('Merchant Used Car Errmsg >>>', errorMsg);
        } else if (result.data.status !== 'Failed') {
          dispatch(setMerchantUsedCar(result.data.data));
        } else {
          console.log('Merchant Used Car Something went wrong');
        }
      })
      .catch((error) => {
        console.log('Merchant Used Car Something went wrong');
      });
  };
};

export const merchantCarClickCount = (usedCarId) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.updateclickcount(usedCarId)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          console.log('click called failed 1');
        } else if (result.data.status !== 'Failed') {
          console.log('click called success ');
        } else {
          console.log('click called Something went wrong 1');
        }
      })
      .catch((error) => {
        console.log('click called Something went wrong 2');
      });
  };
};

export const usedCarBoosting = (carId, boostData) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.usedcarboost(carId, boostData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            console.log('boosting respnse >>>', result.response);
          } else {
            errorMsg = 'Something went wrong';
          }
          console.log('boosting error >>>', result);
        } else if (result.data.status !== 'Failed') {
          console.log('boosting success >>>', result.data);
        } else {
          console.log('Something went wrong 1');
        }
      })
      .catch((error) => {
        console.log('Something went wrong 2');
      });
  };
};

export const fetchSuggestedUsedCarList = (searchParam = '') => {
  return async (dispatch) => {
    // dispatch(usedCarInit());
    UsedCarListingController.getSuggestedUsedCar(searchParam)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchSuggestedUsedCarListFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchSuggestedUsedCarListSuccess(result.data.data));
        } else {
          dispatch(fetchSuggestedUsedCarListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchSuggestedUsedCarListFailure('Something went wrong'));
      });
  };
};

export const fetchSearchUsedCar = (usedCarId, modelName) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.searchusedcar(usedCarId, modelName)
      .then((result) => {
        console.log('Admin search Used Car Result =====>>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          console.log('Admin search Used Car Errmsg >>>', errorMsg);
        } else if (result.data.status !== 'Failed') {
          dispatch(setMerchantUsedCar(result.data.data));
        } else {
          console.log('Admin search Used Car Something went wrong');
        }
      })
      .catch((error) => {
        console.log('Admin search Used Car Something went wrong');
      });
  };
};

export const fetchAdminSearchUsedCar = (status, featured, page) => {
  return async (dispatch) => {
    dispatch(usedCarInit());
    UsedCarListingController.searchedUsedCar(status, featured, page)
      .then((result) => {
        console.log('Admin search Used Car Result =====>>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(getSearchUsedCarFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(getSearchUsedCarSuccess(result.data.data));
        } else {
          dispatch(getSearchUsedCarFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(getSearchUsedCarFailure('Something went wrong'));
      });
  };
};

export const enquiryCountUsedCarSeller = (sellerId) => {
  console.log('Seller Enquiry Called ID ======>>>', sellerId);
  return async (dispatch) => {
    // dispatch(usedCarInit());
    UsedCarListingController.updatesellerenquiry(sellerId)
      .then((result) => {
        console.log('Seller Enquiry Called Result ======>>>', result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          console.log('enquiry called failed');
        } else if (result.data.status !== 'Failed') {
          console.log('enquiry called success');
        } else {
          console.log('Seller enquiry called Something went wrong');
        }
      })
      .catch((error) => {
        console.log('Seller enquiry called Something went wrong');
      });
  };
};

export const usedCarShortListSave = (shortData, shortType) => {
  return async (dispatch) => {
    // dispatch(usedCarInit());
    UsedCarListingController.shortlistcreate(shortData, shortType)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(usedCarShortlistSaveSuccess(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(usedCarShortlistSaveSuccess(result.data.message));
        } else {
          dispatch(usedCarShortlistSaveFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(usedCarShortlistSaveSuccess('Something went wrong'));
      });
  };
};


export const uploadMultipleImage = (data) => {
  return async (dispatch) => {
    dispatch(multipleImageInit());
    let formData = new FormData();
    
    if (data.images && data.images.length > 0) {
      data.images.forEach((file) => {
        formData.append('images', file);
      });
    }
    UsedCarListingController.multipleimgupload(formData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(multipleImageCarFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(multipleImageCarSuccess(result.data.data));
        } else {
          dispatch(multipleImageCarFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(multipleImageCarFailure('Something went wrong'));
      });
  };
};
