import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import AdminForm from '../../../components/admin/AdminForm/AdminForm';
// import FormField from '../../../components/common/FormFileds/Admin/FormField';
// import FileUpload from '../../../components/common/FormFileds/Admin/FileUpload';
import SubCarModelSchema from '../../../schemas/SubCarModelsSchema';
// import { options } from '../../../constants';
import EngineTransmission from '../../../components/admin/CustomTab/SubModel/EngineTransmission';
import Performance from '../../../components/admin/CustomTab/SubModel/Performance';
// import Transmission from '../../../components/admin/CustomTab/SubModel/Transmission';
import Measurements from '../../../components/admin/CustomTab/SubModel/Measurements';
import Suspension from '../../../components/admin/CustomTab/SubModel/Suspension';
import Brakes from '../../../components/admin/CustomTab/SubModel/Brakes';
import Wheels from '../../../components/admin/CustomTab/SubModel/Wheels';
import Exterior from '../../../components/admin/CustomTab/SubModel/Exterior';
import Interior from '../../../components/admin/CustomTab/SubModel/Interior';
import InfotainmentSystem from '../../../components/admin/CustomTab/SubModel/InfotainmentSystem';
import KeyInfo from '../../../components/admin/CustomTab/SubModel/KeyInfo';
import SafetyAndDrivingAssurance from '../../../components/admin/CustomTab/SubModel/SafetyAndDrivingAssurance';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import AdditionalFeatures from '../../../components/admin/CustomTab/SubModel/AdditionallFeatures';


const SubModelForm = (props) => {
    const navigate = useNavigate();

    const { initialValues, onSubmit, fuelTypeList, carModelList, onCancel } = props;

    let initVal = {
        model_id: '',
        name: '',
        price: '',
        instalment: '',
        depreciation: '',
        coe_price: '',
        road_tax: '',
        omv: '',
        arf: '',
        ves: '',
        total_basic: '',
        premium: '',
        engine_capacity: '',
        engine_type: '',
        battery_type: '',
        fuel_type_id: '',
        drive_type: '',
        transmission_type: '',
        transmission: '',
        power: '',
        engine_power: '',
        motor_power: '',
        torque: '',
        acceleration: '',
        top_speed: '',
        fuel_consumption: '',
        co2_emission: '',
        battery_capacity: '',
        drive_range: '',
        energy_consumption: '',
        dc_charging_rate_max: '',
        dc_charging_rate_total: '',
        ac_charging_rate_max: '',
        ac_charging_rate_total: '',
        suspension_front: '',
        suspension_back: '',
        break_front: '',
        break_rear: '',
        wheel_rim_size: '',
        head_light: '',
        is_auto_head_light: 0,
        daytime_lights: '',
        electric_folding_side_mirror: 0,
        rain_sensing_wipers: 0,
        multi_function_steering_wheel: 0,
        paddle_shifters: 0,
        keyless_entry: 0,
        keyless_engine_start: 0,
        multi_zone_aircon: 0,
        rear_aircon: 0,
        seat_upholstery: '',
        driver_electric_seat: 0,
        passenger_electric_seat: 0,
        ventilated_seats: 0,
        electric_tailgate: 0,
        car_roof: 0,
        bluetooth: 0,
        apple_carplay: '',
        android_auto: '',
        wireless_charging: 0,
        navigation_system: 0,
        infotainment: '',
        sound_system: '',
				no_of_airbags: '',
				isofix: false,
				tyre_presure_monitor: false,
				electric_park_brake: false,
				hill_start_assist: false,
				emergency_brake_assist: false,
				parking_sensors: '',
				park_assist: '',
				reverse_camera: false,
				view_camera: '',
				cruise_control: '',
				lane_departure_warning: false,
				blind_spot_monitor: false,
				aditional_feature: ''
    };

    const formik = useFormik({
        initialValues: initialValues || initVal,
        validationSchema: SubCarModelSchema,
        onSubmit,
    });

    console.log(formik.errors);
    useEffect(() => {
        formik.setValues(initialValues || initVal);
    }, [initialValues]);

    return (
        <Tabs>
            <TabList>
                <Tab>Key Info</Tab>
                <Tab>Engine & Transmission</Tab>
                <Tab>Performance</Tab>
                <Tab>Measurements</Tab>
                <Tab>Suspension</Tab>
                <Tab>Brakes </Tab>
                <Tab>Wheels</Tab>
                <Tab>Exterior</Tab>
                <Tab>Interior</Tab>
                <Tab>Infotainment System</Tab>
								<Tab>Safety & Driving Assurance</Tab>
								<Tab>Additional Features</Tab>
            </TabList>
            <AdminForm
                formik={formik}
                onBtnCancelClick={onCancel}
            >
                <TabPanel>
                    <KeyInfo formik={formik} carModelList={carModelList}/>
                </TabPanel>
                <TabPanel>
                    <EngineTransmission
                        formik={formik}
                        fuelTypeList={fuelTypeList}
                    />
                </TabPanel>
                <TabPanel>
                    <Performance formik={formik} />
                </TabPanel>
                <TabPanel>
                    <Measurements formik={formik} />
                </TabPanel>
                <TabPanel>
                    <Suspension formik={formik} />
                </TabPanel>
                <TabPanel>
                    <Brakes formik={formik} />
                </TabPanel>
                <TabPanel>
                    <Wheels formik={formik} />
                </TabPanel>
                <TabPanel>
                    <Exterior formik={formik} />
                </TabPanel>
                <TabPanel>
                    <Interior formik={formik} />
                </TabPanel>
                <TabPanel>
                    <InfotainmentSystem formik={formik} />
                </TabPanel>
								<TabPanel>
                    <SafetyAndDrivingAssurance formik={formik} />
                </TabPanel>
								<TabPanel>
                    <AdditionalFeatures formik={formik} />
                </TabPanel>
            </AdminForm>
        </Tabs>
    );
};

export default SubModelForm;
