// customerActions.js
import * as actionTypes from "./actionTypes";
import UserController from "../../api/UserController";

export const customerInit = () => {
	return {
		type: actionTypes.CUSTOMER_INIT,
	};
};

export const fetchCustomerListSuccess = (customers) => {
	return {
		type: actionTypes.FETCH_CUSTOMER_LIST_SUCCESS,
		payload: customers,
	};
};

export const fetchCustomerListFailure = (error) => {
	return {
		type: actionTypes.FETCH_CUSTOMER_LIST_FAILURE,
		payload: error,
	};
};

export const fetchCustomerSingleSuccess = (customer) => {
	return {
		type: actionTypes.FETCH_CUSTOMER_SINGLE_SUCCESS,
		payload: customer,
	};
};

export const fetchCustomerSingleFailure = (error) => {
	return {
		type: actionTypes.FETCH_CUSTOMER_SINGLE_FAILURE,
		payload: error,
	};
};

export const createCustomerSuccess = (message) => {
	return {
		type: actionTypes.CREATE_CUSTOMER_SUCCESS,
		payload: message,
	};
};

export const createCustomerFailure = (error) => {
	return {
		type: actionTypes.CREATE_CUSTOMER_FAILURE,
		payload: error,
	};
};

export const updateCustomerSuccess = (customer) => {
	return {
		type: actionTypes.UPDATE_CUSTOMER_SUCCESS,
		payload: customer,
	};
};

export const updateCustomerFailure = (error) => {
	return {
		type: actionTypes.UPDATE_CUSTOMER_FAILURE,
		payload: error,
	};
};

export const fetchCustomerList = (page) => {
	return async (dispatch) => {
		dispatch(customerInit());
		UserController.list(page, 'customer')
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response
						? result.response.data
							? result.response.data.message
							: "Something went wrong"
						: "Something went wrong";
					dispatch(fetchCustomerListFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					dispatch(fetchCustomerListSuccess(result.data.data));
				} else {
					dispatch(fetchCustomerListFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(fetchCustomerListFailure("Something went wrong"));
			});
	};
};

export const fetchCustomerSingle = (customerId) => {
	return async (dispatch) => {
		dispatch(customerInit());
		UserController.single(customerId)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = result.response
						? result.response.data
							? result.response.data.message
							: "Something went wrong"
						: "Something went wrong";
					dispatch(fetchCustomerSingleFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					try {
						let addressObj = JSON.parse(result.data.data.address)
						result.data.data.address_one = addressObj.address_line1
						result.data.data.address_two = addressObj.address_line2
					} catch (error) {
						result.data.data.address_one = ''
						result.data.data.address_two = ''
					}


					dispatch(fetchCustomerSingleSuccess(result.data.data));
				} else {
					dispatch(fetchCustomerSingleFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(fetchCustomerSingleFailure("Something went wrong"));
			});
	};
};

export const createCustomer = (customerData, type) => {
	return async (dispatch) => {
		dispatch(customerInit());

		const addressJson = {
			address_line1: customerData.address_one,
			address_line2: customerData.address_two
		};

		let formData = new FormData();
		formData.append("username", customerData.username);
		formData.append("name", customerData.name);
		formData.append("email", customerData.email);
		formData.append("contact_no", customerData.contact_no);
		formData.append("status", customerData.status);
		formData.append("address", JSON.stringify(addressJson));
		formData.append("country_id", customerData.country_id);
		formData.append("city_id", customerData.city_id);
		formData.append("postal_code", customerData.postal_code);
		formData.append("image", customerData.image[0]);


		UserController.create(formData, type)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = "";
					if (result.response) {
						if (
							result.response.data &&
							!Array.isArray(result.response.data.message)
						) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = "Something went wrong";
						}
					} else {
						errorMsg = "Something went wrong";
					}
					dispatch(createCustomerFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					dispatch(createCustomerSuccess(result.data.message));
				} else {
					dispatch(createCustomerFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(createCustomerFailure("Something went wrong"));
			});
	};
};

export const updateCustomer = (customerId, updatedCustomerData, type) => {
	return async (dispatch) => {
		dispatch(customerInit());

		const addressJson = {
			address_line1: updatedCustomerData.address_one,
			address_line2: updatedCustomerData.address_two
		};

		let formData = new FormData();
		formData.append("username", updatedCustomerData.username);
		formData.append("name", updatedCustomerData.name);
		formData.append("email", updatedCustomerData.email);
		formData.append("contact_no", updatedCustomerData.contact_no);
		formData.append("status", updatedCustomerData.status);
		formData.append("address", JSON.stringify(addressJson));
		formData.append("country_id", updatedCustomerData.country_id);
		formData.append("city_id", updatedCustomerData.city_id);
		formData.append("postal_code", updatedCustomerData.postal_code);
		if (updatedCustomerData.image != undefined && typeof updatedCustomerData.image !== 'string') {
			formData.append("image", updatedCustomerData.image[0]);
		}
		UserController.update(customerId, formData, type)
			.then((result) => {
				if (result instanceof Error) {
					let errorMsg = "";
					if (result.response) {
						if (
							result.response.data &&
							!Array.isArray(result.response.data.message)
						) {
							errorMsg = result.response.data.message;
						} else {
							errorMsg = "Something went wrong";
						}
					} else {
						errorMsg = "Something went wrong";
					}
					dispatch(updateCustomerFailure(errorMsg));
				} else if (result.data.status !== "Failed") {
					dispatch(updateCustomerSuccess(result.data.message));
				} else {
					dispatch(updateCustomerFailure("Something went wrong"));
				}
			})
			.catch((error) => {
				dispatch(updateCustomerFailure("Something went wrong"));
			});
	};
};
