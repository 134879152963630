import SellerController from '../../api/SellerController';
import * as actionTypes from './actionTypes';

export const sellerInit = () => {
  return {
    type: actionTypes.SELLER_INIT,
  };
};
export const fetchSellerListSuccess = (sellers) => {
  return {
    type: actionTypes.FETCH_SELLER_LIST_SUCCESS,
    payload: sellers,
  };
};

export const fetchSellerListFailure = (error) => {
  return {
    type: actionTypes.FETCH_SELLER_LIST_FAILURE,
    payload: error,
  };
};
export const createSellerSuccess = (message) => {
  return {
    type: actionTypes.CREATE_SELLER_SUCCESS,
    payload: message,
  };
};
export const createSellerFailure = (error) => {
  return {
    type: actionTypes.CREATE_SELLER_FAILURE,
    payload: error,
  };
};
export const fetchSellerSingleSuccess = (seller) => {
  return {
    type: actionTypes.FETCH_SELLER_LIST_SINGLE_SUCCESS,
    payload: seller,
  };
};

export const fetchSellerSingleFailure = (error) => {
  return {
    type: actionTypes.FETCH_SELLER_LIST_SINGLE_FAILURE,
    payload: error,
  };
};

export const updateSellerSuccess = (seller) => {
  return {
    type: actionTypes.UPDATE_SELLER_SUCCESS,
    payload: seller,
  };
};

export const updateSellerFailure = (error) => {
  return {
    type: actionTypes.UPDATE_SELLER_FAILURE,
    payload: error,
  };
};

export const fetchSellerList = (page, id) => {
  return async (dispatch) => {
    dispatch(sellerInit());
    SellerController.list(page, id)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchSellerListFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(fetchSellerListSuccess(result.data.data));

          // console.log(result.data.data);
        } else {
          dispatch(fetchSellerListFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(fetchSellerListFailure('Something went wrong'));
      });
  };
};

export const createSeller = (sellerData) => {
  sellerData['userType'] = 'seller';
  return async (dispatch) => {
    dispatch(sellerInit());
    SellerController.create(sellerData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(createSellerFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(createSellerSuccess(result.data.message));
        } else {
          dispatch(createSellerFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(createSellerFailure('Something went wrong'));
      });
  };
};

export const fetchSellerSingle = (userId) => {
  return async (dispatch) => {
    dispatch(sellerInit());
    SellerController.single(userId)
      .then((result) => {
        // console.log(result);
        if (result instanceof Error) {
          let errorMsg = result.response
            ? result.response.data
              ? result.response.data.message
              : 'Something went wrong'
            : 'Something went wrong';
          dispatch(fetchSellerSingleFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          let userData = {};
          // console.log('result.data.data', result.data.data);
          userData.username = result.data.data.user.username;
          userData.name = result.data.data.user.user_details.first_name;
          userData.email = result.data.data.user.email;
          userData.contact_no = result.data.data.user.user_details.phone;
          userData.address = result.data.data.user.user_details.address;
          userData.country_id = result.data.data.user.user_details.country_id;
          userData.postal_code = result.data.data.user.user_details.postal_code;
          userData.merchant_id = result.data.data.merchant_id;
          userData.status = result.data.data.user.status;
					userData.user_id = result.data.data.user.id;
					userData.user_details_id = result.data.data.user.user_details.id;
          dispatch(fetchSellerSingleSuccess(userData));
        } else {
          dispatch(fetchSellerSingleFailure('Something went wrong'));
        } 
      })
      .catch((error) => {
        dispatch(fetchSellerSingleFailure('Something went wrong'));
      });
  };
};

export const updateSeller = (sellerId, updatedSellerData) => {
  return async (dispatch) => {
    dispatch(sellerInit());
		// return;
    SellerController.update(sellerId, updatedSellerData)
      .then((result) => {
        if (result instanceof Error) {
          let errorMsg = '';
          if (result.response) {
            // console.log(sellerId, result.response);
            if (
              result.response.data &&
              !Array.isArray(result.response.data.message)
            ) {
              errorMsg = result.response.data.message;
            } else {
              errorMsg = 'Something went wrong';
            }
          } else {
            errorMsg = 'Something went wrong';
          }
          dispatch(updateSellerFailure(errorMsg));
        } else if (result.data.status !== 'Failed') {
          dispatch(updateSellerSuccess(result.data.message));
        } else {
          dispatch(updateSellerFailure('Something went wrong'));
        }
      })
      .catch((error) => {
        dispatch(updateSellerFailure('Something went wrong'));
      });
  };
};
