// import { object } from 'prop-types';
import * as yup from 'yup';

const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];

const BrandSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  status: yup.string().required('Status is required'),
  is_feature: yup.number().oneOf([0, 1]).required('Feature status is required'),
  isImageRequired: yup.boolean().default(false),
  image: yup
    .mixed()
    .test('required', 'Image is required', function (value) {
      const isImageRequired = this.resolve(yup.ref('isImageRequired'));
      if (isImageRequired && (!value || !value.length)) {
        return this.createError({ message: 'Image is required' });
      }
      return true;
    })
    .test(
      'fileType',
      'Invalid file type. Please select a valid image (JPEG, JPG, PNG).',
      (value) => {
        if (!value) {
          return true;
        }
        console.log('jpeg test', value);
        let fileExtension;
        if (typeof value === 'object') {
          fileExtension = value[0]?.name?.split('.')?.pop()?.toLowerCase();
          console.log(fileExtension, fileExtension);
        } else if (typeof value === 'string') {
          fileExtension = value?.split('.')?.pop()?.toLowerCase();
          console.log(value);
        }

        return allowedExtensions.includes(fileExtension);
      }
    )
    .test(
      'fileSize',
      'Invalid file size. Image size should not be greater than 105 x 105 pixels',
      function (value) {
        let img;
        if (!value) {
          return true;
        } else if (typeof value === 'object') {
          return new Promise((resolve, reject) => {
            img = new Image();
            img.onload = function () {
              if (img.width <= 105 && img.height <= 105) {
                resolve(true);
              } else {
                reject(
                  new yup.ValidationError(
                    'Invalid file size. Image size should not be greater than 105 x 105 pixels',
                    value,
                    'image'
                  )
                );
              }
            };
            img.onerror = function () {
              reject(
                new yup.ValidationError(
                  'Error loading the image.',
                  value,
                  'image'
                )
              );
            };
            console.log('2nd', value);
            console.log(typeof value);
            img.src = URL?.createObjectURL(value[0]);
          });
        } else if (typeof value === 'string') {
          // If it's a string, assume it's a URL
          img = new Image();
          return new Promise((resolve, reject) => {
            img.onload = function () {
              if (img.width <= 105 && img.height <= 105) {
                resolve(true);
              } else {
                reject(
                  new yup.ValidationError(
                    'Invalid file size. Image size should not be greater than 105 x 105 pixels',
                    value,
                    'image'
                  )
                );
              }
            };
            img.onerror = function () {
              reject(
                new yup.ValidationError(
                  'Error loading the image.',
                  value,
                  'image'
                )
              );
            };
            img.src = value;
          });
        }
      }
    ),
});

export default BrandSchema;
