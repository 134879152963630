import BaseController from './BaseController';
import { endpoint } from './EndPoint';

export default class AdSTypeController extends BaseController {
  static async list(page) {
    try {
      const pageQueryParam = page ? `?page=${page}` : '';
      return await this.axiosGet(`${endpoint.ADS_TYPE_LIST}${pageQueryParam}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async publicList(page) {
    try { 
      const pageQueryParam = page ? `?page=${page}` : '';
      return await this.axiosGet(`${endpoint.SITE_ADS_TYPE}${pageQueryParam}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }
  static async single(id) {
    try {
      return await this.axiosGet(`${endpoint.ADS_TYPE_LIST}/${id}`, {
        headers: this.getHeaders(),
      });
    } catch (error) {
      return error;
    }
  }

  static async update(id, updatedData) {
    try {
      return await this.axiosPost(
        `${endpoint.ADS_TYPE_UPDATE}/${id}`,
        updatedData,
        { headers: this.getHeaders() }
      );
    } catch (error) {
      return error;
    }
  }
}
