import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import MerchantRegisterSchema from '../../../schemas/MerchantRegisterSchema';
import Loader from '../../../components/common/Loader/Loader';

import add from '../../../assets/images/web/Home_Page_Advert_Image.png';

const PostAnadvert = (props) => {
	const initialValues = {
		company_name: '',
		uen: '',
		name: '',
		contact_no: '',
		email: '',
	};

	useEffect(() => {
		if (props.authSuccess) {
			toast.success(props.authSuccess, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					formik.resetForm();
				},
				onClose: () => { },
			});
		}
		if (props.autError) {
			toast.error(props.autError, {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	}, [props.authSuccess, props.autError]);

	const formik = useFormik({
		initialValues,
		validationSchema: MerchantRegisterSchema,
		onSubmit: (values) => {
			props.submitHandler(values);
		},
	});

	let renderContnet = '';
	if (props.authLoading) {
		renderContnet = (
			<div className="container mx-auto my-16">
				<div className="grid md:grid-cols-1 lg:grid-cols-2 grid-cols-1 gap-y-4 md:mx-auto">
					<Loader />
				</div>
			</div>
		);
	} else {
		renderContnet = (
			<form onSubmit={formik.handleSubmit}>
				<div className="grid grid-cols-2 gap-4 ">
					<div>
						<label htmlFor="company_name" className="">
							Company Name{' '}
						</label>
						<input
							id="company_name"
							name="company_name"
							type="text"
							className="w-full p-1.5 my-2 border-2 text-black border-gray-300 focus:outline-none"
							onChange={formik.handleChange}
							value={formik.values.company_name}
						/>
						{formik.errors.company_name && formik.touched.company_name && (
							<div className="text-[#F87171]">{formik.errors.company_name}</div>
						)}
						<label htmlFor="email" className="">
							Company Email
						</label>
						<input
							id="email"
							name="email"
							type="text"
							className="w-full p-1.5 my-2 border-2 text-black border-gray-300 focus:outline-none"
							onChange={formik.handleChange}
							value={formik.values.email}
						/>
						{formik.errors.email && formik.touched.email && (
							<div className="text-[#F87171]">{formik.errors.email}</div>
						)}
						<label htmlFor="contact_no" className="">
							Mobile Contact
						</label>
						<input
							id="contact_no"
							name="contact_no"
							type="text"
							className="w-full p-1.5 my-2 text-black border-2 border-gray-300 focus:outline-none"
							onChange={formik.handleChange}
							value={formik.values.contact_no}
						/>
						{formik.errors.contact_no && formik.touched.contact_no && (
							<div className="text-[#F87171]">{formik.errors.contact_no}</div>
						)}
					</div>
					<div>
						<label htmlFor="uen" className="">
							Company UEN
						</label>
						<input
							id="uen"
							name="uen"
							type="text"
							className="w-full p-1.5 my-2 border-2 text-black border-gray-300 focus:outline-none"
							onChange={formik.handleChange}
							value={formik.values.uen}
						/>
						{formik.errors.uen && formik.touched.uen && (
							<div className="text-[#F87171]">{formik.errors.uen}</div>
						)}
						<label htmlFor="name" className="">
							Contact Name
						</label>
						<input
							id="name"
							name="name"
							type="text"
							className=" w-full p-1.5 my-2 text-black border-2 border-gray-300 focus:outline-none"
							onChange={formik.handleChange}
							value={formik.values.name}
						/>
						{formik.errors.name && formik.touched.name && (
							<div className="text-[#F87171]">{formik.errors.name}</div>
						)}
					</div>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
					<div>
						<button
							type="submit"
							className="text-[#001A8A] w-full px-6 py-2 rounded bg-white my-7"
						>
							Create your account
						</button>
						<Link to={props.isAuth ? props.redirectTo : '/login'}>
							<p className="mb-3 underline text-white md:mx-auto sm:mx-auto cursor-pointer">
								Manage existing adverts
							</p>
						</Link>
					</div>
				</div>
			</form>
		);
	}

	return (
		<div className="container mx-auto my-16">
			<div className="grid md:grid-cols-1 lg:grid-cols-2 grid-cols-1 gap-y-4 md:mx-auto">
				<div className="bg-[#001A8A] text-white p-6 rounded">
					<p className="text-2xl font-bold my-1">Post an advert on Car Inc</p>
					<p>Affordable and effective price for vehicle merchant</p>
					<p className="my-3">
						Check out our:{' '}
						<Link to={'/sell-your-car'}>
							<span className="underline">advertising plan</span>
						</Link>
					</p>
					{renderContnet}
				</div>
				<div> 
					<img
						src={add}
						alt=""
						className="hidden sm:hidden md:hidden lg:block xl:block w-full h-full"
					/>
				</div>
			</div>
		</div>
	);
};

export default PostAnadvert;
