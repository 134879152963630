import * as yup from 'yup';

const allowedExtensions = ['jpg', 'jpeg', 'png'];

const CompanySettingSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	uen: yup.string().required('Company UEN is required'),
	email: yup.string().email('Invalid email address').required('Email is required'),
	address_one: yup.string(),
	address_two: yup.string(),
	country_id: yup.string().required('Country is required'),
	postal_code: yup.string().required('Postal code is required'),
	contact_no: yup.string().required('Phone number is required').min(8, 'Phone number must be at least 8 characters'),
	fb: yup.string().url('Invalid URL format'),
	insta: yup.string().url('Invalid URL format'),
	tiktok: yup.string().url('Invalid URL format'),
	youtube: yup.string().url('Invalid URL format'),
	image: yup
		.mixed()
		.test('fileType', 'Invalid file type. Please select a valid image (JPEG, JPG, PNG).', (value) => {
			if (!value || !value.name) {
				return true; // Allow undefined or null values
			}
			const fileExtension = value.name.split('.').pop().toLowerCase();
			return allowedExtensions.includes(fileExtension);
		}),
});

export default CompanySettingSchema;