import * as actionTypes from '../actions/actionTypes';

const initialState = {
  adsTypeList: [],
  singleAdsType: null,
  loading: false,
  publicAdsTypeList: [],
  error: null,
  success: null,
};

const AdsTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADS_TYPE_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case actionTypes.FETCH_ADS_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        adsTypeList: action.payload,
        error: null,
      };
    case actionTypes.PUBLIC_ADS_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        publicAdsTypeList: action.payload,
        error: null,
      };
    case actionTypes.FETCH_ADS_TYPE_LIST_FAILURE:
    case actionTypes.FETCH_ADS_TYPE_SINGLE_FAILURE:
    case actionTypes.PUBLIC_ADS_TYPE_FAILURE:
    case actionTypes.UPDATE_ADS_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_ADS_TYPE_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAdsType: action.payload,
        error: null,
      };
    case actionTypes.UPDATE_ADS_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    default:
      return state;
  }
};

export default AdsTypeReducer;
