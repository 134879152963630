import * as yup from 'yup';

const AddsOnsSChema = yup.object().shape({
    name: yup.string().required('Name is required'),
    status: yup.number().integer().required('Status is required'),
    price: yup.number().integer().required('Price is required'),

});

export default AddsOnsSChema;
