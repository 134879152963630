import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import AdminForm from '../../AdminForm/AdminForm';
import FormField from '../../../common/FormFileds/Admin/FormField';

const InfotainmentSystem = (props) => {
	const { formik } = props;
	return (
		<div className="p-6.5">
			<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
				<div className="w-full xl:w-1/2">
					<FormField
						type="checkbox"
						label="Bluetooth"
						id="bluetooth"
						placeholder=" "
						formik={formik}
					/>
				</div>

				<div className="w-full xl:w-1/2">
					<FormField
						type="checkbox"
						label="Wireless Smartphone Charging"
						id="wireless_charging"
						placeholder=" "
						formik={formik}
					/>
				</div>
				<div className="w-full xl:w-1/2">
					<FormField
						type="checkbox"
						label="Navigation System"
						placeholder=" "
						id="navigation_system"
						formik={formik}
					/>
				</div>
				{/* <div className="w-full xl:w-1/2">
          <FormField
            type="checkbox"
            label="Heads-up Display"
            placeholder=" "
            id="heads_up"
            formik={formik}
          />
        </div> */}

			</div>

			<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">

				<div className="w-full xl:w-1/2">
					<FormField
						type="text"
						label="Apple CarPlay"
						id="apple_carplay"
						placeholder=" "
						formik={formik}
					/>
				</div>
				<div className="w-full xl:w-1/2">
					<FormField
						type="text"
						label="Android Auto"
						id="android_auto"
						formik={formik}
						placeholder=" "
					/>
				</div>
			</div>

			<div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
				<div className="w-full xl:w-1/2">
					<FormField
						type="text"
						label="Infotainment"
						id="infotainment"
						placeholder=" "
						formik={formik}
					/>
				</div>
				<div className="w-full xl:w-1/2">
					<FormField
						type="text"
						label="Sound System"
						placeholder=" "
						id="sound_system"
						formik={formik}
					/>
				</div>
			</div>
		</div>
	);
};

export default InfotainmentSystem;
