import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Layout from '../../../components/admin/Layout/Base/Layout';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import PromoTable from '../../../components/admin/Table/Table';
import Loader from '../../../components/common/Loader/Loader';
import useSearch from '../../../hooks/useSearch';
import { buildQueryString } from '../../../helper/buildQueryString';
import {
	fetchOrderListByPromoCodeId
} from '../../../store/actions';
import moment from 'moment';


const PromoCodeWiseOrderList = (props) => {
	const {
		loading,
		error,
		success,
		OrderListByPromoCode,
		fetchOrderListByPromoCodeId,

	} = props;

	const { id } = useParams();



	const [showTable, setShowTable] = useState(false);
	const [page, setPage] = useState(1);

	useEffect(() => {
		setShowTable(true);
	}, [OrderListByPromoCode]);

	useEffect(() => {
		fetchOrderListByPromoCodeId(page, id, buildQueryString(queryParam));
	}, [fetchOrderListByPromoCodeId, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchOrderListByPromoCodeId(page, id, buildQueryString(queryParam));
					setShowTable(true);
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				},
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	}, [success, error]);



	const onPageChange = (page) => {
		setPage(page);
	};

	let queryParam = { add_type_id: '' }
	const { searchParam, handleFieldSearch, handleClearSearch, handleFilterClick } = useSearch(fetchOrderListByPromoCodeId, page, queryParam, id);
	const searchFields = [
		{
			name: 'add_type_id', label: 'Ad Type', type: 'select', options: [
				{ value: 1, label: 'Premium' },
				{ value: 2, label: 'Import Used' },
				{ value: 3, label: 'Consignment' },
				{ value: 4, label: 'Normal Ads' },
				{ value: 5, label: 'Feature Ads' },
			]
		},
	];


	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true,
			cell: (row, index) => (
				<div className="id-cell">
					{index + 1}
				</div>
			),
		},
		{

			name: 'Order Date',
			selector: (row) => moment(row?.orderDate).format('DD/MM/YYYY, HH:mm'),
			sortable: true,
			cell: (row) => (
				<div className="order-date-cell">
					{moment(row?.orderDate).format('DD/MM/YYYY, HH:mm')}
				</div>
			),
		},
		{
			name: 'Order Id',
			selector: (row) => row?.order_id,
			sortable: true,
		},
		{
			name: 'Customer',
			selector: (row) => row?.user?.username,
			sortable: true,
		},
		{

			name: 'Car Plate Id',
			selector: (row) => row?.used_car_listing?.car_plate_no,
			sortable: true,
		},
		{

			name: 'Sub Total Amount',
			selector: (row) => row?.order_items
				?.reduce((sum, order) => sum + order.price, 0)
				.toFixed(2),
			sortable: true,
		},
		{
			name: 'Discount Amount',
			selector: (row) => {
				// Sum the prices of all order items
				const sumPrices = row.order_items.reduce((sum, item) => sum + item.price, 0);
		
				if (row?.promo_codes?.type === 'percentage') {
					// Calculate percentage discount
					const discountAmount = (sumPrices * row.promo_codes.discount_amount / 100).toFixed(2);
					return discountAmount;
				} else if (row?.promo_codes?.type === 'fixed') {
					// Return fixed discount amount
					return row.promo_codes.discount_amount.toFixed(2);
				} else {
					// Handle case where promo_codes type is not fixed or percentage
					return '0.00';
				}
			},
			sortable: true,
		},
		{
			name: 'Total Amount',
			selector: (row) => row?.total_amount?.toFixed(2),
			sortable: true,
		},
	];

	const renderContent = () => {
		if (loading) {
			return <Loader />;
		}

		if (showTable && !loading) {
			return (
				<PromoTable
					defaultPage={page}
					columns={columns}
					searchFields={searchFields}
					data={OrderListByPromoCode.rows == undefined ? [] : OrderListByPromoCode.rows}
					totalCount={OrderListByPromoCode.count}
					handlePageChange={onPageChange}
					handleFieldSearch={handleFieldSearch}
					handleFilterClick={handleFilterClick}
					handleClearSearch={handleClearSearch}
					searchParam={searchParam}
				/>
			);
		}


	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader
							title="Order List"
						/>
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.payment.loading,
		error: state.payment.error,
		success: state.payment.success,
		OrderListByPromoCode: state.payment.OrderListByPromoCode,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrderListByPromoCodeId: (page, id, searchParam) => dispatch(fetchOrderListByPromoCodeId(page, id, searchParam)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeWiseOrderList);

