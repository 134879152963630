import * as actionTypes from '../actions/actionTypes';

const initialState = {
  sellerList: [],
  singleSeller: null,
  loading: false,
  error: null,
  success: null,
};

const SellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELLER_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
        // usedCarList:[]
      };
    case actionTypes.FETCH_SELLER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sellerList: action.payload,
        error: null,
        // success: null,
      };
    case actionTypes.FETCH_SELLER_LIST_FAILURE:
    case actionTypes.FETCH_SELLER_LIST_SINGLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_SELLER_LIST_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSeller: action.payload,
        error: null,
      };
    case actionTypes.CREATE_SELLER_SUCCESS:
    case actionTypes.UPDATE_SELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        sellerList: state.sellerList,
        error: null,
        success: action.payload,
      };
    case actionTypes.UPDATE_SELLER_FAILURE:
    case actionTypes.CREATE_SELLER_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SellerReducer;
