// Header.js
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { FaRegHeart } from 'react-icons/fa6';
import { IoIosArrowDown, IoIosArrowUp, IoIosLogOut } from 'react-icons/io';
import { MdDashboard } from 'react-icons/md';
import { RxAvatar, RxCross1 } from 'react-icons/rx';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuLogo } from '../../../assets/images/svgImages';
import { fetchPublicSettingSingle } from '../../../store/actions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = ({
  isAuth,
  onLogout,
  redirectTo,
  fetchPublicSettingSingle,
  publicSetting,
  userData,
}) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(!publicSetting || !publicSetting?.image){
      fetchPublicSettingSingle();
    }
  }, []);
  const toggleView = (e) => {
    if (e.target.id == 'View') {
      console.log('object');
      setMobileMenuOpen(false);
    }
  };
  const [isOn, setIsOn] = useState(false);
  const handleToggle = () => {
    setIsOn(!isOn);
  };

  
  return (
    <header className="container mx-auto  py-4 ">
      <div className="">
        <div className="lg:ml-.5">
          <ul className="flex justify-normal items-center gap-3 my-1">
            <li className="font-bold text-[#0534FF] cursor-pointer">Cars</li>
            {/* <li className="cursor-pointer">Commercial</li> */}
          </ul>
        </div>
        <div className="flex justify-between items-start md:items-center mt-5">
          <div className="flex flex-col justify-center  items-start gap-6 my-5">
            <Link to={'/'}>
              <div className="cursor-pointer w-[50px] md:w-[115px] -mt-6 md:-mt-0 h-full flex justify-center lg:justify-start items-center ">
                {
                  publicSetting?.image && <img
                  src={publicSetting?.image}
                  alt="CAR INC"
                  className="rounded-md  object-fill "
                />
                }
                
              </div>
            </Link>
          </div>
          <nav className={`order-first lg:order-2 w-fit `}>
            <div className=" mx-auto flex justify-between items-center">
              {/* Mobile Menu Button */}
              <div
                className=" lg:hidden cursor-pointer flex-col justify-center  items-center"
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              >
                <div className="flex justify-center  items-center my-1 ">
                  <MenuLogo size={24} />
                </div>
                {/* <IoMenuSharp /> */}
                <span> Menu</span>
              </div>

              {/* Mobile Menu */}
              <div
                onClick={toggleView}
                id="View"
                className={
                  isMobileMenuOpen
                    ? 'fixed lg:hidden left-0 top-0 w-[48%] sm:w-[42%] md:w-[30%]  h-full  bg-gray-3 ease-in-out duration-500 z-999999'
                    : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
                }
              >
                <div className="p-4 duration-300 cursor-pointer border-gray-600 relative">
                  <div className="flex justify-between items-center mt-10">
                    <p className="font-bold my-3">Menu</p>
                    <div>
                      <RxCross1
                        className="ml-auto "
                        onClick={() => setMobileMenuOpen(false)}
                      />
                    </div>
                  </div>
                  <div className="w-full me-auto absolute top-full left-0   py-2 px-4">
                    <ul>
                      <li className="my-4 cursor-pointer ">
                        <a href="#" className="">
                          Cars
                        </a>
                      </li>
                      <hr className="border-[#E8E7E6] mt-3" />

                      <li className="mb-4 mt-6 cursor-pointer ">
                        <Link to="/" className="">
                          Home
                        </Link>
                      </li>
                      <li
                        className={`my-4 cursor-pointer ${
                          pathname === '/used_car_home'
                            ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                            : 'text-gray-300'
                        }`}
                      >
                        <Link to="/used_car_home" className="">
                          Used Cars
                        </Link>
                      </li>
                      <li
                        className={`my-4 cursor-pointer ${
                          pathname === '/new_car_home'
                            ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                            : 'text-gray-300'
                        }`}
                      >
                        <Link to="/new_car_home" className="">
                          New Cars
                        </Link>
                      </li>

                      <li className="-mt-1 -mb-6 cursor-pointer">
                        <div
                          className="flex justify-between items-center"
                          onClick={handleToggle}
                        >
                          <p className=" ">Sell Your Car</p>
                          <button
                            type="button"
                            onClick={handleToggle}
                            className=""
                          >
                            {isOn ? (
                              <IoIosArrowDown size={22} />
                            ) : (
                              <IoIosArrowUp size={22} />
                            )}
                          </button>
                        </div>
                        <div className="ml-3 relative inline-block text-left">
                          {isOn && (
                            <div>
                              <p
                                className={`my-4 cursor-pointer ${
                                  pathname === '/sell-your-car'
                                    ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                                    : 'text-gray-300'
                                }`}
                              >
                                <Link to="/sell-your-car">Sell Your Car</Link>
                              </p>
                              <p
                                className={`my-4 cursor-pointer ${
                                  pathname === '/get-instant-offers'
                                    ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                                    : 'text-gray-300'
                                }`}
                              >
                                <Link to="/get-instant-offers">
                                  Get Instant Offer
                                </Link>
                              </p>
                            </div>
                          )}
                        </div>
                      </li>
                      <li
                        className={`${
                          pathname === '/car_requests'
                            ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                            : 'text-gray-300'
                        } my-4 cursor-pointer`}
                      >
                        <Link to="/car_requests">Car Requests</Link>
                      </li>
                      <li
                        className={`${
                          pathname === '/news'
                            ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                            : 'text-gray-300'
                        } my-4 cursor-pointer`}
                      >
                        <Link to="/news">News</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {/* Desktop Menu */}
          <nav className="hidden lg:block  mr-auto">
            <ul className=" hidden lg:flex lg:justify-start space-x-4 ">
              <li
                className={`mr-0 cursor-pointer ${
                  pathname === '/used_car_home'
                    ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                    : 'text-gray-300'
                }`}
              >
                <Link to="/used_car_home">Used Cars</Link>
              </li>
              <li
                className={`mr-4 cursor-pointer ${
                  pathname === '/new_car_home'
                    ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                    : 'text-gray-300'
                }`}
              >
                <Link to="/new_car_home">New Cars</Link>
              </li>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button
                    className={`inline-flex w-full justify-center  ${
                      pathname === '/sell-your-car' ||
                      pathname == '/get-instant-offers'
                        ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                        : 'text-gray-300'
                    }`}
                  >
                    Sell Your Car
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        <p className="px-2 py-2">
                          <Link to="/sell-your-car">Sell Your Car</Link>
                        </p>
                      </Menu.Item>
                      <Menu.Item>
                        <p className="px-2 py-2">
                          {' '}
                          <Link to="/get-instant-offers">
                            Get Instant Offer
                          </Link>
                        </p>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <li
                className={`mr-4 cursor-pointer ${
                  pathname === '/car_requests'
                    ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                    : 'text-gray-300'
                }`}
              >
                <Link to="/car_requests">Car Requests</Link>
              </li>
              <li
                className={`mr-4 cursor-pointer ${
                  pathname === '/news'
                    ? 'text-[#0534FF] font-semibold border-b  border-[#0534FF] mr-2 -p-5'
                    : 'text-gray-300'
                }`}
              >
                <Link to="/news">News</Link>
              </li>
            </ul>
          </nav>
          <div className="flex justify-between items-center md:gap-4 order-last gap-2.5  ">
            {(!isAuth || userData.type == 'customer') && (
              <div
                onClick={() =>
                  navigate(isAuth ? '/user-dashboard' : '/login', {
                    state: { activeTab: 2 },
                  })
                }
                className="flex-col justify-center items-center cursor-pointer"
              >
                <div className="ml-2.5">
                  <FaRegHeart size={24} />
                </div>
                <span className="">Shortlist</span>
              </div>
            )}

            {isAuth ? (
              <div className="flex justify-between items-center md:gap-4 order-last gap-2.5">
                <Link to={redirectTo}>
                  <div className="flex justify-center  items-center">
                    <MdDashboard size={24} />
                  </div>
                  <span className="cursor-pointer">Dashboard</span>
                </Link>

                <Link to="/logout">
                  <div className="flex justify-center  items-center">
                    <IoIosLogOut size={24} />
                  </div>
                  <span className="cursor-pointer">Logout</span>
                </Link>
              </div>
            ) : (
              <div className="flex-col justify-center items-center ">
                <Link to={'/login'}>
                  <div className="ml-2.5">
                    <RxAvatar size={24} />
                  </div>
                  <span className="cursor-pointer"> Sign in</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuthenticated,
    redirectTo: state.auth.redirectTo,
    publicSetting: state.setting.publicSetting,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPublicSettingSingle: () => dispatch(fetchPublicSettingSingle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
