import React, { useEffect, useState } from 'react';
// import { MdOutlineCancel } from 'react-icons/md';
import Select from 'react-select';

// import RangeSlider from '../../../components/site/RangeSlider/RangeSlider';
import TogglerHandle from './../../../components/site/TogglerHandle/TogglerHandle';
import {
  yearOptions,
  carPriceRanges,
  mileageRanges,
  engineCapacity,
} from './../../../constants/index';

const FilterBlock = ({
  filters,
  brandList,
  togglerData,
  handleCheckBoxFilterSelect,
  handleClearAll,
  clearAll,
  carModelList,
  handleDropDownFilterSelect,
  handleYearRangeFilterChange,
  queryParams,
}) => {
  const [minYear, setMinYear] = useState('');
  const [maxYear, setMaxYear] = useState('');

  const [minYearValue, setMinYearValue] = useState('');
  const [maxYearValue, setMaxYearValue] = useState('');
  const [modelData, setModelData] = useState([]);
  const [milageOption, setMilageOption] = useState();
  const [engineCapacityOption, setEngineCapacityOption] = useState();

  // useEffect(() => {
  // 	// if (clearAll) {
  // 		setMinYearValue('')
  // 		setMaxYearValue('')
  // 		setMilageOption('')
  // 		setEngineCapacityOption('')

  // 		console.log(minYearValue);
  // 		console.log(maxYearValue);
  // 		console.log(modelData);
  // 		console.log(milageOption);
  // 		console.log(engineCapacityOption);
  // 	// }
  // }, [clearAll])

  // brand
  useEffect(() => {
    if (
      queryParams['brand_id'] !== '' &&
      queryParams['brand_id'] !== undefined
    ) {
      const filteredBrands = brandList?.find(
        (brand) => brand?.value == queryParams['brand_id']
      );
      if (filteredBrands !== undefined) {
        const filteredModels = carModelList?.filter(
          (model) => model?.brand === filteredBrands.value
        );
        setModelData(filteredModels);
      }
    }
  }, [queryParams['brand_id']]);

  // console.log(queryParams)

  useEffect(() => {
    handleYearRangeFilterChange(minYear, maxYear);
  }, [minYear, maxYear]);

  useEffect(() => {
    if (
      queryParams['year_range'] !== '' &&
      queryParams['year_range'] !== undefined &&
      minYear === '' &&
      maxYear === ''
    ) {
      let option = {
        label: queryParams['year_range'],
        value: queryParams['year_range'],
      };
      setMinYearValue(option);
      setMaxYearValue(option);
    }
  }, [queryParams['year_range']]);

  const handleBrandChange = (selectedOption) => {
    const filteredModels = carModelList?.filter(
      (model) => model?.brand === selectedOption?.value
    );
    setModelData(filteredModels);
    handleDropDownFilterSelect(selectedOption, 'brand');
  };

  const handleModelChange = (selectedOption) => {
    handleDropDownFilterSelect(selectedOption, 'model');
  };

  const handlePriceRange = (selectedOption) => {
    handleDropDownFilterSelect(selectedOption, 'price');
  };

  const handleMilageRange = (selectedOption) => {
    setMilageOption(selectedOption);
    handleDropDownFilterSelect(selectedOption, 'milage');
  };

  const handleEngineCapacityRange = (selectedOption) => {
    setEngineCapacityOption(selectedOption);
    handleDropDownFilterSelect(selectedOption, 'engine_capacity');
  };

  const handleMinYearChange = (selectedOption) => {
    setMinYear(selectedOption.value);
    setMinYearValue(selectedOption);
  };

  const handleMaxYearChange = (selectedOption) => {
    setMaxYear(selectedOption.value);
    setMaxYearValue(selectedOption);
  };

  const handleClearFilterAction = () => {
    handleClearAll();
    setMinYearValue('');
    setMaxYearValue('');
    setMilageOption('');
    setEngineCapacityOption('');
  };

  return (
    <>
      <div className="flex justify-between mt-5">
        <p className="text-[#666] text-2xl font-bold">Filters</p>
        <p
          className="text-[#666] opacity-60 cursor-pointer"
          onClick={handleClearFilterAction}
        >
          Clear all
        </p>
      </div>
      <div className="flex flex-wrap w-60 my-3">
        {filters.map((filter, index) => (
          <span className="" key={index}>
            <div className="flex justify-start items-center  bg-[#5cb7ec52] w-fit px-3 rounded-full py-1 m-1 ">
              <span className="mr-3">{filter}</span>
              {/* <MdOutlineCancel className="inline-block cursor-pointer" /> */}
            </div>
          </span>
        ))}
      </div>

      <div className="my-3 ">
        <div>
          <p className="text-[#666] text-2xl font-bold my-2">Make & Model</p>
          <Select
            options={brandList}
            value={
              brandList &&
              brandList?.filter(
                (option) => option?.value == queryParams['brand_id']
              )
            }
            type="text"
            id="brand_id"
            placeholder={'Any make'}
            className=" my-2 shadow-sm sm:text-sm"
            onChange={(selectedOption) => handleBrandChange(selectedOption)}
          />
          <Select
            options={modelData}
            value={
              modelData &&
              modelData.filter(
                (option) => option?.value == queryParams['model_id']
              )
            }
            type="text"
            id="model_id"
            placeholder={'Any make'}
            className=" my-2 shadow-sm sm:text-sm"
            onChange={(selectedOption) => handleModelChange(selectedOption)}
          />
        </div>
        <div>
          <p className="text-[#666] text-2xl font-bold my-2">Year</p>
          <div className="flex justify-between items-center">
            <Select
              options={yearOptions}
              value={minYearValue}
              type="text"
              id="minYear"
              // placeholder={'1900'}
              className="w-3/6 mr-2 my-2 shadow-sm sm:text-sm"
              onChange={(selectedOption) => handleMinYearChange(selectedOption)}
            />
            <span>—</span>
            <Select
              options={yearOptions}
              value={maxYearValue}
              type="text"
              id="maxYear"
              // placeholder={'2024'}
              className="w-3/6 mx-2 my-2 shadow-sm sm:text-sm"
              onChange={(selectedOption) => handleMaxYearChange(selectedOption)}
            />
          </div>
        </div>

        <div>
          <p className="text-[#666] text-2xl font-bold my-2">Price</p>
          <Select
            options={carPriceRanges}
            value={
              carPriceRanges &&
              carPriceRanges.filter(
                (option) => option?.value == queryParams['price_range']
              )
            }
            type="text"
            id="price"
            placeholder={'Select Price Range'}
            className=" my-2 shadow-sm sm:text-sm"
            onChange={(selectedOption) => handlePriceRange(selectedOption)}
          />
        </div>
        <div>
          <p className="text-[#666] text-2xl font-bold my-2">Milage</p>
          <Select
            options={mileageRanges}
            value={
              milageOption &&
              mileageRanges &&
              mileageRanges.filter(
                (option) => option?.value == milageOption.value
              )
            }
            type="text"
            id="milage"
            placeholder={'Select Milage Range'}
            className=" my-2 shadow-sm sm:text-sm"
            onChange={(selectedOption) => handleMilageRange(selectedOption)}
          />
        </div>

        <div>
          <p className="text-[#666] text-2xl font-bold my-2">Engine Capacity</p>
          <Select
            options={engineCapacity}
            value={
              engineCapacityOption &&
              engineCapacity &&
              engineCapacity.filter(
                (option) => option?.value == engineCapacityOption?.value
              )
            }
            type="text"
            id="engine_capacity"
            placeholder={'Select Engine Capacity'}
            className=" my-2 shadow-sm sm:text-sm"
            onChange={(selectedOption) =>
              handleEngineCapacityRange(selectedOption)
            }
          />
        </div>

        {togglerData?.map((item, index) => (
          <TogglerHandle
            key={index}
            arrayName={item?.arrayName}
            label={item?.label}
            isOpen={item?.isOpen}
            title={item?.title}
            id={item?.id}
            onSelectValue={(selectedValues) =>
              handleCheckBoxFilterSelect(item?.id, selectedValues)
            }
            clearAll={clearAll}
            queryData={queryParams[item?.id]}
          />
        ))}
      </div>
    </>
  );
};

export default FilterBlock;
