import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/admin/Layout/Base/Layout';
import CustomerForm from './CustomerUserForm';
import SectionHeader from '../../../../components/admin/SectionHeader/SectionHeader';
import CustomerTable from '../../../../components/admin/Table/Table';
import {
	fetchCustomerList,
	createCustomer,
	fetchCustomerSingle,
	updateCustomer,
} from '../../../../store/actions';
import Loader from '../../../../components/common/Loader/Loader';
import ActionButton from '../../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';


const Customer = (props) => {
	const { loading, error, success, customerList, fetchCustomerList, createCustomer, fetchCustomerSingle, singleCustomer, updateCustomer} = props;

	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1);

	useEffect(() => {
		setShowTable(true)
	}, [customerList])

	useEffect(() => {
		fetchCustomerList(page)
	}, [fetchCustomerList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchCustomerList(page)
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {
					// setShowTable(true)
					// setButtonLabel('Add')
				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id)
			setShowTable(false)
			setButtonLabel('List')
			fetchCustomerSingle(id)
		} else if (action === 'delete') {
			// deleteVehicleType(id);
		}
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null)
	};

	const onPageChange = (page) => {
		setPage(page)
	};

	const onSubmit = (values) => {
		if (editingId) {
			updateCustomer(editingId, values, 'customer')
		} else {
			createCustomer(values, 'customer');
		}
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row.username,
			sortable: true
		},
		{
			name: 'Email',
			selector: row => row.email,
			sortable: true
		},
		{
			name: 'Phone',
			selector: row => row.contact_no,
			sortable: false
		},
		{
			name: 'Status',
			selector: row => row.status ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					showDeleteButton={false}
					onDelete={id => handleEditDelete(id, 'delete')}
				/>
			),
			sortable: false
		},
	];

	const renderContent = () => {
		if (loading) {
			return <Loader />	
		}

		if (showTable && !loading) {
			return (
				<CustomerTable
				defaultPage={page}
					columns={columns}
					data={customerList.rows == undefined ? [] : customerList.rows}
					totalCount={customerList.count}
					handlePageChange={onPageChange}
				/>
			);
		}

		if (editingId && !loading) {
			return (
				<CustomerForm
					onSubmit={onSubmit}
					initialValues={singleCustomer}
					onCancel={handleButtonClick}
					// countryList={countryList}
				/>
			);
		}

		if (!loading) {
			return <CustomerForm onSubmit={onSubmit} onCancel={handleButtonClick}  />;
		}
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Customer" buttonText={buttonLabel} onButtonClick={handleButtonClick} />
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.customer.loading,
		error: state.customer.error,
		success: state.customer.success,
		customerList: state.customer.customerList,
		singleCustomer: state.customer.singleCustomer,
		countryList: state.country.countryList,
		// cityList: state.city.cityList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCustomerList: (page) => dispatch(fetchCustomerList(page)),
		createCustomer: (data, type) => dispatch(createCustomer(data, type)),
		updateCustomer: (id, data, type) => dispatch(updateCustomer(id, data, type)),
		fetchCustomerSingle: (id) => dispatch(fetchCustomerSingle(id)),
		// fetchCountryList: (page, isProcess) => dispatch(fetchCountryList(page, isProcess)),
		// fetchCityList: (page, isProcess, countryId) => dispatch(fetchCityList(page, isProcess, countryId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
