import React from 'react'

const AdType = ({ad}) => {
  return (
    <div className={` h-[24px] flex flex-row justify-center items-center border border-1 rounded ${ad?.toLowerCase() == 'import used' ? 'bg-[#D9F7BE] border-[#73D13D]' : ad?.toLowerCase() == 'premium' ? 'bg-[#E6F4FF] border-[#91CAFF]' : 'bg-[#FFE7BA] border-[#FFC069]'}`}>
        <p className={`text-[12px] font-normal px-4  ${ad?.toLowerCase() == 'import used' ? 'text-[#73D13D]' : ad?.toLowerCase() == 'premium' ? 'text-[#91CAFF]' : 'text-[#FFC069]'}`}>{ad}</p>
    </div>
  )
}

export default AdType;