import * as yup from 'yup';

const allowedExtensions = ['jpg', 'jpeg', 'png'];

const CustomerSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	company_name: yup.string().required('Company Name is required'),
	company_uen: yup.string().required('Company UEN is required'),
	email: yup.string().email('Invalid email address').required('Email is required'),
	contact_no: yup.string().required('Phone number is required').min(8, 'Phone number must be at least 8 characters'),
	company_url:yup.string().optional(),
	company_phone: yup.string().required('Phone number is required').min(8, 'Phone number must be at least 8 characters'),

	address_one: yup.string().required('Address is required'),
	address_two: yup.string(),
	status: yup.string().required('Status is required'),
	approve_status: yup.string().required('Approve Status is required'),
	country_id: yup.string().required('Country is required'),
	city_id: yup.string().required('City is required'),
	postal_code: yup.string().required('Postal code is required').min(4, 'Should be at least 4 characters'),
	image: yup
		.mixed()
		.when('isImageRequired', (isImageRequired) => isImageRequired[0] === undefined ? yup.mixed() : yup.mixed().required('Image is required'))
		.test('fileType', 'Invalid file type. Please select a valid image (JPEG, JPG, PNG).', (value) => {
			if (!value) {
				return true;
			}
			const fileExtension = value.name.split('.').pop().toLowerCase();
			return allowedExtensions.includes(fileExtension);
		}),
});

export default CustomerSchema;
