import React from 'react';

const FullWidthSection = ({ title, bgColor }) => {
  return (
    <div className={`bg-[#0534FF61] text-white flex items-center h-45`}>
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-4 overflow-hidden">{title}</h2>
      </div>
    </div>
  );
};

export default FullWidthSection;
