// Router.js
import React from 'react';
import PublicRoute from "./Public";
import PrivateRoute from "./Private";

const AppRouter = (props) => {
	return (
		<>
			<PublicRoute isAuth={props.isAuthenticated} />
			{props.isAuthenticated && <PrivateRoute />}
		</>
	);
};

export default AppRouter;
