import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


import Layout from '../../../components/admin/Layout/Base/Layout';
import ColorForm from './ColorForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import ColorTable from '../../../components/admin/Table/Table';
import { fetchColorList, createColor, deleteColor, fetchColorSingle, updateColor } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';
// import Alert from '../../../components/common/Alert/Alert';
import ActionButton from '../../../components/admin/ActionButton/ActionButton';
import { toast } from 'react-toastify';


const Colors = (props) => {
	const {
		loading,
		error,
		success,
		colorList,
		fetchColorList,
		createColor,
		deleteColor,
		fetchColorSingle,
		singleColor,
		updateColor
	} = props;

	const [showTable, setShowTable] = useState(false);
	const [buttonLabel, setButtonLabel] = useState('Add');
	const [editingId, setEditingId] = useState(null);
	const [page, setPage] = useState(1)

	useEffect(() => {
		setShowTable(true)
	}, [colorList])

	useEffect(() => {
		fetchColorList(page);
	}, [fetchColorList, page]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchColorList(page);
					setShowTable(true)
					setButtonLabel('Add')
				},
				onClose: () => {
				}
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const handleEditDelete = (id, action) => {
		if (action === 'edit') {
			setEditingId(id)
			setShowTable(false)
			setButtonLabel('List')
			fetchColorSingle(id)
		} else if (action === 'delete') {
			deleteColor(id);
		}
	};

	const handleButtonClick = () => {
		setShowTable(!showTable);
		setButtonLabel(showTable ? 'List' : 'Add');
		setEditingId(null)
	};

	const onPageChange = (page) => {
		setPage(page)
	};

	const onSubmit = (values) => {
		if (editingId) {
			updateColor(editingId, values)
		} else {
			createColor(values);
		}
	};

	const columns = [
		{
			name: 'Id',
			selector: (row, index) => index + 1,
			sortable: true
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true
		},
		{
			name: 'Status',
			selector: row => row.status ? 'Active' : 'Inactive',
			sortable: false
		},
		{
			name: 'Actions',
			selector: (row) => (
				<ActionButton
					row={row}
					onEdit={id => handleEditDelete(id, 'edit')}
					showDeleteButton={true}
					onDelete={id => handleEditDelete(id, 'delete')}
				/>
			),
			sortable: false
		},
	];

	const renderContent = () => {
		if (showTable && !loading) {
			return (
				<ColorTable
				defaultPage={page}
					columns={columns} data={colorList.rows == undefined ? [] : colorList.rows} totalCount={colorList.count} handlePageChange={onPageChange}
				/>
			);
		}

		if (editingId && !loading) {
			return <ColorForm onSubmit={onSubmit} initialValues={singleColor} onCancel={handleButtonClick} />;
		}

		if (!loading) {
			return <ColorForm onSubmit={onSubmit} onCancel={handleButtonClick} />;
		}

		return <Loader />

	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Colors" buttonText={buttonLabel} onButtonClick={handleButtonClick} />
						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.color.loading,
		error: state.color.error,
		success: state.color.success,
		colorList: state.color.colorList,
		singleColor: state.color.singleColor
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchColorList: (page) => dispatch(fetchColorList(page)),
		createColor: (data) => dispatch(createColor(data)),
		deleteColor: (id) => dispatch(deleteColor(id)),
		fetchColorSingle: (id) => dispatch(fetchColorSingle(id)),
		updateColor: (id, data) => dispatch(updateColor(id, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Colors);
