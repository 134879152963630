import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const MenuTabs = ({ tabs , setTabNo}) => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.activeTab) {
      setActiveTab(location?.state?.activeTab);
    setTabNo(location?.state?.activeTab)

    } else {
      setActiveTab(0);
    setTabNo(0)

    }
  }, []);
  const handleTabClick = (index) => {
    setActiveTab(index);
    setTabNo(index)
    console.log(index);
  };

  return (
    <div className="">
      {tabs.length > 0 ? (
        <div className="w-full ">
          <div className="w-full  text-center  bg-[#F1F1F1] ">
            <div className="overflow-x-auto flex justify-start md:justify-center pt-2">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className={`  mx-4 md:mx-4 cursor-pointer p-2 mx-2 font-bold ${
                    index === activeTab
                      ? 'border-b-2 border-[#0534FF] bg-blue-500 text-[#0534FF]'
                      : ''
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  <p className=" w-[140px] md:w-fit">{tab.label}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="my-6">{tabs[activeTab].content}</div>
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default MenuTabs;
