import React, { useEffect } from 'react';
import Layout from '../../../components/site/Layout/Base/Layout';
import HeroBox from '../../../components/site/CarsComponents/HeroBox';
import newCar from '../../../assets/images/web/newCarHero.webp';
import vehicleBanner from '../../../assets/images/web/vehicleBanner.png';
import offerday from '../../../assets/images/web/offerday.png';
import hondaImage from '../../../assets/images/web/honda_crv_banner.png';
import RecommendedByOurTeam from '../../../components/site/UsedCarComponents/RecommendedByOurTeam';
import SendMeGreatCarOffers from '../../../components/site/UsedCarComponents/SendMeGreatCarOffers';
import FAQ from '../../../components/site/UsedCarComponents/FAQ';
import FollowOnSocialMedia from '../../../components/site/FollowOnSocialMedia/FollowOnSocialMedia';
import BrowseByBrand from '../../../components/site/BrowseByBrand/BrowseByBrand';
import {
  fetchBrandList,
  fetchBrandPublicList,
  fetchCarModelList,
  fetchVehicleTypePublicList,
} from '../../../store/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
const NewCarHome = (props) => {
  const navigate = useNavigate();
  const {
    fetchBrandList,
    brandList,
    fetchBrandPublicList,
    brandPublicList,
    fetchCarModelList,
    carModelList,
    vehicleTypePublicList,
    fetchVehicleTypePublicList,
  } = props;
  const fetchData = async () => {
    await Promise.all([
      fetchBrandList('all', true, true),
      fetchBrandPublicList('all', true, true),
      fetchCarModelList('all', true, true),
    ]);
  };
  // console.log(carModelList);
  useEffect(() => {
    fetchData();
  }, [true]);

  const searchHandler = (value) => {
    navigate(
      `/searched_new_cars?brand_id=${value.brand_id}&model_id=${value.model_id}&year_range=${value.year_range}&price_range=${value.price_range}&vehicle_id=${value.vehicle_id}&depreciation_range=${value.depreciation_range}`
    );
  };

  const accordionData = [
    {
      title: 'Where can I find new car deals?',
      accordionKey: 1,
      content:
        'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.',
    },
    {
      title: 'How do I get the best deal on a new car?',
      accordionKey: 2,
      content: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam
    recusandae placeat esse illo vitae tenetur pariatur hic quidem
    blanditiis sunt.`,
    },
    {
      title: 'Are new cars cheaper to insure?',
      accordionKey: 3,
      content: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam
    recusandae placeat esse illo vitae tenetur pariatur hic quidem
    blanditiis sunt.`,
    },
    {
      title: 'Where can I get insurance for my new car?',
      accordionKey: 4,
      content: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam
    recusandae placeat esse illo vitae tenetur pariatur hic quidem
    blanditiis sunt.`,
    },
  ];

  return (
    <Layout>
      <div className="relative">
        <HeroBox
          newCar
          title="NEW CARS"
          img={newCar}
          carModelList={carModelList}
          vehicleData={vehicleTypePublicList}
          brandList={brandPublicList}
          onSearch={searchHandler}
        />
      </div>
      <BrowseByBrand brandList={brandList} newCar />
      <div className="container mx-auto ">
        {/* <div className="container mx-auto mt-[500px] md:mt-[35] lg:mt-3"> */}
        <img src={vehicleBanner} alt="" className="my-5 w-full" />
        <img src={hondaImage} alt="" className="my-5 w-full" />
        <img src={offerday} alt="" className=" w-full" />
      </div>
      <div>
        <RecommendedByOurTeam
          title="Latest reviews by our experts"
          dec={'Watch short videos or read in-depth reviews from Car Inc team.'}
        />
      </div>
      <SendMeGreatCarOffers />
      <FAQ
        title="Frequent Asked Question for New Car"
        accordionData={accordionData}
      />
      <FollowOnSocialMedia />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    brandList: state.brand.brandList,
    brandPublicList: state.brand.brandPublicList,
    carModelList: state.carModel.carModelList,
    vehicleTypePublicList: state.vehicleType.vehicleTypePublicList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVehicleTypePublicList: () =>
    dispatch(fetchVehicleTypePublicList()),
    fetchBrandList: (page, isProcess, isPublic) =>
      dispatch(fetchBrandList(page, isProcess, isPublic)),
    fetchBrandPublicList: (page) => dispatch(fetchBrandPublicList(page)),
    fetchCarModelList: (page, isProcess, isPublic) =>
      dispatch(fetchCarModelList(page, isProcess, isPublic)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCarHome);
