import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Layout from '../../../components/admin/Layout/Base/Layout';
import CompanySettingForm from './CompanySettingForm';
import SectionHeader from '../../../components/admin/SectionHeader/SectionHeader';
import { fetchPublicSettingSingle, fetchSettingSingle, updateSetting } from '../../../store/actions';
import Loader from '../../../components/common/Loader/Loader';

import { toast } from 'react-toastify';

const CompanySetting = (props) => {
	const {
		loading,
		error,
		success,
		updateSetting,
		fetchSettingSingle,
		singleSetting,
		fetchPublicSettingSingle
	} = props;


	useEffect(() => {
		fetchSettingSingle()
	}, [fetchSettingSingle]);

	useEffect(() => {
		if (success) {
			toast.success(success, {
				position: toast.POSITION.TOP_RIGHT,
				onOpen: () => {
					fetchSettingSingle();
				},
			});
		}

		if (error) {
			toast.error(error, {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}, [success, error])

	const onSubmit = (values) => {
		updateSetting(values)
		setTimeout(() => {
			console.log('CLicked')
			fetchPublicSettingSingle()
		},2000)
	};


	const renderContent = () => {
		if (singleSetting) {
			return <CompanySettingForm onSubmit={onSubmit} initialValues={singleSetting} />;
		}
	};

	return (
		<Layout>
			<div className="grid grid-cols-1 gap-1 sm:grid-cols-1">
				<div className="flex flex-col gap-1">
					<div className="rounded-sm border border-stroke dark:border-strokedark dark:bg-boxdark p-10">
						<SectionHeader title="Company Setting" />
						{loading && <Loader />}

						{renderContent()}
					</div>
				</div>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.setting.loading,
		error: state.setting.error,
		success: state.setting.success,
		singleSetting: state.setting.singleSetting
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSettingSingle: (id) => dispatch(fetchSettingSingle(id)),
		updateSetting: (data) => dispatch(updateSetting(data)),
		fetchPublicSettingSingle: () => dispatch(fetchPublicSettingSingle()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetting);
