import React from 'react';
import ProductIfoLogo from '../../../assets/images/web/G-img.png';
import { Link } from 'react-router-dom';


const MarchantHeaderInfo = ({ merchantprofile }) => {
	const handleWhatsAppRedirect = () => {
		// Phone number or WhatsApp ID (replace with your contact)
		const predefinedMessage = encodeURIComponent("hi, I would like to know more about a car");

		let phoneNumber = ''
		if (merchantprofile?.type === 'customer') {
			phoneNumber = merchantprofile?.contact_no;
		}else if(merchantprofile?.type === 'merchant') {
			phoneNumber = merchantprofile?.company_phone;
		}		

		// Check if the user is using a mobile device
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

		// Construct the WhatsApp URL
		// const whatsappURL = isMobile
		// 	? `whatsapp://send?phone=${phoneNumber}`
		// 	: `https://web.whatsapp.com/send?phone=${phoneNumber}`;

		const whatsappURL = isMobile
			? `https://wa.me/${phoneNumber}?text=${predefinedMessage}`
			: `https://wa.me/${phoneNumber}?text=${predefinedMessage}`;

		// Redirect to the WhatsApp URL
		window.open(whatsappURL, '_blank');
	};

	return (
		<div className="w-full lg:w-1/2 p-4 ">
			<div className="w-full p-2 md:p-0 lg:p-0">
				<div className="w-fit">
					<p className="text-[24px]">{merchantprofile?.company_name}</p>
				</div>
				<div className="bg-[#CACACA] h-[2px] my-4"></div>
				<div className="mt-2">
					<p className="text-[16px]">{merchantprofile?.address_one}</p>
					<p className="text-[16px]">{merchantprofile?.address_two}</p>
				</div>

				<div className="mt-13">
					{merchantprofile?.type === 'merchant' && (
						<p>
							Website :{' '}
							{merchantprofile?.company_url ? (
								<Link to={merchantprofile.company_url} className="text-[16px] mx-3">
									{merchantprofile.company_url}
								</Link>
							) : (
								''
							)}
						</p>
					)}

					<p className="text-[16px]">
						Phone :{' '}
						<span className="mx-4">
							{merchantprofile?.type === 'merchant' && merchantprofile?.company_phone && (
								`+65 ${merchantprofile.company_phone}`
							)}
							{merchantprofile?.type !== 'merchant' && merchantprofile?.contact_no}
						</span>
					</p>

				</div>

				<div className="flex flex-row justify-start items-center mt-17">
					<img
						className="w-[70px] h-[70px]"
						src={
							merchantprofile?.image ? merchantprofile?.image : ProductIfoLogo
						}
					/>
					<div
						onClick={handleWhatsAppRedirect}
						className="bg-[#0534FF] w-full h-[40px] flex flex-row justify-center items-center rounded-sm cursor-pointer ms-4"
					>
						<p className="text-white text-[16px] font-bold mr-2">
							Message merchant
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MarchantHeaderInfo;
