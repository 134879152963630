import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CiHeart } from 'react-icons/ci';
import { FaHeart } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import placeholderImage from '../../../assets/images/car_slider_placeholder.png';
import { depreciationCalc } from '../../../helper/depreciationCalc';
import { registrationTimeLeft } from '../../../helper/regRemainingDayCalc';
import AdType from '../ProductComponents/AdType';

const SearchCar = ({ car, shortlistHandler, userData, shortlistedListData }) => {
  const navigate = useNavigate();
  const [imageList, setImageList] = useState([])

  const ProductDetailsHandler = () => {
    navigate(`/used-car-product/${car?.id}`);
  };

  const merchantProfileNavigate = () => {
    navigate(`/usedcar-merchant-profile/${car?.user_id}`);
  };

  useEffect(() => {
    setImageList(JSON.parse(car?.images))
  }, [car])


  return (
    <div className="grid lg:grid-cols-5 grid-cols-1 gap-2 h-fit border-[#E8E7E6] border  w-full  shadow-lg p-3 my-3">
      <div className="w-full flex justify-start items-center gap-1  col-span-3 md:col-span-2 ">
        {/* <Link className="block flex-shrink-0" to="/used-car-product"> */}
        <div className="w-full">
          <img
            onClick={ProductDetailsHandler}
            src={
              imageList?.[0]?.image
                ? imageList?.[0]?.image
                : placeholderImage
            }
            alt=""
            className="slider_img  cursor-pointer"
          />
        </div>
        {/* </Link>  */}
        {/* <div className="w-[150px] h-fit hidden md:block">
            <div className='h-25'>
                <img
                src={
                  car?.car_listing_images?.[1]?.image
                    ? car?.car_listing_images?.[1]?.image
                    : placeholderImage
                } 
                alt=""
                className="search_img cursor-pointer"
              />
            </div>

            <div className='h-25'>
            <img
            src={
              car?.car_listing_images?.[2]?.image
                ? car?.car_listing_images?.[2]?.image
                : placeholderImage
            }
            alt=""
            className="search_img cursor-pointer"
          />
            </div>

            <div className='h-25'>
                <img
                src={
                  car?.car_listing_images?.[3]?.image
                    ? car?.car_listing_images?.[3]?.image
                    : placeholderImage
                }
                alt=""
                className=" search_img cursor-pointer"
              />
            </div>
        </div> */}
        <div className="w-[150px] h-fit hidden md:block">
          {[...Array(4)].map((_, index) => {
            // Skip rendering the first item
            if (index === 0) {
              return null;
            }

            const image = imageList?.[index];
            return (
              <div className='h-25' key={index}>
                <img
                  src={image?.image ? image.image : placeholderImage}
                  alt=""
                  className="search_img cursor-pointer"
                />
              </div>
            );
          })}
        </div>


      </div>

      <div className=" max-h-fit col-span-3  px-3">
        <div className="flex flex-row justify-start items-center mt-1">
          {car?.is_feature === 1 && (
            <div className="flex flex-row justify-center items-center border border-1 rounded bg-[#0534FF] text-white h-[24px] border-[#0534FF] mr-4">
              <p className="text-[12px] font-normal px-4 ">Featured</p>
            </div>
          )}
          {car?.ads_type?.name && <AdType ad={car?.ads_type?.name} />}
        </div>
        <div className="grid grid-cols-4 items-center gap-2 my-1">
          <div className="flex  items-center col-span-3">
            <input
              type="checkbox"
              name=""
              id=""
              className="w-5 h-5 mr-1"
              size={80}
            />
            <span className="text-2xl font-bold w-full mx-2">
              <Link
                className="block flex-shrink-0 "
                to={`/used-car-product/${car?.id}`}
              >
                {car.car_model ? car.car_model?.name : '-'}
              </Link>
            </span>
          </div>
          {
            userData?.id ? <div onClick={() => shortlistHandler(car?.id)} className="flex ms-auto items-center gap-2 cursor-pointer">
              {
                shortlistedListData?.find((item) => item?.car_listing_id == car?.id)?.id ? <FaHeart size={20} color="red" /> : <CiHeart size={20} color="#2A65BA" />
              }
            </div> : <div onClick={() => navigate('/login')} className="flex ms-auto items-center gap-2 cursor-pointer">
              <CiHeart size={20} color="#2A65BA" />
            </div>

          }

        </div>
        <p className="text-2xl font-bold text-[#FF0000]">
          {
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            })
              .format(car?.price)
              .split('.')[0]
          }
        </p>
        <div>
          <p className="my-2 truncate ">{car?.desc}</p>
          <p className="mb-5">
            Depre: {depreciationCalc(car?.is_forFeited, car?.price, car?.arf, car?.coe_expiry) ? `$ ${depreciationCalc(car?.is_forFeited, car?.price, car?.arf, car?.coe_expiry)}` : "0"} / year |{' '}
            {car?.no_of_owner} owner(s) |{' '}
            {car?.engine_capacity?.toLocaleString()} cc{' '}
            {car?.mileage ? (
              <span>| {car?.mileage?.toLocaleString()} km </span>
            ) : (
              ''
            )}{' '}
            {car?.vehicle_type ? <span>| {car?.vehicle_type?.name}</span> : ''}
          </p>
          <p>
            Reg Date:{' '}
            {car?.registration_date
              ? moment(car?.registration_date).format('DD MMMM YYYY')
              : ''}
          </p>
          <p className="text-[#242D3D] text-sm my-1 mb-5">
            {/* {car.coe_expiry
                ?  moment
                    .duration(moment(car.coe_expiry).diff(moment()))
                    .humanize()
                : 'COE expiry date not available'} */}
            (
            {car?.coe_expiry
              ? registrationTimeLeft(car?.coe_expiry)
              : 'COE expired'}
            )
          </p>
        </div>
        {/* <div className="grid grid-cols-2 items-center ">
          <div>
            <div className="flex justify-between items-center">
              <p className="my-2">Depreciation: $ {car.depreciation} / year</p>
            </div>

            <p className="my-3 ">Mileage: {car.mileage}</p>
          </div>
          <div>
            <p className="mt-2">No. of owner: {car.no_of_owner}</p>
            <p className="mt-2">Engine Cap: {car.engine_capacity} cc</p>
            {car.vehicle_type_id && (
              <p className="mt-5">Vehicle Type: {car.vehicle_type?.name}</p>
            )}
          </div>
        </div> */}
        <hr className="border-[#E8E7E6] border  mb-2" />
        <div className="flex justify-between items-center gap-8 mb-1">
          <p onClick={merchantProfileNavigate} className="cursor-pointer my-2">
            {car.user && car.user?.user_company !== null
              ? car.user.user_company.name
              : car.user?.user_details.first_name}
            - <span className="text-[#2A65BA]">See all {car?.user?.carCount} cars</span>
          </p>
          <div className="w-[46px] ">
            {car.user && car.user?.user_company !== null ? (
              <img
                className="cursor-pointer"
                onClick={merchantProfileNavigate}
                src={car?.user?.image}
                alt=""
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCar;
