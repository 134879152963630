import React, { useState } from 'react';
import QR from '../../../assets/images/web/QR.png';
const PayNowQR = ({ data, onSubmit, loading }) => {
  console.log(data.totalPrice);
  const [refId, setRefId] = useState('');
  const [error, setError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState('');

  const handlePayment = () => {
    console.log(refId);
    if (refId) {
      setError('');
      onSubmit(refId);
      setButtonDisabled('Loading...');
    } else {
      setError('Reference ID is required');
    }
  };
  return (
    <div className="">
      <div className="container mx-auto my-15">
        <h1 className="text-center text-5xl font-extrabold p-4 my-4">
          PayNow QR
        </h1>
        <p className="text-center mb-5 text-xl">
          To complete your payment, please follow the steps below:
        </p>
        <ol className="p-4 w-fit mx-auto  text-2xl my-10">
          <li>1. Scan QR on your PayNow supported App</li>
          <li>2. Once payment is made, input payment reference ID</li>
          <li>
            3. Click on “Complete Payment” to complete the payment process
          </li>
        </ol>
        <div className="text-center w-2/5 mx-auto">
          <img src={QR} alt="" className="mx-auto" />
          <p className="font-bold text-3xl my-3">
            S$ {(Math.round(data.totalPrice * 100) / 100).toFixed(2)}
          </p>
        </div>
        <div className="mx-auto text-center my-10">
          <label htmlFor="" className="text-start text-xl ">
            Payment Reference No <span className="text-[#B11212]">*</span>
          </label>
          <br />
          <div className="my-3">
            <input
              onChange={(e) => setRefId(e.target.value) + setError('')}
              type="text"
              className="border border-[#b1aeac] w-4/5 md:w-2/6 my-2 mx-auto block p-1"
              required
            />
            <p className="text-[#b1aeac]">
              Add car plate number as payment reference no
            </p>
          </div>
          {error && (
            <>
              <p className="text-red font-semibold">{error}</p>
            </>
          )}
          <button
            className="bg-[#1677FF] mt-5 text-white px-6 py-2 rounded"
            onClick={handlePayment}
            disabled={buttonDisabled}
          >
            {loading ? 'Loading' : 'Complete Payment'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayNowQR;
