import React, { useEffect } from 'react';
import EditPayment from './EditPayment';
import { connect } from 'react-redux';
import { fetchOrderSingle } from '../../../store/actions';

const ViewPayment = (props) => {
  const { fetchOrderSingle, editItem, paymentItem, loading } = props;
  console.log('editItem >>>', editItem);
  useEffect(() => {
    fetchOrderSingle(paymentItem?.id);
  }, [paymentItem?.id]);
  const addTypeArr = [
    {
      id: 'normal_ads',
      label: 'Normal Ads',
      name: 'add_type',
      value: '4',
    },
    {
      id: 'feature_ads',
      label: 'Feature ads',
      name: 'add_type',
      value: '5',
    },
    {
      id: 'premium_ads',
      label: 'Premium ',
      name: 'add_type',
      value: '1',
    },
    {
      id: 'import_used_ads',
      label: 'Import Used ',
      name: 'add_type',
      value: '2',
    },
    {
      id: 'consignment_ads',
      label: 'Consignment ',
      name: 'add_type',
      value: '3',
    },
  ];
  let add_OnArr = [
    { id: 'none', label: 'None', name: 'service_type', value: '3' },
    {
      id: 'photo_service',
      label: 'Photography Services',
      name: 'service_type',
      value: '1',
    },
    {
      id: 'photo__video_service',
      label: 'Photography & Videography',
      name: 'service_type',
      value: '2',
    },
  ];
  return (
    <div className="">
      <EditPayment editItem={editItem} isSelectDesabled={true} />
      <div className="my-10 px-6 overflow-x-auto">
        <table className="w-full table-auto  ps-4">
          <thead className="">
            <tr className="border-b border-[#E8E7E6]">
              <th className="py-2 text-start   w-fit md:w-[900px]  ">Item</th>
              <th className="py-2 text-start px-4 ">Cost</th>
              <th className="py-2 text-start px-4 ">Quantity</th>
              <th className="py-2 text-start px-4 ">Total</th>
            </tr>
          </thead>
          <tbody>
            {editItem?.order_items?.map((item) => (
              <tr className="border-b border-[#E8E7E6]  px-4 ">
                <td className=" py-2">
                  {item?.type == 'add_on'
                    ? add_OnArr?.find((addon) => addon?.value == item?.item_id)
                        ?.label
                    : item?.type == 'add_type'
                    ? addTypeArr?.find(
                        (addtype) => addtype?.value == item?.item_id
                      )?.label
                    : ''}
                </td>

                <td className="py-2  px-4 ">
                  ${parseFloat(item?.price).toFixed(2)}
                </td>

                <td className=" py-2  px-4 ">
                  <p className="">1</p>
                </td>

                <td className=" py-2  px-4 ">
                  ${parseFloat(item?.price).toFixed(2)}
                </td>
              </tr>
            ))}

            <tr>
              <td className=" py-2  px-4 "></td>

              <td className=" py-2  px-4 "></td>
              <td className=" py-2  px-4 ">Subtotal</td>

              <td className=" py-2  px-4 ">
                {/* ${parseFloat(editItem?.sub_total_amount)?.toFixed(2)}$ */}$
                {editItem?.order_items
                  .reduce((sum, order) => sum + order.price, 0)
                  .toFixed(2)}
              </td>
            </tr>

            <tr>
              <td className=" py-2  px-4 "></td>

              <td className=" py-2  px-4 "></td>
              <td className=" py-2  px-4 ">
                Coupon{' '}
                {loading ? (
                  '0.00'
                ) : (
                  <>{editItem?.promo_codes?.type == 'percentage' ? '%' : '$'}</>
                )}
              </td>

              <td className=" py-2  px-4 ">
                {loading ? (
                  '0.00'
                ) : (
                  <>
                    {editItem?.promo_codes
                      ? `${parseFloat(
                          editItem?.promo_codes?.discount_amount
                        )?.toFixed(2)} `
                      : '0.00'}
                  </>
                )}
              </td>
            </tr>

            <tr>
              <td className=" py-2  px-4 "></td>

              <td className=" py-2  px-4 "></td>
              <td className=" py-2  px-4 ">Tax (0%)</td>

              <td className=" py-2  px-4 ">
                ${parseFloat(editItem?.tax_amount)?.toFixed(2)}
              </td>
            </tr>

            <tr>
              <td className=" py-2  px-4 "></td>

              <td className=" px-4  py-2"></td>
              <td className=" px-4  py-2">Total</td>

              <td className=" px-4  py-2">
                ${parseFloat(editItem?.total_amount)?.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    editItem: state.sitePayment.singleOrder,
    loading: state.sitePayment.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderSingle: (id) => dispatch(fetchOrderSingle(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPayment);
