import * as actionTypes from '../actions/actionTypes';

const initialState = {
	carCategoryList: [],
	singleCarCategory: null,
	loading: false,
	error: null,
	success: null,
};

const carCategoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CAR_CATEGORY_INIT:
			return {
				...state,
				loading: true,
				error: null,
				success: null,
			};
		case actionTypes.FETCH_CAR_CATEGORY_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				carCategoryList: action.payload,
				error: null,
			};
		case actionTypes.FETCH_CAR_CATEGORY_LIST_FAILURE:
		case actionTypes.FETCH_CAR_CATEGORY_SINGLE_FAILURE:
		case actionTypes.CREATE_CAR_CATEGORY_FAILURE:
		case actionTypes.UPDATE_CAR_CATEGORY_FAILURE:
		case actionTypes.DELETE_CAR_CATEGORY_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case actionTypes.FETCH_CAR_CATEGORY_SINGLE_SUCCESS:
			return {
				...state,
				loading: false,
				singleCarCategory: action.payload,
				error: null,
			};
		case actionTypes.CREATE_CAR_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				carCategoryList: state.carCategoryList,
				error: null,
				success: action.payload,
			};
		case actionTypes.UPDATE_CAR_CATEGORY_SUCCESS:
		case actionTypes.DELETE_CAR_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				success: action.payload,
			};
		default:
			return state;
	}
};

export default carCategoryReducer;
